import React from 'react';

const Fullscreen = ({ isFullscreen }: { isFullscreen: boolean }) =>
  !isFullscreen ? (
    <svg width="45" height="44" viewBox="0 0 45 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.502 14.5C13.9497 14.5 13.502 14.9477 13.502 15.5V19.5C13.502 20.0523 13.9497 20.5 14.502 20.5C15.0542 20.5 15.502 20.0523 15.502 19.5V16.5H20.002C20.5542 16.5 21.002 16.0523 21.002 15.5C21.002 14.9477 20.5542 14.5 20.002 14.5H14.502ZM13.502 28.5C13.502 29.0523 13.9497 29.5 14.502 29.5L20.002 29.5C20.5542 29.5 21.002 29.0523 21.002 28.5C21.002 27.9477 20.5542 27.5 20.002 27.5H15.502L15.502 24.5C15.502 23.9477 15.0542 23.5 14.502 23.5C13.9497 23.5 13.502 23.9477 13.502 24.5V28.5ZM31.502 28.5C31.502 29.0523 31.0542 29.5 30.502 29.5H25.002C24.4497 29.5 24.002 29.0523 24.002 28.5C24.002 27.9477 24.4497 27.5 25.002 27.5H29.502L29.502 24.5C29.502 23.9477 29.9497 23.5 30.502 23.5C31.0542 23.5 31.502 23.9477 31.502 24.5L31.502 28.5ZM31.502 15.5C31.502 14.9477 31.0542 14.5 30.502 14.5H25.002C24.4497 14.5 24.002 14.9477 24.002 15.5C24.002 16.0523 24.4497 16.5 25.002 16.5L29.502 16.5L29.502 19.5C29.502 20.0523 29.9497 20.5 30.502 20.5C31.0542 20.5 31.502 20.0523 31.502 19.5V15.5Z"
        fill="white"
      />
    </svg>
  ) : (
    <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5 20.5C20.0523 20.5 20.5 20.0523 20.5 19.5V15C20.5 14.4477 20.0523 14 19.5 14C18.9477 14 18.5 14.4477 18.5 15V18.5H14.5C13.9477 18.5 13.5 18.9477 13.5 19.5C13.5 20.0523 13.9477 20.5 14.5 20.5H19.5ZM20.5 24.5C20.5 23.9477 20.0523 23.5 19.5 23.5H14.5C13.9477 23.5 13.5 23.9477 13.5 24.5C13.5 25.0523 13.9477 25.5 14.5 25.5H18.5V29C18.5 29.5523 18.9477 30 19.5 30C20.0523 30 20.5 29.5523 20.5 29L20.5 24.5ZM23.5 24.5C23.5 23.9477 23.9477 23.5 24.5 23.5L29.5 23.5C30.0523 23.5 30.5 23.9477 30.5 24.5C30.5 25.0523 30.0523 25.5 29.5 25.5H25.5L25.5 29C25.5 29.5523 25.0523 30 24.5 30C23.9477 30 23.5 29.5523 23.5 29L23.5 24.5ZM23.5 19.5C23.5 20.0523 23.9477 20.5 24.5 20.5H29.5C30.0523 20.5 30.5 20.0523 30.5 19.5C30.5 18.9477 30.0523 18.5 29.5 18.5H25.5V15C25.5 14.4477 25.0523 14 24.5 14C23.9477 14 23.5 14.4477 23.5 15V19.5Z"
        fill="white"
      />
    </svg>
  );

export default Fullscreen;
