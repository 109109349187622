import React from 'react';
import PropTypes from 'prop-types';
import Skeleton from 'react-loading-skeleton';

/**
 * @type {React.FunctionComponent<{ show?: boolean }>}
 */
const MapPlaceholder = ({ show }) => {
  if (show) {
    return (
      <Skeleton
        className="sl-map-placeholder"
        style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}
      />
    );
  }
  return null;
};

MapPlaceholder.propTypes = {
  show: PropTypes.bool,
};

MapPlaceholder.defaultProps = {
  show: false,
};

export default MapPlaceholder;
