/** @prettier */

import createReducer from './createReducer';
import {
  SET_ACTIVE_COLLECTION,
  FETCH_COLLECTIONS,
  FETCH_COLLECTIONS_SUCCESS,
  FETCH_COLLECTIONS_FAILURE,
  SET_SELECTED_SPOTS,
  CHANGE_PRIMARY_SPOT_INDEX,
  CHANGE_PRIMARY_SPOT,
  SWAP_PRIMARY_SPOT,
  SET_NUM_CAMS,
  FETCH_COLLECTION_SPOTS,
  FETCH_COLLECTION_SPOTS_FAILURE,
  FETCH_COLLECTION_SPOTS_SUCCESS,
  SET_CAM_ANGLE_INDEX,
} from '../actions/multiCam';

export const initialState = {
  active: null,
  collectionTypes: [],
  collectionsLoading: true,
  collections: [],
  selectedSpots: [],
  numCams: 4,
  primarySpotIndex: 0,
  error: null,
};

const handlers = {};

handlers[SET_ACTIVE_COLLECTION] = (state, { active }) => ({
  ...state,
  active,
  selectedSpots: [],
});

handlers[FETCH_COLLECTIONS] = (state, { collectionTypes }) => ({
  ...state,
  collectionTypes,
  collectionsLoading: true,
});

handlers[FETCH_COLLECTIONS_SUCCESS] = (state, { active, collections }) => ({
  ...state,
  active,
  collections,
  collectionsLoading: false,
});

handlers[FETCH_COLLECTIONS_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  collectionsLoading: false,
});

handlers[SET_SELECTED_SPOTS] = (state, { selectedSpots, numCams }) => ({
  ...state,
  selectedSpots,
  numCams: numCams || state.numCams,
});

handlers[CHANGE_PRIMARY_SPOT_INDEX] = (state, { newIndex }) => ({
  ...state,
  primarySpotIndex: newIndex,
});

handlers[CHANGE_PRIMARY_SPOT] = (state, { newSpot }) => {
  const { primarySpotIndex, selectedSpots } = state;
  return {
    ...state,
    // Cheeky little trick for immutably replacing items in an array :)
    selectedSpots: Object.assign([...selectedSpots], {
      [primarySpotIndex]: newSpot,
    }),
  };
};

handlers[SWAP_PRIMARY_SPOT] = (state, { newSpotIndex }) => {
  const { primarySpotIndex, selectedSpots } = state;
  const currentPrimarySpot = selectedSpots[primarySpotIndex];
  const nextPrimarySpot = selectedSpots[newSpotIndex];
  return {
    ...state,
    // Cheeky little trick for immutably replacing items in an array :)
    selectedSpots: Object.assign([...selectedSpots], {
      [primarySpotIndex]: nextPrimarySpot,
      [newSpotIndex]: currentPrimarySpot,
    }),
  };
};

handlers[SET_NUM_CAMS] = (state, { numCams }) => {
  const { primarySpotIndex } = state;
  return {
    ...state,
    numCams,
    primarySpotIndex: primarySpotIndex > numCams - 1 ? 0 : primarySpotIndex,
  };
};

handlers[FETCH_COLLECTION_SPOTS] = (state, { collectionIndex }) => {
  const { collections } = state;

  return {
    ...state,
    // Cheeky little trick for immutably replacing items in an array :)
    collections: Object.assign([...collections], {
      [collectionIndex]: {
        ...collections[collectionIndex],
        loading: true,
      },
    }),
  };
};

handlers[FETCH_COLLECTION_SPOTS_SUCCESS] = (state, { collectionIndex, spots }) => {
  const { collections } = state;

  return {
    ...state,
    // Cheeky little trick for immutably replacing items in an array :)
    collections: Object.assign([...collections], {
      [collectionIndex]: {
        ...collections[collectionIndex],
        loading: false,
        fetchedSpots: true,
        spots,
      },
    }),
  };
};

handlers[FETCH_COLLECTION_SPOTS_FAILURE] = (state, { collectionIndex, error }) => {
  const { collections } = state;

  return {
    ...state,
    // Cheeky little trick for immutably replacing items in an array :)
    collections: Object.assign([...collections], {
      [collectionIndex]: {
        ...collections[collectionIndex],
        loading: false,
        error,
      },
    }),
  };
};

handlers[SET_CAM_ANGLE_INDEX] = (state, { camAngleIndex, selectedSpotIndex }) => {
  const { selectedSpots } = state;

  return {
    ...state,
    // Cheeky little trick for immutably replacing items in an array :)
    selectedSpots: Object.assign([...selectedSpots], {
      [selectedSpotIndex]: {
        ...selectedSpots[selectedSpotIndex],
        selectedCamAngleIndex: camAngleIndex,
      },
    }),
  };
};

export default createReducer(handlers, initialState);
