import { combineReducers } from 'redux';
import clusters from './clusters';
import location from './location';
import locationView from './locationView';
import spots from './spots';
import status from './status';
import subregions from './subregions';
import regionalForecast from './regionalForecast';
import rerenderMap from './rerenderMap';

export default combineReducers({
  clusters,
  location,
  locationView,
  spots,
  subregions,
  regionalForecast,
  status,
  rerenderMap,
});
