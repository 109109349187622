import { Location } from '../../propTypes/map/location';

export const mapPaths = {
  base: `/surf-reports-forecasts-cams-map`,
  buoys: `/surf-reports-forecasts-cams-map/buoys`,
  windStations: `/surf-reports-forecasts-cams-map/wind-stations`,
  geonameBase: `/surf-reports-forecasts-cams`,
  geonameBuoysBase: `/surf-reports-forecasts-cams/buoys`,
  geonameWindstationBase: `/surf-reports-forecasts-cams/wind-stations`,
};

export const locationObjectToString = (location: Location) => {
  const {
    center: { lat, lon },
    zoom,
  } = location;

  return `${lat},${lon},${zoom}z`;
};

export const getSpotMapPath = () => mapPaths.base;
export const getBuoyMapPath = () => mapPaths.buoys;
export const getWindstationMapPath = () => mapPaths.windStations;

export const getSpotMapPathWithLocation = (location: string) => {
  if (location.indexOf('@') !== -1) {
    return `/surf-reports-forecasts-cams-map/${location}`;
  }
  return `/surf-reports-forecasts-cams-map/@${location}`;
};

export const getBuoyMapPathWithLocation = (location: string) => {
  if (location.indexOf('@') !== -1) {
    return `/surf-reports-forecasts-cams-map/buoys/${location}`;
  }
  return `/surf-reports-forecasts-cams-map/buoys/@${location}`;
};

export const getWindStationMapPathWithLocation = (location: string) => {
  if (location.indexOf('@') !== -1) {
    return `${mapPaths.windStations}/${location}`;
  }
  return `${mapPaths.windStations}/@${location}`;
};

export const getSpotMapPathWithLocationObject = (location: Location) =>
  `/surf-reports-forecasts-cams-map/@${locationObjectToString(location)}`;

export const getBuoyMapPathWithLocationObject = (location: Location) =>
  `/surf-reports-forecasts-cams-map/buoys/@${locationObjectToString(location)}`;

export const getWindStationMapPathWithLocationObject = (location: Location) =>
  `/surf-reports-forecasts-cams-map/wind-stations/@${locationObjectToString(location)}`;

export const getBuoyGeonameMapPath = ({
  breadcrumbs,
  geonameId,
}: {
  breadcrumbs: string;
  geonameId: string;
}) => decodeURIComponent(`/surf-reports-forecasts-cams/buoys/${breadcrumbs}/${geonameId}`);

export const getGeonameMapPath = ({
  breadcrumbs,
  geonameId,
}: {
  breadcrumbs: string;
  geonameId: string;
}) => decodeURIComponent(`/surf-reports-forecasts-cams/${breadcrumbs}/${geonameId}`);

export const getWindstationGeonameMapPath = ({
  breadcrumbs,
  geonameId,
}: {
  breadcrumbs: string;
  geonameId: string;
}) => decodeURIComponent(`${mapPaths.geonameWindstationBase}/${breadcrumbs}/${geonameId}`);

export const stripAtCharacterFromLocation = (location: string) =>
  location.indexOf('@') !== -1 ? location.substring(1) : location;

export const transformLocationParam = (location: string) => {
  const splitLocation = stripAtCharacterFromLocation(location).split(',');
  return {
    center: {
      lat: Number(splitLocation[0]),
      lon: Number(splitLocation[1]),
    },
    zoom: Number(splitLocation[2]?.replace('z', '')),
  };
};
