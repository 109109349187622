import createReducer from './createReducer';
import {
  FETCH_EDITORIAL_TAXONOMY_POSTS,
  FETCH_EDITORIAL_TAXONOMY_POSTS_SUCCESS,
  FETCH_EDITORIAL_TAXONOMY_POSTS_FAILURE,
  RESET_EDITORIAL_TAXONOMY_POSTS,
  ENABLE_INFINITE_SCROLLING,
} from '../actions/editorialTaxonomyPosts';
import type { AppState } from '../stores';

export const initialState = {
  loading: false,
  error: false,
  success: false,
  offset: 0,
  limit: 12,
  posts: [],
  featured: [],
  infiniteScroll: false,
  fetched: 1,
  taxonomy: null,
  term: null,
  subCategory: null,
};

const handlers: any = {};

handlers[FETCH_EDITORIAL_TAXONOMY_POSTS] = (state: AppState) => ({
  ...state,
  loading: true,
  success: initialState.success,
  error: initialState.error,
});

handlers[FETCH_EDITORIAL_TAXONOMY_POSTS_SUCCESS] = (
  state: AppState,
  { posts, featured, taxonomy, term, subCategory, offset, limit }: any,
) => {
  const { fetched }: any = state;
  return {
    ...state,
    posts,
    featured,
    fetched: fetched + 1,
    loading: false,
    success: true,
    taxonomy,
    term,
    subCategory,
    offset,
    limit,
  };
};

handlers[FETCH_EDITORIAL_TAXONOMY_POSTS_FAILURE] = (state: AppState, { error }: any) => ({
  ...state,
  error,
  loading: false,
  success: false,
});

handlers[RESET_EDITORIAL_TAXONOMY_POSTS] = () => initialState;

handlers[ENABLE_INFINITE_SCROLLING] = (state: AppState) => ({
  ...state,
  infiniteScroll: true,
});

export default createReducer(handlers, initialState);
