import React, {
  type CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  dismissContentCard,
  getWindow,
  trackContentCardClick,
  trackContentCardImpressions,
} from '@surfline/web-common';

import { CLICKED_SUBSCRIBE_CTA, BRAZE_CONTENT_CARDS } from 'common/constants';
import TrackableLink from 'components/TrackableLink';
import WavetrakLink from 'components/WavetrakLink';
import withContentCards from 'components/withContentCards';

import Notification from '../Notification';

import styles from './NotificationBarController.module.scss';

const SUBSCRIBE_CTA_PROPERTIES = { location: 'upsell bar' };

interface NotificationBarControllerProps {
  card?: {
    name: string;
    extras: {
      css: CSSProperties;
      href__text: string;
      href__url: string;
    } | null;
  };
  slDisableNotificationCTA?: string;
  warning?: {
    alert: boolean;
    message: string;
    type: string;
  };
}

const NotificationBarController: React.FC<NotificationBarControllerProps> = ({
  card = { name: BRAZE_CONTENT_CARDS.NOTIFICATION_BAR, extras: null },
  slDisableNotificationCTA = 'off',
  warning,
}) => {
  const freeTrialLinkRef = useRef(null);
  const [rendered, setRendered] = useState(false);

  const handleClickCTA = useCallback(
    (e) => {
      e.preventDefault();
      trackContentCardClick(card);
      setTimeout(() => {
        if (card?.extras?.href__url) {
          getWindow()?.location.assign(card.extras.href__url);
        }
      });
    },
    [card],
  );

  const renderBrazeNotification = useMemo(
    () =>
      card?.extras && (
        <Notification
          closable
          type="large"
          level="information"
          onCloseComplete={() => dismissContentCard(BRAZE_CONTENT_CARDS.NOTIFICATION_BAR)}
        >
          <TrackableLink
            ref={freeTrialLinkRef}
            eventName={CLICKED_SUBSCRIBE_CTA}
            eventProperties={SUBSCRIBE_CTA_PROPERTIES}
          >
            <a
              ref={freeTrialLinkRef}
              href={card.extras.href__url}
              className="quiver-notification__tag-line"
              style={{ ...card.extras.css }}
              onClick={handleClickCTA}
            >
              {card.extras.href__text}
            </a>
          </TrackableLink>
        </Notification>
      ),
    [card, handleClickCTA],
  );

  const renderPaymentWarningsNotification = useMemo(
    () => (
      <div>
        <Notification closable type="large" level="error">
          {warning?.type === 'web' ? (
            <WavetrakLink isExternal href="/account/subscription#payment-details">
              {warning?.message}
            </WavetrakLink>
          ) : (
            <span>{warning?.message}</span>
          )}
        </Notification>
      </div>
    ),
    [warning],
  );

  const renderNotification = useMemo(() => {
    if (slDisableNotificationCTA === 'on') return null;
    if (warning?.alert) return renderPaymentWarningsNotification;
    if (card?.extras !== null) {
      if (!rendered) {
        setRendered(true);
        trackContentCardImpressions([card]);
      }
      return renderBrazeNotification;
    }
    return null;
  }, [
    card,
    rendered,
    renderPaymentWarningsNotification,
    renderBrazeNotification,
    slDisableNotificationCTA,
    warning,
  ]);

  const [notification, setNotification] = useState(renderNotification);
  // Updates the bar when the Braze card is set
  useEffect(() => {
    setNotification(renderNotification);
  }, [renderNotification]);

  return (
    <div className={styles.notificaitonBarController}>
      <div className="quiver-notification-bar">{notification}</div>
    </div>
  );
};

export default withContentCards(NotificationBarController);
