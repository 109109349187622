import React from 'react';

import PropTypes from 'prop-types';
import { useLeafletComponent } from './useLeaflet';

const TileLayer = ({ attribution, url }) => {
  const LeafletTileLayer = useLeafletComponent('TileLayer');

  if (!LeafletTileLayer) {
    return null;
  }

  return <LeafletTileLayer attribution={attribution} url={url} />;
};

TileLayer.propTypes = {
  attribution: PropTypes.string,
  url: PropTypes.string,
};

TileLayer.defaultProps = {
  attribution: '&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
  url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
};

export default TileLayer;
