const getSwellEvents = (state) => state.swellEvents.events || [];

export const getActiveSwellEvent = (state) => {
  const data = state.swellEvents.activeEvent;
  if (data) {
    return data.event;
  }
  return null;
};

export const getAllSwellEvents = (state) => {
  const data = state.swellEvents.allEvents;
  if (data) {
    return data.events;
  }
  return null;
};

export default getSwellEvents;
