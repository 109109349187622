import { useMemo } from 'react';
import { configureStore, ThunkAction, Action, ConfigureStoreOptions } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';
import { createReducerInjecter, AsyncReducers, injectReducer } from '@surfline/web-common';

import windStationsAPI from 'common/api/windStations';
import rootReducer from '../reducers';
import { buoysAPI } from '../common/api/buoys';
import { env } from '../config';

export type WavetrakStore = ReturnType<typeof initStore>;
let store: WavetrakStore;

export function initStore(initialState?: ConfigureStoreOptions['preloadedState']) {
  const initializedStore = configureStore({
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({ immutableCheck: { warnAfter: 100 } }).concat(
        ...[buoysAPI.middleware],
        ...[windStationsAPI.middleware],
      ),
    reducer: rootReducer(),
    devTools:
      env !== 'production'
        ? {
            name: 'Surfline - KBYG',
            trace: true,
            traceLimit: 50,
          }
        : false,
  });

  createReducerInjecter(initializedStore, () => rootReducer());

  setupListeners(initializedStore.dispatch);

  return initializedStore;
}

export const getOrCreateStore = (preloadedState?: ConfigureStoreOptions['preloadedState']) => {
  // eslint-disable-next-line no-underscore-dangle
  const s = store ?? initStore(preloadedState);

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return s;

  // Create the store once in the client
  if (!store) {
    store = s;
  }

  return s;
};

export function useStore(initialState: AppState) {
  const memoizedStore = useMemo(() => getOrCreateStore(initialState), [initialState]);
  return memoizedStore;
}

export type AppState = ReturnType<WavetrakStore['getState']>;
export type AppDispatch = ReturnType<typeof getOrCreateStore>['dispatch'];

export interface ReduxInitialProps {
  ssrReduxState?: AppState;
}

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export type { AsyncReducers, injectReducer };
