import createReducer from './createReducer';
import { FETCH_OS_DATA, FETCH_OS_DATA_SUCCESS, FETCH_OS_DATA_FAILURE } from '../actions/deviceData';

export const initialState = {
  osData: {
    osName: null,
  },
  loading: false,
};

const handlers = {};
handlers[FETCH_OS_DATA] = (state) => ({
  ...state,
  loading: true,
});

handlers[FETCH_OS_DATA_SUCCESS] = (state, { osData }) => ({
  ...state,
  loading: false,
  osData,
});

handlers[FETCH_OS_DATA_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error: error.message,
});

export default createReducer(handlers, initialState);
