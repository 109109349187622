import React from 'react';

interface Props {
  className?: string;
  'data-testid'?: string;
}

export const ChevronDown = ({ className, 'data-testid': dataTestId }: Props) => (
  <svg
    className={className}
    fill="none"
    height="44"
    viewBox="0 0 44 44"
    width="44"
    xmlns="http://www.w3.org/2000/svg"
    data-testid={dataTestId}
  >
    <circle cx="22" cy="22" r="21.5" transform="rotate(-90 22 22)" stroke="#E4E7ED" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3217 19.0152C15.7275 18.6406 16.3602 18.6659 16.7348 19.0717L22 24.7757L27.2652 19.0717C27.6398 18.6659 28.2725 18.6406 28.6783 19.0152C29.0841 19.3898 29.1094 20.0225 28.7348 20.4283L22.7348 26.9283C22.5455 27.1334 22.2791 27.25 22 27.25C21.7209 27.25 21.4545 27.1334 21.2652 26.9283L15.2652 20.4283C14.8906 20.0225 14.9159 19.3898 15.3217 19.0152Z"
      fill="#98A2AF"
    />
  </svg>
);

export default ChevronDown;
