/* eslint-disable import/prefer-default-export */
import { getLocalDate } from '@surfline/web-common';
import { groupBy, isNumber } from 'lodash';
import { format } from 'date-fns/fp';
import { BuoyReportResponse, PreTransformedStationData } from '../types/buoys';
import {
  overallMaxBuoySpectraPeriod,
  filteredBuoySwellSpectraHourDataCurrent,
} from './swellSpectraUtils';

const getDayString = format('EEE');

/**
 * @description
 * Helper function to return 1 `reading` object with all data as null
 * except for timestamp and utcOffset. The purpose of this reading is
 * to placehold for any given day in ForecastTable that does not have
 * any individual readings. Without a placeholder reading, the ForecastTable
 * component will break trying to render a day with no readings.
 */
const insertEmptyReading = (
  timestamp: number,
  utcOffset: number,
): Partial<PreTransformedStationData> => ({
  timestamp,
  swells: [],
  utcOffset,
  height: undefined,
  period: undefined,
  peakPeriod: null,
  direction: undefined,
  spectra1d: {
    period: [
      2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27, 28,
    ],
    spread: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    direction: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    directionalSpread: [
      0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
    ],
    energy: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
  },
});

const buoyMapFunc = ({
  timestamp,
  utcOffset,
  period,
  peakPeriod,
  height,
  direction,
  power,
  wind,
  airTemperature,
  waterTemperature,
  pressure,
  dewPoint,
  swells,
  spectra1d,
  spectraFormattedData,
}: PreTransformedStationData) => ({
  timestamp,
  utcOffset,
  period,
  peakPeriod,
  height,
  direction,
  power,
  metrics: {
    wind: wind
      ? {
          direction: wind?.direction,
          gust: wind?.gust,
          speed: wind?.speed,
        }
      : undefined,
    waterTemperature,
    airTemperature,
    pressure,
    dewPoint,
  },
  swellSpectra: {
    ...spectra1d,
    direction: spectra1d?.direction ?? null,
    directionalSpread: spectra1d?.directionalSpread ?? null,
    energy: spectra1d?.energy ?? null,
    period: spectra1d?.period ?? null,
    spread: spectra1d?.directionalSpread ?? null,
    spectraFormattedData,
  },
  swells:
    swells
      ?.filter(
        (swell) =>
          [swell.period, swell.direction, swell.height].map((d) => isNumber(d)).filter(Boolean)
            .length !== 0,
      )
      .map((swell, index) => ({
        ...swell,
        height: swell.height,
        period: swell.period,
        index,
      })) || [],
});

/**
 * @description The response from the API comes back as a flat array of data points with `timestamp`
 * & `utcOffset`. In order to be displayed on the graphs and the table we need to format
 * it into an array of arrays (one for each day containing that day's data points).
 */
const transformBuoyDataToDays = (data: Array<PreTransformedStationData>, utcOffset: number) => {
  // We group each timestamp by the local day it lies in (Mon, Tues, Wed...)
  const dataGroupedByDay = groupBy(data, (d) =>
    getDayString(getLocalDate(d.timestamp, d.utcOffset)),
  );

  const today = new Date();

  // check if `dataGroupedByDay` includes at least 3 days of readings
  if (Object.keys(dataGroupedByDay).length < 3) {
    // if less than 3 unique days exist in readings, find missing day
    for (let i = 0; i < 3; i += 1) {
      // get the timestamp of `i` days before today
      const currentDay = new Date().setDate(today.getDate() - i);
      // convert timestamp from `i` days ago to 'EEE' format (i.e. Mon, Tue, Wed...)
      const weekday = getDayString(currentDay);
      // 'EEE' formatted weekday should exist in `dataGroupedByDay`, if not, add an
      // empty reading to prevent the ForecastTable component from breaking.
      if (!Object.keys(dataGroupedByDay).includes(weekday)) {
        dataGroupedByDay[weekday] = [
          insertEmptyReading(currentDay / 1000, utcOffset) as PreTransformedStationData & {
            period: Array<number>;
          },
        ];
      }
    }
  }

  // We reverse the array at the end since we want to display buoy data
  // looking backwards (it is not a forecast, it's historical data).
  const days = Object.values(dataGroupedByDay)
    .map((day) => day.map(buoyMapFunc))
    .sort((dayA, dayB) => dayA[0].timestamp - dayB[0].timestamp)
    .reverse();

  return days;
};

export const formatBuoyReportTableData = (buoyReport: BuoyReportResponse) => {
  const maxPeriodData = buoyReport?.data?.map((dataPoint) => ({
    direction: dataPoint?.spectra1d?.direction ?? null,
    directionalSpread: dataPoint?.spectra1d?.directionalSpread ?? null,
    energy: dataPoint?.spectra1d?.energy ?? null,
    period: dataPoint?.spectra1d?.period ?? null,
    spread: dataPoint?.spectra1d?.directionalSpread ?? null,
  }));

  const overallMaxPeriod = overallMaxBuoySpectraPeriod(maxPeriodData);
  const period = Array.from({ length: overallMaxPeriod - 1 }, (_, index) => index + 2);

  const data = buoyReport?.data.map((dataPoint) => ({
    ...dataPoint,
    spectraFormattedData: filteredBuoySwellSpectraHourDataCurrent(
      {
        direction: dataPoint?.spectra1d?.direction ?? null,
        directionalSpread: dataPoint?.spectra1d?.directionalSpread ?? null,
        energy: dataPoint?.spectra1d?.energy ?? null,
        period: dataPoint?.spectra1d?.period ?? null,
        spread: dataPoint?.spectra1d?.directionalSpread ?? null,
      },
      period,
    ),
  }));

  return {
    units: buoyReport.associated.units,
    days: buoyReport.data.length ? transformBuoyDataToDays(data, data?.[0]?.utcOffset) : [],
    utcOffset: buoyReport.data[0]?.utcOffset,
    overallMaxPeriod,
    showMinutes: true,
    period,
  };
};
