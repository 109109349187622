import orderBy from 'lodash/orderBy';
import createReducer from '../createReducer';
import { FETCH_SPOT_MAP_DATA_SUCCESS } from '../../actions/spotMapData';
import { conditionToRank } from '../../utils/conditionRank';
import { LEAVE_GEONAME_MAP_ROUTE } from '../../actions/routes';

export const initialState = [];

const handlers = {};

const subregionRank = (spot) => spot.rank[0];
const spotRank = (spot) => spot.rank[1];
const conditionRating = (spot) => conditionToRank(spot.conditions.value);
const surfHeight = (spot) => spot.waveHeight.max;

handlers[FETCH_SPOT_MAP_DATA_SUCCESS] = (_, { spots, doCluster }) => {
  if (!doCluster) {
    return orderBy(spots, [subregionRank, spotRank]);
  }

  const topSpots = orderBy(spots, [conditionRating, surfHeight], ['desc', 'desc']);
  return topSpots.slice(0, 75);
};

handlers[LEAVE_GEONAME_MAP_ROUTE] = () => initialState;

export default createReducer(handlers, initialState);
