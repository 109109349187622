import React, { useEffect, ReactElement, ForwardedRef } from 'react';
import { setupLinkTracking } from '@surfline/web-common';

interface TrackableLinkProps {
  children: ReactElement;
  eventName: string | (() => void);
  eventProperties: object | (() => void);
}

/**
 * @description A link component which can be used to fire
 * track calls to segment when the link is clicked
 * https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track-link
 */
const TrackableLink = React.forwardRef<HTMLAnchorElement, TrackableLinkProps>(
  ({ children, eventName, eventProperties }, ref: ForwardedRef<HTMLAnchorElement>) => {
    useEffect(() => {
      if (ref && 'current' in ref && ref.current) {
        setupLinkTracking(ref.current, eventName, eventProperties);
      }
    }, [ref, eventName, eventProperties]);

    return children;
  },
);

TrackableLink.displayName = 'TrackableLink';

export default TrackableLink;
