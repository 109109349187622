import { getWindow } from '@surfline/web-common';

const window = getWindow();

export const getCanUseLocalStorage = () => {
  try {
    return !!(
      typeof window !== 'undefined' &&
      !!window.localStorage &&
      typeof localStorage.getItem === 'function' &&
      typeof localStorage.setItem === 'function' &&
      typeof localStorage.removeItem === 'function'
    );
  } catch (error) {
    return false;
  }
};
