/* eslint-disable no-underscore-dangle */
import config from 'config';
import type { SearchResult } from 'types/header';

const getResultHref = (result: SearchResult) => {
  if (!result || !result?._source) return '';
  const href =
    (result?._index === 'feed' ? result?._source?.permalink : result?._source?.href) || '';
  return href.replace(config.surflineHost, '');
};

export default getResultHref;
