import fetch from 'isomorphic-fetch';
import { Cookies } from 'react-cookie';
import { canUseDOM, doCookiesWarning, nrNoticeError } from '@surfline/web-common';
import config from '../config';

const checkStatus = (res) => {
  if (res.status >= 200 && res.status < 300) {
    return res;
  }

  const error = new Error(
    res?.statusText ? `Base fetch error: ${res.statusText}` : `Base fetch error`,
  );
  error.response = res;
  error.statusCode = res?.status;

  if (error.statusCode >= 500) {
    nrNoticeError(error, {
      reponseBody: res?.body,
      statusCode: res?.status,
      statusText: res?.statusText,
      url: res?.url,
    });
  }

  throw error;
};

/**
 * @param {Response} response
 */
const parseJSON = (response) => response.json();

/**
 * @param {object} [serverSideCookies]
 * @return {string?}
 */
export const getAccessToken = (serverSideCookies) => {
  const cookies = new Cookies(canUseDOM ? undefined : serverSideCookies);
  return cookies.get('access_token');
};

/**
 * @param {boolean?} [wpAPI]
 * @return {string}
 */
export const getBaseURL = (wpAPI) => {
  const baseUrl = wpAPI ? config.wpAPI : config.servicesURL;

  return baseUrl;
};

/**
 * @template R
 * @returns {Promise<R>}
 */
export const doFetch = (path, options = {}) => {
  doCookiesWarning(path, options);
  const cookies = new Cookies(canUseDOM ? undefined : options.cookies);
  const wpApi = path.includes('wp-json');
  const nextApi = ['/api/content/', '/api/wave-pool/'].some((substring) =>
    path.includes(substring),
  );
  const at = cookies.get('access_token');
  if (at && !wpApi) {
    path += `&accesstoken=${at}`; // eslint-disable-line no-param-reassign
  }
  let baseUrl;
  if (wpApi) {
    baseUrl = config.wpAPI;
  } else if (nextApi) {
    baseUrl = config.appHost;
  } else {
    baseUrl = config.servicesURL;
  }

  return fetch(`${baseUrl}${path}`, {
    ...options,
  })
    .then(checkStatus)
    .then(parseJSON);
};

export const createParamString = (params) =>
  Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== null)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

export const createDecodedParamString = (params) =>
  Object.keys(params)
    .filter((key) => params[key] !== undefined && params[key] !== null)
    .map((key) => `${key}=${params[key]}`)
    .join('&');

export const doExternalFetch = (url) => fetch(url).then(checkStatus).then(parseJSON);

export const doJSFetch = async (path) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream
  const result = await fetch(path, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
    },
  })
    .then((response) => response.body)
    .then((rb) => {
      const reader = rb.getReader();
      return new ReadableStream({
        start(controller) {
          function push() {
            reader.read().then(({ done, value }) => {
              if (done) {
                controller.close();
                return;
              }
              controller.enqueue(value);
              push();
            });
          }

          push();
        },
      });
    })
    .then((stream) => new Response(stream, { headers: { 'Content-Type': 'text/html' } }).text())
    .then((res) => res);

  return result;
};

export default doFetch;
