import React from 'react';
import classNames from 'classnames';

import type {
  MenuState,
  NavigationSettings,
  ServiceConfiguration,
  TaxonomyNode,
} from 'types/header';
import type { UserSettings } from 'types/user';

import { camsReportsLinks } from '../../defaultLinks';
import RecentlyVisited from '../RecentlyVisited';
import TaxonomyNavigator from '../TaxonomyNavigator';

import styles from './CamsReportsMenu.module.scss';

const HIERARCHY = ['🌍', 'Continent', 'Country', 'Region', 'Area'];

interface CamsReportsMenuProps {
  loggedIn?: boolean;
  menuState: MenuState;
  navigationSettings?: NavigationSettings;
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
  recentlyVisited: Array<TaxonomyNode>;
  scrollMenuToTop?: () => void;
  serviceConfig: ServiceConfiguration;
  setMenuState: (stateUpdate: MenuState) => void;
  signInUrl: string;
  userSettings: UserSettings | null;
}

const CamsReportsMenu = ({
  loggedIn = false,
  menuState,
  navigationSettings,
  onClickLink,
  recentlyVisited,
  scrollMenuToTop,
  serviceConfig,
  setMenuState,
  signInUrl,
  userSettings,
}: CamsReportsMenuProps) => {
  const onClickLinkHandler = (clickProperties: any, closeMobileMenu: boolean) => {
    if (onClickLink) {
      onClickLink(
        {
          name: 'Clicked Main Nav',
          properties: {
            ...clickProperties,
            tabName: 'Cams',
          },
        },
        closeMobileMenu,
      );
    }
  };
  return (
    <div
      className={classNames(styles.camsReportsMenu, 'sl-cams-reports-menu')}
      data-testid="cams-reports-menu"
    >
      <RecentlyVisited
        listType="spots"
        loggedIn={loggedIn}
        items={recentlyVisited}
        staticLinks={camsReportsLinks}
        signInUrl={signInUrl}
        onClickLink={onClickLinkHandler}
        userSettings={userSettings}
      />
      <TaxonomyNavigator
        hierarchy={HIERARCHY}
        loggedIn={loggedIn}
        navigationSettings={navigationSettings}
        serviceConfig={serviceConfig}
        savedLocationType="spotsTaxonomyLocation"
        menuState={menuState}
        setMenuState={setMenuState}
        taxonomyType="spot"
        scrollMenuToTop={scrollMenuToTop}
        onClickLink={onClickLinkHandler}
        userSettings={userSettings}
      />
    </div>
  );
};

export default CamsReportsMenu;
