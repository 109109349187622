import { useRouter } from 'next/router';
import type { Location, MapMode } from 'types/map';
import {
  getBuoyMapPathWithLocationObject,
  getWindStationMapPathWithLocationObject,
  getSpotMapPath,
  getSpotMapPathWithLocation,
} from 'utils/urls/mapPaths';
import en from 'intl/translations/en';

const useMapPageMetaDetails = (mapMode: MapMode, location: Location) => {
  const router = useRouter();
  const surfMapLocation = router.query.location as string;
  const buoysDetails = en?.BuoysMapPage;
  const surfSpotsDetails = en?.SurfSpotMapPage;
  const windStationsDetails = en?.WindStationsMapPage;

  if (mapMode === 'buoy') {
    const urlPath = getBuoyMapPathWithLocationObject(location);

    return {
      pageTitle: buoysDetails?.metaTitle,
      metaDescription: buoysDetails?.metaDescription,
      structuredDataPrefix: 'Wave Buoys',
      urlPath,
    };
  }

  if (mapMode === 'windStation') {
    const urlPath = getWindStationMapPathWithLocationObject(location);

    return {
      pageTitle: windStationsDetails?.metaTitle,
      metaDescription: windStationsDetails?.metaDescription,
      structuredDataPrefix: 'Wind Stations',
      urlPath,
    };
  }

  return {
    pageTitle: surfSpotsDetails?.metaTitle,
    metaDescription: surfSpotsDetails?.metaDescription,
    structuredDataPrefix: 'Surf Spots',
    urlPath: surfMapLocation ? getSpotMapPathWithLocation(surfMapLocation) : getSpotMapPath(),
  };
};

export default useMapPageMetaDetails;
