import React from 'react';

export const AddIcon = ({ className }: { className?: string }) => (
  <svg className={className} viewBox="0 0 20 20">
    <circle cx="10" cy="10" r="9" fill="none" vectorEffect="non-scaling-stroke" />
    <path d="M10 5 L 10 15" fill="none" strokeLinecap="round" vectorEffect="non-scaling-stroke" />
    <path d="M5 10 L 15 10" fill="none" strokeLinecap="round" vectorEffect="non-scaling-stroke" />
  </svg>
);

export default AddIcon;
