/**
 * @description Create a reducer.
 * @template S
 * @param {import('redux').PreloadedState<S>} initialState
 * @param {{ [key: string]: import('redux').Reducer<S, any> }} handlers
 */
const createReducer =
  (handlers, initialState) =>
  // eslint-disable-next-line default-param-last
  (state = initialState, action) => {
    const handler = action && action.type ? handlers[action.type] : null;

    if (handler) return handler(state, action);
    return state;
  };

export default createReducer;
