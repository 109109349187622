import React, { AriaRole } from 'react';
import { Typography } from '@mui/material';

import WavetrakLink from 'components/WavetrakLink';

import styles from './BaseMenuItem.module.scss';

interface BaseMenuItemProps {
  children: React.ReactNode;
  href: string;
  isExternal?: boolean;
  newWindow?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  role?: AriaRole;
  tabIndex?: number;
}

const BaseMenuItem = ({
  children,
  href,
  isExternal,
  newWindow = false,
  onClick,
  role,
  tabIndex,
}: BaseMenuItemProps) => (
  <WavetrakLink
    className={styles.baseMenuItem}
    data-testid="base-menu-item"
    href={href}
    isExternal={isExternal}
    onClick={onClick}
    rel={newWindow ? 'noreferrer' : ''}
    role={role}
    tabIndex={tabIndex}
    target={newWindow ? '_blank' : ''}
  >
    <Typography variant="body1" component="span" className={styles.label}>
      {children}
    </Typography>
  </WavetrakLink>
);

export default BaseMenuItem;
