import React from 'react';
import classNames from 'classnames';

import styles from './ForecastIcon.module.scss';

const ForecastIcon = () => (
  <svg className={classNames(styles.forecastIcon, 'quiver-forecast-icon')} viewBox="0 0 16 16">
    <path
      fillRule="nonzero"
      d="M8.636 4.469C5.731 3.346 3.519 4.304 2 7.342V12h12S2.492 9.498 8.636 4.469z"
    />
  </svg>
);

export default ForecastIcon;
