import React from 'react';
import PropTypes from 'prop-types';

const HDCams = ({ isFilled }) => (
  <svg
    className="sl-hd-cam"
    xmlns="http://www.w3.org/2000/svg"
    width="42"
    height="46"
    viewBox="0 0 42 46"
  >
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path
        className="sl-hd-cam-ribbon"
        fill={isFilled ? '#22D737' : null}
        stroke={isFilled ? '#22D737' : '#FFF'}
        d="M1 1v43.483l20-8.571 20 8.571V1H1z"
      />
      <path
        className="sl-hd-cam-camera"
        fill={isFilled ? '#FFF' : null}
        stroke={isFilled ? null : '#22D737'}
        d="M32 15.25v9.233L27.111 22v2.79c0 .668-.552 1.21-1.232 1.21H11.232c-.68 0-1.232-.542-1.232-1.21v-9.58c0-.668.552-1.21 1.232-1.21H25.88c.68 0 1.232.542 1.232 1.21v2.523L32 15.251z"
      />
    </g>
  </svg>
);

HDCams.propTypes = {
  isFilled: PropTypes.bool,
};

HDCams.defaultProps = {
  isFilled: false,
};

export default HDCams;
