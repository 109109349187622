import React, { useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import { Box, Button, CircularProgress } from '@mui/material';
import { canUseDOM, getUserDetails, getWindow } from '@surfline/web-common';
import AddOutlineIcon from 'components/Icons/AddOutlineIcon';
import CheckedCircleOutlineIcon from 'components/Icons/CheckedCircleOutlineIcon';
import { useAppDispatch, useAppSelector } from '../../stores/hooks';
import { useSpotIsFavorite } from '../../hooks/useSpotIsFavorite';
import { doFavoriteSpot, doUnfavoriteSpot } from '../../actions/user';
import { useMinWidthTablet } from '../../hooks/useMediaQueries';
import config from '../../config';
import styles from './FavoriteButton.module.scss';

const LoadingIcon: React.FC = () => (
  <Box className={styles.loadingIcon}>
    <CircularProgress size={20} sx={{ color: 'grey' }} data-testid="favorite-button-loading" />
  </Box>
);

interface Props {
  spotId: string;
}

const FavoriteButton: React.FC<Props> = ({ spotId }) => {
  const dispatch = useAppDispatch();
  const isSpotFavorited = useSpotIsFavorite(spotId);
  const isDesktop = useMinWidthTablet();
  const userDetails = useAppSelector(getUserDetails);
  const router = useRouter();

  const [isAddingFavorite, setIsAddingFavorite] = useState(false);
  const [isRemovingFavorite, setIsRemovingFavorite] = useState(false);

  useEffect(() => {
    if (isAddingFavorite && isSpotFavorited) {
      setIsAddingFavorite(false);
    }
    if (isRemovingFavorite && !isSpotFavorited) {
      setIsRemovingFavorite(false);
    }
  }, [isSpotFavorited, isAddingFavorite, isRemovingFavorite]);

  const handleFavoriteClick = () => {
    if (userDetails) {
      if (isSpotFavorited) {
        setIsRemovingFavorite(true);
        dispatch(doUnfavoriteSpot(spotId));
      } else {
        setIsAddingFavorite(true);
        dispatch(doFavoriteSpot(spotId));
      }
    } else if (canUseDOM) {
      const win = getWindow();
      const redirectUrl = `${config.surflineHost}${router.asPath}`;
      win.location.href = `${
        config.createAccountUrl
      }?alert=noAuthFav&redirectUrl=${encodeURIComponent(redirectUrl)}`;
    }
  };

  const buttonIcon = useMemo(() => {
    if (isAddingFavorite || isRemovingFavorite) {
      return <LoadingIcon />;
    }
    if (isSpotFavorited) {
      return <CheckedCircleOutlineIcon />;
    }
    return <AddOutlineIcon />;
  }, [isSpotFavorited, isAddingFavorite, isRemovingFavorite]);

  return (
    <Button
      className={isDesktop ? '' : styles.favoriteButton}
      data-testid="favorite-button"
      // TODO: See if this can be default in the Theme and change there
      disableRipple
      disableFocusRipple
      onClick={handleFavoriteClick}
      size="medium"
      startIcon={buttonIcon}
      variant="secondary"
      disabled={isAddingFavorite || isRemovingFavorite}
    >
      {isDesktop && (isSpotFavorited ? 'Favorite' : 'Add favorite')}
    </Button>
  );
};

export default FavoriteButton;
