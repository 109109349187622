/* istanbul ignore file */
import { SpotDetails } from 'types/spot';
import { fetchSpotsForCam } from '../common/api/cameras';
import type { AppDispatch } from '../stores';

export const FETCH_CAMERA_SPOT = 'FETCH_CAMERA_SPOT';
export const FETCH_CAMERA_SPOT_SUCCESS = 'FETCH_CAMERA_SPOT_SUCCESS';
export const FETCH_CAMERA_SPOT_FAILURE = 'FETCH_CAMERA_SPOT_FAILURE';

export const fetchCameraSpot =
  (camId: string, callback: ((arg: { spots: SpotDetails[] } | null) => void) | null = null) =>
  async (dispatch: AppDispatch) => {
    let spot;
    dispatch({
      type: FETCH_CAMERA_SPOT,
    });

    try {
      spot = (await fetchSpotsForCam(camId)) as { spots: SpotDetails[] } | null;
      dispatch({
        type: FETCH_CAMERA_SPOT_SUCCESS,
        spot,
      });
      if (callback) {
        callback(spot);
      }
    } catch (error) {
      dispatch({
        type: FETCH_CAMERA_SPOT_FAILURE,
        error,
      });
      if (callback) {
        callback(null);
      }
    }
  };
