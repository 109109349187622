import { groupBy as _groupBy, sortBy } from 'lodash';
import { format } from 'date-fns';
import { getLocalDate } from '@surfline/web-common';

/**
 * @description We take the data that comes in a flat array and then group it by its
 * formatted date string. From there we map map over and create an array for each day
 * and then sort the array to make sure the days are in order
 *
 * @template D
 * @template R
 * @param {Array<D & { timestamp: number, utcOffset: number }>} data
 * @param {(point: D) => R} map
 * @returns {R[][]}
 */
const createFiveDayHourlyData = (data, map = (point) => point) => {
  const firstDataPoint = data?.[0];

  if (
    !firstDataPoint ||
    !Object.prototype.hasOwnProperty.call(firstDataPoint, 'timestamp') ||
    !Object.prototype.hasOwnProperty.call(firstDataPoint, 'utcOffset')
  ) {
    throw new Error('Data points must have a `timestamp` and a `utcOffset`');
  }

  const hourlyDaysAvailable = 5;

  const groupedByDay = _groupBy(data.map(map), (p) =>
    format(getLocalDate(p.timestamp, p.utcOffset), 'MM-dd-yyyy'),
  );

  const hourlyData = sortBy(Object.values(groupedByDay), (day) => day[0].timestamp).slice(
    0,
    hourlyDaysAvailable,
  );

  return hourlyData;
};

export default createFiveDayHourlyData;
