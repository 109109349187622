import React from 'react';

const Expand = ({ className, fill = '#171717' }: { className?: string; fill?: string }) => (
  <svg
    className={className}
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.81364 14.7676C5.79111 15.2202 5.42923 15.5821 4.97661 15.6046L1.35104 15.7851C0.760224 15.8145 0.273147 15.3274 0.302555 14.7366L0.48302 11.111C0.505549 10.6584 0.867434 10.2965 1.32005 10.274C1.84113 10.248 2.27071 10.6776 2.24477 11.1987L2.16858 12.7294L5.48163 9.41637C5.81014 9.08787 6.34275 9.08787 6.67125 9.41637C6.99976 9.74488 6.99976 10.2775 6.67125 10.606L3.3582 13.919L4.88892 13.8429C5.40999 13.8169 5.83958 14.2465 5.81364 14.7676Z"
      fill={fill}
    />
    <path
      d="M10.3584 1.2342C10.381 0.781679 10.7428 0.419902 11.1953 0.397228L14.8224 0.215495C15.4135 0.18588 15.9008 0.673221 15.8712 1.26428L15.6895 4.89137C15.6668 5.34389 15.305 5.70567 14.8525 5.72834C14.331 5.75447 13.9011 5.32452 13.9272 4.80307L14.0039 3.2724L10.6883 6.58802C10.3598 6.91653 9.82717 6.91653 9.49867 6.58802C9.17016 6.25952 9.17016 5.72691 9.49867 5.3984L12.8143 2.08277L11.2836 2.15947C10.7622 2.1856 10.3322 1.75565 10.3584 1.2342Z"
      fill={fill}
    />
  </svg>
);

export default Expand;
