import { getWindow } from '@surfline/web-common';
import Image, { ImageProps, type ImageLoader } from 'next/image';
import config from '../../config';

const normalizeSrc = (src: string) => (src.startsWith('/') ? src.slice(1) : src);

const defaultImageLoader: ImageLoader = ({ src }) => src;

interface CloudFlareLoaderAdditionalProps {
  // https://developers.cloudflare.com/images/image-resizing/url-format/#fit
  fit?: 'scale-down' | 'contain' | 'cover' | 'crop' | 'pad';
  aspectRatio?: number;
}

// Loader docs: https://nextjs.org/docs/api-reference/next/image#loader
const cloudflareLoader: Function =
  ({ fit, aspectRatio }: CloudFlareLoaderAdditionalProps): ImageLoader =>
  ({ src, width, quality }) => {
    const params = [`width=${width}`, 'f=auto'];

    if (quality) {
      params.push(`quality=${quality}`);
    }

    if (fit) {
      params.push(`fit=${fit}`);
    }

    if (aspectRatio) {
      params.push(`height=${Math.round(width / aspectRatio)}`);
    }

    const paramsString = params.join(',');
    return config.cloudflareImageResizingUrl(normalizeSrc(src), paramsString);
  };

const toBase64 = (str: string) =>
  /** istanbul ignore next */
  typeof window === 'undefined' ? Buffer.from(str).toString('base64') : getWindow()!.btoa(str);

const shimmer = (w: string | number = '100%', h: string | number = '100%') => `
  <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="g">
        <stop stop-color="#ebebeb" offset="20%" />
        <stop stop-color="#f5f5f5" offset="50%" />
        <stop stop-color="#ebebeb" offset="70%" />
      </linearGradient>
    </defs>
    <rect width="${w}" height="${h}" fill="#ebebeb" />
    <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
    <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
  </svg>
`;
interface CloudflareResizedImageProps extends ImageProps {
  disableImageLoader?: boolean;
  cloudflareOptions?: CloudFlareLoaderAdditionalProps;
}

const CloudflareResizedImage: React.FC<CloudflareResizedImageProps> = ({
  src,
  width,
  height,
  disableImageLoader = false,
  cloudflareOptions = {},
  ...props
}) => (
  <Image
    placeholder="blur"
    blurDataURL={`data:image/svg+xml;base64,${toBase64(shimmer(width, height))}`}
    {...props}
    loader={disableImageLoader ? defaultImageLoader : cloudflareLoader(cloudflareOptions)}
    alt={props.alt}
    src={src}
    width={width}
    height={height}
    lazyBoundary="400px"
  />
);

export default CloudflareResizedImage;
