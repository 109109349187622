import React from 'react';

const EnhancedTools = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="42" height="32" viewBox="0 0 42 32">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path stroke="#FFF" d="M1 15h8v16H1z" />
      <path stroke="#22D737" d="M17 1h8v30h-8z" />
      <path stroke="#FFF" d="M33 7h8v24h-8z" />
    </g>
  </svg>
);

export default EnhancedTools;
