export const getSpotDetails = (state) => {
  const data = state.spot?.report?.data;
  if (data) {
    return data.spot;
  }
  return null;
};

export const getSpotCameras = (state) => {
  const data = state.spot?.report?.data;
  if (data) {
    return data.spot.cameras || [];
  }
  return [];
};

export const getSpotLotusStatus = (state) => {
  const data = state.spot?.details?.data;
  if (data) {
    return data.sds;
  }
  return null;
};

export const getSpotReport = (state) => {
  const data = state.spot?.report?.data;
  if (data) {
    return data.report;
  }
  return null;
};

export const getTravelDetails = (state) => {
  const data = state.spot?.report?.data;

  if (data && data.spot.travelDetails) {
    return data.spot.travelDetails;
  }
  return null;
};

export const getSpotIdealConditions = (state) => {
  const data = state.spot?.report?.data;

  if (data && data.spot.travelDetails && data.spot.travelDetails.best) {
    return data.spot.travelDetails.best;
  }
  return null;
};

export const getSpotReportData = (state) => {
  const data = state.spot?.report?.data;
  if (data) {
    return data.forecast;
  }
  return null;
};

export const getSpotReportLiveData = (state) => {
  const data = state.spot?.report?.data;
  if (data?.live) {
    return data.live;
  }
  return null;
};

export const getSpotReportAssociated = (state) => {
  const spotReport = state.spot?.report;
  if (spotReport) {
    return spotReport.associated;
  }
  return null;
};

export const getAdvertisingIds = (state) => {
  const associated = state.spot?.report?.associated;
  if (associated) {
    return associated.advertising;
  }
  return null;
};

export const getReverseSortBeachView = (state) =>
  state.spot?.nearby && state.spot?.nearby?.reverseSortBeachView;

export const getNearbySpots = (state) => {
  const data = state.spot?.nearby?.data;
  if (data) {
    return data.spots;
  }
  return null;
};

export const getSpotBuoys = (state) => state.spot.buoys;
export const getSpotLoading = (state) =>
  state.spot?.loading || state.spot?.report?.loading || state.spot?.details?.loading;
