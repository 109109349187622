import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import CircleIcon from 'components/Icons/CircleIcon';
import { color } from 'utils/colors';

import CustomMarker from './CustomMarker';

/**
 * @typedef {object} Props
 * @property {boolean} [active]
 * @property {string} [inactiveFill]
 * @property {string} [activeFill]
 * @property {number} [size]
 * @property {string} [border]
 * @property {number} [borderWidth]
 * @property {import('leaflet').LatLngExpression} [position]
 * @property {import('leaflet').Map} [map]
 * @property {boolean} [usingPortal]
 * @property {string} [className]
 * @property {number} [zIndexOffset]
 * @property {import('leaflet').LeafletEventHandlerFnMap} eventHandlers
 */

/**
 * @type {React.ForwardRefExoticComponent<React.PropsWithChildren<Props>>}
 */
const CircleMarker = React.forwardRef(
  (
    {
      active,
      inactiveFill,
      activeFill,
      size,
      children,
      border,
      borderWidth,
      position,
      map,
      usingPortal,
      className,
      zIndexOffset,
      eventHandlers,
    },
    ref,
  ) => {
    // We have to memoize this to prevent re-mounting the icon every render
    const circleIcon = useMemo(
      () => (
        <CircleIcon
          border={border}
          borderWidth={borderWidth}
          size={size}
          fill={active ? activeFill : inactiveFill}
        />
      ),
      [active, activeFill, inactiveFill, size, border, borderWidth],
    );

    return (
      <CustomMarker
        icon={circleIcon}
        iconSize={[size, size]}
        iconAnchor={[size / 2, size / 2]}
        position={position}
        map={map}
        usingPortal={usingPortal}
        className={className}
        zIndexOffset={zIndexOffset}
        eventHandlers={eventHandlers}
        ref={ref}
      >
        {children}
      </CustomMarker>
    );
  },
);

CircleMarker.propTypes = {
  children: PropTypes.instanceOf(React.Component),
  active: PropTypes.bool,
  activeFill: PropTypes.string,
  inactiveFill: PropTypes.string,
  border: PropTypes.string,
  borderWidth: PropTypes.number,
  size: PropTypes.number,
  position: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }).isRequired,
  eventHandlers: PropTypes.objectOf(PropTypes.func),
  map: PropTypes.shape(),
  usingPortal: PropTypes.bool,
  className: PropTypes.string,
  zIndexOffset: PropTypes.number,
};

CircleMarker.defaultProps = {
  children: null,
  active: false,
  activeFill: color('light-blue', 20),
  inactiveFill: color('dark-blue', 20),
  border: color('dark-blue', 20),
  borderWidth: 1,
  size: 12,
  eventHandlers: null,
  map: null,
  usingPortal: false,
  className: null,
  zIndexOffset: 0,
};

CircleMarker.displayName = 'CircleMarker';

export default CircleMarker;
