import createReducer from '../createReducer';
import {
  CLEAR_SUBREGION_OVERVIEW_DATA,
  FETCH_SUBREGION_OVERVIEW_DATA,
  FETCH_SUBREGION_OVERVIEW_DATA_SUCCESS,
  FETCH_SUBREGION_OVERVIEW_DATA_FAILURE,
} from '../../actions/subregion';

export const initialState = {
  subregionId: '',
  associated: {
    chartsUrl: null,
    units: {
      waveHeight: null,
    },
  },
  overview: {
    data: null,
  },
  loading: false,
  fetched: false,
};

const handlers = {};

handlers[CLEAR_SUBREGION_OVERVIEW_DATA] = () => ({
  ...initialState,
});

handlers[FETCH_SUBREGION_OVERVIEW_DATA] = (state, { subregionId }) => ({
  ...state,
  subregionId,
  loading: true,
  fetched: true,
});

handlers[FETCH_SUBREGION_OVERVIEW_DATA_SUCCESS] = (state, { overview }) => ({
  ...state,
  associated: overview.associated,
  overview,
  loading: false,
  fetched: true,
});

handlers[FETCH_SUBREGION_OVERVIEW_DATA_FAILURE] = (state, { subregionId }) => ({
  ...state,
  subregionId,
  loading: false,
  fetched: true,
});

export default createReducer(handlers, initialState);
