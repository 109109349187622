import React from 'react';

const BrowserIcon = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20ZM11 22C17.0751 22 22 17.0751 22 11C22 4.92487 17.0751 0 11 0C4.92487 0 0 4.92487 0 11C0 17.0751 4.92487 22 11 22Z"
      fill="#171717"
    />
    <path
      d="M16 11C16 13.6113 15.3629 15.9271 14.3851 17.5566C13.3955 19.206 12.1676 20 11 20C9.83241 20 8.60451 19.206 7.61485 17.5566C6.63714 15.9271 6 13.6113 6 11C6 8.38866 6.63714 6.07295 7.61485 4.44343C8.60451 2.794 9.83241 2 11 2C12.1676 2 13.3955 2.794 14.3851 4.44343C15.3629 6.07295 16 8.38866 16 11Z"
      stroke="#171717"
      strokeWidth="2"
    />
    <path d="M21.5 11H1" stroke="black" strokeWidth="2" />
  </svg>
);

export default BrowserIcon;
