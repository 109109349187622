import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLocationMapView } from '../common/fetch';
import { redirectToNotFound } from './status';
import type { Location, BoundingBox } from '../types/map';

export const SET_MAPV2_LOCATION = 'SET_MAPV2_LOCATION';
export const SET_MAPV2_LOCATION_LOADED = 'SET_MAPV2_LOCATION_LOADED';
export const SET_MAPV2_BOUNDING_BOX = 'SET_MAPV2_BOUNDING_BOX';
export const SET_MAPV2_ERROR = 'SET_MAPV2_ERROR';
export const SET_MAPV2_LOADING = 'SET_MAPV2_LOADING';
export const SET_MAPV2_MESSAGE = 'SET_MAPV2_MESSAGE';
export const FETCH_LOCATION_VIEW_V2 = 'FETCH_LOCATION_VIEW_V2';
export const RESET_MAP_STATE = 'RESET_MAP_STATE';
export const ZOOM_IN = 'ZOOM_IN';

export const setMapLocation = createAction<Location, typeof SET_MAPV2_LOCATION>(SET_MAPV2_LOCATION);

export const setMapLocationLoaded = createAction<void, typeof SET_MAPV2_LOCATION_LOADED>(
  SET_MAPV2_LOCATION_LOADED,
);

export const setMapBoundingBox = createAction<BoundingBox, typeof SET_MAPV2_BOUNDING_BOX>(
  SET_MAPV2_BOUNDING_BOX,
);

export const setMapError = createAction<
  { message: string; status: number } | undefined,
  typeof SET_MAPV2_ERROR
>(SET_MAPV2_ERROR);

export const setMapLoading = createAction<boolean | undefined, typeof SET_MAPV2_LOADING>(
  SET_MAPV2_LOADING,
);

export const setMapMessage = createAction<string, typeof SET_MAPV2_MESSAGE>(SET_MAPV2_MESSAGE);

export const resetMapState = createAction<void, typeof RESET_MAP_STATE>(RESET_MAP_STATE);

export const zoomIn = createAction<Location['center'] | undefined, typeof ZOOM_IN>(ZOOM_IN);

export const fetchLocationView = createAsyncThunk(
  FETCH_LOCATION_VIEW_V2,
  async (geonameId: string, thunkAPI) => {
    try {
      if (parseInt(geonameId, 10)) {
        const response = await fetchLocationMapView(geonameId);
        const { taxonomy, travelContent, boundingBox, breadCrumbs, siblings, url } = response;
        return {
          boundingBox,
          taxonomy,
          travelContent,
          breadCrumbs,
          siblings,
          url,
        };
      }
      throw new Error('geonameId is not a number');
    } catch (error) {
      const err = error as { status: number };
      if (err.status === 400) {
        const message = 'The location could not be found';
        thunkAPI.dispatch(redirectToNotFound(message));
        throw new Error(message);
      }
      throw error;
    }
  },
);
