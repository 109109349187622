import createReducer from '../../createReducer';
import {
  RESET_GRAPH_WEATHER_FORECAST,
  FETCH_GRAPH_WEATHER_FORECAST,
  FETCH_GRAPH_WEATHER_FORECAST_SUCCESS,
  FETCH_GRAPH_WEATHER_FORECAST_FAILURE,
} from '../../../actions/graphs';
import createFiveDayHourlyData from './helpers/createFiveDayHourlyData';
import createDayHourlyData from './helpers/createDayHourlyData';

/**
 * @typedef {import('../../../types/weather').Weater} GraphWeatherState
 */

/**
 * @type {GraphWeatherState}
 */
export const initialState = {
  error: null,
  loading: true,
  weatherIconPath: null,
  units: null,
  days: null,
  hourly: null,
};

const handlers = {};

handlers[RESET_GRAPH_WEATHER_FORECAST] = () => initialState;
handlers[FETCH_GRAPH_WEATHER_FORECAST] = () => initialState;

handlers[FETCH_GRAPH_WEATHER_FORECAST_SUCCESS] = (state, { response, desired16DayInterval }) => ({
  ...state,
  loading: false,
  weatherIconPath: response.associated.weatherIconPath,
  units: response.associated.units,
  days: createDayHourlyData(response.data.weather, 16, desired16DayInterval, (pt) => pt),
  // Note: the first day of hourly data is used by the weather graph, but no other new graphs use the hourly array
  hourly: createFiveDayHourlyData(response.data.weather),
});

handlers[FETCH_GRAPH_WEATHER_FORECAST_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

export default createReducer(handlers, initialState);
