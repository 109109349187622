import { nrNoticeError } from '@surfline/web-common';

import { getKbygQueryParams } from 'hooks/useConstructSpotPageURL';
import {
  fetchRatingForecast,
  fetchRegionalConditionForecast,
  fetchConsistencyForecast,
  fetchSunlightTimes,
  fetchSurfForecast,
  fetchSwellsForecast,
  fetchSwellSpectraForecast,
  fetchTideForecast,
  fetchWeatherForecast,
  fetchWindForecast,
} from '../common/api/kbyg';

const baseFetchGraphForecast =
  (action, success, failure, fetch) =>
  /**
   * @param {object} props
   * @param {string} props.id
   * @param {number} props.days
   * @param {number} props.intervalHours
   * @param {object} [props.units]
   * @param {number} [props.desired16DayInterval]
   * @param {boolean} [props.isGraphUpdates]
   * @param {boolean} [props.isPremium]
   * @param {string} [props.startDate]
   * @param {string} [props.cameraId]

   * @param {Record<string | string[]>} [props.query={}]
   */
  ({
    id,
    days,
    intervalHours,
    units,
    desired16DayInterval = intervalHours,
    isGraphUpdates = false,
    query = {},
    isPremium = false,
    startDate,
    cameraId,
  }) =>
  async (dispatch) => {
    dispatch({ type: action });
    try {
      const {
        corrected,
        correctedRating,
        correctedWind,
        crowdModelName,
        lineupEndpoint,
        noCache,
        ratingsAlgorithm,
        ratingsEndpoint,
        waveCountEndpoint,
      } = query;

      const kbygQueryParams = getKbygQueryParams(query);

      const response = await fetch({
        cameraId,
        corrected,
        correctedRating,
        correctedWind,
        crowdModelName,
        days,
        id,
        intervalHours,
        kbygQueryParams,
        lineupEndpoint,
        noCache,
        ratingsAlgorithm,
        ratingsEndpoint,
        startDate,
        units,
        waveCountEndpoint,
      });

      dispatch({
        type: success,
        intervalHours,
        desired16DayInterval,
        response,
        isGraphUpdates,
        isPremium,
      });
    } catch (error) {
      if (error.statusCode === 400) {
        nrNoticeError(error);
      }

      dispatch({
        type: failure,
        error: {
          message: error.message || 'Something went wrong here.',
          statusCode: error.statusCode,
        },
      });
    }
  };

export const FETCH_GRAPH_CONDITION_FORECAST = 'FETCH_GRAPH_CONDITION_FORECAST';
export const FETCH_GRAPH_CONDITION_FORECAST_SUCCESS = 'FETCH_GRAPH_CONDITION_FORECAST_SUCCESS';
export const FETCH_GRAPH_CONDITION_FORECAST_FAILURE = 'FETCH_GRAPH_CONDITION_FORECAST_FAILURE';

export const fetchGraphConditionForecast = baseFetchGraphForecast(
  FETCH_GRAPH_CONDITION_FORECAST,
  FETCH_GRAPH_CONDITION_FORECAST_SUCCESS,
  FETCH_GRAPH_CONDITION_FORECAST_FAILURE,
  fetchRegionalConditionForecast,
);

export const FETCH_GRAPH_SURF_FORECAST = 'FETCH_GRAPH_SURF_FORECAST';
export const FETCH_GRAPH_SURF_FORECAST_SUCCESS = 'FETCH_GRAPH_SURF_FORECAST_SUCCESS';
export const FETCH_GRAPH_SURF_FORECAST_FAILURE = 'FETCH_GRAPH_SURF_FORECAST_FAILURE';

export const fetchGraphSurfForecast = () =>
  baseFetchGraphForecast(
    FETCH_GRAPH_SURF_FORECAST,
    FETCH_GRAPH_SURF_FORECAST_SUCCESS,
    FETCH_GRAPH_SURF_FORECAST_FAILURE,
    fetchSurfForecast,
  );

export const FETCH_GRAPH_SWELLS_FORECAST = 'FETCH_GRAPH_SWELLS_FORECAST';
export const FETCH_GRAPH_SWELLS_FORECAST_SUCCESS = 'FETCH_GRAPH_SWELLS_FORECAST_SUCCESS';
export const FETCH_GRAPH_SWELLS_FORECAST_FAILURE = 'FETCH_GRAPH_SWELLS_FORECAST_FAILURE';

export const fetchGraphSwellsForecast = () =>
  baseFetchGraphForecast(
    FETCH_GRAPH_SWELLS_FORECAST,
    FETCH_GRAPH_SWELLS_FORECAST_SUCCESS,
    FETCH_GRAPH_SWELLS_FORECAST_FAILURE,
    fetchSwellsForecast,
  );

export const FETCH_GRAPH_TIDE_FORECAST = 'FETCH_GRAPH_TIDE_FORECAST';
export const FETCH_GRAPH_TIDE_FORECAST_SUCCESS = 'FETCH_GRAPH_TIDE_FORECAST_SUCCESS';
export const FETCH_GRAPH_TIDE_FORECAST_FAILURE = 'FETCH_GRAPH_TIDE_FORECAST_FAILURE';

export const fetchGraphTideForecast = baseFetchGraphForecast(
  FETCH_GRAPH_TIDE_FORECAST,
  FETCH_GRAPH_TIDE_FORECAST_SUCCESS,
  FETCH_GRAPH_TIDE_FORECAST_FAILURE,
  fetchTideForecast,
);

export const RESET_GRAPH_WEATHER_FORECAST = 'RESET_GRAPH_WEATHER_FORECAST';
export const FETCH_GRAPH_WEATHER_FORECAST = 'FETCH_GRAPH_WEATHER_FORECAST';
export const FETCH_GRAPH_WEATHER_FORECAST_SUCCESS = 'FETCH_GRAPH_WEATHER_FORECAST_SUCCESS';
export const FETCH_GRAPH_WEATHER_FORECAST_FAILURE = 'FETCH_GRAPH_WEATHER_FORECAST_FAILURE';

export const fetchGraphWeatherForecast = baseFetchGraphForecast(
  FETCH_GRAPH_WEATHER_FORECAST,
  FETCH_GRAPH_WEATHER_FORECAST_SUCCESS,
  FETCH_GRAPH_WEATHER_FORECAST_FAILURE,
  fetchWeatherForecast,
);

export const FETCH_GRAPH_SUNLIGHT_TIMES = 'FETCH_GRAPH_SUNLIGHT_TIMES';
export const FETCH_GRAPH_SUNLIGHT_TIMES_SUCCESS = 'FETCH_GRAPH_SUNLIGHT_TIMES_SUCCESS';
export const FETCH_GRAPH_SUNLIGHT_TIMES_FAILURE = 'FETCH_GRAPH_SUNLIGHT_TIMES_FAILURE';

export const fetchGraphSunlightTimes = baseFetchGraphForecast(
  FETCH_GRAPH_SUNLIGHT_TIMES,
  FETCH_GRAPH_SUNLIGHT_TIMES_SUCCESS,
  FETCH_GRAPH_SUNLIGHT_TIMES_FAILURE,
  fetchSunlightTimes,
);

export const FETCH_GRAPH_WIND_FORECAST = 'FETCH_GRAPH_WIND_FORECAST';
export const FETCH_GRAPH_WIND_FORECAST_SUCCESS = 'FETCH_GRAPH_WIND_FORECAST_SUCCESS';
export const FETCH_GRAPH_WIND_FORECAST_FAILURE = 'FETCH_GRAPH_WIND_FORECAST_FAILURE';

export const fetchGraphWindForecast = baseFetchGraphForecast(
  FETCH_GRAPH_WIND_FORECAST,
  FETCH_GRAPH_WIND_FORECAST_SUCCESS,
  FETCH_GRAPH_WIND_FORECAST_FAILURE,
  fetchWindForecast,
);

export const FETCH_GRAPH_RATING_FORECAST = 'FETCH_GRAPH_RATING_FORECAST';
export const FETCH_GRAPH_RATING_FORECAST_SUCCESS = 'FETCH_GRAPH_RATING_FORECAST_SUCCESS';
export const FETCH_GRAPH_RATING_FORECAST_FAILURE = 'FETCH_GRAPH_RATING_FORECAST_FAILURE';

export const fetchGraphRatingForecast = baseFetchGraphForecast(
  FETCH_GRAPH_RATING_FORECAST,
  FETCH_GRAPH_RATING_FORECAST_SUCCESS,
  FETCH_GRAPH_RATING_FORECAST_FAILURE,
  fetchRatingForecast,
);

export const RESET_GRAPH_CONSISTENCY_FORECAST = 'RESET_GRAPH_CONSISTENCY_FORECAST';
export const FETCH_GRAPH_CONSISTENCY_FORECAST = 'FETCH_GRAPH_CONSISTENCY_FORECAST';
export const FETCH_GRAPH_CONSISTENCY_FORECAST_SUCCESS = 'FETCH_GRAPH_CONSISTENCY_FORECAST_SUCCESS';
export const FETCH_GRAPH_CONSISTENCY_FORECAST_FAILURE = 'FETCH_GRAPH_CONSISTENCY_FORECAST_FAILURE';

export const fetchGraphConsistencyForecast = baseFetchGraphForecast(
  FETCH_GRAPH_CONSISTENCY_FORECAST,
  FETCH_GRAPH_CONSISTENCY_FORECAST_SUCCESS,
  FETCH_GRAPH_CONSISTENCY_FORECAST_FAILURE,
  fetchConsistencyForecast,
);

export const FETCH_GRAPH_SWELL_SPECTRA_FORECAST = 'FETCH_GRAPH_SWELL_SPECTRA_FORECAST';
export const FETCH_GRAPH_SWELL_SPECTRA_FORECAST_SUCCESS =
  'FETCH_GRAPH_SWELL_SPECTRA_FORECAST_SUCCESS';
export const FETCH_GRAPH_SWELL_SPECTRA_FORECAST_FAILURE =
  'FETCH_GRAPH_SWELL_SPECTRA_FORECAST_FAILURE';
export const RESET_GRAPH_SWELL_SPECTRA_FORECAST = 'RESET_GRAPH_SWELL_SPECTRA_FORECAST';

export const fetchGraphSwellSpectraForecast = baseFetchGraphForecast(
  FETCH_GRAPH_SWELL_SPECTRA_FORECAST,
  FETCH_GRAPH_SWELL_SPECTRA_FORECAST_SUCCESS,
  FETCH_GRAPH_SWELL_SPECTRA_FORECAST_FAILURE,
  fetchSwellSpectraForecast,
);
