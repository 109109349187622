import React from 'react';
import classNames from 'classnames';

import styles from './NewsIcon.module.scss';

const NewsIcon = () => (
  <svg
    className={classNames(styles.newsIcon, 'quiver-news-icon')}
    viewBox="0 0 12 10"
    version="1.1"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-549.000000, -156.000000)" fillRule="nonzero">
        <g transform="translate(538.000000, 124.000000)">
          <g transform="translate(10.000000, 27.000000)">
            <g transform="translate(0.000000, 3.000000)">
              <rect x="0" y="0" />
              <path
                d="M0.998826291,2 L12.9988263,2 L12.9988263,12 L0.998826291,12 L0.998826291,2 Z M2.99882629,4 L2.99882629,7 L10.9988263,7 L10.9988263,4 L2.99882629,4 Z M2.99882629,9 L2.99882629,10 L8.99882629,10 L8.99882629,9 L2.99882629,9 Z"
                id="Combined-Shape"
                fill="#96A9B1"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default NewsIcon;
