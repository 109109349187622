import type { TaxonomyNode } from 'types/header';

import alphabetizeItems from './alphabetizeItems';

const groupItems = (
  items: Array<TaxonomyNode>,
  spotReportViews: Array<{
    _id?: string;
    cameras: Array<{}>;
    conditions: any;
    insightsCameraId?: string | null;
  }>,
) => {
  const cities = alphabetizeItems(items.filter((item) => item.type === 'geoname' && item.hasSpots));
  const spots = items
    .filter((item) => item.type === 'spot')
    .map((spot) => {
      const spotReportView = spotReportViews.find(({ _id }) => _id === spot.spot);
      if (!spotReportView) {
        return spot;
      }
      const newSpot = {
        ...spot,
        cameras:
          spotReportView.cameras && spotReportView.cameras.length > 0 ? spotReportView.cameras : [],
        hasCamera: spotReportView.cameras.length > 0,
        conditions: spotReportView.conditions,
        insightsCameraId: spotReportView.insightsCameraId,
      };
      return newSpot;
    });
  if (cities.length === 0) {
    return [
      {
        city: null,
        spots: alphabetizeItems(spots),
      },
    ];
  }

  return cities.map((city) => ({
    city,
    spots: alphabetizeItems(spots.filter((spot) => spot?.liesIn?.includes(city?._id ?? ''))),
  }));
};

export default groupItems;
