import { slugify } from '@surfline/web-common';

export const spotReportPaths = {
  base: '/surf-report',
  baseWavePool: '/wave-pool',
};

type Props =
  | {
      camId?: string;
      corrected?: boolean;
      initialStartDayIndex?: number | string;
      lineupEndpoint?: string;
      ratingsAlgorithm?: string;
      ratingsEndpoint?: string;
      startDate?: string;
      noCache?: boolean;
      view?: string;
      wavecountEndpoint?: string;
    }
  | Record<string, string>;

export const spotReportPath = (
  queryParams: Props,
  pathDetails: { _id: string; isWavePool?: boolean; name: string },
) => {
  const result = Object.entries(queryParams)
    .filter(
      ([key, value]) =>
        value !== undefined && value !== null && key !== 'spotSlug' && key !== 'spotId',
    )
    .map(([key, value]) => `${key}=${value}`);

  const newUrl = `${
    !!pathDetails?.isWavePool === true ? spotReportPaths.baseWavePool : spotReportPaths.base
  }/${slugify(pathDetails?.name)}/${pathDetails?._id}${
    result.length ? `?${result.join('&')}` : ''
  }`;

  return newUrl;
};

export default spotReportPath;
