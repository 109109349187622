import { useMemo } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { getUserFavorites } from '@surfline/web-common';

/**
 * @param {string | undefined } spotId
 */
export const useSpotIsFavorite = (spotId) => {
  const favorites = useSelector(getUserFavorites, shallowEqual);
  const isFavorite = useMemo(
    () => !!favorites.find((favorite) => favorite._id === spotId),
    [favorites, spotId],
  );

  return isFavorite;
};
