import React from 'react';
import { contestLinks, newsLinks } from '../../defaultLinks';

import BaseMenu from '../BaseMenu';
import BaseMenuSection from '../BaseMenuSection';

interface NewsMenuProps {
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
}

const NewsMenu = ({ onClickLink }: NewsMenuProps) => {
  const onClickHandler = (properties: any, closeMobileMenu: boolean) => {
    if (onClickLink) {
      onClickLink(
        {
          name: 'Clicked Main Nav',
          properties: {
            ...properties,
            tabName: 'News',
            clickEndLocation: 'News',
          },
        },
        closeMobileMenu,
      );
    }
  };
  return (
    <BaseMenu testId="news-menu">
      <BaseMenuSection links={contestLinks} onClick={onClickHandler} />
      <BaseMenuSection links={newsLinks} onClick={onClickHandler} />
    </BaseMenu>
  );
};

export default NewsMenu;
