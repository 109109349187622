import { getCanUseLocalStorage } from './canUseLocalStorage';

const LOCAL_STORAGE_KEY = 'multi-cam:preferences';

/**
 * @description Grabs the multi cam preferences from local storage. If
 * local storage is disabled, it will return an empty objet to
 * prevent breaking code.
 * @returns {{}}
 */
export const getMultiCamPreferences = () => {
  if (getCanUseLocalStorage()) {
    return JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY)) || {};
  }
  return {};
};

/**
 * @description Saves the multi cam selected spots preference to local storage
 * for each different collection the user has available
 * @param {string} activeCollectionId
 * @param {boolean} activeCollectionFetchedSpots
 * @param {number} numCams
 * @param {[{
 *  _id: String
 * }]} selectedSpots
 * @returns {void}
 */
export const saveMultiCamPreference = (
  activeCollectionId,
  activeCollectionFetchedSpots,
  selectedSpots,
  numCams,
) => {
  const current = getMultiCamPreferences();

  if (
    getCanUseLocalStorage() &&
    activeCollectionId &&
    activeCollectionFetchedSpots &&
    selectedSpots.length
  ) {
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        ...current,
        [activeCollectionId]: selectedSpots.map(
          (spot) => `${spot?._id}:${spot?.selectedCamAngleIndex || ''}`,
        ),
        numCams: {
          ...current.numCams,
          [activeCollectionId]: numCams,
        },
      }),
    );
  }
};
