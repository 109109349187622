import React from 'react';
import { Button, Typography } from '@mui/material';

import WavetrakLink from 'components/WavetrakLink';
import type { TaxonomyNode } from 'types/header';

import styles from './Header.module.scss';

interface HeaderProps {
  activeNode: TaxonomyNode;
  label?: string;
  listType?: string;
  onClickChange?: () => void;
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
}

const Header = ({
  activeNode,
  label = '',
  listType = '',
  onClickChange,
  onClickLink,
}: HeaderProps) => {
  const { name, links } = activeNode;
  const www = listType === 'spots' && links && links.find((link) => link && link.key === 'www');
  const travel =
    listType === 'spots' && links && links.find((link) => link && link.key === 'travel');

  const subregionTitle = listType === 'subregions' ? ' Forecasts' : '';

  const handleClickedChange = () => {
    if (onClickChange) {
      onClickChange();
    }
    if (onClickLink) {
      onClickLink(
        {
          completion: false,
          linkName: 'change',
        },
        false,
      );
    }
  };

  const handleClickedSpotLink = () => {
    if (onClickLink) {
      onClickLink(
        {
          clickEndLocation: 'Map',
          destinationUrl: !www ? '' : www?.href,
          completion: true,
          linkName: 'View spots on map',
        },
        true,
      );
    }
  };

  const handleClickedTravelLink = () => {
    if (onClickLink) {
      onClickLink(
        {
          clickEndLocation: 'Travel',
          destinationUrl: !travel ? '' : travel?.href,
          completion: true,
          linkName: 'View travel information',
        },
        true,
      );
    }
  };

  return (
    <div className={styles.header}>
      <Typography variant="h5" className={styles.headerTitle}>
        {listType === 'geonames' && label ? `Select ${label}` : `${name}${subregionTitle}`}
      </Typography>
      {www ? (
        <Typography
          variant="h6"
          component={WavetrakLink}
          href={www?.href ?? ''}
          onClick={handleClickedSpotLink}
        >
          View spots on map
        </Typography>
      ) : null}
      {listType === 'spots' && travel ? (
        <Typography
          variant="h6"
          component={WavetrakLink}
          href={travel?.href ?? ''}
          onClick={handleClickedTravelLink}
        >
          View travel information
        </Typography>
      ) : null}
      <Button
        className={styles.button}
        onClick={handleClickedChange}
        size="small"
        variant="secondary"
      >
        Change
      </Button>
    </div>
  );
};

export default Header;
