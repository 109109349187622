import { oneDaySeconds } from 'utils/time';
import createReducer from '../../createReducer';
import {
  FETCH_GRAPH_SWELLS_FORECAST,
  FETCH_GRAPH_SWELLS_FORECAST_SUCCESS,
  FETCH_GRAPH_SWELLS_FORECAST_FAILURE,
} from '../../../actions/graphs';
import createDayHourlyData from './helpers/createDayHourlyData';
import createFiveDayHourlyData from './helpers/createFiveDayHourlyData';

/**
 * @typedef {import('../../../types/swell').SwellsData} GraphSwellState
 */

/**
 * @type {GraphSwellState}
 */
export const initialState = {
  error: null,
  loading: true,
  units: null,
  utcOffset: null,
  location: null,
  forecastLocation: null,
  offshoreLocation: null,
  overallMaxSwellHeight: null,
  days: null,
  runInitializationTimestamp: null,
};

const handlers = {};

handlers[FETCH_GRAPH_SWELLS_FORECAST] = () => initialState;

const maxSwell = (swells) => Math.max(...swells.map((swell) => swell.height));

handlers[FETCH_GRAPH_SWELLS_FORECAST_SUCCESS] = (
  state,
  { response, desired16DayInterval, isGraphUpdates },
) => {
  const mapFunc = ({ power, probability, timestamp, utcOffset, swells }) => ({
    power,
    probability,
    timestamp,
    utcOffset,
    swells: swells.map((swell, index) => ({
      ...swell,
      index,
    })),
  });

  const data = response.data.swells;

  const returnedDays = createDayHourlyData(data, 16, desired16DayInterval, mapFunc);

  const [lastDayOfData] = returnedDays.slice(-1);
  const [lastHourOfData] = lastDayOfData.slice(-1);

  const missingDays = Array.from({ length: 16 - returnedDays.length }, (_, index) =>
    Array.from({ length: isGraphUpdates ? 24 / desired16DayInterval : 24 }, (_, hourIndex) => ({
      probability: null,
      timestamp: lastHourOfData.timestamp + oneDaySeconds * (index + 1) + hourIndex,
      utcOffset: lastHourOfData.utcOffset,
      // fill with the same number of swells from the last bit of fetched data
      swells: Array(lastHourOfData.swells.length).fill({
        height: null,
        period: null,
        direction: null,
        directionMin: null,
        optimalScore: null,
        index: null,
      }),
    })),
  );
  const combinedDays = [].concat(returnedDays, missingDays);

  return {
    ...state,
    loading: false,
    units: response.associated.units,
    utcOffset: response.associated.utcOffset,
    location: response.associated.location,
    forecastLocation: response.associated.forecastLocation,
    offshoreLocation: response.associated.offshoreLocation,
    runInitializationTimestamp: response.associated.runInitializationTimestamp,
    overallMaxSwellHeight: Math.max(...response.data.swells.map(({ swells }) => maxSwell(swells))),
    days: combinedDays,
    hourly: createFiveDayHourlyData(response.data.swells, mapFunc),
  };
};

handlers[FETCH_GRAPH_SWELLS_FORECAST_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

export default createReducer(handlers, initialState);
