import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './PageRail.module.scss';

interface PageRailProps {
  children?: ReactNode;
  side: 'left' | 'right';
  open?: boolean;
  className?: string;
}

const getClassNames = (
  side: 'left' | 'right',
  isOpen: boolean = false,
  className: string = '',
): string =>
  classNames({
    'quiver-page-rail': true,
    [styles.pageRailLeft]: side === 'left',
    [styles.pageRailRight]: side === 'right',
    [`quiver-page-rail--${side}`]: true,
    [`quiver-page-rail--${side}--open`]: isOpen,
    [`quiver-page-rail--${side}--closed`]: !isOpen,
    [className]: !!className,
  });

const PageRail: React.FC<PageRailProps> = ({ children, side, open = false, className = '' }) => (
  <div className={getClassNames(side, open, className)}>{children}</div>
);

export default PageRail;
