import React from 'react';

const PremiumAnalysis = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="47" height="47" viewBox="0 0 47 47">
    <g fill="none" fillRule="evenodd" strokeWidth="2">
      <path
        stroke="#FFF"
        d="M25.587 30.96a9.944 9.944 0 0 0 8.008-2.962l.247-.25.35-.041C40.894 26.914 46 21.209 46 14.4 46 7 40 1 32.6 1h-1.2C24 1 18 7 18 14.4c0 5.72 3.617 10.753 8.922 12.634l1.124.398-.589 1.038a12.229 12.229 0 0 1-1.87 2.49z"
      />
      <path
        stroke="#22D737"
        d="M23 16.465c2.526-5.642 9.6-9.424 14-3.403-5.43 0-5.43 5.688 0 6.938"
      />
      <g stroke="#FFF">
        <g transform="translate(1 24)">
          <path strokeLinecap="round" d="M0 22c0-3.6 5.762-6 11-6s11 2.4 11 6" />
          <circle cx="11" cy="5" r="5" />
        </g>
      </g>
    </g>
  </svg>
);

export default PremiumAnalysis;
