import React from 'react';
import classNames from 'classnames';

import styles from './CamIcon.module.scss';

const SingleCamIcon = ({ className }: { className?: string }) => (
  <svg
    viewBox="0 0 12 12"
    version="1.1"
    className={classNames(styles.camIcon, 'quiver-cam-icon', className)}
    data-testid="cam-icon-single"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g className="quiver-cam-icon__icon" fill="#96A9B2">
        <path d="M12,3 L12,9 L9,7.09090909 L9,4.90909091 L12,3 Z M9,8.93333333 C9,9.5224 8.53945714,10 7.97142857,10 L1.02857143,10 C0.460542857,10 0,9.5224 0,8.93333333 L0,3.06666667 C0,2.4776 0.460542857,2 1.02857143,2 L7.97142857,2 C8.53945714,2 9,2.4776 9,3.06666667 L9,8.93333333 Z" />
      </g>
    </g>
  </svg>
);

const MultiCamIcon = ({ className }: { className?: string }) => (
  <svg
    width="14px"
    height="10px"
    viewBox="0 0 14 10"
    className={classNames('quiver-multi-cam-icon', className)}
    data-testid="cam-icon-multi"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        className="quiver-multi-cam-icon__icon"
        transform="translate(-516.000000, -238.000000)"
        fill="#96A9B2"
      >
        <g transform="translate(500.000000, 56.000000)">
          <g transform="translate(16.000000, 176.000000)">
            <g transform="translate(0.000000, 6.000000)">
              <g>
                <path d="M14,2.83377778 L14,8.98844444 L11.3333333,7.33333333 L11.3333333,9.19342222 C11.3333333,9.63893333 11.0324444,10 10.6611852,10 L2.67214815,10 C2.30088889,10 2,9.63893333 2,9.19342222 L2,2.80657778 C2,2.36106667 2.30088889,2 2.67214815,2 L10.6611852,2 C11.0324444,2 11.3333333,2.36106667 11.3333333,2.80657778 L11.3333333,4.48888889 L14,2.83377778 Z" />
                <path
                  d="M1,0 L8.5,0 C8.77614237,-5.07265313e-17 9,0.223857625 9,0.5 L9,0.5 C9,0.776142375 8.77614237,1 8.5,1 L0,1 L0,1 C-6.76353751e-17,0.44771525 0.44771525,1.01453063e-16 1,0 Z"
                  fillRule="nonzero"
                />
                <path
                  d="M0,1 L1,1 L1,7.5 C1,7.77614237 0.776142375,8 0.5,8 L0.5,8 C0.223857625,8 3.38176876e-17,7.77614237 0,7.5 L0,1 Z"
                  fillRule="nonzero"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

const MultiCamIconWithText = ({ className }: { className?: string }) => (
  <svg
    width="45px"
    height="10px"
    viewBox="0 0 45 10"
    className={classNames('quiver-multi-cam-icon-with-text', className)}
    data-testid="cam-icon-multi-text"
  >
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        className="quiver-multi-cam-icon-with-text__icon"
        transform="translate(0.000000, -1.000000)"
        fill="#96A9B2"
      >
        <g>
          <g>
            <path d="M45,3.83377778 L45,9.98844444 L42.3333333,8.33333333 L42.3333333,10.1934222 C42.3333333,10.6389333 42.0324444,11 41.6611852,11 L33.6721481,11 C33.3008889,11 33,10.6389333 33,10.1934222 L33,3.80657778 C33,3.36106667 33.3008889,3 33.6721481,3 L41.6611852,3 C42.0324444,3 42.3333333,3.36106667 42.3333333,3.80657778 L42.3333333,5.48888889 L45,3.83377778 Z" />
            <text
              fontFamily="SourceSansPro-Semibold, Source Sans Pro"
              fontSize="10"
              fontWeight="500"
              letterSpacing="0.3333334"
            >
              <tspan x="0" y="10">
                MULTI
              </tspan>
            </text>
            <path
              d="M32,1 L39.5,1 C39.7761424,1 40,1.22385763 40,1.5 L40,1.5 C40,1.77614237 39.7761424,2 39.5,2 L31,2 L31,2 C31,1.44771525 31.4477153,1 32,1 Z"
              fillRule="nonzero"
            />
            <path
              d="M31,2 L32,2 L32,8.5 C32,8.77614237 31.7761424,9 31.5,9 L31.5,9 C31.2238576,9 31,8.77614237 31,8.5 L31,2 Z"
              fillRule="nonzero"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const CamIcon = ({
  className,
  isMultiCam = false,
  withText = false,
}: {
  className?: string;
  isMultiCam?: boolean;
  withText?: boolean;
}) => {
  if (isMultiCam) {
    return withText ? (
      <MultiCamIconWithText className={className} />
    ) : (
      <MultiCamIcon className={className} />
    );
  }
  return <SingleCamIcon className={className} />;
};

export default CamIcon;
