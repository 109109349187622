import React, { useRef, useState } from 'react';
import { useMount } from 'react-use';
import { Cookies } from 'react-cookie';
import { isNil } from 'lodash';
import { Typography } from '@mui/material';
import { trackEvent, type UserDetails } from '@surfline/web-common';

import Favorite from 'components/Favorite';
import { Chevron, MultiCam } from 'components/Icons';
import WavetrakLink from 'components/WavetrakLink';
import type { TaxonomyNavigatorSettings } from 'types/header';
import type { FavoriteItem } from 'types/favorites';
import createAccessibleOnClick from 'utils/createAccessibleOnClick';

import AddFavorites from './components/AddFavorites';

import styles from './FavoritesBar.module.scss';

const FAVORITES_POSITION_COOKIE = 'favoritesPosition';
const cookie = new Cookies();

interface FavoritesBarProps {
  settings?: TaxonomyNavigatorSettings;
  favorites?: Array<FavoriteItem>;
  user?: Partial<UserDetails> | null;
}

const FavoritesBar = ({ favorites = [], settings, user }: FavoritesBarProps) => {
  const [scrollTS, setScrollTS] = useState<number>(new Date().getTime());
  const ulRef = useRef<HTMLDivElement>(null);

  const saveScrollPosition = (scrollTo: number) => {
    const hour = 60 * 60;
    const day = hour * 24;
    const month = day * 30;
    cookie.set(FAVORITES_POSITION_COOKIE, scrollTo, { path: '/', maxAge: month });
  };

  const detectScroll = (element: HTMLDivElement | null) => {
    if (element?.scrollLeft) {
      const scrollPos = element.scrollLeft;
      const currentScrollTS = new Date().getTime();
      const scrollTSDiff = currentScrollTS - scrollTS;
      // only check every second during scrolls, wait a second to set the latest value.
      if (scrollTSDiff > 1000) {
        // reset the timer
        setScrollTS(currentScrollTS);
        setTimeout(() => {
          saveScrollPosition(scrollPos);
        }, 1000);
      }
    }
  };

  const handleScroll = (element: HTMLDivElement, to: number, duration: number) => {
    if (duration <= 0) {
      // save scroll position cookie on last scroll event
      saveScrollPosition(to);
      return;
    }
    if (!isNil(element?.scrollLeft)) {
      const el = element;
      const difference = to - (el?.scrollLeft ?? 0);
      const perTick = (difference / duration) * 10;
      setTimeout(() => {
        el.scrollLeft += perTick;
        handleScroll(el, to, duration - 10);
      }, 10);
    }
  };

  const adjustScrollPosition = (direction: string) => {
    if (ulRef?.current) {
      if (direction === 'left') {
        handleScroll(ulRef.current, ulRef.current.scrollLeft - ulRef.current.clientWidth, 150);
      } else if (direction === 'right') {
        handleScroll(ulRef.current, ulRef.current.scrollLeft + ulRef.current.clientWidth, 150);
      }
    }
  };

  const initScrollPosition = (element: HTMLDivElement | null) => {
    const favoritesPositionCookie = cookie.get(FAVORITES_POSITION_COOKIE);
    if (favoritesPositionCookie && element !== null) {
      handleScroll(element, favoritesPositionCookie, 10);
    }
  };

  useMount(() => {
    initScrollPosition(ulRef?.current);
  });

  return (
    <div className={styles.favoritesBar} data-testid="header-favorites-bar">
      <div className={styles.content}>
        <div className={styles.contentFavorites}>
          <div className={styles.link}>
            <Typography
              className={styles.linkAnchor}
              component={WavetrakLink}
              data-testid="favorites-bar-multi-cam"
              href="/surf-cams"
              variant="overline3"
            >
              <MultiCam className={styles.linkIcon} />
              <span className={styles.linkLabel}>Multi-cam</span>
            </Typography>
          </div>
          <div
            className={styles.scrollWrapper}
            onScroll={() => detectScroll(ulRef?.current)}
            ref={ulRef}
          >
            <ul className={styles.contentFavoritesList}>
              {favorites.map((favorite) => (
                <li
                  className={styles.contentFavoritesItem}
                  data-testid="favorite-list-item"
                  key={favorite._id}
                >
                  <Favorite
                    favorite={favorite}
                    settings={settings}
                    large={false}
                    onClickLink={({ name, properties }) => trackEvent(name, properties)}
                  />
                </li>
              ))}
            </ul>
            <AddFavorites user={user} />
          </div>
          <div className={styles.contentFavoritesArrows}>
            {['left', 'right'].map((dir) => (
              <div
                key={dir}
                className={styles.contentFavoritesArrow}
                {...createAccessibleOnClick(() => adjustScrollPosition(dir), 'button')}
              >
                <Chevron direction={dir} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FavoritesBar;
