import React from 'react';

export const ClockIcon: React.FC<{ className: string }> = ({ className = '' }) => (
  <svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" className={className}>
    <defs>
      <circle id="path-1" cx="6" cy="6" r="6" />
      <mask
        id="mask-2"
        maskContentUnits="userSpaceOnUse"
        maskUnits="objectBoundingBox"
        x="0"
        y="0"
        width="12"
        height="12"
        fill="white"
      >
        <use xlinkHref="#path-1" />
      </mask>
    </defs>
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="premium-analysis-paywall" transform="translate(-41.000000, -24.000000)">
        <g id="Group-4">
          <g id="Group-2-Copy-2" transform="translate(41.000000, 22.000000)">
            <g id="Group">
              <g id="Group-2" transform="translate(0.000000, 2.000000)">
                <g id="Group">
                  <use
                    id="Oval-3"
                    stroke="#999999"
                    mask="url(#mask-2)"
                    strokeWidth="1.2"
                    xlinkHref="#path-1"
                  />
                  <path
                    d={`M6.6,6 L6.6,2.70205364 C6.6,2.53974152 6.46568542,2.4 6.3,2.4 C6.13916016,
                    2.4 6,2.53523402 6,2.70205364 L6,6.29794636 L6.00000179,6.29897346 L6,
                    6.3 C6,6.46083984 6.1318983,6.6 6.29460311,6.6 L9.30539689,6.6 C9.46327171,
                    6.6 9.6,6.46568542 9.6,6.3 C9.6,6.13916016 9.4681017,6 9.30539689,6 L6.6,6 Z`}
                    id="Combined-Shape"
                    fill="#999999"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default ClockIcon;
