import LogRocket from 'logrocket';
import { Cookies } from 'react-cookie';
import setupLogRocketReact from 'logrocket-react';
import { getUserDetails, getWavetrakIdentity } from '@surfline/web-common';
import { endsWith } from 'lodash';

import { NextRouter } from 'next/router';
import { getUserWasLoggedOut } from 'selectors/user';
import config from '../config';
import { type WavetrakStore } from '../stores';

const cookies = new Cookies();

const setupLogRocket = (store: WavetrakStore, query: NextRouter['query']) => {
  const { anonymousId, email } = getWavetrakIdentity() || {};
  let logRocketEnabled;

  const isInternalAccount =
    email && (endsWith(email, '@surfline.com') || endsWith(email, '@magicseaweed.com'));

  if (query?.logrocket === 'on' || isInternalAccount) {
    logRocketEnabled = true;
    const expire = new Date();
    expire.setDate(expire.getDate() + 1);

    cookies.set('log_rocket_enabled', true, { path: '/', expires: expire });
  } else {
    logRocketEnabled = cookies.get('log_rocket_enabled') === 'true';
  }

  if (logRocketEnabled) {
    LogRocket.init(config.appKeys.logrocket, {
      network: {
        requestSanitizer: (req) => {
          req.headers['X-Auth-AccessToken'] = 'redacted';
          req.url = req.url.replace(/access(_)?token=([^&]*)/, 'accesstoken=**redacted**');
          req.url = req.url.replace(/email=([^&]*)/, 'email=**redacted**');

          if (req.body) {
            req.body = req.body.replace(/firstName=([^&]*)/, 'firstName=**redacted**');
            req.body = req.body.replace(/lastName=([^&]*)/, 'lastName=**redacted**');
            req.body = req.body.replace(/email=([^&]*)/, 'email=**redacted**');
            req.body = req.body.replace(/password=([^&]*)/, 'password=**redacted**');
            req.body = req.body.replace(/access(_)?token=([^&]*)/, 'accesstoken=**redacted**');
          }

          return req;
        },

        responseSanitizer: (res) => {
          if (res.body) {
            res.body = res.body.replace(/firstName=([^&]*)/, 'firstName=**redacted**');
            res.body = res.body.replace(/lastName=([^&]*)/, 'lastName=**redacted**');
            res.body = res.body.replace(/email=([^&]*)/, 'email=**redacted**');
            res.body = res.body.replace(/password=([^&]*)/, 'password=**redacted**');
            res.body = res.body.replace(/access(_)?token=([^&]*)/, 'accesstoken=**redacted**');
          }

          return res;
        },
      },
    });

    setupLogRocketReact(LogRocket);

    const storeState = store.getState();
    const userData = getUserDetails(storeState);
    const wasUserLoggedOut = getUserWasLoggedOut(storeState);
    if (userData) {
      LogRocket.identify(userData?._id || anonymousId || 'Unidentified');
    }

    if (wasUserLoggedOut) {
      LogRocket.track('User Logged Out', { anonymousId: anonymousId || 'Unidentified' });
    }
  }
};

export default setupLogRocket;
