import type { NextRouter } from 'next/router';
import { once } from 'lodash';
import {
  BrazeEvents,
  addBrazeAlias,
  canUseDOM,
  createSplitFilter,
  getAppboy,
  getUser,
  getWavetrakSplitFilters,
  getWindow,
  refreshContentCards,
  setWavetrakAnalyticsReadyCB,
  setWavetrakFeatureFrameworkCB,
  setupFeatureFramework,
} from '@surfline/web-common';

import * as treatments from 'common/treatments';
import config from 'config';
import { type WavetrakStore } from 'stores';
import setupLogRocket from 'utils/setupLogRocket';

const setupAnalytics = (store: WavetrakStore) => {
  const brazeTimeout = setTimeout(() => {
    getWindow()?.document.dispatchEvent(new CustomEvent(BrazeEvents.BRAZE_TIMEOUT));
  }, 3000);

  const analyticsCallback = () => {
    getWindow()?.document.dispatchEvent(new CustomEvent(BrazeEvents.BRAZE_LOADED));
    addBrazeAlias();
    const { subdivisionCode } = getUser(store.getState());
    getAppboy()?.getUser()?.setCustomUserAttribute('location.lastSubdivisionOne', subdivisionCode);
    setTimeout(() => {
      if (!getAppboy()?.getCachedContentCards()?.cards.length) {
        refreshContentCards();
      }
    }, 6000);
    clearTimeout(brazeTimeout);
  };
  setWavetrakAnalyticsReadyCB(analyticsCallback);
};

export const instantiateClientSideAnalytics = async (
  store: WavetrakStore,
  router: NextRouter,
  setAnalyticsReady: () => void,
) => {
  if (canUseDOM) {
    try {
      setWavetrakFeatureFrameworkCB(
        once(() => {
          setupAnalytics(store);
          setupLogRocket(store, router.query);
          setAnalyticsReady();
        }),
      );

      try {
        await setupFeatureFramework({
          authorizationKey: config.splitio,
          user: getUser(store.getState()),
          splitFilters: [...createSplitFilter(treatments), ...getWavetrakSplitFilters()],
        });
      } catch (_) {
        // We can ignore errors for the time being since it can fail for users with adblock
      }
    } catch (error) {
      getWindow()?.newrelic?.noticeError(error);
    }
  }
};

export default setupAnalytics;
