import React from 'react';

interface SurflineLogoProps {
  className?: string;
  fill?: string;
}
export const SurflineLogo = ({ className, fill = '#ffffff' }: SurflineLogoProps) => (
  <svg className={className} width="105px" height="18px" viewBox="0 0 105 18" role="img">
    <title>Surfline</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Navigation" transform="translate(-121.000000, -357.000000)" fill={fill}>
        <g id="surfline-header-desktop" transform="translate(51.000000, 336.000000)">
          <g id="surfline-logo" transform="translate(70.000000, 21.000000)">
            <path
              d="M6.71291516,15.6154353 C6.34045631,15.6102457 5.98021758,15.5580901 5.63754526,15.4636392 C7.50314911,15.0002069 8.89853321,13.2982738 8.92551929,11.2538264 C8.93290228,10.6466418 9.42170678,10.1611537 10.0176918,10.169976 C10.6121494,10.1782794 11.08797,10.6762226 11.0800779,11.2818503 C11.0474909,13.7087721 9.09278208,15.6476109 6.71291516,15.6154353 L6.71291516,15.6154353 Z M3.52473871,12.2777367 C2.92900823,12.2686549 2.45293301,11.7720091 2.46107975,11.1653435 C2.49315755,8.73868116 4.44786638,6.79906387 6.82824247,6.83201791 C7.19993756,6.8361696 7.56068547,6.88988207 7.90412155,6.98459248 C6.03775394,7.44672739 4.64287901,9.14710358 4.61563835,11.1941458 C4.60800077,11.8008114 4.11919627,12.2860401 3.52473871,12.2777367 L3.52473871,12.2777367 Z M12.7914028,8.36035849 C12.0462305,6.4139947 6.76994235,0.058018055 6.76994235,0.058018055 C6.76994235,0.058018055 1.49289042,6.4139947 0.748481894,8.36035849 C0.349036965,9.23013737 0.125256154,10.2003352 0.125256154,11.2226887 C0.125256154,14.9636201 3.1000898,17.9946127 6.76994235,17.9946127 C10.4395403,17.9946127 13.4141194,14.9636201 13.4141194,11.2226887 C13.4141194,10.2003352 13.1903386,9.23013737 12.7914028,8.36035849 L12.7914028,8.36035849 Z"
              id="Fill-1"
            />
            <path
              d="M17.6672277,11.5496342 L17.9200313,11.7805719 C18.8072624,12.5795126 19.9231114,13.2313278 21.2227713,13.2313278 C21.7803139,13.2313278 22.7036962,13.0001306 22.814441,12.2852616 C22.9254403,11.5703927 22.1929973,11.5073389 21.6929911,11.4019898 L20.5906351,11.191551 C18.444987,10.7919509 16.7942534,9.46730261 17.1799507,6.98511145 C17.7644795,3.22160521 21.4557172,1.1179962 24.8386517,1.1179962 C26.6128593,1.1179962 28.1742332,1.55963213 29.5545968,2.5056983 L27.3967286,6.56475292 C26.5313919,5.89191979 25.5194138,5.36621215 24.3024943,5.36621215 C23.8068159,5.36621215 23.0557882,5.555114 22.9544631,6.20744817 C22.8630668,6.79620959 23.5512119,6.88028129 23.9682233,6.98511145 L25.2019455,7.27988138 C27.5097646,7.82712591 29.0405883,9.13127527 28.6220495,11.8226078 C28.0344657,15.6079104 24.3600306,17.4795438 20.9959355,17.4795438 C19.0363895,17.4795438 16.8512806,16.9330777 15.2382258,16.0287879 L17.6672277,11.5496342"
              id="Fill-2"
            />
            <path
              d="M35.707678,6.06032268 L34.8120456,11.8226078 C34.7112297,12.473904 34.6450374,13.168274 35.5113925,13.168274 C36.3772384,13.168274 36.5271893,12.473904 36.6280053,11.8226078 L37.5231285,6.06032268 L42.8062905,6.06032268 L41.8253719,12.3690739 C41.2762306,15.9024208 38.0185522,17.4795438 34.8418322,17.4795438 C31.6635845,17.4795438 28.8957291,15.9024208 29.445125,12.3690739 L30.4262982,6.06032268 L35.707678,6.06032268"
              id="Fill-3"
            />
            <path
              d="M52.4489777,11.3176586 C51.9446434,10.9811123 51.5953519,10.7081387 50.9135714,10.7081387 C49.5321895,10.7081387 48.7002035,11.5496342 48.4838057,12.9375958 L47.8173004,17.2278476 L42.4934047,17.2278476 L44.2294245,6.06032268 L49.5523018,6.06032268 L49.1971548,7.55363338 L49.3201197,7.55363338 C50.4983422,6.08108113 51.5171941,5.76581223 53.3061676,5.80784808 L52.4489777,11.3176586"
              id="Fill-4"
            />
            <path
              d="M55.8293663,5.0717017 C56.3362464,1.81210673 58.0610643,0.003267654 61.362786,0.003267654 C62.1458916,0.003267654 62.8913184,0.129634693 63.6555846,0.255742252 L62.9852605,4.56727146 C62.7329661,4.46244131 62.5397356,4.37759116 62.2922783,4.37759116 C61.3841712,4.37759116 61.0608474,5.13501496 60.9457747,5.87090186 L60.8811099,6.29151987 L62.7113263,6.06032268 L62.0348922,10.4131093 L60.3459708,10.2665028 L59.2634725,17.2278476 L53.9411043,17.2278476 L55.0223297,10.2665028 L53.5548978,10.4131093 L54.231332,6.06032268 L55.6468284,6.25000298 L55.8293663,5.0717017"
              id="Fill-5"
            />
            <polyline
              id="Fill-6"
              points="65.0922116 0.171670546 70.415089 0.171670546 67.7651066 17.2278476 62.4414655 17.2278476 65.0922116 0.171670546"
            />
            <path
              d="M71.5011515,6.06032268 L76.8250472,6.06032268 L75.0895366,17.2278476 L69.7658955,17.2278476 L71.5011515,6.06032268 L71.5011515,6.06032268 Z M77.6285196,2.6113069 C77.344402,4.4411639 75.8171425,5.24036407 74.2906467,5.24036407 C72.7633873,5.24036407 71.4843488,4.4411639 71.7689756,2.6113069 C72.0207608,0.991629158 73.4329476,0.003267654 75.1037934,0.003267654 C76.775403,0.003267654 77.8803048,0.991629158 77.6285196,2.6113069 L77.6285196,2.6113069 Z"
              id="Fill-7"
            />
            <path
              d="M83.376555,7.84788435 L83.5005382,7.84788435 C84.721022,6.62832566 85.6321841,5.80784808 87.3452911,5.80784808 C90.0696125,5.80784808 90.8188581,7.88992021 90.436725,10.350315 L89.3684835,17.2278476 L84.0440787,17.2278476 L84.8903215,11.7805719 C84.9924103,11.1284972 85.1359966,10.2031895 84.2283987,10.2031895 C83.3406584,10.2031895 83.1970721,11.1284972 83.0952378,11.7805719 L82.2500134,17.2278476 L76.9258631,17.2278476 L78.6611191,6.06032268 L83.860777,6.06032268 L83.376555,7.84788435"
              id="Fill-8"
            />
            <path
              d="M96.8698505,10.2454849 C96.9905242,9.46730261 97.4869662,8.79421 98.2922207,8.79421 C99.0557232,8.79421 99.4839363,9.48832054 99.3660631,10.2454849 L96.8698505,10.2454849 L96.8698505,10.2454849 Z M104.247743,12.5582352 C105.166289,8.37359199 102.490339,5.80784808 98.5905951,5.80784808 C95.1035351,5.80784808 91.9377622,8.12137688 91.3631623,11.8226078 C90.7653951,15.6712236 93.6404312,17.4795438 97.0862484,17.4795438 C99.6041007,17.4795438 102.747216,16.2387077 103.866883,13.5465967 L98.6881015,13.5465967 C98.5035268,14.0725638 98.0282154,14.4721639 97.4716911,14.4721639 C96.357115,14.4721639 96.3695897,13.4627845 96.5103756,12.5582352 L104.247743,12.5582352 L104.247743,12.5582352 Z"
              id="Fill-9"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default SurflineLogo;
