import { get as _get } from 'lodash';
import * as editorialAPI from '../common/api/editorial';
import getFeedGeotarget from '../utils/getFeedGeotarget';
import { redirectToNotFound } from './status';

export const FETCH_EDITORIAL_TAXONOMY_POSTS = 'FETCH_EDITORIAL_TAXONOMY_POSTS';
export const FETCH_EDITORIAL_TAXONOMY_POSTS_SUCCESS = 'FETCH_EDITORIAL_TAXONOMY_POSTS_SUCCESS';
export const FETCH_EDITORIAL_TAXONOMY_POSTS_FAILURE = 'FETCH_EDITORIAL_TAXONOMY_POSTS_FAILURE';

export const fetchEditorialTaxonomyPosts =
  (
    taxonomy: string,
    term: string | null,
    subCategory: string | null,
    countryCode: string | null,
    isServer = false,
  ) =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: FETCH_EDITORIAL_TAXONOMY_POSTS,
    });
    try {
      const termOverride = subCategory || term;
      const taxPostsState = getState().editorialTaxonomyPosts;
      const { offset, limit, fetched, infiniteScroll } = taxPostsState;
      const state = getState();
      const geotarget = countryCode || getFeedGeotarget(state);
      const { posts, featured }: any =
        (await editorialAPI.fetchEditorialTaxonomyPosts(
          taxonomy || taxPostsState.taxonomy,
          termOverride || taxPostsState.term,
          subCategory || taxPostsState.subCategory,
          limit,
          offset,
          geotarget,
          isServer,
        )) || {};
      if (featured) {
        const [addFeatured] = featured;
        addFeatured.isFeatured = true;
        if (fetched === 1) {
          posts.splice(5 * fetched + 1, 0, addFeatured);
        } else {
          posts.splice(0, 0, addFeatured);
        }
      }
      dispatch({
        type: FETCH_EDITORIAL_TAXONOMY_POSTS_SUCCESS,
        posts: !infiniteScroll ? posts : taxPostsState.posts.concat(posts),
        featured,
        offset: fetched > 1 ? offset + 6 : 12,
        taxonomy: taxonomy || taxPostsState.taxonomy,
        term: termOverride || taxPostsState.term,
        subCategory: subCategory || taxPostsState.subCategory,
        infiniteScroll: taxonomy && false, // Reset
        limit: 6,
      });
    } catch (error: any) {
      const wpStatusCode = _get(error, 'response.status', null);
      if (error.statusCode === 400 || wpStatusCode === 404) {
        dispatch(redirectToNotFound('The taxonomy posts was not found'));
      } else {
        dispatch({
          type: FETCH_EDITORIAL_TAXONOMY_POSTS_FAILURE,
          error,
        });
      }
    }
  };

export const ENABLE_INFINITE_SCROLLING = 'ENABLE_INFINITE_SCROLLING';

export const startInfiniteScrolling = () => ({
  type: ENABLE_INFINITE_SCROLLING,
});

export const RESET_EDITORIAL_TAXONOMY_POSTS = 'RESET_EDITORIAL_TAXONOMY_POSTS';

export const resetEditorialTaxonomyPosts = () => ({ type: RESET_EDITORIAL_TAXONOMY_POSTS });
