/**
 * @typedef {import('../stores').AppState} State
 */

import { useAppSelector } from '../stores/hooks';

/**
 * @param {State} state
 */
export const getMapLocation = (state) => state.mapV2.location;

/**
 * @param {State} state
 */
export const getMapZoom = (state) => state.mapV2.location?.zoom;

/**
 * @param {State} state
 */
export const getMapLocationLoaded = (state) => state.mapV2.locationLoaded;

/**
 * @param {State} state
 */
export const getMapBoundingBox = (state) => state.mapV2.boundingBox;

/**
 * @param {State} state
 */
export const getMapError = (state) => state.mapV2.error;

/**
 * @param {State} state
 */
export const getMapLoading = (state) => state.mapV2.loading;

/**
 * @param {State} state
 */
export const getMapMessage = (state) => state.mapV2.message;

/**
 * @param {State} state
 */
export const getLocationViewBoundingBox = (state) => state.mapV2.locationView.boundingBox;

/**
 * @param {State} state
 */
export const getLocationView = (state) => state.mapV2.locationView;

/**
 * @param {State} state
 */
export const getLocationViewGeonameId = (state) => state.mapV2.locationView?.taxonomy?.geonameId;

export const useMapLoading = () => useAppSelector(getMapLoading);
export const useMapError = () => useAppSelector(getMapError);
export const useLocationViewBoundingBox = () => useAppSelector(getLocationViewBoundingBox);
export const useLocationView = () => useAppSelector(getLocationView);
