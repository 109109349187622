import React from 'react';
import { Typography } from '@mui/material';

import type { TaxonomyNode } from 'types/header';

import styles from './RenderCity.module.scss';

interface RenderCityProps {
  node: TaxonomyNode;
}

const RenderCity = ({ node }: RenderCityProps) => (
  <Typography className={styles.renderCity} variant="h5">
    {node?.name}
  </Typography>
);

export default RenderCity;
