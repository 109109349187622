import { SearchResult } from 'types/search';
import baseFetch from '../baseFetch';

export const fetchSearchResults = async (
  term: string,
  cookies: { [key: string]: string },
  {
    size = 10,
    newsSearch = true,
    includeWavePools = false,
  }: { size?: number; newsSearch?: boolean; includeWavePools?: boolean } = {},
) =>
  baseFetch<Array<SearchResult>>(
    `/search/site?q=${term}&querySize=${size}&suggestionSize=${size}&newsSearch=${newsSearch}${
      includeWavePools ? '&includeWavePools=true' : ''
    }`,
    {
      cookies,
    },
  );

export default fetchSearchResults;
