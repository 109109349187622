import * as searchAPI from '../common/api/search';

export const FETCH_SEARCH_RESULTS = 'FETCH_SEARCH_RESULTS';
export const FETCH_SEARCH_RESULTS_SUCCESS = 'FETCH_SEARCH_RESULTS_SUCCESS';
export const FETCH_SEARCH_RESULTS_FAILURE = 'FETCH_SEARCH_RESULTS_FAILURE';
export const SHOW_ALL_TOGGLE = 'SHOW_ALL_TOGGLE';

export const fetchSearchResults =
  (term: string, cookies: { [key: string]: string }) => async (dispatch: any) => {
    dispatch({
      type: FETCH_SEARCH_RESULTS,
    });
    try {
      const results = await searchAPI.fetchSearchResults(term, cookies, {
        newsSearch: true,
        size: 50,
      });
      dispatch({
        type: FETCH_SEARCH_RESULTS_SUCCESS,
        results,
      });
    } catch (error) {
      dispatch({
        type: FETCH_SEARCH_RESULTS_FAILURE,
        error,
      });
    }
  };

export const showAllToggle = (section: any) => async (dispatch: any) => {
  dispatch({
    type: SHOW_ALL_TOGGLE,
    section,
  });
};
