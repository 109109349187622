import React from 'react';
import { useMount } from 'react-use';
import { Button, Typography } from '@mui/material';
import productCDN from '@surfline/quiver-assets';
import { trackNavigatedToPage } from '@surfline/web-common';

import CloudflareResizedImage from 'components/CloudflareResizedImage';
import WavetrakLink from 'components/WavetrakLink';

import styles from './ErrorNotFound.module.scss';

const COPY = {
  title: 'Page Not Found',
  paragraph_top: "The link you've followed may be broken, or the page may have been removed.",
  paragraph_bottom_before: 'Go ahead... go shred back to the ',
  paragraph_bottom_link: 'homepage',
  paragraph_bottom_after: ' for surf reports, forecasts and surf news.',
  button_text: 'Back to Surfline.com',
};

const IMAGE_SRC = `${productCDN}/backgrounds/404-mobile-image.jpg`;

const ErrorNotFound = () => {
  const onClickHandler = () => {
    window.location.replace('/');
  };

  useMount(() => {
    trackNavigatedToPage('Error', undefined, '404');
  });

  return (
    <div className={styles.body} data-testid="error-404">
      <div className={styles.container}>
        <Typography variant="title1" component="h1" className={styles.containerTitle}>
          {COPY.title}
        </Typography>
        <Typography className={styles.containerParagraph}>{COPY.paragraph_top}</Typography>
        <Typography className={styles.containerParagraphDesktop}>
          {COPY.paragraph_bottom_before}
          <span>
            <WavetrakLink href="/" className={styles.containerParagraphLink}>
              {COPY.paragraph_bottom_link}
            </WavetrakLink>
          </span>
          {COPY.paragraph_bottom_after}
        </Typography>
        <Button variant="primary" onClick={onClickHandler} className={styles.button}>
          {COPY.button_text}
        </Button>
        <div className={styles.imageContainer}>
          <CloudflareResizedImage
            alt="404 Error"
            className={styles.containerImage}
            src={IMAGE_SRC}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorNotFound;
