const suggestTypes = new Map();
suggestTypes.set('spot-suggest', 'Surf Spots');
suggestTypes.set('subregion-suggest', 'Regional Forecast');
suggestTypes.set('geoname-suggest', 'Map Area');
suggestTypes.set('travel-suggest', 'Travel Guide');
suggestTypes.set('feed-tag-suggest', 'Surf News');

const buildSectionData = (section: {
  suggest: Array<any>;
  hits: {
    hits: any;
  };
  took: any;
}) => {
  const [suggestType, sectionTitle] = Array.from(suggestTypes.entries()).find(
    (entry) => section.suggest[entry[0]],
  ) as any;

  const {
    hits: { hits },
    suggest,
    took,
  } = section;
  const [{ options }] = suggest[suggestType];
  const typeResultsCount = {
    'spot-suggest': 10,
    'subregion-suggest': 3,
    'geoname-suggest': 3,
    'feed-tag-suggest': 10,
    'travel-suggest': 3,
  };

  const suggestions = options
    .filter((option: any) => hits.map((hit: any) => hit._id).indexOf(option._id) <= -1)
    .map((filteredOption: any) => ({ ...filteredOption, isSuggestion: true }));

  const sectionLimit = (typeResultsCount as any)?.[suggestType];
  const sectionResults = [...hits, ...suggestions].slice(0, sectionLimit);

  return {
    sectionResults,
    sectionTitle,
    sectionLimit,
    sectionTook: took,
  };
};

export default buildSectionData;
