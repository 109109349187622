import { useRouter } from 'next/router';

const usePageName = () => {
  const router = useRouter();
  const { route } = router;
  if (route === '/') return 'Home';
  if (route === '/surf-report/[spotSlug]/[spotId]') return 'Spot Report';
  if (
    route.startsWith('/surf-report/[spotSlug]/[spotId]/surf-charts') ||
    route.startsWith('/surf-charts')
  )
    return 'Charts';
  if (route.startsWith('/surf-report/[spotSlug]/[spotId]/spot-guide')) return 'Spot Guide';
  if (route.startsWith('/surf-report/[spotSlug]/[spotId]/clips')) return 'My Clips';
  if (route.startsWith('/surf-reports-forecasts-cams-map')) return 'Maps';
  if (route.startsWith('/surf-forecasts/[subregionSlug]/[subregionId]/')) return 'Premium Analysis';
  return route;
};

export default usePageName;
