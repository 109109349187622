import addCustomUserAgentHeader from 'utils/addCustomUserAgentHeader';
import baseFetch, { createParamString } from '../baseFetch';

export const fetchContestNews = async (params, cookies, isServer = false) =>
  baseFetch(`/wp-json/sl-contests/v1/news?${createParamString(params)}`, {
    cookies,
    headers: { ...addCustomUserAgentHeader(isServer) },
  });

export const fetchContestEntries = async (params, cookies, isServer = false) =>
  baseFetch(`/wp-json/sl-contests/v1/entries?${createParamString(params)}`, {
    cookies,
    headers: { ...addCustomUserAgentHeader(isServer) },
  });

export const fetchContestEntriesLocations = async (params, cookies, isServer = false) =>
  baseFetch(`/wp-json/sl-contests/v1/entries/locations?${createParamString(params)}`, {
    cookies,
    headers: { ...addCustomUserAgentHeader(isServer) },
  });

export const fetchContestEntriesSurfers = async (params, cookies, isServer) =>
  baseFetch(`/wp-json/sl-contests/v1/entries/surfers?${createParamString(params)}`, {
    cookies,
    headers: { ...addCustomUserAgentHeader(isServer) },
  });

export const fetchContestEntriesMonths = async (params, cookies, isServer = false) =>
  baseFetch(`/wp-json/sl-contests/v1/entries/months?${createParamString(params)}`, {
    cookies,
    headers: { ...addCustomUserAgentHeader(isServer) },
  });

export const fetchContestEntry = async (id, cookies, isServer = false) =>
  baseFetch(`/wp-json/sl-contests/v1/entry?${createParamString({ id })}`, {
    cookies,
    headers: { ...addCustomUserAgentHeader(isServer) },
  });

export const fetchContestDetails = async (params, cookies, isServer = false) =>
  baseFetch(`/wp-json/sl-contests/v1/details?${createParamString(params)}`, {
    cookies,
    headers: { ...addCustomUserAgentHeader(isServer) },
  });

export const fetchContest = async (params, cookies, isServer = false) =>
  baseFetch(`/wp-json/sl-contests/v1/contest?${createParamString(params)}`, {
    cookies,
    headers: { ...addCustomUserAgentHeader(isServer) },
  });

export const submitContestEntry = (entry) =>
  baseFetch('/wp-json/sl-contests/v1/submit', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(entry),
  });

export const upvoteContestEntry = (entryId) =>
  baseFetch(`/wp-json/sl-contests/v1/entries/${entryId}/votes`, {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  });
