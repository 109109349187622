import React, { useState } from 'react';
import classNames from 'classnames';
import { Alert, Collapse, IconButton, Typography, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  dismissContentCard,
  trackContentCardClick,
  trackContentCardImpressions,
} from '@surfline/web-common';
import withContentCards from 'components/withContentCards';

import WavetrakLink from 'components/WavetrakLink';

import styles from './ContentCard.module.scss';

export interface ContentCardProps {
  card?: {
    name: string;
    extras: {
      backgroundColor: string;
      body: string;
      closeButtonColor: string;
      fontColor: string;
      linkText: string;
      linkUrl: string;
    };
  };
  className?: string;
  testId?: string;
}

export const ContentCard: React.FC<ContentCardProps> = ({
  card,
  className,
  testId = 'content-card',
}) => {
  const [closed, setClosed] = useState(false);
  const [rendered, setRendered] = useState(false);
  const theme = useTheme();

  const cardName = card?.name ?? '';
  const backgroundColor = card?.extras?.backgroundColor ?? theme.palette.background.default;
  const linkUrl = card?.extras?.linkUrl;
  const closeButtonColor = card?.extras?.closeButtonColor ?? theme.palette.secondary.main;
  const fontColor = card?.extras?.fontColor ?? theme.palette.secondary.main;
  const linkText = card?.extras?.linkText;

  // Component CTA was clicked
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if ((e.target as HTMLElement).closest('.close-icon') === null) {
      trackContentCardClick(card);
      if (linkUrl) {
        setTimeout(() => {
          window.location.assign(linkUrl);
        }, 200);
      }
    }
  };

  const handleClickAnchor = (e: React.MouseEvent<HTMLElement>) => {
    // let the main onclick handle the tracking and redirect
    e.preventDefault();
  };

  // Component was dismissed
  const handleCloseClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setClosed(true);
    dismissContentCard(cardName);
  };

  // Component was displayed for the first time
  if (!rendered && card?.extras) {
    setRendered(true);
    trackContentCardImpressions([card]);
  }

  return card?.extras ? (
    <Collapse
      className={classNames(styles.contentCard, {
        [styles['contentCard--isLink']]: !!linkUrl,
      })}
      data-testid={testId}
      in={!closed}
    >
      <Alert
        action={
          <div className={styles.action}>
            <IconButton
              aria-label="close"
              color="inherit"
              data-testid="close-button"
              size="small"
              onClick={handleCloseClick}
            >
              <CloseIcon className="close-icon" fontSize="inherit" htmlColor={closeButtonColor} />
            </IconButton>
          </div>
        }
        icon={false}
        sx={{ backgroundColor }}
        className={classNames(styles.alert, className)}
        onClick={handleClick}
      >
        <Typography variant="subtitle1" component="span" sx={{ color: fontColor, fontWeight: 400 }}>
          {card?.extras?.body}
        </Typography>{' '}
        {linkText && linkUrl && (
          <Typography
            className={styles.anchor}
            component={WavetrakLink}
            href={card.extras.linkUrl}
            isExternal
            onClick={handleClickAnchor}
            sx={{ color: fontColor }}
            variant="subtitle1"
            data-testid={`${testId}-link`}
          >
            {linkText}
          </Typography>
        )}
      </Alert>
    </Collapse>
  ) : (
    <div data-testid={testId} />
  );
};

export default withContentCards(ContentCard);
