import createReducer from '../createReducer';
import { FETCH_SUBREGION_OVERVIEW_DATA_SUCCESS } from '../../actions/subregion';

export const initialState = null;

const handlers = {};

handlers[FETCH_SUBREGION_OVERVIEW_DATA_SUCCESS] = (state, { subregionId }) => subregionId;

export default createReducer(handlers, initialState);
