import React from 'react';
import PropTypes from 'prop-types';
import { useLeafletCoreImport } from './useLeaflet';

/**
 * @typedef {Object} Props
 * @property {import('leaflet').Map} [Props.map]
 */

/**
 * @description
 * There are some cases where we want to render map children using a portal from `react-dom`.
 * The problem with this is that it is no longer a child of the `MapContainer` in the react
 * tree meaning that it won't have access to the context that it requires in order to render
 * the child component (marker, popup, etc...) into the map layers.
 *
 * In order to support using portals, we can manually pass the instance of the map into the map
 * child component and use the `LeafletProvider` in order to get the instance into the container
 *
 * @type {React.FunctionComponent<Props>}
 */
const MapContext = ({ map, children }) => {
  const LeafletProvider = useLeafletCoreImport('LeafletProvider');

  if (!LeafletProvider || !map) {
    return null;
  }

  return <LeafletProvider value={{ map }}>{children}</LeafletProvider>;
};

MapContext.propTypes = {
  map: PropTypes.shape().isRequired,
  children: PropTypes.node.isRequired,
};

export default MapContext;
