import { useSelector } from 'react-redux';
import type { MapMode } from 'types/map';

import { getMapMessage, getMapError, getMapLoading } from '../../selectors/mapV2';

const useNotification = (mapMode: MapMode) => {
  const message = useSelector(getMapMessage);
  const error = useSelector(getMapError);
  const loading = useSelector(getMapLoading);
  const errorMessage =
    mapMode === 'windStation' ? 'Oh no! No data found' : 'Oh buoy! No data found';
  const loadingMessage =
    mapMode === 'windStation' ? 'Loading wind stations...' : 'Loading Buoys...';

  if (loading) {
    return { isLoading: true, message: loadingMessage };
  }

  if (error) {
    return { isError: true, message: errorMessage };
  }

  if (message) {
    return { isMessage: true, message };
  }

  return {};
};

export default useNotification;
