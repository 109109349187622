import createReducer from '../createReducer';
import {
  FETCH_SPOT_MAP_DATA,
  FETCH_SPOT_MAP_DATA_SUCCESS,
  FETCH_SPOT_MAP_DATA_FAILURE,
} from '../../actions/spotMapData';

export const initialState = {
  loading: true,
  error: null,
};

const handlers = {};

handlers[FETCH_SPOT_MAP_DATA] = (state) => ({
  ...state,
  loading: true,
  error: null,
});

handlers[FETCH_SPOT_MAP_DATA_SUCCESS] = (state) => ({
  ...state,
  loading: false,
  error: null,
});

handlers[FETCH_SPOT_MAP_DATA_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

export default createReducer(handlers, initialState);
