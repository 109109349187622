import { memo } from 'react';
import classNames from 'classnames';

export const CameraInsightsIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="camera-insights-icon"
    className={classNames('cameraInsightsIcon', className)}
  >
    <rect width="24" height="24" rx="3" fill="#171717" />
    <path
      d="M18.75 15.3613V8.43726L15.75 10.2993V8.40666C15.75 7.90545 15.4115 7.49927 14.9938 7.49927L5.25 15.5919C5.25 16.0931 5.5885 16.4993 6.00617 16.4993H14.9938C15.4115 16.4993 15.75 16.0931 15.75 15.5919V13.4993L18.75 15.3613Z"
      fill="#E5FD49"
    />
    <rect x="5.25" y="7.5" width="7.59375" height="0.84375" rx="0.421875" fill="#E5FD49" />
    <rect x="5.25" y="9.1875" width="5.34375" height="0.84375" rx="0.421875" fill="#E5FD49" />
    <rect x="5.25" y="10.875" width="3.4875" height="0.84375" rx="0.421875" fill="#E5FD49" />
    <rect x="5.25" y="12.563" width="1.6875" height="0.84375" rx="0.421875" fill="#E5FD49" />
  </svg>
);

export default memo(CameraInsightsIcon);
