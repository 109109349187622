import React, { ReactNode } from 'react';
import classNames from 'classnames';

import styles from './BaseMenu.module.scss';

const BaseMenu = ({
  children,
  position = 'left',
  testId = 'base-menu',
}: {
  children: ReactNode;
  position?: string;
  testId?: string;
}) => (
  <div
    className={classNames(styles.baseMenu, {
      [styles['baseMenu--open-right']]: position === 'right',
      'sl-base-menu': true,
      'sl-base-menu--open-right': position === 'right',
    })}
    data-testid={testId}
  >
    {children}
  </div>
);

export default BaseMenu;
