import addClientIpHeader from 'utils/addClientIpHeader';
import addCustomUserAgentHeader from 'utils/addCustomUserAgentHeader';
import addGeoCountryCodeHeader from 'utils/addGeoCountryCodeHeader';
import baseFetch, { createParamString } from '../baseFetch';

export const fetchRegionalOverview = ({
  clientIp,
  cookies,
  countryCode,
  subregionId,
}: {
  clientIp?: string | null;
  cookies?: Record<string, string>;
  countryCode?: string | null;
  subregionId: string;
}) => {
  const clientIpHeader = addClientIpHeader({ clientIp });
  const countryCodeHeader = addGeoCountryCodeHeader({ countryCode });
  return baseFetch(`/kbyg/regions/overview?${createParamString({ subregionId })}`, {
    cookies,
    headers: {
      ...clientIpHeader,
      ...countryCodeHeader,
    },
  });
};

export const fetchRegionalConditionForecast = (subregionId: string, days: number) =>
  baseFetch(`/kbyg/regions/forecasts/conditions?${createParamString({ subregionId, days })}`);

export const fetchNearbySubregions = (subregionId: string, cookies: Record<string, string>) =>
  baseFetch(`/kbyg/regions/nearby?${createParamString({ subregionId })}`, {
    cookies,
  });

export const fetchFavoriteSubregions = (cookies: Record<string, string>) =>
  baseFetch('/kbyg/favorites/subregions?', { cookies });

export const fetchForecasterContent = ({
  cookies,
  days = 7,
  subregionId,
}: {
  cookies: Record<string, string>;
  days?: number;
  subregionId: string;
}) =>
  baseFetch(`/kbyg/forecast-content?${createParamString({ subregionId, days })}`, {
    cookies,
  });

export const fetchRegionalArticles = async (
  subregionId: string,
  cookies: Record<string, string>,
  isServer: boolean = false,
  noCache: boolean = false,
) => {
  let cacheEnabled = true;
  const headers: Record<string, string> = {};
  if (noCache) {
    headers['Cache-Control'] = 'no-cache';
    cacheEnabled = false;
  }
  return baseFetch(`/feed/regional?${createParamString({ subregionId, cacheEnabled })}`, {
    cookies,
    headers: {
      ...headers,
      ...addCustomUserAgentHeader(isServer),
    },
  });
};
