import { get as _get } from 'lodash';
import * as editorialAPI from '../common/api/editorial';
import getFeedGeotarget from '../utils/getFeedGeotarget';
import { redirectToNotFound } from './status';

export const FETCH_EDITORIAL_TAXONOMY_DETAILS = 'FETCH_EDITORIAL_TAXONOMY_DETAILS';
export const FETCH_EDITORIAL_TAXONOMY_DETAILS_SUCCESS = 'FETCH_EDITORIAL_TAXONOMY_DETAILS_SUCCESS';
export const FETCH_EDITORIAL_TAXONOMY_DETAILS_FAILURE = 'FETCH_EDITORIAL_TAXONOMY_DETAILS_FAILURE';

export const fetchEditorialTaxonomyDetails =
  (
    taxonomy: string,
    term: string | null,
    subCategory: string | null,
    countryCode: string | null,
    isServer = false,
  ) =>
  async (dispatch: any, getState: any) => {
    dispatch({
      type: FETCH_EDITORIAL_TAXONOMY_DETAILS,
    });
    try {
      const termOverride = subCategory || term;
      const state = getState();
      const geotarget = countryCode || getFeedGeotarget(state);
      const details = await editorialAPI.fetchEditorialTaxonomyDetails(
        taxonomy,
        termOverride,
        subCategory,
        geotarget,
        isServer,
      );
      dispatch({
        type: FETCH_EDITORIAL_TAXONOMY_DETAILS_SUCCESS,
        details,
      });
    } catch (error: any) {
      const wpStatusCode = _get(error, 'response.status', null);
      if (error.statusCode === 400 || wpStatusCode === 404) {
        dispatch(redirectToNotFound('The taxonomy details was not found'));
      } else {
        dispatch({
          type: FETCH_EDITORIAL_TAXONOMY_DETAILS_FAILURE,
          error,
        });
      }
    }
  };
