import React from 'react';
import { Typography } from '@mui/material';
import styles from './NewNotification.module.scss';

const NewNotification: React.FC = () => (
  <div className={styles.newNotification} data-testid="new-notification">
    <Typography component="div" variant="subtitle1" color="white">
      NEW
    </Typography>
  </div>
);

export default NewNotification;
