import createReducer from '../createReducer';
import {
  FETCH_LOCATION_VIEW,
  FETCH_LOCATION_VIEW_SUCCESS,
  FETCH_LOCATION_VIEW_FAILURE,
} from '../../actions/map';
import { LEAVE_GEONAME_MAP_ROUTE } from '../../actions/routes';

/**
 * @type {import('../../propTypes/locationView').LocationView}
 */

export const initialState = {
  loading: false,
  taxonomy: {
    location: {
      coordinates: [],
    },
  },
  breadCrumbs: [],
  boundingBox: null,
  travelContent: null,
  siblings: [],
  url: '',
};

const handlers = {};

handlers[FETCH_LOCATION_VIEW] = (state) => ({
  ...state,
  loading: true,
});

handlers[FETCH_LOCATION_VIEW_SUCCESS] = (
  state,
  { taxonomy, travelContent, boundingBox, breadCrumbs, siblings, url },
) => ({
  ...state,
  loading: false,
  boundingBox,
  breadCrumbs,
  taxonomy,
  travelContent,
  siblings,
  url,
});

handlers[FETCH_LOCATION_VIEW_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

handlers[LEAVE_GEONAME_MAP_ROUTE] = () => initialState;

export default createReducer(handlers, initialState);
