import { createReducer } from '@reduxjs/toolkit';
import {
  fetchLocationView,
  resetMapState,
  setMapBoundingBox,
  setMapError,
  setMapLoading,
  setMapLocation,
  setMapLocationLoaded,
  setMapMessage,
  zoomIn,
} from '../actions/mapV2';
import { LocationView } from '../types/locationView';
import { BoundingBox, Location } from '../types/map';

interface MapV2State {
  location: Location;
  boundingBox?: BoundingBox | null;
  error?: { message: string; status?: number } | null;
  locationLoaded: boolean;
  loading?: boolean;
  message: string;
  locationView: LocationView;
}

export const initialState: MapV2State = {
  location: {
    center: { lat: 0, lon: 0 },
    zoom: 12,
  },
  locationLoaded: false,
  boundingBox: null,
  error: null,
  loading: false,
  message: '',
  locationView: {
    loading: false,
    taxonomy: {},
    breadCrumbs: [],
    siblings: [],
    url: '',
  },
};

const mapV2Reducer = createReducer(initialState, (builder) => {
  builder.addCase(setMapLocation, (state, action) => ({
    ...state,
    location: action.payload,
    locationLoaded: true,
  }));

  builder.addCase(setMapLocationLoaded, (state) => ({
    ...state,
    locationLoaded: true,
  }));

  builder.addCase(setMapBoundingBox, (state, action) => ({
    ...state,
    boundingBox: action.payload,
  }));
  builder.addCase(setMapError, (state, action) => {
    if (action.payload) {
      return {
        ...state,
        error: action.payload,
      };
    }
    return {
      ...state,
      error: null,
    };
  });
  builder.addCase(setMapLoading, (state, action) => ({
    ...state,
    loading: action.payload,
  }));
  builder.addCase(setMapMessage, (state, action) => ({
    ...state,
    message: action.payload,
  }));

  builder.addCase(fetchLocationView.pending, (state) => ({
    ...state,
    locationView: {
      ...state.locationView,
      loading: true,
    },
  }));

  builder.addCase(fetchLocationView.fulfilled, (state, action) => ({
    ...state,
    locationView: {
      ...state.locationView,
      loading: false,
      ...action.payload,
    },
  }));

  builder.addCase(fetchLocationView.rejected, (state, action) => ({
    ...state,
    locationView: {
      ...state.locationView,
      loading: false,
      error: action.error,
    },
  }));

  builder.addCase(resetMapState, () => initialState);

  builder.addCase(zoomIn, (state, action) => ({
    ...state,
    location: {
      ...state.location,
      center: action.payload || state.location.center,
      zoom: state.location.zoom + 1,
    },
  }));
});

export default mapV2Reducer;
