import { useMount, useUnmount } from 'react-use';
import { useDispatch } from 'react-redux';
import { sessionTimeout } from 'actions/status';

const TIMEOUT_DURATION = 600000; // 10 minutes until session timeout is reached in the client browser

/**
 * Sets client-side session timeout so full page refreshes can be triggered on WavetrakLink components.
 * Once the timeout duration is reached, the redux store is updated so WavetrakLink and other
 * components can respond accordingly and ensure links trigger a full page refresh.
 */
const useSessionTimeout = () => {
  const dispatch = useDispatch();
  let timeout: ReturnType<typeof setTimeout>;

  useMount(() => {
    timeout = setTimeout(() => {
      dispatch(sessionTimeout());
    }, TIMEOUT_DURATION);
  });

  useUnmount(() => {
    clearTimeout(timeout);
  });
};

export default useSessionTimeout;
