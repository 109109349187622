import React from 'react';
import Head from 'next/head';

import ErrorFiveHundred from 'components/ErrorFiveHundred';
import ErrorNotFound from 'components/ErrorNotFound';

interface Props {
  statusCode: number;
}

const ErrorPage: React.FC<Props> = ({ statusCode }) => {
  if (statusCode === 404) {
    return (
      <>
        <Head>
          <title>SURFLINE.COM | Not Found</title>
        </Head>
        <ErrorNotFound />
      </>
    );
  }
  return (
    <>
      <Head>
        <title>SURFLINE.COM | 500</title>
      </Head>
      <ErrorFiveHundred />
    </>
  );
};

export default ErrorPage;
