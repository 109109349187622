import React from 'react';

const CheckedCircleOutlineIcon = ({ fill = '#171717' }: { fill?: string }) => (
  <svg
    className="checked-circle-outline-icon"
    data-testid="checked-circle-outline-icon"
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 31C26.9706 31 31 26.9706 31 22C31 17.0294 26.9706 13 22 13C17.0294 13 13 17.0294 13 22C13 26.9706 17.0294 31 22 31ZM22 33C28.0751 33 33 28.0751 33 22C33 15.9249 28.0751 11 22 11C15.9249 11 11 15.9249 11 22C11 28.0751 15.9249 33 22 33Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.0303 18.4697C27.3232 18.7626 27.3232 19.2374 27.0303 19.5303L21.2071 25.3536C20.8166 25.7441 20.1834 25.7441 19.7929 25.3536L17.4697 23.0303C17.1768 22.7374 17.1768 22.2626 17.4697 21.9697C17.7626 21.6768 18.2374 21.6768 18.5303 21.9697L20.5 23.9393L25.9697 18.4697C26.2626 18.1768 26.7374 18.1768 27.0303 18.4697Z"
      fill={fill}
    />
  </svg>
);

export default CheckedCircleOutlineIcon;
