import createReducer from '../createReducer';
import {
  FETCH_NEARBY_SUBREGIONS,
  FETCH_NEARBY_SUBREGIONS_SUCCESS,
  FETCH_NEARBY_SUBREGIONS_FAILURE,
} from '../../actions/subregion';

export const initialState = {};

const handlers = {};

handlers[FETCH_NEARBY_SUBREGIONS] = (state) => ({
  ...state,
  data: null,
  loading: true,
});

handlers[FETCH_NEARBY_SUBREGIONS_SUCCESS] = (state, { nearbySubregions }) => ({
  ...state,
  data: nearbySubregions.data,
  units: nearbySubregions.associated.units,
  loading: false,
});

handlers[FETCH_NEARBY_SUBREGIONS_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

export default createReducer(handlers, initialState);
