import type { UserDetails } from '@surfline/web-common';

const getAddFavoritesHref = (
  redirectUrl: string | null = null,
  user: Partial<UserDetails> | null = null,
) => {
  const query = redirectUrl ? `?redirectUrl=${encodeURIComponent(redirectUrl)}` : '';
  const href = user ? '/setup/favorite-surf-spots' : '/create-account';
  return `${href}${query}`;
};

export default getAddFavoritesHref;
