import { parseISO, isAfter, isBefore, endOfDay, isValid, startOfDay } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

type LimitedTimePremiumPlusConfig = {
  start: string;
  end: string;
};

const computeLimitedTimePremiumPlusEligibility = (
  limitedTimePremiumPlusConfig: LimitedTimePremiumPlusConfig | null | undefined,
  userTimeZone: string = 'America/Los_Angeles',
): boolean => {
  if (
    !limitedTimePremiumPlusConfig ||
    !limitedTimePremiumPlusConfig.start ||
    !limitedTimePremiumPlusConfig.end
  ) {
    return false;
  }

  const { start, end } = limitedTimePremiumPlusConfig;
  const startDate = startOfDay(parseISO(start));
  const endDate = endOfDay(parseISO(end));

  if (!isValid(startDate) || !isValid(endDate)) {
    return false;
  }

  const currentDate = new Date();
  const currentDateInUserTZ = utcToZonedTime(currentDate, userTimeZone);
  const startDateInUserTZ = utcToZonedTime(startDate, userTimeZone);
  const endDateInUserTZ = utcToZonedTime(endDate, userTimeZone);

  return (
    isAfter(currentDateInUserTZ, startDateInUserTZ) &&
    isBefore(currentDateInUserTZ, endDateInUserTZ)
  );
};

export default computeLimitedTimePremiumPlusEligibility;
