import { groupBy as _groupBy, sortBy } from 'lodash';

import { formatTimestampInTimezone, isTimestampThreeHourly } from 'utils/time';

/**
 * @description We take the data that comes in a flat array and then group it by its
 * formatted date string. From there we map map over and create an array for each day
 * and then sort the array to make sure the days are in order
 */
const createDayHourlyData = <
  T extends { timestamp: number; utcOffset: number },
  U extends { timestamp: number; utcOffset: number },
>(
  data: Array<T>,
  numDays = 16,
  desired16DayInterval: 1 | 3 = 1,
  map = (point: T): U => point as unknown as U,
) => {
  const firstDataPoint = data?.[0];

  if (
    !firstDataPoint ||
    !Object.prototype.hasOwnProperty.call(firstDataPoint, 'timestamp') ||
    !Object.prototype.hasOwnProperty.call(firstDataPoint, 'utcOffset')
  ) {
    throw new Error('Data points must have a `timestamp` and a `utcOffset`');
  }

  const hourlyDaysAvailable = numDays;
  const flatData =
    desired16DayInterval !== 3
      ? data
      : data.filter((p) => isTimestampThreeHourly(p.timestamp, p.utcOffset));

  const groupedByDay = _groupBy(flatData.map(map), (p) =>
    formatTimestampInTimezone(p.timestamp, p.utcOffset, 'MM-dd-yyyy'),
  );

  const hourlyData = sortBy(Object.values(groupedByDay), (day) => day[0].timestamp).slice(
    0,
    hourlyDaysAvailable,
  );

  return hourlyData;
};

export default createDayHourlyData;
