import React from 'react';

export const CTACheckmarkIcon: React.FC<{ className?: string; strokeFill?: string }> = ({
  className,
  strokeFill = '#171717',
}) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    display="inline"
    className={className}
  >
    <path
      d="M9 17.6667L12.1765 20.8295C12.2721 20.9246 12.4258 20.9268 12.524 20.8346L23 11"
      stroke={strokeFill}
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default CTACheckmarkIcon;
