import React from 'react';
import { pageStructuredData, placeStructuredData } from '@surfline/web-common';
import type { Location, MapMode } from 'types/map';
import useMapPageMetaDetails from 'hooks/map/useMapPageMetaDetails';
import MetaTags from '../MetaTags';
import config from '../../config';

const defaultLocation = {
  center: { lat: 0, lon: 0 },
  zoom: 0,
};

const MapPageMeta = ({
  location = defaultLocation,
  mapMode,
}: {
  location?: Location;
  mapMode: MapMode;
}) => {
  const { pageTitle, metaDescription, structuredDataPrefix, urlPath } = useMapPageMetaDetails(
    mapMode,
    location,
  );

  // Midnight of current day
  const currentDay = new Date();
  const startOfCurrentDayUTC = new Date(currentDay.setUTCHours(0, 0, 0, 0));

  const url = `${config.surflineHost}${urlPath}`;

  if (mapMode === 'surf') {
    return <MetaTags title={pageTitle} description={metaDescription} urlPath={urlPath} />;
  }

  return (
    <MetaTags title={pageTitle} description={metaDescription} urlPath={urlPath}>
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: pageStructuredData({
            url,
            headline: pageTitle,
            description: metaDescription,
            dateModified: startOfCurrentDayUTC,
            publisher: 'Surfline',
          }),
        }}
      />
      <script
        type="application/ld+json"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: placeStructuredData(
            structuredDataPrefix,
            location?.center?.lat,
            location?.center?.lon,
          ),
        }}
      />
    </MetaTags>
  );
};

export default MapPageMeta;
