import {
  FETCH_FAVORITE_SUBREGIONS,
  FETCH_FAVORITE_SUBREGIONS_SUCCESS,
  FETCH_FAVORITE_SUBREGIONS_FAILURE,
} from 'actions/subregion';
import createReducer from 'reducers/createReducer';

export const initialState = {};

const handlers = {};

handlers[FETCH_FAVORITE_SUBREGIONS] = (state) => ({
  ...state,
  data: null,
  loading: true,
});

handlers[FETCH_FAVORITE_SUBREGIONS_SUCCESS] = (state, { favoriteSubregions }) => ({
  ...state,
  data: favoriteSubregions.subregions,
  units: favoriteSubregions.associated.units,
  loading: false,
});

handlers[FETCH_FAVORITE_SUBREGIONS_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

export default createReducer(handlers, initialState);
