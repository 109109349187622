// TODO: Change this back to `react-leaflet` once we move off my fork of the package
/** @type {import('@monsonjeremy/react-leaflet').MapContainerProps} */
const mapOptions = {
  minZoom: 3,
  maxZoom: 18,
  preferCanvas: true,
  worldCopyJump: true,
};

export default mapOptions;
