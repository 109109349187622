import fetch from 'isomorphic-fetch';
import type { Fetcher } from 'swr';
import { nrNoticeError, Units } from '@surfline/web-common';

import baseFetch, { createParamString } from 'common/baseFetch';
import config from 'config';
import type { CameraInsightsReponse } from 'types/cameraInsights';
import type { RewindRecording } from 'types/recordings';
import addCustomUserAgentHeader from 'utils/addCustomUserAgentHeader';
import { Camera } from 'types/camera';

const checkStatus = (res: Response) => {
  if (res.status >= 200 && res.status < 300) return res;

  const error: any = new Error(
    res?.statusText ? `Camera fetch error: ${res.statusText}` : `Camera fetch error`,
  );
  error.response = res;
  error.statusCode = res?.status;

  if (error.statusCode >= 500) {
    nrNoticeError(error, {
      reponseBody: res?.body,
      statusCode: res?.status,
      statusText: res?.statusText,
      url: res?.url,
    });
  }

  throw error;
};

interface CameraResponse extends Omit<Camera, 'status'> {
  isDown: {
    altMessage: string;
    message: string;
    status: boolean;
    subMessage: string;
  };
  status: string;
}

export const fetchCamera = async (cameraId: string): Promise<CameraResponse> =>
  fetch(`${config.camerasServiceURL}/cameras/${cameraId}`, {
    headers: { ...addCustomUserAgentHeader(true) }, // always run on server
  })
    .then(checkStatus)
    .then((response) => response.json());

export const fetchCamEmbedDetails = async (cameraId: string) => {
  const response = await fetch(`${config.surflineEmbedURL}/cam-details/${cameraId}.json`);
  return response.json();
};

export const fetchSpotsForCam = async (cameraId: string) =>
  baseFetch(`/cameras/rewind/id/${cameraId}?`);

export interface FetchCameraInsightsParams {
  cameraId: string;
  endDate?: number;
  startDate?: number;
  units?: Units;
}

export const fetchCameraInsights = async ({
  cameraId,
  endDate,
  startDate,
  units,
}: FetchCameraInsightsParams): Promise<CameraInsightsReponse> =>
  baseFetch(
    `/cameras/insights/id/${cameraId}?${createParamString({
      endDate,
      startDate,
      'units[waveHeight]': units?.surfHeight,
    })}`,
  );

export type CamInsightsRecordingFetcherInput = {
  camId: string;
  startDate: number;
  endDate: number;
  surfHeightUnit: string | undefined;
};

export const fetchCamInsightsRecording: Fetcher<
  RewindRecording[] | null,
  CamInsightsRecordingFetcherInput
> = async ({ camId, startDate, endDate, surfHeightUnit }) => {
  if (!camId) return null;

  return baseFetch(
    `/cameras/recording/v2/id/${camId}?allowPartialMatch=true&${createParamString({
      startDate,
      endDate,
      cacheEnabled: true,
      'units[waveHeight]': surfHeightUnit,
    })}`,
    {
      headers: {
        'X-Auth-ClientId': `Basic ${Buffer.from(`${config.clientId}`).toString('base64')}`,
      },
    },
  );
};
