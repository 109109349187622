// Surfline
export const lightBluePalette = new Map([
  ['core', '#42A5FC'],
  ['5', '#F2F7F9'],
  ['10', '#E6F3FF'],
  ['20', '#B5DBFC'],
  ['30', '#8CC6F9'],
  ['40', '#66B5FA'],
  ['50', '#42A5FC'],
  ['60', '#3193EC'],
  ['70', '#2283DD'],
  ['80', '#1171CE'],
  ['90', '#0261BF'],
]);

export const darkBluePalette = new Map([
  ['core', '#003366'],
  ['10', '#0058B0'],
  ['20', '#004D99'],
  ['30', '#004488'],
  ['40', '#003C78'],
  ['50', '#003366'],
  ['60', '#002E5C'],
  ['70', '#012850'],
  ['80', '#012345'],
  ['90', '#011D38'],
]);

// FishTrack
export const aquaPalette = new Map([
  ['core', '#67D3DF'],
  ['10', '#D5F3F7'],
  ['20', '#BAEBF1'],
  ['30', '#9DE3EA'],
  ['40', '#82DBE5'],
  ['50', '#67D3DF'],
  ['60', '#5AC1CC'],
  ['70', '#4AA9B4'],
  ['80', '#3B939D'],
  ['90', '#2D7E87'],
]);

export const sharkBluePalette = new Map([
  ['core', '#385C6C'],
  ['10', '#ADC4CD'],
  ['20', '#92ABB7'],
  ['30', '#728F9C'],
  ['40', '#567785'],
  ['50', '#385C6C'],
  ['60', '#305160'],
  ['70', '#284754'],
  ['80', '#203C49'],
  ['90', '#18313D'],
]);

// Buoyweather
export const marineBluePalette = new Map([
  ['core', '#002447'],
  ['10', '#D7F0FA'],
  ['20', '#9FDAF3'],
  ['30', '#6BC5EC'],
  ['40', '#48B7E6'],
  ['50', '#27AAE1'],
  ['60', '#209CD0'],
  ['70', '#198DBF'],
  ['80', '#117FAD'],
  ['90', '#09709B'],
]);

export const nauticalBluePalette = new Map([
  ['core', '#3B698F'],
  ['10', '#A4C2DA'],
  ['20', '#8BACC8'],
  ['30', '#6D94B3'],
  ['40', '#557FA1'],
  ['50', '#3B698F'],
  ['60', '#2F597C'],
  ['70', '#254B6B'],
  ['80', '#183A56'],
  ['90', '#0D2B44'],
]);

// Shared
export const greenPalette = new Map([
  ['core', '#23D737'],
  ['10', '#D9FBDD'],
  ['20', '#A4F2AD'],
  ['30', '#62E270'],
  ['40', '#43DD54'],
  ['50', '#23D737'],
  ['60', '#1EB92F'],
  ['70', '#199927'],
  ['80', '#147B20'],
  ['90', '#115D19'],
]);

export const orangePalette = new Map([
  ['core', '#FFBE00'],
  ['10', '#FFF8E5'],
  ['20', '#FDE7A4'],
  ['30', '#FCD358'],
  ['40', '#FEC92E'],
  ['50', '#FFBE00'],
  ['60', '#E9AE01'],
  ['70', '#D39E03'],
  ['80', '#BC8D03'],
  ['90', '#A77E05'],
]);

export const redPalette = new Map([
  ['core', '#FA5065'],
  ['10', '#FFF3F4'],
  ['20', '#FCBAC1'],
  ['30', '#F98694'],
  ['40', '#F96B7D'],
  ['50', '#FA5065'],
  ['60', '#DE485B'],
  ['70', '#C34151'],
  ['80', '#A93B48'],
  ['90', '#8E333E'],
]);

export const brightRedPalette = new Map([
  ['core', '#FF0000'],
  ['30', '#F5424B'],
  ['50', '#FF0000'],
  ['60', '#DD0000'],
]);

export const grayPalette = new Map([
  ['core', '#A9A9A9'],
  ['10', '#FAFAFA'],
  ['20', '#F4F4F4'],
  ['30', '#E6E6E6'],
  ['40', '#CCCCCC'],
  ['50', '#A9A9A9'],
  ['55', '#96A9B2'],
  ['60', '#999999'],
  ['70', '#6E7273'],
  ['80', '#555555'],
  ['85', '#3D3D3D'],
  ['90', '#333333'],
  ['95', '#1F2532'],
]);

export const blueGrayPalette = new Map([
  ['core', '#B5C4CB'],
  ['10', '#F2F7F9'],
  ['15', '#E6F4FB'],
  ['20', '#E5EEF2'],
  ['30', '#D5E0E5'],
  ['40', '#C5D2D8'],
  ['50', '#B5C4CB'],
  ['60', '#A5B6BF'],
  ['70', '#96A9B2'],
  ['80', '#7A8E97'],
  ['90', '#60737C'],
]);

export const whitePalette = new Map([['core', '#FFFFFF']]);

export const blackPalette = new Map([
  ['core', '#000000'],
  ['30', '#222222'],
  ['40', '#1a1a1a'],
  ['50', '#000000'],
]);

export const socialPalette = new Map([
  ['facebook', '#3a5998'],
  ['twitter', '#4099ff'],
]);

export const swellPurple = new Map([
  ['core', '#9100FF'],
  ['10', '#f1e1fc'],
  ['20', '#edd8fb'],
  ['50', '#9100FF'],
]);

export const conditions = new Map([
  ['very-poor', 'var(--conditionsVeryPoor)'],
  ['poor', 'var(--conditionsPoor)'],
  ['poor-to-fair', 'var(--conditionsPoorToFair)'],
  ['fair', 'var(--conditionsFair)'],
  ['fair-to-good', 'var(--conditionsFairToGood)'],
  ['good', 'var(--conditionsGood)'],
  ['epic', 'var(--conditionsEpic)'],
]);

export const colorPalettes = new Map([
  ['light-blue', lightBluePalette],
  ['dark-blue', darkBluePalette],
  ['aqua', aquaPalette],
  ['shark-blue', sharkBluePalette],
  ['marine-blue', marineBluePalette],
  ['nautical-blue', nauticalBluePalette],
  ['green', greenPalette],
  ['orange', orangePalette],
  ['red', redPalette],
  ['bright-red', brightRedPalette],
  ['gray', grayPalette],
  ['blue-gray', blueGrayPalette],
  ['white', whitePalette],
  ['black', blackPalette],
  ['social', socialPalette],
  ['swell-purple', swellPurple],
  ['conditions', conditions],
]);

const color = (palette: string, tone: string | number = 'core') =>
  colorPalettes.get(palette)?.get(String(tone));

export default color;
