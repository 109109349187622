import React from 'react';

const PremiumTagLarge: React.FC<{ className?: string }> = ({ className }) => (
  <svg width="24px" height="30px" viewBox="0 0 24 30" className={className}>
    <g id="Premium-only-stories" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="desktop---wp-paywall"
        transform="translate(-315.000000, -1384.000000)"
        fill="#22D737"
        fillRule="nonzero"
      >
        <path
          d="M317,1384 L337,1384 C338.104569,1384 339,1384.89543 339,1386 L339,1414 L327,1408.57339 L315,1414 L315,1386 C315,1384.89543 315.895431,1384 317,1384 Z"
          id="Rectangle-Copy-14"
        />
      </g>
    </g>
  </svg>
);

export default PremiumTagLarge;
