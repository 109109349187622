import React from 'react';
import classNames from 'classnames';
import { useMount } from 'react-use';

import type { MenuState, NavigationSettings, ServiceConfiguration } from 'types/header';

import { fetchSpotReportViews } from 'common/api/spot';
import type { UserSettings } from 'types/user';
import fetchTaxonomy from '../../../helpers/fetchTaxonomy';

import Breadcrumb from './components/Breadcrumb';
import ChildList from './components/ChildList';
import Header from './components/Header';
import { EARTH_TAXONOMY_ID } from './constants';
import { getListType, groupItems, alphabetizeItems } from './utils';
import { loadSavedLocation, saveLocation } from './utils/locationStore';
import { loadSavedNavigation, saveNavigation } from './utils/navigationStore';

import styles from './TaxonomyNavigator.module.scss';

interface TaxonomyNavigatorProps {
  hierarchy: Array<string>;
  loggedIn?: boolean;
  menuState: MenuState;
  navigationSettings?: NavigationSettings;
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
  savedLocationType: string;
  scrollMenuToTop?: () => void;
  serviceConfig: ServiceConfiguration;
  setMenuState: (state: MenuState) => void;
  taxonomyType?: string;
  userSettings: UserSettings | null;
}

const TaxonomyNavigator = ({
  hierarchy,
  loggedIn = false,
  menuState,
  navigationSettings,
  onClickLink,
  savedLocationType,
  scrollMenuToTop,
  serviceConfig,
  setMenuState,
  taxonomyType = 'spot',
  userSettings,
}: TaxonomyNavigatorProps) => {
  const { loading, error, taxonomy } = menuState;

  const setActiveNodeTaxonomy = async (taxonomyId: string, maxDepth: number) => {
    try {
      setMenuState({ loading: true, error: false });
      const activeTaxonomy = await fetchTaxonomy(taxonomyId, maxDepth, serviceConfig);
      const spotCount = activeTaxonomy.contains.filter((item: any) => item.type === 'spot').length;
      const geonameCount = activeTaxonomy.contains.filter(
        (item: any) => item.type === 'geoname' && item.hasSpots && item.liesIn.length < 3,
      ).length;

      const inLength = hierarchy.length - 1;
      const contains = activeTaxonomy.contains.filter(
        (item: any) =>
          (item.type === 'geoname' || item.type === taxonomyType) &&
          (item.type === 'spot' ||
            item.hasSpots ||
            (taxonomyType === 'subregion' && item.hasSpots)) &&
          (geonameCount * 2 < spotCount || spotCount > 50
            ? activeTaxonomy.in.length >= inLength || item.depth === 0
            : true),
      );

      const listType = getListType(contains, taxonomyType);

      let children;
      if (listType === 'spots') {
        const spotIds = contains
          .filter((item: any) => item.type === 'spot')
          .map((item: any) => item.spot);
        const { data } = await fetchSpotReportViews(spotIds, {
          units: userSettings?.units,
        });
        children = groupItems(contains, data);
      } else if (listType === 'subregions') {
        const subregions = contains.filter((item: any) => item.type === 'subregion');
        children = alphabetizeItems(subregions);
      } else {
        children = alphabetizeItems(contains);
      }
      setMenuState({
        taxonomyId,
        loading: false,
        error: false,
        taxonomy: {
          listType,
          parents: activeTaxonomy.in.filter((item: any) => item.type === 'geoname'),
          activeNode: {
            name: activeTaxonomy.name,
            _id: activeTaxonomy._id,
            type: activeTaxonomy.type,
            taxonomyId: activeTaxonomy.taxonomyId,
            links: activeTaxonomy.associated.links,
          },
          children: children as any,
        },
      });
    } catch (e) {
      setMenuState({ error: true });
    }
  };

  const taxonomyDepth = (taxonomyId: string) => {
    if (taxonomyId === taxonomy?.activeNode?._id) return taxonomy?.parents?.length ?? 0;

    const parentsIndex = taxonomy?.parents?.findIndex(({ _id }) => _id === taxonomyId);
    if (parentsIndex && parentsIndex > -1) return parentsIndex;
    return (taxonomy?.parents?.length ?? 0) + 1;
  };

  const updateActiveNodeTaxonomy = async (taxonomyId: string) => {
    // No need to await saveLocation. If it errors we can ignore it and try again next time.
    saveLocation(taxonomyId, savedLocationType, loggedIn, serviceConfig, navigationSettings);
    saveNavigation(taxonomyId, savedLocationType);
    const inLength = hierarchy.length - 2;
    const depth = taxonomyDepth(taxonomyId) < inLength || taxonomyId === EARTH_TAXONOMY_ID ? 0 : 2;
    await setActiveNodeTaxonomy(taxonomyId, depth);
    if (scrollMenuToTop) {
      scrollMenuToTop();
    }
  };

  const handleBreadcrumbClickLink = (clickProperties: any, closeMobileMenu: boolean) => {
    if (onClickLink) {
      onClickLink(
        {
          ...clickProperties,
          locationCategory: 'Taxonomy',
          currentTaxonomyLevel: taxonomy?.parents?.length,
        },
        closeMobileMenu,
      );
    }
  };

  const handleHeaderClickChange = () => updateActiveNodeTaxonomy(EARTH_TAXONOMY_ID);

  const handleHeaderClickLink = (clickProperties: any, closeMobileMenu: boolean) => {
    if (onClickLink) {
      onClickLink(
        {
          ...clickProperties,
          locationCategory: 'Taxonomy',
          currentTaxonomyLevel: taxonomy?.parents?.length,
        },
        closeMobileMenu,
      );
    }
  };

  const handleChildListClickLink = (clickProperties: any, closeMobileMenu: boolean) => {
    if (onClickLink) {
      onClickLink(
        {
          ...clickProperties,
          locationCategory: 'Taxonomy',
          currentTaxonomyLevel: taxonomy?.parents?.length,
        },
        closeMobileMenu,
      );
    }
  };

  useMount(() => {
    const activeNodeId = menuState?.taxonomy?.activeNode?._id;
    if (!activeNodeId) {
      const savedLocation = loadSavedLocation(savedLocationType, navigationSettings, loggedIn);
      const savedNavigation = loadSavedNavigation(savedLocationType);
      const activeNodeFromSavedLocation = savedLocation || savedNavigation || EARTH_TAXONOMY_ID;
      // always fetch maxDepth of 2 on first load since savedLocation's parents are not saved
      // to settings (unless using Earth as active node)
      setActiveNodeTaxonomy(
        activeNodeFromSavedLocation,
        activeNodeFromSavedLocation === EARTH_TAXONOMY_ID ? 0 : 2,
      );
    }
  });

  return (
    <div
      className={classNames(styles.taxonomyNavigator, 'sl-taxonomy-navigator')}
      data-testid="taxonomy-navigator"
    >
      <Breadcrumb
        nodes={[...(taxonomy?.parents ?? []), taxonomy?.activeNode]}
        nodesPlaceholder={hierarchy}
        listType={taxonomy?.listType}
        onClickNode={updateActiveNodeTaxonomy}
        onClickLink={handleBreadcrumbClickLink}
      />
      <Header
        listType={taxonomy?.listType}
        activeNode={taxonomy?.activeNode}
        label={hierarchy[(taxonomy?.parents?.length ?? 0) + 1]}
        onClickChange={handleHeaderClickChange}
        onClickLink={handleHeaderClickLink}
      />
      <ChildList
        listType={taxonomy?.listType}
        nodes={taxonomy?.children}
        loading={loading}
        error={error}
        onClickNode={updateActiveNodeTaxonomy}
        onClickLink={handleChildListClickLink}
      />
    </div>
  );
};

export default TaxonomyNavigator;
