import KDBush from 'kdbush';

// longitude/latitude to spherical mercator in [0..1] range
const lonToX = (lon) => lon / 360 + 0.5;

const latToY = (lat) => {
  const sin = Math.sin((lat * Math.PI) / 180);
  const y = 0.5 - (0.25 * Math.log((1 + sin) / (1 - sin))) / Math.PI;
  return Math.max(Math.min(y, 1), 0);
};

// spherical mercator to longitude/latitude
const xToLon = (x) => (x - 0.5) * 360;
const yToLat = (y) => {
  const y2 = ((180 - y * 360) * Math.PI) / 180;
  return (360 * Math.atan(Math.exp(y2))) / Math.PI - 90;
};

const buildCluster = (radius, extent, points, zoom, tree) => {
  const clusters = [];
  const r = radius / (extent * 2 ** zoom);
  const visitedPoints = new Map();

  // loop through each point
  points.forEach((p) => {
    // if we've already visited the point, skip it
    if (!visitedPoints.has(p.id)) {
      visitedPoints.set(p.id, p);

      // find all nearby points
      const neighborIds = tree.within(p.x, p.y, r);

      const clusteredPoints = [p.point];
      let wx = p.x;
      let wy = p.y;

      neighborIds.forEach((neighborId) => {
        const b = tree.points[neighborId];
        // filter out neighbors that are already processed
        if (!visitedPoints.has(b.id)) {
          visitedPoints.set(b.id, b); // make sure this point doesn't get processed twice
          wx += b.x; // accumulate coordinates for calculating weighted center
          wy += b.y;
          clusteredPoints.push(b.point);
        }
      });

      const numPoints = clusteredPoints.length;

      clusters.push({
        x: wx / numPoints, // weighted cluster center
        y: wy / numPoints,
        points: clusteredPoints,
      });
    }
  });

  return clusters;
};

const defaultOptions = {
  radius: 40, // cluster radius in pixels
  extent: 256, // tile extent (radius is calculated relative to it)
  nodeSize: 64, // size of the KD-tree leaf node, affects performance
  properties: () => {}, // function to append properties to cluster
};

/**
 * @template T
 * @typedef {object} Options
 * @property {number} [radius]
 * @property {number} [extent]
 * @property {number} [nodeSize]
 * @property {T} properties
 */

/**
 * @typedef {object} Geometry
 * @property {'Point'} type
 * @property {[number, number]} coordinates
 */

/**
 * @typedef {object} Point
 * @property {Geometery} geometry
 */

/**
 * @template T
 * @typedef {object} Cluster
 * @property {'Feature'} type
 * @property {Geometry} geometry
 * @property {Options<T>['properties']} properties
 */

/**
 * @template T
 * @param {Point[]} points
 * @param {number} zoom
 * @param {Options<T>} options
 * @returns {Cluster[]}
 */
const cluster = (points, zoom, options) => {
  const radius = (options && options.radius) || defaultOptions.radius;
  const extent = (options && options.extent) || defaultOptions.extent;
  const nodeSize = (options && options.nodeSize) || defaultOptions.nodeSize;
  const properties = (options && options.properties) || defaultOptions.properties;

  // generate a cluster object for each point
  const pointClusters = points.map((p, i) => ({
    x: lonToX(p.geometry.coordinates[0]),
    y: latToY(p.geometry.coordinates[1]),
    id: i,
    point: p,
  }));

  // generate a set of clusters for the zoom level
  const tree = new KDBush(
    pointClusters,
    (p) => p.x,
    (p) => p.y,
    nodeSize,
    Float32Array,
  );
  const clusters = buildCluster(radius, extent, pointClusters, zoom, tree);

  return clusters.map((c) => ({
    type: 'Feature',
    properties: properties(c.points),
    geometry: {
      type: 'Point',
      coordinates: [xToLon(c.x), yToLat(c.y)],
    },
  }));
};

export default cluster;
