import { createAction } from '@reduxjs/toolkit';
import { ScrollPosition } from 'types/scrollPosition';

export const SET_ACTIVE_SPOT = 'SET_ACTIVE_SPOT';
export const CLEAR_ACTIVE_SPOT = 'CLEAR_ACTIVE_SPOT';
export const SCROLL_TO_SPOT = 'SCROLL_TO_SPOT';
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION';

export const setActiveSpot = createAction(SET_ACTIVE_SPOT, (spotId: string) => ({
  payload: spotId,
}));

export const clearActiveSpot = createAction(CLEAR_ACTIVE_SPOT);

export const scrollToSpot = createAction(SCROLL_TO_SPOT, (spotId: string) => ({
  payload: spotId,
}));

export const setScrollPosition = createAction(
  SET_SCROLL_POSITION,
  (component, scrollPosition: ScrollPosition) => ({
    payload: {
      component,
      scrollPosition,
    },
  }),
);
export const setSpotListScrollPosition = createAction(
  SET_SCROLL_POSITION,
  (scrollPosition: ScrollPosition) => ({
    payload: {
      component: 'spotList',
      scrollPosition,
    },
  }),
);

export const setCamListScrollPosition = createAction(
  SET_SCROLL_POSITION,
  (scrollPosition: ScrollPosition) => ({
    payload: {
      component: 'camList',
      scrollPosition,
    },
  }),
);
