import createReducer from '../createReducer';
import {
  FETCH_REGIONAL_ARTICLES,
  FETCH_REGIONAL_ARTICLES_SUCCESS,
  FETCH_REGIONAL_ARTICLES_FAILURE,
} from '../../actions/subregion';

export const initialState = {
  loading: false,
  error: null,
  articles: [],
};

const handlers = {};

handlers[FETCH_REGIONAL_ARTICLES] = (state) => ({
  ...state,
  loading: true,
});

handlers[FETCH_REGIONAL_ARTICLES_SUCCESS] = (state, { articles }) => ({
  ...state,
  articles,
  loading: false,
});

handlers[FETCH_REGIONAL_ARTICLES_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

export default createReducer(handlers, initialState);
