import { Cookies } from 'react-cookie';
import type {
  NavigationSettings,
  ServiceConfiguration,
  TaxonomyNavigatorSettings,
} from 'types/header';

import updateSettings from '../../../../helpers/updateSettings';

const cookie = new Cookies();

export const loadSavedLocation = (
  savedLocationType: string,
  navigationSettings: NavigationSettings | undefined,
  loggedIn: boolean = false,
) => {
  if (loggedIn) {
    return (navigationSettings && (navigationSettings as any)?.[savedLocationType]) ?? '';
  }
  return cookie.get(`navigation.${savedLocationType}`) || '';
};

export const saveLocation = async (
  taxonomyId: string,
  savedLocationType: string,
  loggedIn: boolean,
  serviceConfig: ServiceConfiguration,
  navigationSettings?: NavigationSettings,
) => {
  if (loggedIn) {
    const settings: TaxonomyNavigatorSettings = {
      navigation: {
        ...navigationSettings,
        [savedLocationType]: taxonomyId,
      },
    };
    await updateSettings(settings, serviceConfig);
  } else {
    cookie.set(`navigation.${savedLocationType}`, taxonomyId);
  }
};
