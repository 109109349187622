import { createAction } from '@reduxjs/toolkit';

import * as spotApi from 'common/api/spot';
import getUrlBuoyTitle from 'utils/getUrlBuoyTitle';

import config from '../config';
import { internalServerError, redirectToNotFound } from './status';

export const SPOT_LOADING = 'SPOT_LOADING';

/** @type {import('@reduxjs/toolkit').ActionCreatorWithPayload<boolean, typeof SPOT_LOADING>} */
export const setSpotLoading = createAction(SPOT_LOADING);

/**
 * Accepts a spot report result and removes
 * travel details if its status is not PUBLISHED,
 * otherwise, returns the object whole.
 * @param {Object}
 */
const attachTravelDetails = ({ travelDetails, ...spotReport }) =>
  travelDetails.status === 'PUBLISHED'
    ? {
        ...spotReport,
        travelDetails,
      }
    : spotReport;

export const FETCH_SPOT_REPORT = 'FETCH_SPOT_REPORT';
export const FETCH_SPOT_REPORT_SUCCESS = 'FETCH_SPOT_REPORT_SUCCESS';
export const UPDATE_SPOT_REPORT_FORECAST = 'UPDATE_SPOT_REPORT_FORECAST';

/**
 * @param {{ spotId: string }} args
 * @param {function} callback
 * @param {Record<string, string | string[]>} cookies
 * @param {string | undefined | null} [clientIp]
 * @param {string | undefined | null} [countryCode]
 */
export const fetchSpotReport =
  (
    { spotId },
    callback = () => {},
    cookies = null,
    clientIp = null,
    countryCode = null,
    loading = true,
  ) =>
  async (dispatch) => {
    dispatch({ type: FETCH_SPOT_REPORT, loading });
    let spotReport;
    try {
      spotReport = await spotApi.fetchReport({
        clientIp,
        cookies,
        countryCode,
        spotId,
      });
      const { spot, report, forecast, associated, live } = spotReport;

      dispatch({
        type: FETCH_SPOT_REPORT_SUCCESS,
        data: {
          spot: attachTravelDetails(spot),
          report,
          forecast,
          live,
        },
        associated,
        legacySpotId: associated.advertising.spotId,
        legacySubregionId: associated.advertising.subregionId,
      });
    } catch (err) {
      if (400 <= err.statusCode && err.statusCode < 500) {
        return dispatch(redirectToNotFound('The Spot Report Could not be found'));
      }
      return dispatch(internalServerError('The server could not return the Spot Report'));
    }

    return callback(spotReport);
  };

export const FETCH_NEARBY_SPOTS = 'FETCH_NEARBY_SPOTS';
export const FETCH_NEARBY_SPOTS_SUCCESS = 'FETCH_NEARBY_SPOTS_SUCCESS';
export const FETCH_NEARBY_SPOTS_FAILURE = 'FETCH_NEARBY_SPOTS_FAILURE';

/**
 * @param {string} spotId
 * @param {Record<string, string | string[]>} cookies
 * @param {string | undefined | null} [clientIp]
 * @param {string | undefined | null} [countryCode]
 */
export const fetchNearbySpots =
  (spotId, cookies, clientIp = null, countryCode = null) =>
  async (dispatch) => {
    dispatch({ type: FETCH_NEARBY_SPOTS });
    try {
      const nearbySpots = await spotApi.fetchNearbySpots(spotId, cookies, clientIp, countryCode);
      dispatch({
        type: FETCH_NEARBY_SPOTS_SUCCESS,
        nearbySpots,
      });
    } catch (err) {
      dispatch({
        type: FETCH_NEARBY_SPOTS_FAILURE,
        error: err.message,
      });
    }
  };

export const FETCH_SPOT_DETAILS = 'FETCH_SPOT_DETAILS';
export const FETCH_SPOT_DETAILS_SUCCESS = 'FETCH_SPOT_DETAILS_SUCCESS';
export const FETCH_SPOT_DETAILS_FAILURE = 'FETCH_SPOT_DETAILS_FAILURE';

export const fetchSpotDetails =
  (spotId, cookies, callback = () => {}, loading = true) =>
  async (dispatch) => {
    dispatch({ type: FETCH_SPOT_DETAILS, loading });
    let details;
    try {
      details = await spotApi.fetchSpotDetails(spotId, cookies);
      dispatch({
        type: FETCH_SPOT_DETAILS_SUCCESS,
        details,
      });
    } catch (err) {
      dispatch({
        type: FETCH_SPOT_DETAILS_FAILURE,
        error: err.message,
      });
    }

    return callback(details);
  };

export const FETCH_BUOYS = 'FETCH_BUOYS';
export const FETCH_BUOYS_SUCCESS = 'FETCH_BUOYS_SUCCESS';
export const FETCH_BUOYS_FAILURE = 'FETCH_BUOYS_FAILURE';
export const CLEAR_BUOYS = 'CLEAR_BUOYS';

export const fetchBuoys =
  (legacySpotId, legacySubregionId, { units, timezone }) =>
  async (dispatch) => {
    dispatch({ type: FETCH_BUOYS });
    try {
      const buoyResponse = await spotApi.fetchBuoys(legacySpotId, units);
      const buoyImagePaths = buoyResponse.buoys.map(({ buoy_id: buoyId, title }) => ({
        url: `http://www.surfline.com/buoy-report/${getUrlBuoyTitle(
          title,
        )}_${legacySubregionId}_${buoyId}/`,
        img: `${config.buoyPlotApi}?buoy_id=${buoyId}&bp_timezone=${timezone}&units=${units}`,
      }));
      dispatch({
        type: FETCH_BUOYS_SUCCESS,
        buoys: buoyImagePaths,
      });
    } catch (error) {
      dispatch({
        type: FETCH_BUOYS_FAILURE,
        error: error.message,
      });
    }
  };

export const clearBuoys = () => ({
  type: CLEAR_BUOYS,
});
