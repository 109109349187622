interface IconProps {
  colorPalette?: {
    primary?: string;
    secondary?: string;
    accent?: string;
  };
}

export const CharterIcon = () => (
  <svg
    data-testid="charter-icon"
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="40" height="40" fill="white" />
  </svg>
);

export const CampIcon = () => (
  <svg
    data-testid="camp-icon"
    width="30"
    height="25"
    viewBox="0 0 30 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.38842 10.9076H21.6316L27.5706 24.0909L29.2357 21.6693L30 22.1965L28.3579 24.5863H15.552L9.39049 10.9074L9.38842 10.9076ZM19.1589 0.792969C20.853 0.792969 22.2263 2.16619 22.2263 3.86034C22.2263 5.55443 20.8531 6.92772 19.1589 6.92772C17.4648 6.92772 16.0915 5.55449 16.0915 3.86034C16.0915 2.16625 17.4648 0.792969 19.1589 0.792969ZM1.64212 24.5859L0 22.1962L0.764248 21.6689L2.42943 24.0906L8.36835 10.9072L14.5298 24.5862H12.2837L8.36847 15.894L4.45328 24.5862H1.64425L1.64212 24.5859ZM8.36744 24.5859H11.2611L8.36744 18.1612L5.4726 24.5859H8.36744ZM13.3254 14.1111L14.7016 17.1659H20.9365L19.5603 14.1111H13.3254Z"
      fill="black"
    />
  </svg>
);

export const BedAndBreakfastIcon = () => (
  <svg
    data-testid="bed-and-breakfast-icon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.1563 2.32157C19.4513 2.082 19.6398 1.71638 19.6398 1.30672C19.6398 0.585001 19.0548 0 18.3331 0C17.6114 0 17.0264 0.585001 17.0264 1.30672C17.0264 1.7164 17.2149 2.08203 17.5099 2.3216C16.0035 2.42578 14.5794 3.07048 13.505 4.1449C12.3277 5.32209 11.6665 6.91851 11.6665 8.58308C11.6665 8.69004 11.753 8.77653 11.8597 8.77653H24.8066C24.9134 8.77653 24.9999 8.69004 24.9999 8.58308C24.9999 6.91803 24.3381 5.3209 23.1602 4.14371C22.0858 3.06978 20.6622 2.42561 19.1563 2.32157Z"
      fill="black"
    />
    <path
      d="M25.8332 9.30273H10.8333C10.3732 9.30273 10 9.67592 10 10.1361C10 10.5092 10.3732 10.9694 10.8333 10.9694H25.8332C26.2934 10.9694 26.6665 10.5962 26.6665 10.1361C26.6665 9.76288 26.2934 9.30273 25.8332 9.30273Z"
      fill="black"
    />
    <path
      d="M6.34678 17.1001C7.81766 17.1001 9.00997 15.9077 9.00997 14.4369C9.00997 12.9657 7.81766 11.7734 6.34678 11.7734C4.8759 11.7734 3.68335 12.9657 3.68335 14.4369C3.68335 15.9077 4.8759 17.1001 6.34678 17.1001Z"
      fill="black"
    />
    <path
      d="M2.21332 12.4131H0V29.5564H2.21332V25.3029H27.7134V29.5536H29.9999L29.9998 20.9463H2.21332V12.4131Z"
      fill="black"
    />
    <path
      d="M9.78613 13.5566H24.5229C25.6723 13.5566 26.7743 14.0131 27.587 14.8257C28.3997 15.6383 28.8563 16.7407 28.8563 17.8899V20.2699H9.78619V20.2672H3.20654V17.9004H9.78617L9.78613 13.5566Z"
      fill="black"
    />
  </svg>
);

export const TravelTimeIcon = ({ colorPalette = { secondary: '#fff' } }: IconProps) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M45.6893 39.7294C46.0921 40.4533 47.0055 40.7136 47.7294 40.3107C48.4533 39.9079 48.7136 38.9945 48.3107 38.2706L45.6893 39.7294ZM2.68927 38.2706C2.28645 38.9945 2.54673 39.9079 3.27062 40.3107C3.99451 40.7136 4.9079 40.4533 5.31073 39.7294L2.68927 38.2706ZM5.31073 39.7294C5.75234 38.9358 6.20882 38.1763 6.67873 37.4506L4.16063 35.8199C3.65339 36.6032 3.16253 37.4202 2.68927 38.2706L5.31073 39.7294ZM9.86013 33.2624C11.0819 31.8977 12.3601 30.718 13.6767 29.7197L11.864 27.3292C10.3856 28.4503 8.96715 29.7622 7.62497 31.2614L9.86013 33.2624ZM18.0969 27.0958C19.7002 26.3893 21.3338 25.9196 22.9734 25.6824L22.5438 22.7133C20.6265 22.9907 18.7311 23.538 16.8871 24.3505L18.0969 27.0958ZM28.0266 25.6824C29.6662 25.9196 31.2998 26.3893 32.9031 27.0958L34.1129 24.3505C32.2689 23.538 30.3734 22.9907 28.4562 22.7133L28.0266 25.6824ZM37.3233 29.7197C38.6399 30.718 39.9181 31.8977 41.1399 33.2624L43.375 31.2614C42.0328 29.7622 40.6144 28.4503 39.136 27.3292L37.3233 29.7197ZM44.3213 37.4506C44.7912 38.1763 45.2477 38.9358 45.6893 39.7294L48.3107 38.2706C47.8375 37.4202 47.3466 36.6032 46.8394 35.8199L44.3213 37.4506Z"
      fill={colorPalette.secondary}
    />
    <path d="M15 13.5H30" stroke={colorPalette.secondary} strokeWidth="4" strokeLinecap="round" />
    <path
      d="M21 13.5L26.5 8"
      stroke={colorPalette.secondary}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M23.5 13.5L26.5 8"
      stroke={colorPalette.secondary}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M34.9878 9.37067C35.6112 9.91619 35.5455 10.8761 35 11.4995L31.5 14.9995C30.9545 15.623 29.6357 15.6739 29.0123 15.1284C28.3888 14.5829 28.3256 13.6352 28.8712 13.0118L33 9.49953C33.5455 8.87607 34.3643 8.82515 34.9878 9.37067Z"
      fill={colorPalette.secondary}
    />
    <path
      d="M21.92 28.96C21.74 29.74 21.13 30.28 20.24 30.28V31H21.68V36H22.64V28.96H21.92ZM25.3916 35.12C25.9316 33.66 28.8816 33.27 28.8816 31.15C28.8816 29.81 28.0716 28.88 26.5816 28.88C25.0616 28.88 24.2416 30.01 24.2416 31.35H25.2016C25.2016 30.57 25.5716 29.77 26.5816 29.77C27.5516 29.77 27.9216 30.41 27.9216 31.07C27.9216 32.45 25.9516 32.82 24.8616 34.18C24.5016 34.63 24.2416 35.27 24.2416 36H28.8816V35.12H25.3916Z"
      fill={colorPalette.secondary}
    />
  </svg>
);

export const ConnectivityIcon = ({ colorPalette = { secondary: '#fff' } }: IconProps) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_6_4048)">
      <mask id="mask0_6_4048" maskUnits="userSpaceOnUse" x="0" y="8" width="51" height="36">
        <path
          d="M25.1777 42.4894L1.1436 8.50006L25.1777 8.50006H49.2117L25.1777 42.4894Z"
          fill="white"
          stroke="white"
        />
      </mask>
      <g mask="url(#mask0_6_4048)">
        <circle cx="25.1777" cy="40" r="28" stroke={colorPalette.secondary} strokeWidth="3" />
        <circle cx="25.1777" cy="40" r="20" stroke={colorPalette.secondary} strokeWidth="3" />
        <circle cx="25.1777" cy="39.6445" r="11" stroke={colorPalette.secondary} strokeWidth="3" />
      </g>
      <circle cx="25.1777" cy="38" r="2" stroke={colorPalette.secondary} strokeWidth="2" />
    </g>
    <defs>
      <clipPath id="clip0_6_4048">
        <rect width="50" height="50" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const CurrencyIcon = ({
  colorPalette = { primary: '#4F99A9', secondary: '#fff' },
}: IconProps) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="7"
      y="25.418"
      width="33.8652"
      height="13.0487"
      rx="2"
      transform="rotate(-10.9238 7 25.418)"
      stroke={colorPalette.secondary}
      fill={colorPalette.primary}
      strokeWidth="2"
    />
    <rect
      x="7"
      y="21.418"
      width="33.8652"
      height="13.0487"
      rx="2"
      transform="rotate(-10.9238 7 21.418)"
      fill={colorPalette.primary}
      stroke={colorPalette.secondary}
      strokeWidth="2"
    />
    <rect
      x="7"
      y="17.418"
      width="33.8652"
      height="13.0487"
      rx="2"
      transform="rotate(-10.9238 7 17.418)"
      fill={colorPalette.primary}
      stroke={colorPalette.secondary}
      strokeWidth="2"
    />
    <path
      d="M30.346 19.557C30.5054 20.3828 30.1882 21.3275 29.3309 22.205C28.4767 23.0793 27.1489 23.8069 25.5383 24.1178C23.9277 24.4286 22.4244 24.2474 21.3063 23.7538C20.1839 23.2583 19.538 22.4995 19.3786 21.6737C19.2192 20.8479 19.5364 19.9032 20.3938 19.0257C21.2479 18.1514 22.5757 17.4238 24.1863 17.1129C25.7969 16.8021 27.3002 16.9833 28.4183 17.4769C29.5407 17.9724 30.1866 18.7312 30.346 19.557Z"
      stroke={colorPalette.secondary}
      strokeWidth="2"
    />
    <ellipse
      cx="12.2362"
      cy="19.7294"
      rx="1.8814"
      ry="1.30487"
      transform="rotate(-10.9238 12.2362 19.7294)"
      fill={colorPalette.secondary}
    />
    <ellipse
      cx="37.4881"
      cy="21.5009"
      rx="1.8814"
      ry="1.30487"
      transform="rotate(-10.9238 37.4881 21.5009)"
      fill={colorPalette.secondary}
    />
  </svg>
);

export const AvgCostIcon = ({
  colorPalette = { primary: '#4F99A9', secondary: '#fff' },
}: IconProps) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27 38.9476C34.1797 38.9476 40 37.1096 40 34.8423V31.128C40 30.567 39.5386 30.1157 38.9777 30.1282L27 30.3949L15.0223 30.1282C14.4614 30.1157 14 30.567 14 31.128V34.8423C14 37.1096 19.8203 38.9476 27 38.9476Z"
      fill={colorPalette.primary}
      stroke={colorPalette.secondary}
      strokeWidth="2"
    />
    <ellipse
      cx="27"
      cy="30.1053"
      rx="13"
      ry="4.10526"
      fill={colorPalette.primary}
      stroke={colorPalette.secondary}
      strokeWidth="2"
    />
    <path
      d="M31 33.9476C38.1797 33.9476 44 32.1096 44 29.8423V26.128C44 25.567 43.5386 25.1157 42.9777 25.1282L31 25.3949L19.0223 25.1282C18.4614 25.1157 18 25.567 18 26.128V29.8423C18 32.1096 23.8203 33.9476 31 33.9476Z"
      fill={colorPalette.primary}
      stroke={colorPalette.secondary}
      strokeWidth="2"
    />
    <ellipse
      cx="31"
      cy="25.1053"
      rx="13"
      ry="4.10526"
      fill={colorPalette.primary}
      stroke={colorPalette.secondary}
      strokeWidth="2"
    />
    <path
      d="M24.0101 29.0239C28.0358 23.0791 29.7775 17.2292 27.9002 15.9579L24.8247 13.8752C24.3602 13.5607 23.7279 13.6897 23.4237 14.1611L16.9284 24.2283L9.99146 33.9964C9.66664 34.4538 9.78155 35.0889 10.2461 35.4034L13.3216 37.4861C15.1989 38.7574 19.9843 34.9687 24.0101 29.0239Z"
      fill={colorPalette.primary}
      stroke={colorPalette.secondary}
      strokeWidth="2"
    />
    <ellipse
      cx="16.6885"
      cy="24.0661"
      rx="13"
      ry="4.10526"
      transform="rotate(-55.8946 16.6885 24.0661)"
      fill={colorPalette.primary}
      stroke={colorPalette.secondary}
      strokeWidth="2"
    />
  </svg>
);

export const VisaIcon = ({ colorPalette = { secondary: '#fff' } }: IconProps) => (
  <svg width="52" height="50" viewBox="0 0 52 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="2"
      y="8"
      width="48"
      height="32"
      rx="2"
      stroke={colorPalette.secondary}
      strokeWidth="3"
    />
    <circle cx="16" cy="23" r="6.5" stroke={colorPalette.secondary} strokeWidth="3" />
    <path
      d="M25 39C25 36.2386 20.9706 34 16 34C11.0294 34 7 36.2386 7 39"
      stroke={colorPalette.secondary}
      strokeWidth="3"
    />
    <path
      d="M33 20H37.7368M33 28H43"
      stroke={colorPalette.secondary}
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const DrinkWaterIcon = ({ colorPalette = { secondary: '#fff' } }: IconProps) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5 10L26.0887 8.7851C25.7083 8.28765 25.1169 7.9971 24.4907 8.00002C23.8644 8.00295 23.2757 8.29902 22.9 8.8L24.5 10ZM32 30C32 34.1421 28.6421 37.5 24.5 37.5V41.5C30.8513 41.5 36 36.3513 36 30H32ZM24.5 37.5C20.3579 37.5 17 34.1421 17 30H13C13 36.3513 18.1487 41.5 24.5 41.5V37.5ZM17 30C17 27.7986 17.7418 25.1109 19.2989 21.9066C20.8479 18.7187 23.1426 15.1431 26.1 11.2L22.9 8.8C19.8574 12.8569 17.4021 16.6579 15.7011 20.1584C14.0082 23.6424 13 26.9547 13 30H17ZM22.9113 11.2149C29.3809 19.6751 32 25.4659 32 30H36C36 24.0406 32.6191 17.3249 26.0887 8.7851L22.9113 11.2149Z"
      fill={colorPalette.secondary}
    />
    <path
      d="M28 30C28 28.8123 27.7397 27.544 27.2037 26.16C26.7084 24.8813 25.9779 23.5039 25 22"
      stroke={colorPalette.secondary}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);

export const HazardsIcon = ({ colorPalette = { secondary: '#fff' } }: IconProps) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle
      cx="25"
      cy="25"
      r="15"
      stroke={colorPalette.secondary}
      strokeWidth="4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M15 15L36 36" stroke={colorPalette.secondary} strokeWidth="4" strokeLinejoin="round" />
  </svg>
);

export const CardsIcon = ({ colorPalette = { secondary: '#fff' } }: IconProps) => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="8"
      y="12"
      width="34"
      height="24"
      rx="2"
      stroke={colorPalette.secondary}
      strokeWidth="3"
    />
    <rect x="8" y="18" width="34" height="4" rx="2" fill={colorPalette.secondary} />
    <rect x="12" y="27" width="11" height="2" rx="1" fill={colorPalette.secondary} />
    <rect x="26" y="27" width="5" height="2" rx="1" fill={colorPalette.secondary} />
  </svg>
);
