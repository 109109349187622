import productCDN from '@surfline/quiver-assets';
import { ArrowLayerOptions } from '@wavetrak/maptiler';

import { Units } from 'types/units';

export const CLICKED_SUBSCRIBE_CTA = 'Clicked Subscribe CTA';
export const SURF_CHECK_TREATMENT_VARIANT = 'surf_check';
export const ACCOUNT_BENEFITS_TREATMENT_VARIANT = 'account_benefits';

/*
cam error message type options
SUB - Top message + secondary message below
MSG - Top message only
ALT - Single word message for small cam displays
*/
export const SUB = 'SUB';
export const MSG = 'MSG';
export const ALT = 'ALT';

export const LOLA = 'LOLA';
export const LOTUS = 'LOTUS';

export const ONLINE = 'ONLINE';
export const OFFLINE = 'OFFLINE';

export const BUOY_CURRENT_READINGS_MAP_WIDTH = 285;
export const BUOY_CURRENT_READINGS_MAP_HEIGHT = 260;
export const DMY = 'DMY';
export const MDY = 'MDY';

export const BRAZE_CONTENT_CARDS = {
  BEST_BETS_PAYWALL: 'BEST_BETS_PAYWALL',
  CAM_REWINDS_PAYWALL: 'CAM_REWINDS_PAYWALL',
  FCST_PAGE_PREMIUM_FEATURE_MODULE: 'FCST_PAGE_PREMIUM_FEATURE_MODULE',
  FORECAST_GRAPHS_PAYWALL: 'FORECAST_GRAPHS_PAYWALL',
  FORECAST_GRAPHS_PAYWALL_DAY_SELECTOR: 'FORECAST_GRAPHS_PAYWALL_DAY_SELECTOR',
  FORECAST_GRAPHS_PAYWALL_V2: 'FORECAST_GRAPHS_PAYWALL_V2',
  HOMEPAGE_HEADER: 'Braze Content Card - Homepage Header',
  HOMEPAGE_OUTLOOK: 'Braze Content Card - Homepage Outlook',
  MOBILE_WEB_DAY_SELECTOR_PAYWALL: 'MOBILE_WEB_DAY_SELECTOR_PAYWALL',
  NOTIFICATION_BAR: 'NOTIFICATION_BAR',
  PREMIUM_CAM_PAYWALL: 'PREMIUM_CAM_PAYWALL',
  PREMIUM_PILL_FCST: 'PREMIUM_PILL_FCST',
  PREMIUM_PILL_SPOT: 'PREMIUM_PILL_SPOT',
  SPOT_CURRENT_CONDITIONS: 'Braze Content Card - Spot Current Conditions',
  SPOT_HEADER: 'Braze Content Card - Spot Header',
  SPOT_PAGE_PREMIUM_FEATURE_MODULE: 'SPOT_PAGE_PREMIUM_FEATURE_MODULE',
};

export const GOOGLE_IMA_SDK_URL = 'https://imasdk.googleapis.com/js/sdkloader/ima3.js';
export const JW_PLAYER_URL = 'https://wa.cdn-surfline.com/quiver/0.20.6/scripts/jwplayer.js';
export const LEAFLET_URL = `${productCDN}/css/leaflet.css`;
export const MAP_TILER_URLS = {
  js: {
    src: `${productCDN}/scripts/maplibre-gl.min.js`,
    integrity:
      'sha512-q2N2p6YiaEzirZOG4PzaBRuu0q7etZ5+DWt9c21BWe/bVV1b2rdOts8A8/1VhtCEdrPFbIlRhJ837SlNNBxq2Q==',
  },
  css: {
    src: `${productCDN}/css/maplibre-gl.min.css`,
    integrity:
      'sha512-xCoo1+StqFbBVMd7FA3uCxiGzIeCWIhHpyZjkOUVtYOf9vzha1VBw3pvGVzcR+EKkIqazzvFj6HVnUaEfeiDmg==',
  },
};
export const VIDEO_JS_URLS = {
  css: { src: `${productCDN}/css/video-js.min.css` },
  js: { src: `${productCDN}/scripts/video.min.js` },
  videojs_contrib_ads_plugin: {
    css: `${productCDN}/css/videojs-contrib-ads.min.css`,
    js: `${productCDN}/scripts/videojs-contrib-ads.min.js`,
  },
  videojs_ima_plugin: {
    css: `${productCDN}/css/videojs.ima.min.css`,
    js: `${productCDN}/scripts/videojs.ima.min.js`,
  },
};
export const PREMIUM_FORECAST_DAYS = 16;
export const PREMIUM_FORECAST_DAYS_CHARTS = PREMIUM_FORECAST_DAYS + 2;
export const NON_PREMIUM_FORECAST_DAYS = 5;

// homepage specific - we should merge these all into one constant at some point though
export const PREMIUM_FORECAST_DAYS_HOMEPAGE = 16;
export const NON_PREMIUM_FORECAST_DAYS_HOMEPAGE = 5;

export const ADBLOCK_ELEMENTID = 'jYArWGEuFkPT2';

export const NEW_ARROW_SETTINGS: ArrowLayerOptions = {
  decodeChannels: 'rg',
  decodeMin: -4,
  decodeMax: 4,
  size: 10,
  speed: 0.00122,
  maxAmount: 128,
  density: 4,
  refreshInterval: 1385,
  color: [255, 255, 255, 120],
  fastSpeed: 7,
  rttTimestep: 70,
  worldSpaceConstant: false,
  worldSpaceFactor: 0.04,
  speedWeightedByVectorNorm: false,
  scaleSize: {
    decodeChannels: 'b',
    decodeMin: -1,
    decodeMax: 16,
    arrowMinSize: 5,
  },
};

export const HISTORIC_CHARTS_DATE_LIMIT = 1262304000; // 2010-01-01 00:00:00

export const SWELL_SPECTRA_GRAPH_HEIGHT = 215;

export const DEFAULT_UNITS: Units = {
  swellHeight: 'ft',
  temperature: 'F',
  tideHeight: 'ft',
  waveHeight: 'ft',
  windSpeed: 'kts',
};

export const PRODUCT_TUTORIALS = {
  tutorials: {
    default: { id: '2kbFMPGZ0P74fptJBbwAM6' },
    withoutSwellSpectra: { id: '3rOiAf0nvC2TEmkbSFGHLC' },
    smartCams: { id: '43Z9K461AvJvZE7rMeAtVD' },
    clips: { id: '5fQVnMNDbuG6VlPcR6FUtJ' },
  },
  articles: {
    cameraInsights: {
      id: '7KFkwiUVfcZCiFY481lJmC',
      tooltip: 'Camera Insights',
    },
    camSurfHeight: {
      id: '531dmtkh4HrQxvHaVxqW0P',
      tooltip: 'Cam Surf Heights',
    },
    findClips: {
      id: 'DhC4Vit8luUZK6VEKz9kd',
      tooltip: 'Find Clips',
    },
    editClips: {
      id: '6JF4dG1gbXYBAS7yRL1CLr',
      tooltip: 'Edit Clips',
    },
    waveTimeline: {
      id: '35qhpxdjgJbHFk2hfgTLza',
      tooltip: 'Wave Timeline',
    },
    lineup: {
      id: '5K3WMoyl3URBzeBHMOO6Ns',
      tooltip: 'Lineup Forecast',
    },
    lotus: {
      id: '74WF5wxvEWIRnsU7NWxbTT',
      tooltip: 'LOTUS',
    },
    surfHeight: {
      id: '531dmtkh4HrQxvHaVxqW0P',
      tooltip: 'Surf Height',
    },
    swellHeight: { id: '7wZqhENMaIw4XEjceStrOL', tooltip: 'Swell Height' },
    swellSpectra: {
      id: '7pLnQoH4Rc8ISMWDx6UX9t',
      tooltip: 'Swell Spectra',
    },
    tide: {
      id: '5WaT3nmRMmeR4fwrhUVQuG',
      tooltip: 'Tide Information',
    },
    waveConsistency: {
      id: '3NzkwVcOsVYsKkUdIRHIx9',
      tooltip: 'Wave Consistency',
    },
    waveEnergy: {
      id: '1A8cqelHmB5gcQBJH0od7d',
      tooltip: 'Wave Energy',
    },
    wind: {
      id: '57x0vG7ofjK3uZT7yp0hY',
      tooltip: 'Wind Information',
    },
    paywallCamInsights: {
      id: '3BkrMD1FDuMKrgkUzMVi2T',
      tooltip: 'Learn more about Smart Cam Insights.',
    },
    paywallRewinds: {
      id: 'bUD5tSGJepaOtXHnkjkm3',
      tooltip: 'Learn more about the Smart Rewind graph.',
    },
    paywallSmartClips: {
      id: '01ByhuCjGZ3LkDXg4ExoYt',
      tooltip: 'Learn more about the Smart Clip editor.',
    },
    paywallForecasterContent: {
      id: '1Obs03fajPZsuMRUQczeLa',
    },
  },
};

export const SPLIT_INCOGNITO_ID = 'incognito-window';
export const USER_AGENT_KBYG = 'wavetrak/kbyg-web';
