import React, { useRef, useMemo } from 'react';
import { Typography } from '@mui/material';
import { UserDetails } from '@surfline/web-common';

import { AddIcon } from 'components/Icons';
import TrackableLink from 'components/TrackableLink';

import getAddFavoritesHref from '../../../helpers/getAddFavoritesHref';

import styles from './AddFavorites.module.scss';

interface AddFavoritesProps {
  user?: Partial<UserDetails> | null;
  entitlements?: Array<string>;
  window?: Window | null;
}

const AddFavorites = ({ user = null, entitlements = [], window = null }: AddFavoritesProps) => {
  const loginStatus = user ? 'loggedIn' : 'loggedOut';
  let userType = 'anonymous';

  if (user) {
    userType = entitlements.indexOf('sl_premium') > -1 ? 'sl_premium' : 'sl_basic';
  }

  const linkRef = useRef();
  const eventProperties = useMemo(
    () => ({
      location: 'Favorites Bar',
      loginStatus,
      userType,
    }),
    [loginStatus, userType],
  );

  return (
    <TrackableLink
      ref={linkRef as any}
      eventName="Clicked Add Favorites"
      eventProperties={eventProperties}
    >
      <a
        ref={linkRef as any}
        className={styles.addFavorites}
        href={getAddFavoritesHref(window ? window.location.href : null, user)}
      >
        <div className={styles.wrapper}>
          <AddIcon className={styles.icon} />
          <Typography variant="subHeadline" component="p" className={styles.label}>
            Add favorites
          </Typography>
        </div>
      </a>
    </TrackableLink>
  );
};

export default AddFavorites;
