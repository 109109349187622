import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { debounce } from 'lodash';
import { getLocationFromMap } from './map';
import { setMapLocation } from '../../actions/mapV2';
import useMap from '../../hooks/map/useMap';
import useMapLocation from '../../hooks/map/useMapLocation';
import { locationObjectToString } from '../../utils/urls/mapPaths';

/**
 * @typedef {import ('../../propTypes/map/location').Location} Location
 */

/**
 * @description
 * We use the zoom and center for positioning the map so we want it to stay synced with the redux store
 */
const useSyncMapLocationToStore = () => {
  const dispatch = useDispatch();
  const [map] = useMap();
  const { location: currLocation } = useMapLocation();

  const doSyncMapLocation = useCallback(
    /** @param {Location} location */
    (location) => {
      const locationChanged =
        locationObjectToString(location) !== locationObjectToString(currLocation);
      if (locationChanged) {
        dispatch(setMapLocation(location));
      }
    },
    [dispatch, currLocation],
  );

  const onMoveEndCallback = useRef(doSyncMapLocation);

  useEffect(() => {
    onMoveEndCallback.current = doSyncMapLocation;
  }, [doSyncMapLocation]);

  useEffect(() => {
    if (map) {
      const debouncedMoveCallback = debounce(() => {
        onMoveEndCallback.current(getLocationFromMap(map));
      }, 750);

      map.on('moveend', debouncedMoveCallback);
    }
  }, [map]);
};

export default useSyncMapLocationToStore;
