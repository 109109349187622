/**
 * @typedef {import ('../../../propTypes/map/location').Location} Location
 * @typedef {import ('../../../propTypes/map/location').BoundingBox} BoundingBox
 * @typedef {import('@types/leaflet').Map?} Map
 */

/**
 * @param {Map} map
 * @returns {Location}
 */
export const getLocationFromMap = (map) => {
  const { lat, lng } = map.getCenter();
  const zoom = map.getZoom();

  return {
    center: { lat, lon: lng },
    zoom,
  };
};

/**
 * @param {Map} map
 * @returns {BoundingBox}
 */
export const getBoundingBoxFromMap = (map) => {
  const bounds = map.getBounds();

  // WARNING: Do not use destructuring here on `bounds` and its inner functions. This will
  // break the this context and cause the function to throw.
  return {
    north: bounds.getNorth(),
    south: bounds.getSouth(),
    east: bounds.getEast(),
    west: bounds.getWest(),
  };
};
