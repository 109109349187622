import React from 'react';

import PropTypes from 'prop-types';
import { useLeafletComponent } from './useLeaflet';
import MapContext from './MapContext';

/**
 * @typedef {object} Props
 * @property {string} name
 * @property {string} className
 * @property {React.CSSProperties} style
 * @property {boolean} [usingPortal]
 * @property {import('leaflet').Map} [map]
 */

/** @type {React.FunctionComponent<React.PropsWithChildren<Props>>} */
const Pane = ({ name, style, className, children, usingPortal, map }) => {
  const LeafletPane = useLeafletComponent('Pane');

  if (!LeafletPane) {
    return null;
  }

  if (usingPortal) {
    return (
      <MapContext map={map}>
        <LeafletPane name={name} style={style} className={className}>
          {children}
        </LeafletPane>
      </MapContext>
    );
  }

  return (
    <LeafletPane name={name} style={style} className={className}>
      {children}
    </LeafletPane>
  );
};

Pane.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape(),
  children: PropTypes.node,
  usingPortal: PropTypes.bool,
  map: PropTypes.shape(),
};

Pane.defaultProps = {
  className: null,
  style: null,
  children: null,
  usingPortal: false,
  map: null,
};

export default Pane;
