import createReducer from '../createReducer';
import { RERENDER_MAP } from '../../actions/map';

export const initialState = {
  doRerender: false,
};

const handlers = {};

handlers[RERENDER_MAP] = (state) => ({
  ...state,
  doRerender: true,
});

export default createReducer(handlers, initialState);
