import React from 'react';
import classNames from 'classnames';
import { Typography } from '@mui/material';
import { slugify } from '@surfline/web-common';

import WavetrakLink from 'components/WavetrakLink';
import { useDeviceSize } from 'hooks/useDeviceSize';
import type { SearchResult as SearchResultInterface } from 'types/header';

import SearchResult from '../SearchResult';
import { trackClickedSearchResults } from '../../helpers';

import styles from './SearchSection.module.scss';

interface SearchSectionProps {
  closeSearch: () => void;
  searchTerm: string;
  sectionLimit: number;
  sectionResults: Array<SearchResultInterface>;
  sectionTitle: string;
  viewAllLink: {
    title?: string;
    href?: string;
  };
  handleResultClick: (result: SearchResultInterface) => void;
}

const getSectionClasses = (section: string) =>
  classNames(styles.section, {
    [styles[`section--${slugify(section)}`]]: section.toLocaleLowerCase() === 'surf news',
  });

const SearchSection = ({
  closeSearch,
  searchTerm,
  sectionLimit,
  sectionResults,
  sectionTitle,
  viewAllLink,
  handleResultClick,
}: SearchSectionProps) => {
  const { isMobile } = useDeviceSize();

  const handleViewAllClick = () => {
    closeSearch();
    return trackClickedSearchResults(window, window ? window.location.href : '', {
      searchId: '',
      searchResultRank: null,
      destinationPage: `/search/${searchTerm}`,
      queryTerm: searchTerm,
      resultName: '',
      resultType: `see all ${viewAllLink?.title ?? ''} results`,
      isMobileView: isMobile,
    });
  };

  return (
    <div className={getSectionClasses(sectionTitle)}>
      <Typography variant="h5" className={styles.title}>
        {sectionTitle}
      </Typography>
      {sectionResults.length ? (
        <ul className={styles.results}>
          {sectionResults.map((item, itemSectionIndex) => (
            <SearchResult
              key={item?._id ?? `search-result-index-${itemSectionIndex}`}
              result={item}
              searchTerm={searchTerm}
              handleClick={handleResultClick}
            />
          ))}
        </ul>
      ) : (
        <Typography className={styles.noResults} component="span" variant="body1">
          No results found
        </Typography>
      )}
      {sectionResults.length >= sectionLimit ? (
        <Typography
          className={styles.viewAll}
          component={WavetrakLink}
          href={viewAllLink.href || ''}
          onClick={handleViewAllClick}
          variant="subtitle1"
        >
          {`View all ${viewAllLink.title}`}
        </Typography>
      ) : null}
    </div>
  );
};

export default SearchSection;
