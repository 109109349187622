import { oneDaySeconds } from 'utils/time';
import createReducer from '../../createReducer';
import {
  FETCH_GRAPH_CONDITION_FORECAST,
  FETCH_GRAPH_CONDITION_FORECAST_SUCCESS,
  FETCH_GRAPH_CONDITION_FORECAST_FAILURE,
} from '../../../actions/graphs';

export const initialState = {
  error: null,
  loading: true,
  units: null,
  utcOffset: null,
  days: null,
};

const handlers = {};

handlers[FETCH_GRAPH_CONDITION_FORECAST] = () => initialState;

handlers[FETCH_GRAPH_CONDITION_FORECAST_SUCCESS] = (state, { response, isPremium }) => {
  // ensure we have 16 days of data when not premium
  const returnedDays = response.data.conditions;
  const [lastDayOfData] = returnedDays.slice(-1);
  const missingDays = Array.from({ length: 16 - returnedDays.length }, (_, index) => ({
    am: null,
    forecastDay: null,
    forecaster: null,
    human: false,
    observation: null,
    pm: null,
    timestamp: lastDayOfData.timestamp + oneDaySeconds * (index + 1),
    utcOffset: lastDayOfData.utcOffset,
  }));
  const combinedDays = isPremium
    ? returnedDays
    : [...returnedDays, ...missingDays].map((day) => ({
        ...day,
        forecaster: null,
        human: false,
        observation: null,
      }));

  return {
    ...state,
    loading: false,
    units: response.associated.units,
    utcOffset: response.associated.utcOffset,
    days: combinedDays,
  };
};

handlers[FETCH_GRAPH_CONDITION_FORECAST_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

export default createReducer(handlers, initialState);
