import { AnyAction, Reducer } from 'redux';
import {
  favoriteSpot,
  unfavoriteSpot,
  setRecentlyVisited,
  updateUserSettings,
} from '@surfline/web-common';
import { doFetchUserFavorites } from 'actions/user';
import type { FavoriteItem } from 'types/favorites';
import createReducer from './createReducer';

export const initialState = {
  user: {
    details: null,
    entitlements: [],
    promotionEntitlements: [],
    favorites: [],
    settings: null,
    warnings: [],
  },
};

const handlers: { [key: string]: Reducer<any, AnyAction> } = {};

handlers[updateUserSettings.fulfilled.toString()] = (state, { payload: { settings } }) => ({
  ...state,
  user: {
    ...state.user,
    settings: {
      ...state.user.settings,
      ...settings,
    },
  },
});

handlers[favoriteSpot.pending.toString()] = (state) => ({
  ...state,
  user: {
    ...state.user,
    favoriteSpotLoading: true,
  },
});

handlers[favoriteSpot.fulfilled.toString()] = (state, { payload: { favorites } }) => ({
  ...state,
  user: {
    ...state.user,
    favoriteSpotLoading: false,
    favorites,
  },
});

handlers[favoriteSpot.rejected.toString()] = (state, { error }) => ({
  ...state,
  user: {
    ...state.user,
    favoriteSpotLoading: false,
    favoriteSpotError: error.message,
  },
});

handlers[unfavoriteSpot.pending.toString()] = (state) => ({
  ...state,
  user: {
    ...state.user,
    favoriteSpotLoading: true,
  },
});

handlers[unfavoriteSpot.fulfilled.toString()] = (state, { payload: { spotId } }) => ({
  ...state,
  user: {
    ...state.user,
    favoriteSpotLoading: false,
    favorites: state.user.favorites.filter((favorite: FavoriteItem) => favorite?._id !== spotId),
  },
});

handlers[unfavoriteSpot.rejected.toString()] = (state, { error }) => ({
  ...state,
  user: {
    ...state.user,
    favoriteSpotLoading: false,
    favoriteSpotError: error.message,
  },
});

handlers[doFetchUserFavorites.fulfilled.toString()] = (state, { payload: { favorites } }) => ({
  ...state,
  user: {
    ...state.user,
    favorites,
  },
});

handlers[doFetchUserFavorites.rejected.toString()] = (state) => ({
  ...state,
  user: {
    ...state.user,
  },
});

handlers[setRecentlyVisited.fulfilled.toString()] = (state, { payload: { recentlyVisited } }) => ({
  ...state,
  user: {
    ...state.user,
    settings: {
      ...state.user.settings,
      recentlyVisited,
    },
  },
});

export default createReducer(handlers, initialState);
