import { useEffect } from 'react';
import useMap from '../../hooks/map/useMap';
import useMapLocation from '../../hooks/map/useMapLocation';

/**
 * @description
 * This hook is responsible for watching for changes to the location and ensuring that
 * they always sync to the map by calling `map.setView()`. If not it becomes possible
 * that the map loads before a state change can be made and it would load with the incorrect
 * center point.
 */
const useSyncLocationToMap = () => {
  const [map] = useMap();
  const { location, locationLoaded } = useMapLocation();

  useEffect(() => {
    if (map && location && locationLoaded) {
      map.setView(location.center, location.zoom);
    }
  }, [map, location, locationLoaded]);
};

export default useSyncLocationToMap;
