import React from 'react';

export const YouTube = () => (
  <svg
    className="quiver-youtube-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <title>YouTube Logo</title>
    <path
      d={`M18.8104222,5.88293333 C18.8104222,5.88293333 18.6246444,4.57362222
        18.0548555,3.99686667 C17.3326444,3.24024445 16.5226111,3.23665556 16.1512666,
        3.19232222 C13.4925333,3 9.50422223,3 9.50422223,3 L9.49577776,3 C9.49577776,3
        5.5076778,3 2.84873333,3.19232222 C2.47738889,3.23665556 1.66777778,3.24024445
        0.945144445,3.99686667 C0.375355557,4.57362222 0.19,5.88293333 0.19,5.88293333
        C0.19,5.88293333 0,7.42045556 0,8.9579778 L0,10.3996556 C0,11.9369666 0.19,13.4744889
        0.19,13.4744889 C0.19,13.4744889 0.375355557,14.7838 0.945144445,15.3605556 C1.66777778,
        16.1173889 2.61714445,16.0931111 3.04,16.1724889 C4.56021112,16.3183667 9.5,16.3635445
        9.5,16.3635445 C9.5,16.3635445 13.4925333,16.3574222 16.1512666,16.1653111 C16.5226111,
        16.1209778 17.3326444,16.1173889 18.0548555,15.3605556 C18.6246444,14.7838 18.8104222,
        13.4744889 18.8104222,13.4744889 C18.8104222,13.4744889 19,11.9369666 19,10.3996556 L19,
        8.9579778 C19,7.42045556 18.8104222,5.88293333 18.8104222,5.88293333 L18.8104222,
        5.88293333 Z M7.53856668,12.1461778 L7.53772222,6.80781111 L12.6715222,9.48617777
        L7.53856668,12.1461778 L7.53856668,12.1461778 Z`}
    />
  </svg>
);

export default YouTube;
