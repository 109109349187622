import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';

import { setSpotLoading } from 'actions/spot';

import report from './report';
import nearby from './nearby';
import buoys from './buoys';
import details from './details';

export default combineReducers({
  report,
  nearby,
  buoys,
  details,
  loading: createReducer(false, (builder) => {
    builder.addCase(setSpotLoading, (state, action) => action.payload);
  }),
});
