import { Cookies } from 'react-cookie';

const writeDebugLog = (message: string) => {
  const cookies = new Cookies();
  const hasDebugCookie = cookies.get('wavetrak-debug') === 'true';
  if (hasDebugCookie) {
    // eslint-disable-next-line no-console
    console.log(`WT-Debug: ${message}`);
  }
};

export default writeDebugLog;
