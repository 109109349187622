import { transformGraphDataToDays as transformDataToDays } from '@surfline/web-common';

import {
  FETCH_GRAPH_RATING_FORECAST,
  FETCH_GRAPH_RATING_FORECAST_SUCCESS,
  FETCH_GRAPH_RATING_FORECAST_FAILURE,
} from 'actions/graphs';
import createReducer from 'reducers/createReducer';
import createDayHourlyData from './helpers/createDayHourlyData';
import createFiveDayHourlyWave from './helpers/createFiveDayHourlyData';

/**
 * @typedef {import('../../../types/conditionRating').ConditionRating} Rating
 */

/**
 * @typedef {import('../../../types/conditionRating').ConditionData} GraphRatingState
 */

/**
 * @type {GraphRatingState}
 */
export const initialState = {
  error: null,
  loading: true,
  location: null,
  days: null,
  hourly: null,
};

const handlers = {};

handlers[FETCH_GRAPH_RATING_FORECAST] = () => initialState;

handlers[FETCH_GRAPH_RATING_FORECAST_SUCCESS] = (
  state,
  { response, desired16DayInterval, isPremium },
) => {
  /** @type {{ rating: Rating[] }}  */
  const { rating } = response.data;

  /**
   * @param {Rating} p
   */
  const mapFunc = (p) => ({
    ...p,
    rating: p.rating.key,
  });

  return {
    ...state,
    loading: false,
    location: response.associated.location,
    days:
      desired16DayInterval === 1 || desired16DayInterval === 3
        ? createDayHourlyData(rating, 16, desired16DayInterval, mapFunc)
        : // TODO: Only Keep `createDayHourlyData` when tearing out old graphs
          transformDataToDays(
            rating
              .filter((_, index) => index % desired16DayInterval === 0)
              .map((singleRating) => {
                if (isPremium) return singleRating;

                return {
                  ...singleRating,
                  rating: {
                    key: 'none',
                    value: null,
                  },
                };
              }),
            desired16DayInterval,
            mapFunc,
          ),
    hourly: createFiveDayHourlyWave(rating, mapFunc),
  };
};

handlers[FETCH_GRAPH_RATING_FORECAST_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

export default createReducer(handlers, initialState);
