import { accountPaths } from '@surfline/web-common';
import type { EnvConfigBase } from 'config';

const base: EnvConfigBase = {
  accountSettingsUrl: '/account/edit-settings',
  appStoreUrl: {
    iOS: 'https://apps.apple.com/app/apple-store/id393782096',
  },
  buoyPlotApi: 'https://tile.surfline.com/dashboard/buoyplot.cfm',
  camStillURL: 'https://camstills.cdn-surfline.com',
  chartEmbedClientIds: ['6123ef4f5192b46d74d575a6'],
  childGridThreeCol: 1090,
  childGridTwoCol: 680,
  cloudflareImageResizingUrl: (src: string, query = 'q=85,f=auto,fit=contain') =>
    `https://www.surfline.com/cdn-cgi/image/${query}/${src}`,
  createAccountUrl: '/create-account',
  desktopWidth: 1201,
  desktopXLWidth: 1435,
  desktopXXLWidth: 1600,
  facebookOGImage: '/facebook-og-default.png',
  funnelUrl: accountPaths.funnelPath,
  hostForSEO: 'https://www.surfline.com',
  mapTileKey: '3tFgnOQBQixe61aigsBT',
  mapTileUrl: 'https://api.maptiler.com/maps/062c0d04-1842-4a45-8181-c5bec3bf2214',
  meta: {
    title: {
      homepage:
        'SURFLINE.COM | Global Surf Reports, Surf Forecasts, Live Surf Cams and Coastal Weather',
      cookieList: 'Cookie List - Surfline',
      search: (term: string) => `Search results for ${term} - Surfline`,
      travel: {
        landing: 'Travel - Surfline',
        zone: (name: string) => `Travel Zone ${name} - Surfline`,
        spot: (name: string) => `${name} Surf Guide - Surfline`,
      },
      tideChartsPage: (name: string) =>
        `${name} Tides - Updated High & Low Tide ${name} Tide Time Charts - Surfline`,
    },
    description: {
      homepage:
        'The most accurate and trusted surf reports, forecasts' +
        ' and coastal weather. Surfers from around the world choose Surfline for dependable' +
        ' and up to date surfing forecasts and high quality surf content, live surf cams and features.',
      tideChartsPage: (name: string) =>
        `Get the latest tide tables and graphs for ${name}, including sunrise and sunset times. Available for extended date ranges with Surfline Premium.`,
    },
  },
  mobileWidth: 512,
  multiCamConditionsPollingInterval: 1200000,
  rangeSelectorWidths: {
    oneDay: 375,
    twoDay: 769,
    threeDay: 1070,
    fourDay: 1440,
    fiveDay: 1920,
  },
  smallMobileWidth: 350,
  surfNetworkBaseUrl: 'https://api.nodplatform.com/',
  tabletLargeWidth: 976,
  twitterOGImage: '/twitter-og-default.png',
};

export default base;
