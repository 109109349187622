import { USER_AGENT_KBYG } from 'common/constants';

const addCustomUserAgentHeader = (isServer = false) =>
  isServer
    ? {
        'user-agent': USER_AGENT_KBYG,
      }
    : null;

export default addCustomUserAgentHeader;
