import React from 'react';

interface Props {
  className?: string;
}

export const TideIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_6_1861" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
      <circle cx="20" cy="20" r="20" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_6_1861)">
      <circle cx="20" cy="20" r="19" stroke="#171717" strokeWidth="2" />
      <path
        d="M0.833008 19.5835C0.833008 19.5835 6.54857 22.0835 10.4163 22.0835C14.2841 22.0835 16.1319 19.5835 19.9997 19.5835C23.8674 19.5835 25.7152 22.0835 29.583 22.0835C33.4508 22.0835 39.1663 19.5835 39.1663 19.5835"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M0.833008 24.5835C0.833008 24.5835 6.54857 27.0835 10.4163 27.0835C14.2841 27.0835 16.1319 24.5835 19.9997 24.5835C23.8674 24.5835 25.7152 27.0835 29.583 27.0835C33.4508 27.0835 39.1663 24.5835 39.1663 24.5835"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M0.833008 29.5835C0.833008 29.5835 6.54857 32.0835 10.4163 32.0835C14.2841 32.0835 16.1319 29.5835 19.9997 29.5835C23.8674 29.5835 25.7152 32.0835 29.583 32.0835C33.4508 32.0835 39.1663 29.5835 39.1663 29.5835"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M0.833008 34.5835C0.833008 34.5835 6.54857 37.0835 10.4163 37.0835C14.2841 37.0835 16.1319 34.5835 19.9997 34.5835C23.8674 34.5835 25.7152 37.0835 29.583 37.0835C33.4508 37.0835 39.1663 34.5835 39.1663 34.5835"
        stroke="black"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default TideIcon;
