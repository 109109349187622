import type { Request } from 'express';
import UAParser from 'ua-parser-js';

export const getDeviceInformation = (
  headers: Request['headers'],
): { os: { name: string | null; version: string | null } } => {
  try {
    const userAgent = headers['user-agent'];
    const parser = new UAParser();
    const ua = userAgent;
    const { name, version } = parser.setUA(ua || '').getOS();
    return { os: { name: name?.toLowerCase() ?? null, version: version ?? null } };
  } catch (error) {
    return { os: { name: null, version: null } };
  }
};

export default getDeviceInformation;
