import type { TaxonomyNode } from 'types/header';

const alphabetizeItems = (items: Array<TaxonomyNode>) =>
  items.sort((a, b) => {
    const aName = a?.name ?? '';
    const bName = b?.name ?? '';
    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

export default alphabetizeItems;
