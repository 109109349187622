import { slugify } from '@surfline/web-common';
import router from 'next/router';
import { CustomLocation } from 'types/userCustomLocations';
import { UserFavorite } from 'types/userFavorites';
import { ChartSlug } from '../../types/mapTiler';
import { locationObjectToString } from './mapPaths';
import { Location } from '../../propTypes/map/location';

export const forecastChartsPaths = {
  base: '/surf-charts',
  spot: '/surf-report',
};

const subregionForecastChartsPath = (
  subregionId: string,
  subregionName: string,
  chartLayerType: ChartSlug,
  location: Location,
  spotId?: string,
) =>
  `${forecastChartsPaths.base}/${slugify(subregionName)}/${subregionId}/@${locationObjectToString(
    location,
  )}?type=${chartLayerType}&pinnedLocation=${location.center.lon}%2C${location.center.lat}${
    spotId ? `&spotId=${spotId}` : ''
  }`;

export const spotForecastChartsPath = (
  spotId: string,
  spotName: string,
  chartLayerType: ChartSlug,
  location: Location,
) =>
  `${forecastChartsPaths.spot}/${slugify(spotName)}/${spotId}/surf-charts/@${locationObjectToString(
    location,
  )}?type=${chartLayerType}&pinnedLocation=${location.center.lon}%2C${location.center.lat}`;

export const forecastChartsPath = (
  chartsSlug: ChartSlug,
  location: Location,
  pinnedLocation = false,
) =>
  `${forecastChartsPaths.base}/@${locationObjectToString(location)}?type=${chartsSlug}${
    pinnedLocation ? `&pinnedLocation=${location.center.lon}%2C${location.center.lat}` : ''
  }`;

export const buildChartsUrlFromUserFavorite = (spot: UserFavorite | CustomLocation, zoom = 7) => {
  const currentChartSlug = router.query?.type as ChartSlug;
  const location = {
    center: {
      lat: spot.lat,
      lon: spot.lon,
    },
    zoom,
  };

  if ('subregionName' in spot) {
    return spotForecastChartsPath(spot._id, spot.name, currentChartSlug, location);
  }
  return forecastChartsPath(currentChartSlug, location, true);
};

export default subregionForecastChartsPath;
