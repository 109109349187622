import React from 'react';
import classNames from 'classnames';

import { useUserEntitlementStatus } from 'selectors/user';
import styles from './UserAvatar.module.scss';

interface UserAvatarProps {
  accountAlert?: boolean | null;
  accountWarning?: boolean;
  isPremiumPlusUser: boolean;
  showNotification?: boolean;
}

const UserAvatar = ({
  accountAlert = false,
  accountWarning = false,
  isPremiumPlusUser,
  showNotification,
}: UserAvatarProps) => {
  const isSubscribed = useUserEntitlementStatus();
  const notification = (!isSubscribed && accountAlert) || accountWarning || showNotification;

  const getRingClassName = () =>
    classNames(styles.ring, {
      [styles['ring--premium-plus']]: isSubscribed && isPremiumPlusUser,
      [styles['ring--premium']]: isSubscribed,
      [styles['ring--alert']]: !isSubscribed && accountAlert,
      [styles['ring--warning']]: accountWarning,
    });

  const getNotificationClassName = () =>
    classNames(styles.notification, {
      [styles['ring--alert']]: !isSubscribed && accountAlert,
      [styles['ring--warning']]: accountWarning,
      [styles['notification--warning']]: showNotification,
      [styles['notification-ring--warning']]: showNotification,
    });

  return (
    <svg
      className={styles.notificationSvg}
      width="34px"
      height="34px"
      role="img"
      viewBox="0 0 34 34"
    >
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(1.000000, 1.000000)"
      >
        <circle
          className={getRingClassName()}
          strokeWidth="2"
          cx="16"
          cy="16"
          r="16"
          data-testid="user-avatar-ring"
        />
        <circle stroke="#000" cx="16" cy="13.6" r="6.1" className={styles.userIcon} />
        <path
          className={styles.userIcon}
          d="M26.4493244,27.5833333 C25.9299768,25.0267564 21.4813083,22.9 16,22.9 C10.5186917,22.9 6.07002319,25.0267564 5.55067563,27.5833333"
          stroke="#000"
        />
      </g>
      {notification ? (
        <circle
          className={getNotificationClassName()}
          strokeWidth="2"
          fillRule="nonzero"
          cx="28"
          cy="6"
          r="5"
          data-testid="user-avatar-notification"
        />
      ) : null}
    </svg>
  );
};

export default UserAvatar;
