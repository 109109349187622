import type { TaxonomyNode } from 'types/header';

const getListType = (nodes: Array<TaxonomyNode>, taxonomyType: string = 'spot') => {
  const targetTaxonomy = nodes.find((node) => node.type === taxonomyType);

  if (!targetTaxonomy) {
    return 'geonames';
  }
  return `${taxonomyType}s`;
};

export default getListType;
