import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';
import { Box } from '@mui/material';
import {
  getEntitlements,
  getUserDetails,
  getUserFavorites,
  getUserFreeTrialEligible,
  getUserSettings,
  getUserEmailVerified,
  getUserSubscriptionWarnings,
} from '@surfline/web-common';

import config from 'config';
import type { TaxonomyNavigatorSettings } from 'types/header';
import { useTreatments, parseTreatmentWithConfig } from 'utils/treatments';
import { getIsGuestUser } from 'selectors/user';
import {
  PREMIUM_PERKS,
  RELEASE_ANNOUNCEMENT,
  SL_PREMIUM_PLUS,
  SL_WEB_SHARED_SUBSCRIPTIONS,
} from 'common/treatments';

import useConditions from 'hooks/useConditions';
import { isPremiumPlusUser } from 'utils/user';
import ErrorBoundary from '../ErrorBoundary';
import ErrorMessage from '../ErrorMessage';
import FavoritesBar from './components/FavoritesBar';
import NavigationBar from './components/NavigationBar';
import NotificationBarController from './components/NotificationBarController';
import { loadSavedNavigation } from './components/NavigationBar/components/TaxonomyNavigator/utils/navigationStore';

import styles from './Header.module.scss';

interface HeaderContentProps {
  isCTAVisible?: boolean;
  isFavoritesBarVisible?: boolean;
  isVisible?: boolean;
}

const HeaderContent: React.FC<HeaderContentProps> = ({
  isCTAVisible,
  isFavoritesBarVisible = true,
  isVisible,
}) => {
  const entitlements = useSelector(getEntitlements, shallowEqual);
  const favorites = useSelector(getUserFavorites, shallowEqual);
  const userDetails = useSelector(getUserDetails, shallowEqual);
  const userIsFreeTrialEligible = useSelector(getUserFreeTrialEligible, shallowEqual);
  const userIsVerified = useSelector(getUserEmailVerified, shallowEqual);
  const userSettings = useSelector(getUserSettings, shallowEqual);
  const isGuestUser = useSelector(getIsGuestUser);
  const userAccountSubsWarning = useSelector(getUserSubscriptionWarnings, shallowEqual) as any as {
    alert?: boolean;
    message?: string;
    type?: string;
  };
  const { themeClassName } = useConditions();

  const accountAlert = useMemo(() => userDetails && !userIsVerified, [userDetails, userIsVerified]);
  const accountWarning = userAccountSubsWarning?.alert;
  const spotsTaxonomyLocation = loadSavedNavigation('spotsTaxonomyLocation');
  const forecastsTaxonomyLocation = loadSavedNavigation('forecastsTaxonomyLocation');
  const navSettings = useMemo<TaxonomyNavigatorSettings>(
    () => ({
      units: userSettings?.units,
      navigation: {
        spotsTaxonomyLocation: spotsTaxonomyLocation ?? undefined,
        forecastsTaxonomyLocation: forecastsTaxonomyLocation ?? undefined,
      },
      recentlyVisited: userSettings?.recentlyVisited,
    }),
    [
      forecastsTaxonomyLocation,
      spotsTaxonomyLocation,
      userSettings?.recentlyVisited,
      userSettings?.units,
    ],
  );
  const treatments = useTreatments();
  const featureReleaseTreatment = treatments[RELEASE_ANNOUNCEMENT];
  const premiumPerksTreatment = treatments[PREMIUM_PERKS];
  const isPremiumPlusSplitEnabled = treatments[SL_PREMIUM_PLUS] === 'on';
  const isPremiumPlus = isPremiumPlusUser(entitlements);
  const sharedSubscriptionsEnabled = treatments[SL_WEB_SHARED_SUBSCRIPTIONS] === 'on';
  const featureReleaseConfig = useMemo(
    () => parseTreatmentWithConfig(featureReleaseTreatment),
    [featureReleaseTreatment],
  );
  const premiumPerksConfig = useMemo(
    () => parseTreatmentWithConfig(premiumPerksTreatment),
    [premiumPerksTreatment],
  );
  const showManageMembersLink =
    !isGuestUser && sharedSubscriptionsEnabled && isPremiumPlusSplitEnabled && isPremiumPlus;

  return (
    <header
      className={classNames(styles.header, { [styles.headerHidden]: !isVisible })}
      data-testid="header-content"
    >
      <NotificationBarController user={userDetails} warning={userAccountSubsWarning} />
      <NavigationBar
        user={userDetails}
        settings={navSettings}
        hideCTA={!isCTAVisible}
        entitlements={entitlements}
        serviceConfig={{
          serviceUrl: config.servicesURL,
        }}
        freeTrialEligible={userIsFreeTrialEligible}
        accountAlert={accountAlert}
        accountWarning={accountWarning}
        conditionColorsClass={themeClassName}
        userSettings={userSettings}
        latestFeatureRelease={featureReleaseConfig}
        latestPremiumPerkRelease={premiumPerksConfig}
        isPremiumPlusSplitEnabled={isPremiumPlusSplitEnabled}
        showManageMembersLink={showManageMembersLink}
      />
      {isFavoritesBarVisible && (
        <FavoritesBar settings={navSettings} favorites={favorites} user={userDetails} />
      )}
    </header>
  );
};

export interface HeaderProps extends HeaderContentProps {
  isError?: boolean;
}

export const Header: React.FC<HeaderProps> = (props) => {
  const { isError } = props;
  return (
    <div data-testid="header">
      <ErrorBoundary
        error={isError}
        render={() => (
          <Box className={styles.headerError} data-testid="header-error">
            <ErrorMessage dataTestId="error-message" />
          </Box>
        )}
      >
        <HeaderContent {...props} />
      </ErrorBoundary>
    </div>
  );
};

export default Header;
