import React from 'react';
import classNames from 'classnames';

import conditionClassModifier from 'utils/conditionClassModifier';
import conditionEnumToText from 'utils/conditionEnumToText';

import styles from './SurfConditions.module.scss';

const surfConditionsClass = (condition: string) =>
  classNames(styles.surfConditions, {
    [`sl-conditions-color--${conditionClassModifier(condition)}-background`]: !!condition,
    'quiver-surf-conditions': true,
    [`quiver-surf-conditions--${conditionClassModifier(condition)}`]: !!condition,
  });

interface SurfConditionsProps {
  conditions?: string;
  defaultCondition?: string;
}

const SurfConditions = ({ conditions, defaultCondition = '' }: SurfConditionsProps) => {
  const conditionEnum = conditions;
  const conditionText = conditionEnumToText(conditionEnum) || defaultCondition;
  return (
    <span className={surfConditionsClass(conditions || '')} data-testid="surf-conditions">
      {conditionText}
    </span>
  );
};

export default SurfConditions;
