import React, { MouseEventHandler } from 'react';
import { color } from 'utils/colors';

interface Props {
  border?: string;
  borderWidth?: number;
  fill?: string;
  onClick?: MouseEventHandler<SVGSVGElement>;
  size?: number;
}

const CircleIcon: React.FC<Props> = ({
  border = color('dark-blue', 20),
  borderWidth = 1,
  fill = color('dark-blue', 20),
  onClick = () => {},
  size = 12,
}) => (
  <svg onClick={onClick} width={`${size + 1}px`} height={`${size + 1}px`}>
    <g>
      <circle
        cx={`${size / 2}`}
        cy={`${size / 2}`}
        r={`${size / 2 - 1}`} // accounts for 1px border width
        stroke={border}
        strokeWidth={borderWidth}
        fill={fill}
      />
    </g>
  </svg>
);

export default CircleIcon;
