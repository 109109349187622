import createReducer from './createReducer';
import {
  CLEAR_CONTEST_ENTRIES,
  CLEAR_CONTEST_ENTRY,
  FETCH_CONTEST,
  FETCH_CONTEST_SUCCESS,
  FETCH_CONTEST_FAILURE,
  FETCH_CONTEST_DETAILS,
  FETCH_CONTEST_DETAILS_SUCCESS,
  FETCH_CONTEST_DETAILS_FAILURE,
  FETCH_CONTEST_ENTRY,
  FETCH_CONTEST_ENTRY_SUCCESS,
  FETCH_CONTEST_ENTRY_FAILURE,
  FETCH_CONTEST_NEWS,
  FETCH_CONTEST_NEWS_SUCCESS,
  FETCH_CONTEST_NEWS_FAILURE,
  FETCH_CONTEST_ENTRIES,
  FETCH_CONTEST_ENTRIES_SUCCESS,
  FETCH_CONTEST_ENTRIES_FAILURE,
  FETCH_CONTEST_ENTRIES_LOCATIONS,
  FETCH_CONTEST_ENTRIES_LOCATIONS_SUCCESS,
  FETCH_CONTEST_ENTRIES_LOCATIONS_FAILURE,
  FETCH_CONTEST_ENTRIES_SURFERS,
  FETCH_CONTEST_ENTRIES_SURFERS_SUCCESS,
  FETCH_CONTEST_ENTRIES_SURFERS_FAILURE,
  FETCH_CONTEST_ENTRIES_MONTHS,
  FETCH_CONTEST_ENTRIES_MONTHS_SUCCESS,
  FETCH_CONTEST_ENTRIES_MONTHS_FAILURE,
  CHANGE_CONTEST_ENTRY_FORM,
  CLEAR_CONTEST_ENTRY_FORM,
  SUBMIT_CONTEST_ENTRY_FORM,
  SUBMIT_CONTEST_ENTRY_FORM_SUCCESS,
  SUBMIT_CONTEST_ENTRY_FORM_FAILURE,
  UPVOTE_CONTEST_ENTRY,
  UPVOTE_CONTEST_ENTRY_SUCCCESS,
} from '../actions/contests';

export const initialState = {
  loading: false,
  error: null,
  contest: null,
  contestDetails: {
    loading: false,
    details: null,
  },
  contestEntries: {
    entries: [],
    activeEntry: null,
    pageOffset: 0,
    sort: null,
    location: null,
    surfer: null,
    month: null,
    displayLoadMore: true,
    loading: false,
    submittingVote: false,
  },
  contestNews: {
    loading: false,
    news: [],
  },
  entryForm: {
    fields: {
      name: null,
      email: null,
      date: null,
      surfer: null,
      photographer: null,
      location: null,
      videoUrl: null,
      instagramHandle: null,
      mediaHost: 'youtube',
    },
    loading: false,
    success: false,
    error: {
      message: null,
      details: [],
    },
  },
};

const handlers = {};

handlers[FETCH_CONTEST_NEWS] = (state) => ({
  ...state,
  contestNews: {
    ...state.contestNews,
    loading: true,
  },
});

handlers[FETCH_CONTEST_NEWS_SUCCESS] = (state, { contestNews }) => {
  const transformedNews = contestNews.map((contest) => ({
    ...contest,
    content: { title: contest.title.rendered },
    permalink: contest.link,
    media: {
      promobox1x: contest.acf.contest_carousel,
      promobox2x: contest.acf.contest_carousel,
    },
  }));
  return {
    ...state,
    loading: false,
    contestNews: {
      loading: false,
      news: transformedNews,
    },
  };
};

handlers[FETCH_CONTEST_NEWS_FAILURE] = (state, { error }) => ({
  ...state,
  contestNews: {
    ...state.contestNews,
    loading: false,
    error,
  },
});

handlers[CLEAR_CONTEST_ENTRIES] = (state) => ({
  ...state,
  contestEntries: {
    ...state.contestEntries,
    entries: initialState.contestEntries.entries,
  },
});

handlers[FETCH_CONTEST_ENTRIES] = (state) => ({
  ...state,
  contestEntries: {
    ...state.contestEntries,
    loading: true,
  },
});

handlers[FETCH_CONTEST_ENTRIES_SUCCESS] = (
  state,
  { entries, location, sort, pageOffset, displayLoadMore, surfer, month },
) => ({
  ...state,
  loading: false,
  contestEntries: {
    ...state.contestEntries,
    entries: [...state.contestEntries.entries, ...entries],
    pageOffset,
    location,
    surfer,
    month,
    sort,
    displayLoadMore,
    loading: false,
  },
});

handlers[FETCH_CONTEST_ENTRIES_FAILURE] = (state, { error }) => ({
  ...state,
  contestEntries: {
    ...state.contestEntries,
    loading: false,
    error,
  },
});

handlers[FETCH_CONTEST_ENTRIES_LOCATIONS] = (state) => ({
  ...state,
  contestEntriesLocations: {
    ...state.contestEntriesLocations,
    loading: true,
  },
});

handlers[FETCH_CONTEST_ENTRIES_LOCATIONS_SUCCESS] = (state, { locations }) => ({
  ...state,
  loading: false,
  contestEntriesLocations: {
    ...state.contestEntriesLocations,
    locations,
    loading: false,
  },
});

handlers[FETCH_CONTEST_ENTRIES_LOCATIONS_FAILURE] = (state, { error }) => ({
  ...state,
  contestEntriesLocations: {
    ...state.contestEntriesLocations,
    loading: false,
    error,
  },
});

handlers[FETCH_CONTEST_ENTRIES_SURFERS] = (state) => ({
  ...state,
  contestEntriesSurfers: {
    ...state.contestEntriesSurfers,
    loading: true,
  },
});

handlers[FETCH_CONTEST_ENTRIES_SURFERS_SUCCESS] = (state, { surfers }) => ({
  ...state,
  loading: false,
  contestEntriesSurfers: {
    ...state.contestEntriesSurfers,
    surfers,
    loading: false,
  },
});

handlers[FETCH_CONTEST_ENTRIES_SURFERS_FAILURE] = (state, { error }) => ({
  ...state,
  contestEntriesSurfers: {
    ...state.contestEntriesSurfers,
    loading: false,
    error,
  },
});

handlers[FETCH_CONTEST_ENTRIES_MONTHS] = (state) => ({
  ...state,
  contestEntriesMonths: {
    ...state.contestEntriesMonths,
    loading: true,
  },
});

handlers[FETCH_CONTEST_ENTRIES_MONTHS_SUCCESS] = (state, { months }) => ({
  ...state,
  loading: false,
  contestEntriesMonths: {
    ...state.contestEntriesMonths,
    months,
    loading: false,
  },
});

handlers[FETCH_CONTEST_ENTRIES_MONTHS_FAILURE] = (state, { error }) => ({
  ...state,
  contestEntriesMonths: {
    ...state.contestEntriesMonths,
    loading: false,
    error,
  },
});

handlers[CLEAR_CONTEST_ENTRY] = (state) => ({
  ...state,
  contestEntries: {
    ...state.contestEntries,
    activeEntry: initialState.contestEntries.activeEntry,
  },
});

handlers[FETCH_CONTEST_ENTRY] = (state) => ({
  ...state,
  loading: true,
});

handlers[FETCH_CONTEST_ENTRY_SUCCESS] = (state, { activeEntry }) => ({
  ...state,
  loading: false,
  contestEntries: {
    ...state.contestEntries,
    activeEntry,
  },
});

handlers[FETCH_CONTEST_ENTRY_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

handlers[FETCH_CONTEST_DETAILS] = (state) => ({
  ...state,
  contestDetails: {
    ...state.contestDetails,
    loading: true,
  },
});

handlers[FETCH_CONTEST_DETAILS_SUCCESS] = (state, { contestDetails }) => ({
  ...state,
  loading: false,
  contestDetails: {
    loading: false,
    details: contestDetails,
  },
});

handlers[FETCH_CONTEST_DETAILS_FAILURE] = (state, { error }) => ({
  ...state,
  contestDetails: {
    ...state.contestDetails,
    loading: false,
    error,
  },
});

handlers[FETCH_CONTEST] = (state) => ({
  ...state,
  loading: true,
});

handlers[FETCH_CONTEST_SUCCESS] = (state, { contest }) => ({
  ...state,
  loading: false,
  contest,
});

handlers[FETCH_CONTEST_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

handlers[CHANGE_CONTEST_ENTRY_FORM] = (state, { details }) => ({
  ...state,
  entryForm: {
    ...state.entryForm,
    fields: {
      ...state.entryForm.fields,
      ...details,
    },
  },
});

handlers[CLEAR_CONTEST_ENTRY_FORM] = (state) => ({
  ...state,
  entryForm: {
    ...initialState.entryForm,
  },
});

handlers[SUBMIT_CONTEST_ENTRY_FORM] = (state) => ({
  ...state,
  entryForm: {
    ...state.entryForm,
    loading: true,
    success: false,
    error: { ...initialState.entryForm.error },
  },
});

handlers[SUBMIT_CONTEST_ENTRY_FORM_SUCCESS] = (state) => ({
  ...state,
  entryForm: {
    ...state.entryForm,
    loading: false,
    success: true,
  },
});

handlers[SUBMIT_CONTEST_ENTRY_FORM_FAILURE] = (state, { error }) => ({
  ...state,
  entryForm: {
    ...state.entryForm,
    loading: false,
    error,
  },
});

handlers[UPVOTE_CONTEST_ENTRY] = (state) => ({
  ...state,
  contestEntries: {
    ...state.contestEntries,
    submittingVote: true,
  },
});

handlers[UPVOTE_CONTEST_ENTRY_SUCCCESS] = (state, { entryId, updatedVoteCount }) => ({
  ...state,
  contestEntries: {
    ...state.contestEntries,
    submittingVote: false,
    entries: state.contestEntries.entries.map((entry) =>
      entry.id === entryId
        ? {
            ...entry,
            voteCount: updatedVoteCount,
          }
        : entry,
    ),
  },
});

export default createReducer(handlers, initialState);
