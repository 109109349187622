import { useDispatch } from 'react-redux';
import { useUnmount } from 'react-use';
import { resetMapState } from '../../actions/mapV2';

const useResetMapStateOnUnmount = () => {
  const dispatch = useDispatch();

  useUnmount(() => {
    dispatch(resetMapState());
  });
};

export default useResetMapStateOnUnmount;
