import { createReducer } from '@reduxjs/toolkit';
import { toggleCtaDrawer } from '../actions/cta';

type CtaState = {
  drawer: {
    open: boolean;
    type?: string;
  };
};

export const initialState: CtaState = {
  drawer: {
    open: false,
    type: 'cam',
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder.addCase(toggleCtaDrawer, (state, action) => ({
    ...state,
    drawer: {
      open: !state?.drawer?.open,
      type: action?.payload?.type || state?.drawer?.type,
    },
  })),
);

export default reducer;
