import createReducer from '../createReducer';
import {
  FETCH_FORECASTER_CONTENT,
  FETCH_FORECASTER_CONTENT_SUCCESS,
  FETCH_FORECASTER_CONTENT_FAILURE,
} from '../../actions/subregion';

export const initialState = {};

const handlers = {};

handlers[FETCH_FORECASTER_CONTENT] = (state) => ({
  ...state,
  data: null,
  loading: true,
});

handlers[FETCH_FORECASTER_CONTENT_SUCCESS] = (state, { subregionForecast }) => ({
  ...state,
  data: subregionForecast,
  loading: false,
});

handlers[FETCH_FORECASTER_CONTENT_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

export default createReducer(handlers, initialState);
