import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useLocalStorage } from 'react-use';

export const LOCAL_STORAGE_KEY = 'preferredForecastView';

const usePreferredForecastView = (
  isAnonymous: boolean,
): {
  preferredForecastViewLocalStorage?: string | null;
  togglePreferredForecastView: (value: any) => void;
} => {
  const {
    query: { referral, view },
  } = useRouter();

  // NOTE: `null` and `undefined` are treatly as two distinct cases within this hook.
  // If the localStorage item is `null`, the `preferredForecastView` value has yet to been set and is subject to change.
  // Otherwise, if the localStorage item is undefined, the user is registered and the localStorage value will never be set.
  const anonDefaultForecastView = referral === 'msw' || view === 'table' ? 'TABLE' : null;

  const defaultLocalStorageKey = !isAnonymous ? undefined : anonDefaultForecastView;

  const [preferredForecastViewLocalStorage, setPreferredForecastViewLocalStorage] = useLocalStorage(
    LOCAL_STORAGE_KEY,
    defaultLocalStorageKey,
  );
  const [preferredForecastView, setPreferredForecastView] = useState(
    isAnonymous ? preferredForecastViewLocalStorage : undefined,
  );

  useEffect(() => {
    // NOTE: local storage value will never update if the user is registered.
    // For registered users, `preferredForecastViewLocalStorage` and `preferredForecastView` will always be undefined.
    if (preferredForecastViewLocalStorage !== preferredForecastView) {
      // keep the state var in sync with the local storage var
      setPreferredForecastView(preferredForecastViewLocalStorage);
    }
  }, [preferredForecastView, preferredForecastViewLocalStorage]);

  const togglePreferredForecastView = useCallback(
    (value: any) => {
      setPreferredForecastViewLocalStorage(value);
    },
    [setPreferredForecastViewLocalStorage],
  );

  return { preferredForecastViewLocalStorage: preferredForecastView, togglePreferredForecastView };
};

export default usePreferredForecastView;
