import React, { FC } from 'react';

interface RailIconProps {
  title?: string;
  description?: string;
}

const RailIcon: FC<RailIconProps> = ({
  title = 'Rail Icon',
  description = 'Click this icon to open the rail',
}) => (
  <svg
    width="24px"
    height="19px"
    viewBox="0 0 24 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>{title}</title>
    <desc>{description}</desc>
    <defs>
      <path
        id="rail-icon"
        d="M6.27988001,7.94775187 C6.44303566,8.05117211 6.54243755,7.98651946 6.49714237,7.7818466 L5.89397058,5.05632699 L7.90638777,3.22341601 C8.05040299,3.09224685 8.00604033,2.97138372 7.82340263,2.95491276 L5.17055351,2.71566883 L4.13427855,0.146026132 C4.06011929,-0.0378659898 3.94354317,-0.0469411513 3.86572412,0.146026132 L2.82944916,2.71566883 L0.17660004,2.95491276 C-0.0132466085,2.97203385 -0.0449317055,3.09722767 0.093614903,3.22341601 L2.10603209,5.05632699 L1.5028603,7.7818466 C1.45969533,7.97689379 1.56316243,8.04724499 1.72012266,7.94775187 L4.00000133,6.50259443 L6.27988001,7.94775187 Z"
      />
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-333.000000, -106.000000)">
        <g transform="translate(335.000000, 108.000000)">
          <g
            transform="translate(0.000000, 1.000000)"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.28571429"
          >
            <g transform="translate(0.581818, 0.000000)">
              <path
                d="M10.4181818,10.961039 L10.4181818,-8.67532468 L10.4181818,10.961039 Z"
                transform="translate(10.418182, 1.142857) rotate(-90.000000) translate(-10.418182, -1.142857) "
              />
              <path
                d="M10.4181818,17.8181818 L10.4181818,-1.81818182 L10.4181818,17.8181818 Z"
                transform="translate(10.418182, 8.000000) rotate(-90.000000) translate(-10.418182, -8.000000) "
              />
              <path
                d="M10.4181818,24.6753247 L10.4181818,5.03896104 L10.4181818,24.6753247 Z"
                transform="translate(10.418182, 14.857143) rotate(-90.000000) translate(-10.418182, -14.857143) "
              />
            </g>
          </g>
          <g>
            <circle stroke="#FFFFFF" strokeWidth="2" fill="#333333" cx="6" cy="6" r="7" />
            <g transform="translate(2.000000, 1.500000)">
              <mask fill="white">
                <use xlinkHref="#rail-icon" />
              </mask>
              <use fill="#FFFFFF" xlinkHref="#rail-icon" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default RailIcon;
