import HDCams from '../../components/Icons/HDCams';
import PremiumAnalysis from '../../components/Icons/PremiumAnalysis';
import EnhancedTools from '../../components/Icons/EnhancedTools';
import Calendar from '../../components/Icons/Calendar';

const translations = {
  premiumCTA: {
    ForecastVisualizerCTA: {
      header: {
        icon: Calendar,
        title: 'Want the Complete, Long-Range Forecast?',
      },
      linkText: 'Start Free Trial',
      mainText: '',
      learnMoreText: 'Learn more about Surfline Premium',
      features: {
        forecasts: {
          icon: PremiumAnalysis,
          title: 'Trusted Forecast Team',
          description: 'Plan your next trip or session with expert guidance.',
        },
        expert: {
          icon: EnhancedTools,
          title: 'Exclusive Forecast Tools',
          description: 'Access curated condition reports and proprietary surf, wind and buoy data.',
        },
        cams: {
          icon: HDCams,
          title: 'Ad-Free and Premium Cams',
          description:
            'Watch live conditions ad-free -- and access exclusive, high-resolution cams.',
        },
      },
    },
    ChartRangeSelectorCTA: {
      mainText: 'Want the Complete, Long-Range Forecast?',
      linkText: 'Start Free Trial',
    },
    SubHeader: {
      textBefore: 'Unlock premium cam',
      text: ' stream',
      textAfter: 's',
      linkText: 'START FREE TRIAL',
    },
    MobileForecastCTA: {
      text: 'Plan ahead with 16 day forecasts. ',
      linkText: 'Start Free Trial',
    },
    RegionForecastUpdateCTA: {
      defaultText: "Looking for today's Best Bet, along with expert forecast analysis?",
      spotPageText: 'Looking for expert forecast analysis on this spot?',
      linkText: 'Start Free Trial',
    },
    CamHeaderCTA: {
      text: 'Stream Live Conditions ad free -',
      linkText: 'Start Free Trial',
    },
  },
  SpotForecast: {
    buoyError: {
      message: 'We apologize, we are working to restore buoys for this spot ASAP.',
    },
  },
  SpotPage: {
    header: {
      titleSuffix: 'Surf Report & Forecast',
    },
    conditionsNoneText: 'conditions unobservable',
    conditionsExpiredText: 'Report Coming Soon',
    pageLevelLinkText: {
      today: {
        text: 'Today',
        title: (spotName: string) => `${spotName} Surf Report & Forecast`,
      },
      spotForecast: {
        text: 'Spot Forecast',
        title: (spotName: string) => `${spotName} Surf Forecast`,
      },
    },
    externalLinkText: {
      region: 'Regional Forecast',
      premiumAnalysis: 'Premium Analysis',
      regionalAnalysis: 'Regional Analysis',
      buoys: 'Buoys',
      charts: 'Charts',
      travel: 'Travel',
      camRewind: 'Cam Rewind',
    },
    forecastHeaderLinkText: {
      spot: 'Report & Forecast',
      regionalAnalysis: 'Analysis',
      charts: 'Charts',
      guide: 'Guide',
    },
  },
  RegionalForecast: {
    header: {
      titleSuffix: 'Regional Forecast',
    },
    pageLevelLinkText: {
      forecast: {
        text: 'Regional Forecast',
        title: (subregionName: string) => `${subregionName} Regional Surf Forecast`,
        path: (subregionName: string, subregionId: string) =>
          `/surf-forecasts/${subregionName}/${subregionId}`,
      },
      premiumAnalysis: {
        text: 'Premium Analysis',
        title: (subregionName: string) => `${subregionName} Premium Analysis`,
        path: (subregionName: string, subregionId: string) =>
          `/surf-forecasts/${subregionName}/${subregionId}`,
      },
      charts: {
        text: 'Charts',
        title: (subregionName: string) => `${subregionName} Charts`,
        path: (subregionName: string, subregionId: string) =>
          `/surf-forecasts/${subregionName}/${subregionId}/charts`,
      },
    },
    externalLinkText: {
      buoys: 'Buoys',
      charts: 'Charts',
      travel: 'Travel',
    },
  },
  ForecastHeader: {
    menuHeader: 'More',
    camsReportsTitle: 'Cams & Reports',
    spotForecastsTitle: 'Spot Forecasts',
    nearbySpotsTitle: 'Nearby Spots',
    nearbySubregionsTitle: 'Nearby Regions',
  },
  SpotCamera: {
    CamList: {
      nearbyError: {
        title: 'Loading Error',
        message: 'We apologize, we are working to restore service ASAP. ',
        link: {
          beforeText: 'Check out ',
          linkText: 'Cams & Forecasts',
          afterText: ' instead.',
        },
      },
    },
  },
  ChartPlayer: {
    description: 'Access long-range charts',
    link: 'Start Free Trial',
  },
  swellTrendSlider: {
    tooltipPaywall: 'Premium members get the detailed 16-day forecast.',
    hourlyPremiumTooltip: 'The 1-day hourly forecast is only available up to 5 days.',
    hourlyFreeTooltip: 'Premium Members get five days of hourly wind.',
  },
  MultiCam: {
    cam: {
      filler: (hasEnoughFavorites: boolean) => (hasEnoughFavorites ? 'add spot' : 'add favorites'),
    },
    regwall: {
      header: 'Registered Users Can Stream up to Four Cams with Multi-Cam',
      disclaimer: '*No credit card needed',
      createAccount: 'create account',
      link: 'Sign in',
      preLinkCopy: 'Already a member?',
    },
    paywall: {
      header: 'Premium Members Can Access up to Nine Cams at Once with Multi-Cam',
      link: 'Learn more about Surfline Premium',
      goPremium: 'Start Free Trial',
    },
  },
  SurfSpotMapPage: {
    metaTitle:
      'Global Map of Surf Spots with local surf reports, swell forecasts, and surf cams - Surfline',
    metaDescription:
      'View a global map of surf spots with surf reports, surf cams and forecasts, plus live swell and wind observations from buoys and wind stations.',
  },
  WindStationsMapPage: {
    metaTitle: 'Global Map of Live Wind Stations with Wind Speed and Wind Direction - Surfline',
    metaDescription:
      'View a global map of wind stations with the latest observations of wind speed and direction, plus surf spots and wave buoys for surf and swell observations and forecasts.',
  },
  BuoysMapPage: {
    metaTitle:
      'Global Map of Wave Buoys with Swell Height, Peak Period & Wave Direction - Surfline',
    metaDescription:
      'View a global map of wave buoys with the latest buoy observations of wave height, peak period and mean wave direction, plus surf spots and wind stations.',
  },
};

export default translations;
