import { createReducer } from '@reduxjs/toolkit';
import { analyticsReady } from '../actions/analytics';

type AnalyticsState = {
  ready: boolean;
};
export const initialState: AnalyticsState = {
  ready: false,
};

const reducer = createReducer(initialState, (builder) =>
  builder.addCase(analyticsReady, () => ({ ready: true })),
);

export default reducer;
