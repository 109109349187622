import React, { useState, FC, useMemo } from 'react';
import { Alert, IconButton } from '@mui/material';
import { CloseIcon } from 'components/Icons';

const getClassName = (levelValue: string, typeValue: string) =>
  `quiver-notification quiver-notification--${levelValue} quiver-notification--${typeValue}`;

interface NotificationProps {
  children: React.ReactNode;
  closable?: boolean;
  type?: 'small' | 'large';
  level?: 'error' | 'information';
  onCloseComplete?: () => void;
}

const Notification: FC<NotificationProps> = ({
  children,
  closable = false,
  type = 'small',
  level = 'information',
  onCloseComplete = null,
}) => {
  const [closed, setClosed] = useState(false);

  const handleCloseClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    setClosed(true);
    if (onCloseComplete) {
      onCloseComplete();
    }
  };

  const serverity = useMemo(() => {
    switch (level) {
      case 'error':
        return 'error';
      case 'information':
        return 'info';
      default:
        return 'info';
    }
  }, [level]);

  if (closed) {
    return null;
  }

  return (
    <Alert
      className={getClassName(level, type)}
      action={
        closable ? (
          <IconButton
            aria-label="close"
            color="inherit"
            data-testid="close-button"
            href="#"
            onClick={handleCloseClick}
            size="small"
          >
            <CloseIcon className="close-icon" />
          </IconButton>
        ) : null
      }
      icon={false}
      severity={serverity}
    >
      {children}
    </Alert>
  );
};

export default Notification;
