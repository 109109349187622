import React, { MouseEvent, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { Typography } from '@mui/material';

import WavetrakLink from 'components/WavetrakLink';

import styles from './NavigationItem.module.scss';

interface NavigationItemProps {
  beta?: boolean;
  children?: ReactNode;
  href?: string;
  isAvatar?: boolean;
  isExternal?: boolean;
  linkDisplay: string | ReactNode;
  onClick?: (event: MouseEvent) => void;
  role?: string;
  tabIndex?: number;
  testId?: string;
}

const NavigationItem = ({
  beta = false,
  children,
  href = '',
  isAvatar = false,
  isExternal = false,
  linkDisplay,
  onClick,
  role,
  tabIndex,
  testId = 'navigation-item',
}: NavigationItemProps) => {
  const [focus, setFocus] = useState<boolean>(false);

  const handleClick = (event: MouseEvent<HTMLDivElement>) => {
    if (event?.target) {
      const anchor = event.target as HTMLAnchorElement;
      if (anchor?.getAttribute('href')) {
        setFocus(false);
      }
    }
  };

  const handleMouseEnter = () => {
    setFocus(true);
  };

  const handleMouseLeave = () => {
    setFocus(false);
  };

  const handleFocus = () => {
    setFocus(true);
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={classNames(styles.navigationItem, {
        [styles['navigationItem--focus']]: focus,
      })}
      data-testid={testId}
      onClick={handleClick}
      onFocus={handleFocus}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <WavetrakLink
        className={classNames(styles.link, { [styles['link--isAvatar']]: isAvatar })}
        href={href}
        role={role}
        tabIndex={tabIndex}
        onClick={onClick}
        isExternal={isExternal}
      >
        <Typography variant="subHeadline" component="span" className={styles.label}>
          {linkDisplay}
        </Typography>
        {beta && <sup>BETA</sup>}
      </WavetrakLink>
      {children}
    </div>
  );
};

export default NavigationItem;
