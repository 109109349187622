import createReducer from '../../createReducer';
import {
  FETCH_GRAPH_SUNLIGHT_TIMES,
  FETCH_GRAPH_SUNLIGHT_TIMES_FAILURE,
  FETCH_GRAPH_SUNLIGHT_TIMES_SUCCESS,
} from '../../../actions/graphs';

/**
 * @typedef {import('../../../types/sunlightTimes').SunlightTimes} SunlightTimes
 */

/**
 * @type {SunlightTimes}
 */
export const initialState = {
  utcOffset: null,
  days: null,
  loading: false,
};

const handlers = {};

handlers[FETCH_GRAPH_SUNLIGHT_TIMES] = () => ({
  ...initialState,
  loading: true,
});

handlers[FETCH_GRAPH_SUNLIGHT_TIMES_SUCCESS] = (state, { response }) => ({
  location: response.associated.location,
  utcOffset: response.data.sunlight[0]?.midnightUTCOffset,
  days: response.data.sunlight,
  loading: false,
});

handlers[FETCH_GRAPH_SUNLIGHT_TIMES_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

export default createReducer(handlers, initialState);
