import { slugify } from '@surfline/web-common';

export const buoyReportPaths = {
  base: '/buoy-report/:buoySlug/:buoyId',
};

/**
 * @param {object} params
 * @param {string | undefined} params.name
 * @param {string} params.id
 * @param {string} params.sourceID
 */
export const getBuoyReportPath = ({ name, id, sourceID }) => {
  if (!name) {
    return `/buoy-report/buoy-${sourceID}/${id}`;
  }
  return `/buoy-report/${slugify(name)}-buoy-${sourceID}/${id}`;
};
