import React from 'react';

const Calendar = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="48"
    height="52"
    viewBox="0 0 48 52"
    className="sl-calendar-icon"
  >
    <defs>
      <path id="a" d="M32 0h2v8h-2z" />
      <path id="b" d="M16 0h2v8h-2z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path
        stroke="#FFF"
        strokeWidth="2"
        d="M1 5v45.613l5-1.667 6 2 6-2 6 2 6-2 5.995 1.998 5.829-1.998L47 50.624V5H1z"
      />
      <path
        stroke="#22D737"
        strokeWidth="2"
        d="M12 42c0-12.698 14-25.396 22-12.817-8 0-8 9.632 0 11.748"
      />
      <path stroke="#FFF" strokeWidth="2" d="M48 18H0" />
      <g>
        <path stroke="#FFF" strokeWidth="2" d="M33 1h1v6h-1z" />
      </g>
      <g>
        <path stroke="#FFF" strokeWidth="2" d="M17 1h1v6h-1z" />
      </g>
    </g>
  </svg>
);

export default Calendar;
