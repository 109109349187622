import { getTopSpots } from '../common/api/spots';
import type { AppDispatch } from '../stores';

export const FETCH_TOP_SPOTS = 'FETCH_TOP_SPOTS';
export const FETCH_TOP_SPOTS_SUCCESS = 'FETCH_TOP_SPOTS_SUCCESS';
export const FETCH_TOP_SPOTS_FAILURE = 'FETCH_TOP_SPOTS_FAILURE';

export const fetchTopSpots =
  (geonameId: string | string[] | undefined, cookies?: any) => async (dispatch: AppDispatch) => {
    dispatch({
      type: FETCH_TOP_SPOTS,
    });
    try {
      const response = await getTopSpots(geonameId, cookies);
      const {
        // @ts-ignore - update after travel migration
        data: { topSpots },
      } = response;
      dispatch({
        type: FETCH_TOP_SPOTS_SUCCESS,
        topSpots,
      });
    } catch (error) {
      dispatch({
        type: FETCH_TOP_SPOTS_FAILURE,
        error,
      });
    }
  };
