import productCDN from '@surfline/quiver-assets';
import type { EnvConfig } from 'config';

const mapTilerKey = '2EmSRjjKKgmwLUx2ipZW';

const sandbox: EnvConfig = {
  appHost: 'https://sandbox.surfline.com',
  appKeys: {
    segment: 'VQDMbHw65jkXg4go8KmBnDiXzeAz7GiO',
    fbAppId: process.env.FB_APP_ID || '564041023804928',
    logrocket: 'h57nhx/sl_sandbox',
    appsFlyer: 'd81e6dc0-c4cf-4c84-8d7c-351bf6cf2f03',
  },
  camerasServiceURL: 'http://cameras-service.sandbox.surfline.com',
  cdnUrl: 'https://product-cdn.sandbox.surfline.com',
  clientId: process.env.WEB_CLIENT_ID || '5bff1583ffda1e1eb8d35c4b',
  clientSecret: process.env.WEB_CLIENT_SECRET || '',
  defaultCountryCode: 'US',
  defaultImageUrl: 'https://spot-thumbnails.sandbox.surfline.com/spots/default/default_1500.jpg',
  geoTargetServiceUrl: 'http://geo-target-service.sandbox.surfline.com',
  kbygServiceURL: 'http://kbyg-api.sandbox.surfline.com',
  legacyApi: 'https://api.surfline.com/v1',
  mapTiler: {
    key: mapTilerKey,
    style: `https://api.maptiler.com/maps/345b8a3e-73ca-475f-b7e6-208e3bb15e62/style.json?key=${mapTilerKey}`,
  },
  oneTrustDomainScript: '89f3dcce-1ffc-42b2-9d52-91fd3af3d675-test',
  react: {
    integrity:
      'sha512-qlzIeUtTg7eBpmEaS12NZgxz52YYZVF5myj89mjJEesBd/oE9UPsYOX2QAXzvOAZYEvQohKdcY8zKE02ifXDmA==',
    src: `${productCDN}/scripts/react.production.min.js`,
  },
  reactDOM: {
    integrity:
      'sha512-9jGNr5Piwe8nzLLYTk8QrEMPfjGU0px80GYzKZUxi7lmCfrBjtyCc1V5kkS5vxVwwIB7Qpzc7UxLiQxfAN30dw==',
    src: `${productCDN}/scripts/react-dom.production.min.js`,
  },
  robots: 'noindex,nofollow',
  servicesURL: 'https://services.sandbox.surfline.com',
  she: {
    headerScriptUrl: 'https://ads.blogherads.com/sk/12/123/1236760/28994/header.js',
    staticScriptUrl: 'https://ads.blogherads.com/static/blogherads.js',
  },
  signInUrl: 'https://sandbox.surfline.com/sign-in',
  splitio: process.env.SPLITIO_AUTHORIZATION_KEY || 'cctgg8rco0ucv5ctphapda18sq0nd77hq37e',
  subscriptionServiceURL: 'http://subscription-service.sandbox.surfline.com',
  surflineEmbedURL: 'https://embed.sandbox.surfline.com',
  surflineHost: 'https://sandbox.surfline.com',
  userServiceURL: 'http://user-service.sandbox.surfline.com',
  wpAPI: 'https://sandbox.surfline.com',
};

export default sandbox;
