import React from 'react';
import { Typography } from '@mui/material';

import WavetrakLink from 'components/WavetrakLink';
import config from 'config';
import type { TaxonomyNode } from 'types/header';

import generateSlug from '../../../helpers/generateSlug';
import subregionPagePath from '../../../helpers/subregionPagePath';

import styles from './RenderSubregion.module.scss';

interface RenderSubregionProps {
  subregion: TaxonomyNode;
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
}

const RenderSubregion = ({ subregion, onClickLink }: RenderSubregionProps) => {
  const getHref = ({ associated, _id, name }: TaxonomyNode) => {
    if (associated) {
      return associated.links
        .find((link) => link.key === 'www')
        ?.href?.replace(config.surflineHost, '');
    }
    const slug = generateSlug(name || '');
    return subregionPagePath(_id || '', slug);
  };

  const onClickHandler = () => {
    if (onClickLink) {
      onClickLink(
        {
          clickEndLocation: 'Region Forecast',
          completion: true,
          destinatonUrl: getHref(subregion),
          linkName: subregion.name,
        },
        true,
      );
    }
  };

  return (
    <div className={styles.renderSubregion}>
      <Typography
        className={styles.link}
        component={WavetrakLink}
        href={getHref(subregion) || ''}
        onClick={onClickHandler}
        variant="body2"
        isExternal
      >
        {subregion.name}
      </Typography>
    </div>
  );
};

export default RenderSubregion;
