import { getUserRegion } from './fetch';

export const surflineLocation = {
  // Default to Surfline HQ
  center: {
    lat: 33.654213041213,
    lon: -118.0032588192,
  },
  zoom: 12,
};

export const getUserLocationByIP = async () => {
  const region = await getUserRegion();
  let zoom = 12;

  if (!region.city) zoom = 7;
  if (!region.country) zoom = 5;

  return {
    center: {
      lat: region.location.latitude,
      lon: region.location.longitude,
    },
    zoom,
  };
};

export const getUserLocationByGPS = () =>
  new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      (position) =>
        resolve({
          center: {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          },
          zoom: 12,
        }),
      (err) => reject(err),
    );
  });
