import React, { FC } from 'react';
import classNames from 'classnames';

import styles from './WindArrow.module.scss';

interface WindArrowProps {
  degrees: number;
  speed: number;
}

const rotate = (degrees: number) => `rotate(${degrees} 7.5 7.5)`;

const WindArrow: FC<WindArrowProps> = ({ degrees, speed }) => (
  <svg
    viewBox="0 0 15 15"
    preserveAspectRatio="xMinYMin meet"
    className={classNames(styles.windArrow, 'quiver-wind-arrow')}
  >
    {speed > 0 ? (
      <polygon
        data-testid="polygon"
        points="7.5,0 5,8.75 1.5,8.75 7.5,15 13.5,8.75 10,8.75"
        transform={rotate(degrees)}
      />
    ) : null}
  </svg>
);

export default WindArrow;
