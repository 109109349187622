import React, { useRef, useState } from 'react';
import { useMount, useUnmount } from 'react-use';
import PRODUCT_CDN from '@surfline/quiver-assets';
import { getWindow } from '@surfline/web-common';

import config from 'config';
import { ADBLOCK_ELEMENTID, CLICKED_SUBSCRIBE_CTA } from 'common/constants';
import CloudflareResizedImage from 'components/CloudflareResizedImage';
import TrackableLink from 'components/TrackableLink';
import CtaHackChecker from 'utils/CTAHackChecker';

import { Button, Modal, Typography } from '@mui/material';
import styles from './AdBlock.module.scss';

const WAIT_FOR_ADBLOCK = 1500; // milliseconds

const ctaHackChecker = new CtaHackChecker({
  targetClasses: ['quiver-modal-container'],
  backgroundTransMatch: '0.7',
  blurPxMatch: '6px',
});

let resizeTimeout: any;

interface Props {}

const AdBlock: React.FC<Props> = () => {
  const [showAdBlocker, setShowAdBlocker] = useState<boolean>(false);
  const signInLinkRef = useRef(null);
  const signUpLinkRef = useRef(null);

  const detectAdBlocker = () => {
    const win = getWindow();
    const [head] = win.document.getElementsByTagName('head');
    const oldScript = win?.document.getElementById('ads-blocker-detection');
    if (oldScript) head.removeChild(oldScript);
    const script = win?.document.createElement('script');
    script.id = 'ads-blocker-detection';
    script.type = 'text/javascript';
    script.src = `${PRODUCT_CDN}/scripts/ad/doubleclick.js`;
    script.onload = () => {
      // The script loaded so we need to confirm the DOM element got added and was not hidden.
      // We have to wait a slight delay because ad blockers can hide these after the page has loaded.
      setTimeout(() => {
        const elementAddedFromAdScript = win?.document.getElementById(ADBLOCK_ELEMENTID);
        if (!elementAddedFromAdScript) {
          // the script was unable to insert the div into the DOM element
          setShowAdBlocker(true);
        } else {
          // Ad blockers allow these elements to render but hide them after load so confirm its still visible.
          const adStyles = win?.getComputedStyle(elementAddedFromAdScript);
          const isBlocked =
            adStyles &&
            (adStyles.display === 'none' ||
              adStyles.visibility === 'hidden' ||
              adStyles.opacity === '0');

          if (isBlocked) {
            setShowAdBlocker(true);
          } else {
            setShowAdBlocker(false);
          }

          win?.document?.body?.removeChild(elementAddedFromAdScript);
        }
      }, WAIT_FOR_ADBLOCK);
    };
    script.onerror = () => {
      setShowAdBlocker(true);
    };
    head.appendChild(script);
  };

  const onClickWhitelist = () => {
    setTimeout(() => getWindow()?.location.reload(), 300);
  };

  const getSignInEventProperties = () => ({
    linkUrl: `${config.surflineHost}/sign-in?returnUrl=${getWindow()?.location}`,
    linkName: 'Ad Blocker - Sign In',
    linkLocation: 'Ad Blocker Modal',
    category: 'modal',
  });

  const getSignUpEventProperties = () => ({
    location: 'modal ad blocker',
  });

  const onClickUnblock = () => {
    getWindow()?.open(
      'https://support.surfline.com/hc/en-us/articles/360029359271-Why-can-t-I-block-ads-',
      '_blank',
    );
  };

  const resizeListener = () => {
    ctaHackChecker.disconnect();
    ctaHackChecker.stopDOMCheck();
    if (resizeTimeout) return;
    resizeTimeout = setTimeout(() => {
      resizeTimeout = null;
      ctaHackChecker?.observe();
      ctaHackChecker?.startDOMCheck();
    }, 3000);
  };

  useMount(() => {
    ctaHackChecker?.observe();
    ctaHackChecker?.startDOMCheck();
    getWindow()?.addEventListener('resize', resizeListener);
    detectAdBlocker();
  });

  useUnmount(() => {
    ctaHackChecker?.disconnect();
    ctaHackChecker.stopDOMCheck();
    getWindow()?.removeEventListener('resize', resizeListener);
  });

  return showAdBlocker ? (
    <Modal className={styles.modal} open={showAdBlocker} disableScrollLock>
      <div className={styles.modalContent}>
        <div className={styles.adBlock}>
          <Typography className={styles.heading} variant="h3">
            We See You&#39;re Using an Ad Blocker
          </Typography>
          <Typography variant="body2">
            Advertising supports our efforts to provide much of our forecast tools and editorial
            content to our users for free. To continue using our services, whitelist Surfline in
            your <span className={styles.nowrap}>ad blocker.</span>
          </Typography>
          <div className={styles.imageContainer}>
            <CloudflareResizedImage
              alt="Hand Stop"
              className={styles.image}
              height={87}
              width={87}
              src={`${PRODUCT_CDN}/icons/hand-stop.png`}
            />
          </div>
          <Button
            className={styles.button}
            variant="primary"
            size="medium"
            onClick={onClickUnblock}
          >
            Unblock Ads
          </Button>
          <Button
            variant="primary"
            size="medium"
            onClick={onClickWhitelist}
            id="sl-ad-block__whitelisted"
            className={styles.button}
          >
            I&#39;ve whitelisted
          </Button>
          <Typography className={styles.footerText} variant="caption" mt={3} component="div">
            {'Already a premium member? '}
            <TrackableLink
              ref={signInLinkRef}
              eventName="Clicked Link"
              eventProperties={getSignInEventProperties}
            >
              <a
                ref={signInLinkRef}
                href={`${config.surflineHost}/sign-in?redirectUrl=${getWindow()?.location}`}
              >
                Sign In
              </a>
            </TrackableLink>
            {' or '}
            <TrackableLink
              ref={signUpLinkRef}
              eventName={CLICKED_SUBSCRIBE_CTA}
              eventProperties={getSignUpEventProperties}
            >
              <a ref={signUpLinkRef} href={`${config.surflineHost}/upgrade`}>
                Sign Up
              </a>
            </TrackableLink>
            {' to skip this message.'}
          </Typography>
        </div>
      </div>
    </Modal>
  ) : null;
};

export default AdBlock;
