import React from 'react';

import type { TaxonomyNode } from 'types/header';

import BreadcrumbNode from '../BreadcrumbNode';
import { EARTH_TAXONOMY_ID } from '../../constants';

import styles from './Breadcrumb.module.scss';

interface BreadcrumbProps {
  listType?: string;
  nodes: Array<TaxonomyNode>;
  nodesPlaceholder?: Array<string>;
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
  onClickNode?: (taxonomyId: string) => Promise<void>;
}

const Breadcrumb = ({
  listType = '',
  nodes,
  nodesPlaceholder = [],
  onClickLink,
  onClickNode,
}: BreadcrumbProps) => {
  const geonameList = nodesPlaceholder.map((placeholder, index) => {
    if (nodes[index]) {
      return (
        <BreadcrumbNode
          label={nodes[index].name}
          taxonomyId={index === 0 ? EARTH_TAXONOMY_ID : nodes[index]._id}
          key={placeholder}
          onClickNode={onClickNode}
          onClickLink={onClickLink}
        />
      );
    }
    if (listType === 'spots' && index < 5) {
      return null;
    }
    return <BreadcrumbNode label={placeholder} key={placeholder} />;
  });

  return <div className={styles.breadcrumb}>{geonameList}</div>;
};

export default Breadcrumb;
