import addCustomUserAgentHeader from 'utils/addCustomUserAgentHeader';
import baseFetch, { createParamString } from '../baseFetch';

export const fetchEditorialArticleById = (
  id: string | string[] | undefined,
  preview: boolean | null,
  geotarget: string,
  cookies: any,
  isServer = false,
) => {
  const opts: {
    credentials?: string;
  } = {};
  let url = `/wp-json/sl/v1/posts?${createParamString({ id: id?.toString() || '' })}`;
  if (preview) {
    opts.credentials = 'include';
    /* Updated values passed to createParamString to be strings before passing them to the function */
    url += `&${createParamString({
      preview: 'true',
      uuid: JSON.stringify(Date.now()),
    })}`;
  }
  if (geotarget) {
    url += `&${createParamString({ geotarget })}`;
  }

  return baseFetch(url, { ...opts, cookies, headers: { ...addCustomUserAgentHeader(isServer) } });
};

export const fetchEditorialTaxonomyDetails = (
  taxonomy: string,
  term: string | null,
  subCategory: string | null,
  geotarget: string,
  isServer = false,
) => {
  const opts = {
    headers: { ...addCustomUserAgentHeader(isServer) },
  };
  let url = `/wp-json/sl/v1/taxonomy/details/${taxonomy}`;
  if (term) url += `/${term}`;
  if (geotarget) {
    url += `?${createParamString({ geotarget })}`;
  }
  return baseFetch(url, opts);
};

export const fetchEditorialTaxonomyPosts = (
  taxonomy: string,
  term: string,
  subCategory: string,
  limit: string,
  offset: string,
  geotarget: string,
  isServer = false,
) => {
  const opts = {
    headers: { ...addCustomUserAgentHeader(isServer) },
  };
  let url = `/wp-json/sl/v1/taxonomy/posts/${taxonomy}`;
  if (term) url += `/${term}`;
  return baseFetch(`${url}?${createParamString({ limit, offset, geotarget })}`, opts);
};
