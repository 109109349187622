import { TravelLocationView } from 'types/locationView';
import { fetchLocationViewTravel } from '../common/api/travelLocationView';
import type { AppDispatch } from '../stores';
import { redirectToNotFound } from './status';

export const FETCH_LOCATION_VIEW_TRAVEL = 'FETCH_LOCATION_VIEW_TRAVEL';
export const FETCH_LOCATION_VIEW_TRAVEL_SUCCESS = 'FETCH_LOCATION_VIEW_TRAVEL_SUCCESS';
export const FETCH_LOCATION_VIEW_TRAVEL_FAILURE = 'FETCH_LOCATION_VIEW_TRAVEL_FAILURE';

export const fetchTravelLocationView =
  (geonameId: string | string[] | undefined, cookies?: any) => async (dispatch: AppDispatch) => {
    dispatch({
      type: FETCH_LOCATION_VIEW_TRAVEL,
    });
    try {
      const response = await fetchLocationViewTravel(geonameId, cookies);
      const { taxonomy, url, associated, boundingBox, children, travelContent, breadCrumbs } =
        response as TravelLocationView;

      await dispatch({
        type: FETCH_LOCATION_VIEW_TRAVEL_SUCCESS,
        url,
        taxonomy,
        associated,
        boundingBox,
        children,
        travelContent,
        breadCrumbs,
      });
    } catch (error: any) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The Spot Report Could not be found'));
      }
      dispatch({
        type: FETCH_LOCATION_VIEW_TRAVEL_FAILURE,
        error,
      });
    }
  };
