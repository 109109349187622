import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { useCookies } from 'react-cookie';
import { useMount } from 'react-use';
import { Box, Button, Divider, SwipeableDrawer, Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';

import config from 'config';
import { BrowserIcon, SurflineWaterDrop } from 'components/Icons';
import usePageName from 'hooks/usePageName';
import type { Device } from 'types/device';
import { oneMinute } from 'utils/time';
import { useUserCountryCode } from 'selectors/user';

import styles from './AppBanner.module.scss';

// @ts-ignore Image can't be found on this path
import phoneImage from '../../../public/images/app-banner-phone.png';

interface Props {
  device: Device;
  variant?: 1 | 2;
}

const COOKIE_NAME = 'sl_appBanner';
const inTwentyMinutes = () => new Date(new Date().getTime() + 20 * oneMinute);

const AppBanner: React.FC<Props> = ({ device, variant }) => {
  const [cookies, setCookie] = useCookies([COOKIE_NAME]);
  const pageName = usePageName();
  const countryCode = useUserCountryCode();
  const isIOS = useMemo(() => device.os.name?.toLowerCase() === 'ios', [device]);
  const appBannerCookie = cookies[COOKIE_NAME];
  const isBannerActive = isIOS;
  const [isOpen, setIsOpen] = useState(appBannerCookie !== 'closed' && isBannerActive);

  const activeVariant = useMemo(() => {
    if (variant) return variant;
    if (countryCode === 'US') return 2;
    return 1;
  }, [variant, countryCode]);

  const handleOnClickApp = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      trackEvent('Mobile CTA Clicked', {
        action: 'app',
        ctaType: activeVariant === 1 ? 'small_banner' : 'large_banner',
        pageScreenName: pageName,
      });
      setCookie(COOKIE_NAME, 'closed', { path: '/', expires: inTwentyMinutes() });
      setIsOpen(false);
      window.location.href =
        activeVariant === 1
          ? `${config.appStoreUrl.iOS}?pt=261378&ct=surfline-mweb-small-banner&mt=8`
          : `${config.appStoreUrl.iOS}?pt=261378&ct=surfline-mweb-lrg-banner&mt=8`;
    },
    [activeVariant, pageName, setCookie],
  );

  const handleOnClickContinue = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      trackEvent('Mobile CTA Clicked', {
        action: 'browser',
        ctaType: activeVariant === 1 ? 'small_banner' : 'large_banner',
        pageScreenName: pageName,
      });
      setCookie(COOKIE_NAME, 'closed', { path: '/', expires: inTwentyMinutes() });
      setIsOpen(false);
    },
    [activeVariant, pageName, setCookie],
  );

  const handleOnOpen = useCallback(() => {
    setCookie(COOKIE_NAME, 'open', { path: '/', expires: inTwentyMinutes() });
    setIsOpen(true);
  }, [setCookie]);

  const handleOnClose = useCallback(() => {
    setCookie(COOKIE_NAME, 'closed', { path: '/', expires: inTwentyMinutes() });
    setIsOpen(false);
  }, [setCookie]);

  useMount(() => {
    if (isOpen) {
      trackEvent('Mobile CTA Viewed', {
        ctaType: activeVariant === 1 ? 'small_banner' : 'large_banner',
        pageScreenName: pageName,
      });
    }
  });

  if (!isBannerActive) return null;

  return (
    <SwipeableDrawer
      anchor="bottom"
      className={styles.banner}
      data-testid="app-banner"
      disableBackdropTransition={!isIOS}
      disableDiscovery={isIOS}
      onClose={handleOnClose}
      onOpen={handleOnOpen}
      open={isOpen}
      ModalProps={{
        BackdropProps: {
          onClick: (event) => {
            event.stopPropagation();
          },
        },
      }}
    >
      <Box p={4}>
        {activeVariant === 1 && (
          <Typography align="center" variant="h3">
            Surfline is better on the app
          </Typography>
        )}
        {activeVariant === 2 && (
          <>
            <Typography align="center" variant="h2">
              Surfline is better
              <br />
              on the app
            </Typography>
            <div className={styles.phoneImageWrapper}>
              <Image
                alt="Surfline home screen on an iPhone"
                className={styles.phoneImage}
                data-testid="app-banner-image"
                height="251"
                src={phoneImage}
                unoptimized
                width="240"
              />
            </div>
            <Divider />
          </>
        )}
        <ul className={styles.actions}>
          <li className={styles.action}>
            <div className={classNames(styles.iconWrapper, styles.surflineWrapper)}>
              <SurflineWaterDrop className={styles.icon} />
            </div>
            <Typography className={styles.title} variant="h3">
              Surfline App
            </Typography>
            <Button
              className={styles.button}
              data-testid="app-banner-open"
              href={config.appStoreUrl.iOS}
              onClick={handleOnClickApp}
              variant="primary"
            >
              Open App
            </Button>
          </li>
          <li className={styles.action}>
            <div className={styles.iconWrapper}>
              <BrowserIcon />
            </div>
            <Typography className={styles.title} variant="h3">
              Browser
            </Typography>
            <Button
              className={styles.button}
              data-testid="app-banner-continue"
              onClick={handleOnClickContinue}
              variant="secondary"
            >
              Continue
            </Button>
          </li>
        </ul>
      </Box>
    </SwipeableDrawer>
  );
};

export default AppBanner;
