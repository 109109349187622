import createReducer from '../../createReducer';
import {
  FETCH_GRAPH_TIDE_FORECAST,
  FETCH_GRAPH_TIDE_FORECAST_SUCCESS,
  FETCH_GRAPH_TIDE_FORECAST_FAILURE,
} from '../../../actions/graphs';
import createTideDayHourlyData from './helpers/createTideDayHourlyData';

/**
 * @typedef {import('../../../types/tide').TideData} GraphTideState
 */

/**
 * @type {GraphTideState}
 */
export const initialState = {
  error: null,
  loading: true,
  units: null,
  days: null,
  tideLocation: null,
  utcOffset: null,
};

const handlers = {};

handlers[FETCH_GRAPH_TIDE_FORECAST] = () => initialState;

handlers[FETCH_GRAPH_TIDE_FORECAST_SUCCESS] = (state, { response }) => {
  if (response.data.tides.length === 0) {
    return {
      ...state,
      loading: false,
      message: 'This location does not have tides',
      units: null,
      tideLocation: null,
    };
  }

  return {
    ...state,
    loading: false,
    units: response.associated.units,
    utcOffset: response.associated.utcOffset,
    tideLocation: response.associated.tideLocation,
    days: createTideDayHourlyData(response.data.tides, 16, 1),
  };
};

handlers[FETCH_GRAPH_TIDE_FORECAST_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

export default createReducer(handlers, initialState);
