/* eslint-disable @typescript-eslint/naming-convention */
import React, { KeyboardEvent, useEffect, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { useMount } from 'react-use';
import { v1 as uuid } from 'uuid';
import { debounce, isArray, isNil } from 'lodash';
import { CircularProgress, Dialog } from '@mui/material';
import useSWR from 'swr';
import { canUseDOM, getWindow } from '@surfline/web-common';

import { CloseIcon } from 'components/Icons';
import { useDeviceSize } from 'hooks/useDeviceSize';
import { useAppSelector } from 'stores/hooks';
import { getSessionTimeout, getStatusCode } from 'selectors/status';
import type { SearchResult } from 'types/header';

import { fetchSearchResults } from 'common/api/search';
import { SL_WEB_WAVE_POOL_SEARCH_RESULTS } from 'common/treatments';
import { useTreatments } from 'utils/treatments';
import SearchInput from './components/SearchInput';
import SearchSection from './components/SearchSection';
import {
  buildSectionData,
  getResultHref,
  getViewAllLink,
  trackClickedSearchResults,
  trackFocusedSearch,
  trackQuery,
} from './helpers';

import styles from './SiteSearch.module.scss';

const getSiteSearchClasses = (viewportCheck: string) =>
  classNames(styles.siteSearch, {
    [styles['siteSearch--mobile']]: viewportCheck === 'mobile',
  });

const ONE_MINUTE_IN_MS = 60 * 1000;

type FetchArgs = [
  string,
  { [key: string]: string },
  {
    size?: number;
    newsSearch?: boolean;
  },
];

interface SiteSearchProps {
  closeSearch: () => void;
  searchIsOpen: boolean;
  serviceUrl: string;
  viewport?: string;
}

const SiteSearch = ({ searchIsOpen, serviceUrl, closeSearch, viewport }: SiteSearchProps) => {
  const router = useRouter();
  const [initialRender, setInitialRender] = useState<boolean>(true);
  const [searchId, setSearchId] = useState<string>('');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [prevPosition, setPrevPosition] = useState<string | null>(null);
  const [prevOverflow, setPrevOverflow] = useState<string | null>(null);
  const { isMobile } = useDeviceSize();
  const status = useAppSelector(getStatusCode);
  const sessionTimeout = useAppSelector(getSessionTimeout);

  const isRefreshRequired = useMemo(
    () => (status !== 200 && !isNil(status)) || sessionTimeout,
    [sessionTimeout, status],
  );

  const sectionLimits = new Map();

  const viewAllLink = useMemo(() => `/search/${searchTerm}`, [searchTerm]);

  const treatments = useTreatments();
  const includeWavePools = treatments[SL_WEB_WAVE_POOL_SEARCH_RESULTS] === 'on';

  const args = useMemo<FetchArgs | undefined>(
    () =>
      searchTerm
        ? [
            searchTerm,
            {},
            {
              size: 10,
              newsSearch: true,
              includeWavePools,
            },
          ]
        : undefined,
    [includeWavePools, searchTerm],
  );

  const { data, isLoading } = useSWR(
    args,
    ([term, cookies, options]: FetchArgs) => fetchSearchResults(term, cookies, options),
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnMount: true,
      revalidateOnReconnect: false,
      errorRetryCount: 0,
      dedupingInterval: ONE_MINUTE_IN_MS,
    },
  );

  // set input value in state and fetch search results
  const handleInputChange = debounce(async (event) => {
    const value = event?.target?.value ?? '';
    await setSearchTerm(value);
    trackQuery(serviceUrl, value, searchId, data);
  }, 100);

  const handleInputKeyUp = (event: KeyboardEvent<HTMLInputElement>) => {
    // trigger view all link with enter key
    if (searchTerm && event.key === 'Enter') {
      if (canUseDOM) {
        closeSearch();
        router.push(viewAllLink);
      }
    }
  };

  const handleViewAllClick = () => {
    closeSearch();
    if (canUseDOM) {
      trackClickedSearchResults(window, window ? window.location.href : '', {
        searchId: '',
        searchResultRank: null,
        destinationPage: `/search/${searchTerm}`,
        queryTerm: searchTerm,
        resultName: '',
        resultType: 'see all results',
        isMobileView: isMobile,
      });
    }
  };

  const handleResultClick = (item: SearchResult) => {
    const { _type, _index, itemSectionIndex } = item;
    const newsResult = _index === 'feed';
    const resultUrl = getResultHref(item);
    if (canUseDOM) {
      trackClickedSearchResults(window, serviceUrl, {
        searchId,
        searchResultRank: itemSectionIndex,
        destinationPage: resultUrl,
        queryTerm: searchTerm,
        resultName: searchTerm,
        resultType: newsResult ? 'news' : _type || '',
        isMobileView: isMobile,
      });
    }
    if (resultUrl) {
      closeSearch();
      if (isRefreshRequired) {
        return getWindow()?.location?.assign(resultUrl);
      }
      return router.push(resultUrl);
    }
    return null;
  };

  useMount(() => {
    const searchUUID = uuid();
    setSearchId(searchUUID);
    setInitialRender(false);
  });

  useEffect(() => {
    if (canUseDOM && searchIsOpen) {
      if (viewport === 'mobile') {
        setPrevPosition(document?.body?.style?.position ?? null);
        if (document?.body?.style?.position) {
          document.body.style.position = 'fixed';
        }
      }
      setPrevOverflow(document?.documentElement?.style?.overflow);
      if (document?.documentElement?.style?.overflow) {
        document.documentElement.style.overflow = 'hidden';
      }
      trackFocusedSearch(serviceUrl, {
        searchId,
      });
    }
    if (canUseDOM && !searchIsOpen) {
      if (viewport === 'mobile' && document?.body?.style?.position && prevPosition) {
        document.body.style.position = prevPosition;
      }
      if (document?.documentElement?.style?.overflow && prevOverflow) {
        document.documentElement.style.overflow = prevOverflow;
      }
      setSearchTerm('');
    }
  }, [prevOverflow, prevPosition, searchId, searchIsOpen, serviceUrl, viewport]);

  if (initialRender) return null;

  return (
    <Dialog
      className={getSiteSearchClasses(viewport || '')}
      fullScreen
      onClose={closeSearch}
      open={searchIsOpen}
    >
      <div>
        <div className={styles.searchTop}>
          <div className="sl-section-container">
            <button type="button" className={styles.searchClose} onClick={closeSearch}>
              <CloseIcon />
            </button>
            <SearchInput
              handleViewAllClick={handleViewAllClick}
              searchTerm={searchTerm}
              viewAllLink={viewAllLink}
              onChange={handleInputChange}
              onKeyUp={handleInputKeyUp}
            />
          </div>
        </div>
        <div className={classNames('sl-section-container', styles.results)}>
          {data && searchTerm?.length && !isLoading ? (
            data.reduce(
              (result, section, sectionIndex) => {
                const { sectionLimit, sectionResults, sectionTitle } = buildSectionData(
                  section as any,
                );

                const updatedResult: SearchResult = { ...result };

                updatedResult.totalCount = (updatedResult?.totalCount ?? 0) + sectionResults.length;
                sectionLimits.set(sectionTitle, result.totalCount);

                if (!isArray(updatedResult?.sections)) {
                  updatedResult.sections = [];
                }

                if (!(sectionTitle === 'Regional Forecast')) {
                  updatedResult.sections.push(
                    <SearchSection
                      closeSearch={closeSearch}
                      // eslint-disable-next-line react/no-array-index-key
                      key={sectionIndex}
                      searchTerm={searchTerm}
                      sectionLimit={sectionLimit}
                      sectionResults={sectionResults}
                      sectionTitle={sectionTitle}
                      viewAllLink={getViewAllLink(searchTerm, sectionTitle)}
                      handleResultClick={handleResultClick}
                    />,
                  );
                }
                return result;
              },
              { sections: [], itemIndex: 0, totalCount: 0 },
            ).sections
          ) : (
            <div className={styles.resultsPlaceholder}>
              <p>Search for surf spots, travel maps, regional forecasts and surf news.</p>
              {isLoading && <CircularProgress className={styles.loading} />}
            </div>
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default SiteSearch;
