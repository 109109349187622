import { createApi } from '@reduxjs/toolkit/query/react';
import type { Units } from 'types/units';
import { BoundingBox } from 'types/map';
import { WindStationsBoundingBoxResponse } from 'types/windStations';

import { getAccessToken } from '../baseFetch';
import axiosBaseQuery from './axiosBaseQuery';

// We don't need to support server-side render for wind stations so we can
// just retrieve the acccess token from cookies at the top level
const ACCESS_TOKEN = getAccessToken();

const windStationsAPI = createApi({
  reducerPath: 'windStationsAPI',
  // 30 seconds is so that our data does not get out of date easily. 60 is default and buoys is set to 1200
  keepUnusedDataFor: 30,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getWindStationsInBoundingBox: builder.query<
      WindStationsBoundingBoxResponse,
      BoundingBox & { units?: Partial<Units> }
    >({
      query: ({ north, south, east, west, units }) => ({
        path: `/kbyg/weather-stations/bounds`,
        params: {
          north,
          south,
          east,
          west,
          accesstoken: ACCESS_TOKEN,
          'units[windSpeed]': units?.windSpeed ?? null,
        },
      }),
      transformResponse: (response: WindStationsBoundingBoxResponse) => ({
        data: response?.data?.filter((windStation) => windStation?.lastObserved),
        associated: response.associated,
      }),
    }),
  }),
});

export default windStationsAPI;
