import { combineReducers } from 'redux';

import consistency from './consistency';
import daySummary from './daySummary';
import ratings from './ratings';
import sunlightTimes from './sunlightTimes';
import swellSpectra from './swellSpectra';
import tide from './tide';
import surf from './surf';
import swells from './swells';
import weather from './weather';
import wind from './wind';

export default combineReducers({
  consistency,
  daySummary,
  ratings,
  sunlightTimes,
  swellSpectra,
  tide,
  surf,
  swells,
  weather,
  wind,
});
