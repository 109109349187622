import React from 'react';

export const Search = ({ className }: { className: string }) => (
  <svg className={className} viewBox="0 0 18 17" version="1.1">
    <g fillRule="evenodd">
      <g transform="translate(-94.000000, -43.000000)" fillRule="nonzero">
        <g transform="translate(90.000000, 29.166667)">
          <g transform="translate(0.000000, 9.875000)">
            <rect x="0" y="0" width="24" height="24" />
            <g transform="translate(4.000000, 4.000000)">
              <path d="M15,15 L10.75,10.75" strokeLinecap="round" />
              <circle cx="6.5" cy="6.5" r="5.75" />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Search;
