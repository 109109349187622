import React from 'react';
import { Typography } from '@mui/material';

import { Chevron } from 'components/Icons';
import type { TaxonomyNode } from 'types/header';

import styles from './RenderGeoname.module.scss';

interface RenderGeonameProps {
  node: TaxonomyNode;
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
  onClickNode?: (nodeId: string) => void;
}

const RenderGeoname = ({ onClickLink, onClickNode, node }: RenderGeonameProps) => {
  const handleClickedButton = () => {
    if (onClickNode) {
      onClickNode(node?._id || '');
    }
    if (onClickLink) {
      onClickLink(
        {
          clickEndLocation: 'Taxonomy Level',
          completion: false,
          linkName: node.name,
        },
        false,
      );
    }
  };

  return (
    <div className={styles.renderGeoname}>
      <Typography
        component="button"
        type="button"
        onClick={handleClickedButton}
        className={styles.button}
        variant="body2"
      >
        {node.name}
        <Chevron className={styles.chevron} direction="right" />
      </Typography>
    </div>
  );
};

export default RenderGeoname;
