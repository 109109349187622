import React, { ChangeEventHandler, KeyboardEvent } from 'react';
import { Typography } from '@mui/material';

import { Chevron, Search } from 'components/Icons';
import WavetrakLink from 'components/WavetrakLink';
import { useDeviceSize } from 'hooks/useDeviceSize';

import styles from './SearchInput.module.scss';

interface SearchInputProps {
  handleViewAllClick: (searchTerm?: string, isMobile?: boolean) => void;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onKeyUp: (event: KeyboardEvent<HTMLInputElement>) => void;
  searchTerm?: string;
  viewAllLink: string;
}

const SearchInput = ({
  handleViewAllClick,
  onChange,
  onKeyUp,
  searchTerm,
  viewAllLink,
}: SearchInputProps) => {
  const { isMobile } = useDeviceSize();

  return (
    <div className={styles.input}>
      <Search className={styles.icon} />
      <input
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        onChange={onChange}
        onKeyUp={onKeyUp}
        placeholder={
          isMobile
            ? 'Search spots, forecasts and more'
            : 'Search spots, regions, forecasts, articles, etc.'
        }
        spellCheck="false"
        data-testid="search-input-component-search-input"
      />
      {searchTerm ? (
        <Typography
          className={styles.link}
          component={WavetrakLink}
          href={viewAllLink}
          onClick={() => handleViewAllClick(searchTerm, isMobile)}
          variant="subtitle1"
          id="sl-search-input-view-all"
        >
          View All Results
          <Chevron direction="right" className={styles.linkIcon} />
        </Typography>
      ) : null}
    </div>
  );
};

export default SearchInput;
