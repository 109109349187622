/* istanbul ignore file */
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  favoriteSpot,
  fetchUserFavorites,
  FETCH_USER_FAVORITES,
  setRecentlyVisited,
  unfavoriteSpot,
  updateUserSettings,
} from '@surfline/web-common';
import { UserFavorites } from 'types/userFavorites';
import config from 'config';

import type { UserSettings } from 'types/user';

export const doFavoriteSpot = (spotId: string, serverSideCookies?: string) =>
  favoriteSpot({
    spotId,
    useAccessTokenQueryParam: true,
    serverSide: null,
    serverSideCookies,
    sevenRatings: false,
  });

export const doUnfavoriteSpot = (spotId: string, serverSideCookies?: string) =>
  unfavoriteSpot({ spotId, useAccessTokenQueryParam: true, serverSideCookies });

export const doSetRecentlyVisited = (
  type: 'spots' | 'subregions',
  _id: string,
  name: string,
  useAccessTokenQueryParam?: boolean,
  serverSideCookies?: string,
) => setRecentlyVisited({ type, _id, name, useAccessTokenQueryParam, serverSideCookies });

export const doUpdateUserSettings = (
  updatedSetting: Partial<UserSettings>,
  serverSideCookies?: string,
) => updateUserSettings({ updatedSetting, useAccessTokenQueryParam: true, serverSideCookies });

export const doFetchUserFavorites = createAsyncThunk(FETCH_USER_FAVORITES, async () => {
  const { data }: { data: { favorites: Array<UserFavorites> } } = await fetchUserFavorites(
    {},
    {
      customUrl: `${config.servicesURL}/kbyg`,
    },
  );
  return { favorites: data.favorites };
});
