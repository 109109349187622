import createReducer from './createReducer';
import type { AppState } from '../stores';
import {
  FETCH_EDITORIAL_TAXONOMY_DETAILS,
  FETCH_EDITORIAL_TAXONOMY_DETAILS_SUCCESS,
  FETCH_EDITORIAL_TAXONOMY_DETAILS_FAILURE,
} from '../actions/editorialTaxonomyDetails';

export const initialState = {
  loading: false,
  error: false,
  success: false,
  details: null,
};

const handlers: any = {};

handlers[FETCH_EDITORIAL_TAXONOMY_DETAILS] = (state: AppState) => ({
  ...state,
  loading: true,
  success: initialState.success,
  error: initialState.error,
});

handlers[FETCH_EDITORIAL_TAXONOMY_DETAILS_SUCCESS] = (state: AppState, { details }: any) => ({
  ...state,
  details,
  loading: false,
  success: true,
});

handlers[FETCH_EDITORIAL_TAXONOMY_DETAILS_FAILURE] = (state: AppState, { error }: any) => ({
  ...state,
  error,
  loading: false,
  success: false,
});

export default createReducer(handlers, initialState);
