import React from 'react';

export const SurflineWaterDrop = ({
  className,
  fill = '#FFFFFF',
}: {
  className?: string;
  fill?: string;
}) => (
  <svg
    className={className}
    fill="none"
    height="32"
    viewBox="0 0 24 32"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.0554 15.6845C21.4465 11.1316 12.0005 0.0554199 12.0005 0.0554199C12.0005 0.0554199 2.55315 11.1329 0.944276 15.6872C0.512496 16.9094 0.277344 18.2247 0.277344 19.5945C0.277344 26.0685 5.52646 31.3176 12.0005 31.3176C18.4745 31.3176 23.7237 26.0685 23.7237 19.5945C23.7237 18.2221 23.4885 16.9068 23.0554 15.6845ZM6.13892 21.5461C5.06014 21.5461 4.18462 20.6719 4.18462 19.5918C4.18462 15.2753 7.68403 11.7772 11.9992 11.7772C12.6741 11.7772 13.3291 11.8636 13.9535 12.023C10.5829 12.8906 8.0919 15.9516 8.0919 19.5918C8.09323 20.6719 7.21904 21.5461 6.13892 21.5461ZM12.0005 27.4077C11.3256 27.4077 10.6706 27.3227 10.0462 27.1619C13.4167 26.2944 15.9078 23.2347 15.9078 19.5931C15.9078 18.5143 16.782 17.6388 17.8621 17.6388C18.9409 17.6388 19.8164 18.513 19.8164 19.5931C19.8164 23.9096 16.317 27.4077 12.0005 27.4077Z"
      fill={fill}
    />
  </svg>
);

export default SurflineWaterDrop;
