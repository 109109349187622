import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useCookies } from 'react-cookie';
import { useAppSelector } from 'stores/hooks';
import { getUserSettings, getUser, UserLocation } from '@surfline/web-common';
import computeLimitedTimePremiumPlusDetails from 'utils/computeLimitedTimePremiumPlusDetails';
import { CelebrationIcon } from '../Icons';
import styles from './LimitedTimeOfferBanner.module.scss';

type SplitConfig = {
  start: string;
  end: string;
  introBannerText: string;
  outroBannerText: string;
  bannerVersion: string;
};

interface LimitedTimeOfferBannerProps {
  splitConfig: SplitConfig;
}

const LimitedTimeOfferBanner: React.FC<LimitedTimeOfferBannerProps> = ({ splitConfig }) => {
  const [cookies, setCookie] = useCookies();
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const userSettings = useAppSelector(getUserSettings);
  const { location: userLocation } = useAppSelector(getUser) as { location: UserLocation };
  const userTimezone = userLocation?.time_zone || 'America/Los_Angeles';
  const dateFormat = userSettings?.date?.format ? userSettings.date.format : 'MDY';
  const configDetails = computeLimitedTimePremiumPlusDetails(splitConfig, dateFormat, userTimezone);
  const {
    introBannerText,
    outroBannerText,
    isValid,
    daysUntilEnd,
    cookieExpiryDate,
    bannerVersion,
  } = configDetails;
  let cookieName = '';
  let bannerText = '';
  if (daysUntilEnd !== undefined && daysUntilEnd !== null) {
    if (daysUntilEnd > 2) {
      bannerText = introBannerText || '';
      cookieName = `sl_limitedAccess_intro_${bannerVersion}`;
    } else {
      bannerText = outroBannerText || '';
      cookieName = `sl_limitedAccess_outro_${bannerVersion}`;
    }
  }
  useEffect(() => {
    if (!isValid) return;
    const cookieValue = cookies[cookieName];
    if (cookieValue !== 'closed') {
      setIsBannerVisible(true);
    }
  }, [cookies, cookieName, isValid]);

  const handleClose = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (cookieExpiryDate) {
        setCookie(cookieName, 'closed', {
          path: '/',
          expires: cookieExpiryDate as Date,
        });
        setIsBannerVisible(false);
      }
    },
    [cookieExpiryDate, cookieName, setCookie],
  );

  // Replace "Premium+" with a span that has a special class
  const formattedText = `${bannerText.replace(
    'Premium+',
    `<span class="${styles.premiumPlusText}">Premium+</span>`,
  )}`;

  const memoizedFormattedText = useMemo(() => ({ __html: formattedText }), [formattedText]);

  if (!isValid || !isBannerVisible || !memoizedFormattedText) return null;

  return (
    <Box className={styles.box} data-testid="limited-time-offer_banner">
      <div className={styles.clickableArea} role="button" tabIndex={0} data-testid="clickable-area">
        <CelebrationIcon />
        <Typography
          className={styles.text}
          variant="body1"
          dangerouslySetInnerHTML={memoizedFormattedText}
        />
      </div>
      <IconButton className={styles.closeButton} onClick={handleClose} data-testid="close-button">
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default LimitedTimeOfferBanner;
