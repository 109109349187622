import { AnyAction, Reducer } from 'redux';
import createReducer from './createReducer';
import {
  FETCH_EDITORIAL_ARTICLE,
  FETCH_EDITORIAL_ARTICLE_SUCCESS,
  FETCH_EDITORIAL_ARTICLE_FAILURE,
} from '../actions/editorial';

export const initialState = {
  loading: false,
  error: false,
  success: false,
  article: null,
};

const handlers: { [key: string]: Reducer<any, AnyAction> } = {};

handlers[FETCH_EDITORIAL_ARTICLE] = (state) => ({
  ...state,
  loading: true,
  success: initialState.success,
  error: initialState.error,
});

handlers[FETCH_EDITORIAL_ARTICLE_SUCCESS] = (state, { article }) => ({
  ...state,
  article,
  loading: false,
  success: true,
});

handlers[FETCH_EDITORIAL_ARTICLE_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  loading: false,
  success: false,
});

export default createReducer(handlers, initialState);
