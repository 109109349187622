import React, { Component, ReactNode } from 'react';
import ErrorFiveHundred from 'components/ErrorFiveHundred';

interface Props {
  children: ReactNode;
  error?: string | boolean | Error;
  errorsToSuppress?: string[];
  properties?: {
    componentStack?: string;
    [key: string]: any;
  };
  render?: () => ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  constructor(props: Props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  componentDidCatch(error: Error, errorInfo: any) {
    const { hasError } = this.state;
    const { properties, errorsToSuppress = [] } = this.props;

    if (!hasError) this.setState({ hasError: true });

    console.error(error, errorInfo);
    // @ts-ignore - update after travel migration
    if (window && window.newrelic) {
      if (errorInfo?.componentStack && properties?.componentStack) {
        properties.componentStack = errorInfo.componentStack.toString();
      }
      const errorMessage = error?.message ?? error.toString();
      if (errorsToSuppress?.includes?.(errorMessage)) {
        // @ts-ignore - update after travel migration
        window.newrelic?.recordCustomEvent?.(errorMessage, { error, ...properties });
      } else {
        // @ts-ignore - update after travel migration
        window.newrelic?.noticeError?.(error, properties);
      }
    }
  }

  render() {
    const { children, render, error } = this.props;
    const { hasError } = this.state;

    if (hasError || error) {
      if (render) {
        return render();
      }
      return <ErrorFiveHundred />;
    }

    return children;
  }
}

export default ErrorBoundary;
