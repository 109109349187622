import { useEffect } from 'react';

const useMutationObserver = (
  ref: any,
  callback: any,
  options = {
    attributes: true,
    characterData: true,
    childList: true,
    subtree: true,
  },
) => {
  // @ts-ignore
  useEffect(() => {
    if (ref?.current) {
      const observer = new MutationObserver(callback);
      observer.observe(ref.current, options);
      return () => observer.disconnect();
    }
    return null;
  }, [callback, options]);
};

export default useMutationObserver;
