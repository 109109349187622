import { trackEvent } from '@surfline/web-common';

import type { SearchResult } from 'types/header';

export const shipAnalytics = (serviceUrl: string, log: any) =>
  fetch(`${serviceUrl}/search/analytics`, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ log }),
  });

export const trackClickedSearchResults = (
  window: Window | null,
  serviceUrl: string,
  {
    searchId,
    searchResultRank,
    destinationPage,
    queryTerm,
    resultName,
    resultType,
    isMobileView,
  }: {
    searchId: string;
    searchResultRank: any;
    destinationPage?: string;
    queryTerm?: string;
    resultName?: string;
    resultType: string;
    isMobileView: boolean;
  },
  callback = () => {},
) => {
  const body = {
    searchId,
    location: 'navigation',
    searchResultRank,
    destinationPage,
    sourcePage: window && window.location ? window.location.href : null,
    queryTerm,
    resultName,
    resultType,
    isMobileView,
  };
  if (serviceUrl) {
    shipAnalytics(serviceUrl, { event: 'Viewed Search Results', ...body });
    trackEvent('Viewed Search Results', body, undefined, callback);
  } else {
    callback();
  }
};

export const trackFocusedSearch = (serviceUrl: string, { searchId }: { searchId: string }) => {
  shipAnalytics(serviceUrl, { event: 'Focused Search', searchId });

  trackEvent('Focused Search', {
    location: 'navigation',
  });
};

export const trackQuery = (
  serviceUrl: string,
  value: string,
  searchId: string,
  searchResults?: Array<SearchResult>,
) => {
  const searchResultHits = searchResults?.map((section) => section?.hits?.hits);
  shipAnalytics(serviceUrl, {
    event: 'Query',
    searchId,
    query: value,
    hits: {
      spots: searchResultHits?.[0].slice(0, 10).length,
      subregions: searchResultHits?.[1].slice(0, 3).length,
      geonames: searchResultHits?.[2].slice(0, 3).length,
      news: searchResultHits?.[3].slice(0, 10).length,
      travel: searchResultHits?.[4].slice(0, 3).length,
    },
  });
};
