import productCDN from '@surfline/quiver-assets';
import type { EnvConfig } from 'config';

const mapTilerKey = 'ABULzONjGX9yyrLkKNZl';

const staging: EnvConfig = {
  appHost: 'https://staging.surfline.com',
  appKeys: {
    appsFlyer: 'd81e6dc0-c4cf-4c84-8d7c-351bf6cf2f03',
    fbAppId: process.env.FB_APP_ID || '564041023804928',
    logrocket: 'h57nhx/sl_sandbox',
    segment: 'VQDMbHw65jkXg4go8KmBnDiXzeAz7GiO',
  },
  camerasServiceURL: 'http://cameras-service.staging.surfline.com',
  cdnUrl: 'https://product-cdn.staging.surfline.com',
  clientId: process.env.WEB_CLIENT_ID || '5bff1583ffda1e1eb8d35c4b',
  clientSecret: process.env.WEB_CLIENT_SECRET || '',
  defaultCountryCode: 'US',
  defaultImageUrl: 'https://spot-thumbnails.staging.surfline.com/spots/default/default_1500.jpg',
  ga4MeasurementId: 'G-273TVEPYKD',
  geoTargetServiceUrl: 'http://geo-target-service.staging.surfline.com',
  kbygServiceURL: 'http://kbyg-api.staging.surfline.com',
  legacyApi: 'https://api.surfline.com/v1',
  mapTiler: {
    key: mapTilerKey,
    style: `https://api.maptiler.com/maps/5c2beb01-3d14-40b5-8bb4-463b7948c4a6/style.json?key=${mapTilerKey}`,
  },
  oneTrustDomainScript: '89f3dcce-1ffc-42b2-9d52-91fd3af3d675-test',
  react: {
    integrity:
      'sha512-qlzIeUtTg7eBpmEaS12NZgxz52YYZVF5myj89mjJEesBd/oE9UPsYOX2QAXzvOAZYEvQohKdcY8zKE02ifXDmA==',
    src: `${productCDN}/scripts/react.production.min.js`,
  },
  reactDOM: {
    integrity:
      'sha512-9jGNr5Piwe8nzLLYTk8QrEMPfjGU0px80GYzKZUxi7lmCfrBjtyCc1V5kkS5vxVwwIB7Qpzc7UxLiQxfAN30dw==',
    src: `${productCDN}/scripts/react-dom.production.min.js`,
  },
  robots: 'noindex,nofollow',
  servicesURL: 'https://services.staging.surfline.com',
  she: {
    headerScriptUrl: 'https://ads.blogherads.com/sk/12/123/1236760/28994/header.js',
    staticScriptUrl: 'https://ads.blogherads.com/static/blogherads.js',
  },
  signInUrl: 'https://staging.surfline.com/sign-in',
  splitio: process.env.SPLITIO_AUTHORIZATION_KEY || '7907oa075f52vgvpbf52t21gdhptmk6ff5mm',
  subscriptionServiceURL: 'http://subscription-service.staging.surfline.com',
  surflineEmbedURL: 'https://embed.staging.surfline.com',
  surflineHost: 'https://staging.surfline.com',
  userServiceURL: 'http://user-service.staging.surfline.com',
  wpAPI: 'https://staging.surfline.com',
};

export default staging;
