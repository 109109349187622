/** @prettier */

export const LINK = 'LINK';
export const BTN = 'BTN';
export const ENTER_KEY = 'Enter';
export const SPACE_KEY = ' ';
export const SPACE_KEY_CODE = 32;
export const ENTER_KEY_CODE = 13;
/**
 * We want the site to be as useable as possible for regular users, but also
 * for anyone with disabilities or screen readers. To ensure this we use the
 * JSX A11y library which gives us lint rules to ensure that we make the site
 * accesible. Creating `keydown` events for every `onClick` can be a pain
 * so this factory function will help streamline creating accessible click events
 *
 * @param {Function} handlerFn The function to be called for both `onClick` and `onKeyDown`
 * @param {String} [type="LINK"] The type of onClick ('LINK' or 'BTN'). Buttons will listen
 * for the `SPACE` key (<button> should fire on space key by default) and links for the `ENTER` key
 */
const createAccessibleOnClick = (handlerFn, type = LINK, bubble = true) => ({
  role: 'button',
  onClick: (event) => {
    if (type === BTN) event.preventDefault();
    if (!bubble) {
      event.stopPropagation();
    }
    handlerFn(event);
  },
  onTouchEnd: (event) => {
    if (type === BTN) event.preventDefault();
    if (!bubble) {
      event.stopPropagation();
    }
    handlerFn(event);
  },
  tabIndex: 0,
  onKeyDown: (event) => {
    const key = type === LINK ? ENTER_KEY : SPACE_KEY;
    const keyCode = type === LINK ? ENTER_KEY_CODE : SPACE_KEY_CODE;

    if (event?.keycode === keyCode || event?.key === key) {
      if (!bubble) {
        event.stopPropagation();
      }
      handlerFn(event);
    }
  },
  onBlur: (event) => {
    if (!bubble) {
      event.stopPropagation();
    }
  },
});

export default createAccessibleOnClick;
