import { BaseQueryFn } from '@reduxjs/toolkit/query';
import axios, { AxiosRequestConfig, AxiosError } from 'axios';
import { getBaseURL } from '../baseFetch';

const axiosBaseQuery = () => {
  const baseUrl = getBaseURL();
  const baseQueryFn: BaseQueryFn<
    { path: string } & Omit<AxiosRequestConfig, 'url'>,
    unknown,
    { message: string; status?: number }
  > = async (options) => {
    const url = `${baseUrl}${options.path}`;
    try {
      const result = await axios({ url, ...options });
      return { data: result.data };
    } catch (error) {
      const err = error as AxiosError;
      return {
        error: { status: err.response?.status, message: err.message },
      };
    }
  };
  return baseQueryFn;
};

export default axiosBaseQuery;
