import { Box, Typography } from '@mui/material';
import { SkullIcon } from '@wavetrak/theme';
import cx from 'classnames';
import React from 'react';
import styles from './ErrorMessage.module.scss';

export interface ErrorMessageProps {
  className?: string;
  dataTestId?: string;
  message?: string | null;
  subMessage?: string | null;
  Icon?: React.FC | null;
}

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  className,
  dataTestId,
  Icon = SkullIcon,
  message = 'Something went wrong here.',
  subMessage = null,
}) => (
  <Box className={cx(styles.errorMessage, className)} data-testid={dataTestId}>
    {Icon !== null ? <Icon className={styles.icon} /> : null}
    <Typography variant="title3">{message}</Typography>
    {subMessage && (
      <Typography className={styles.subMessage} component="p" variant="body1">
        {subMessage}
      </Typography>
    )}
  </Box>
);

export default ErrorMessage;
