import React from 'react';
import classNames from 'classnames';
import { Typography } from '@mui/material';

import type { TaxonomyNode } from 'types/header';

import RenderSpot from '../../../RenderSpot';
import RenderSubregion from '../../../RenderSubregion';
import RenderGeoname from '../RenderGeoname';
import RenderCity from '../RenderCity';

import styles from './ChildList.module.scss';

const flattenNodes = (groups: any) => {
  let result: Array<TaxonomyNode> = [];
  groups?.forEach((group: any) => {
    if (group.spots && group.spots.length === 0) return;
    result.push(group.city);
    const spots = [
      ...group.spots.slice(0, group.spots.length - 1),
      { ...group.spots[group.spots.length - 1], lastOfGroup: true },
    ];
    result = result.concat(spots);
  });
  return result.filter((node) => node !== null);
};

const childListClassNames = (listType: string) =>
  classNames({
    [styles['childList--spot']]: listType === 'spots',
    [styles.childList]: listType !== 'spots',
  });

interface ChildListProps {
  error?: boolean;
  listType?: string;
  loading?: boolean;
  nodes?: Array<TaxonomyNode>;
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
  onClickNode?: (taxonomyId: string) => Promise<void>;
}

const ChildList = ({
  error = false,
  listType = '',
  loading = false,
  nodes,
  onClickLink,
  onClickNode,
}: ChildListProps) => (
  <div className={childListClassNames(listType)}>
    {(() => {
      if (error) {
        return (
          <Typography variant="body1" component="p" className={styles.message}>
            Sorry, an error occurred
          </Typography>
        );
      }
      if (loading) {
        return (
          <Typography variant="body1" component="p" className={styles.message}>
            Fetching data
          </Typography>
        );
      }
      if (listType === 'subregions') {
        return nodes
          ?.filter((node) => !!node._id && !!node.name)
          ?.map((node) => (
            <RenderSubregion key={node._id} subregion={node} onClickLink={onClickLink} />
          ));
      }
      if (listType === 'geonames') {
        return nodes?.map((node) => (
          <RenderGeoname
            key={node._id}
            node={node}
            onClickNode={onClickNode}
            onClickLink={onClickLink}
          />
        ));
      }
      return flattenNodes(nodes)
        ?.filter((node) => !!node.name)
        ?.map((node) => {
          if (node.type === 'geoname') {
            return <RenderCity node={node} key={node._id} />;
          }
          return <RenderSpot spot={node} key={node._id} onClickLink={onClickLink} />;
        });
    })()}
  </div>
);

export default ChildList;
