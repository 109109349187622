import React from 'react';

const cloneChildrenWithProps = (children: React.ReactElement, props: any) => {
  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, props);
    }
    return child;
  });

  return childrenWithProps;
};

export default cloneChildrenWithProps;
