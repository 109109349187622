import React from 'react';

interface Props {
  className?: string;
}

export const CloseIcon = ({ className }: Props) => (
  <svg
    className={className}
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="close-icon"
  >
    <circle cx="16" cy="16" r="16" fill="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8985 10.5113C11.5082 10.1206 10.8752 10.1206 10.4848 10.5113C10.0944 10.9019 10.0944 11.5353 10.4848 11.926L14.5845 16.0283L10.4856 20.1262C10.095 20.5167 10.095 21.1498 10.4856 21.5403C10.8762 21.9308 11.5094 21.9308 11.9 21.5403L15.9982 17.443L20.0984 21.5459C20.4888 21.9366 21.1218 21.9366 21.5122 21.5459C21.9026 21.1553 21.9026 20.5219 21.5122 20.1313L17.4126 16.029L21.5158 11.9267C21.9064 11.5362 21.9064 10.9031 21.5158 10.5127C21.1253 10.1222 20.492 10.1222 20.1015 10.5127L15.9988 14.6143L11.8985 10.5113Z"
      fill="#171717"
    />
  </svg>
);

export default CloseIcon;
