import React from 'react';

export const Snooze = () => (
  <svg width="61" height="60" viewBox="0 0 61 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M26.353 53H9.64898V48.392L18.721 39.464H9.64898V34.568H26.353V39.176L17.281 48.104H26.353V53Z"
      fill="#968095"
    />
    <path
      d="M39.8007 34H28.2007V30.8L34.5007 24.6H28.2007V21.2H39.8007V24.4L33.5007 30.6H39.8007V34Z"
      fill="#968095"
    />
    <path
      d="M47.5164 21H41.4844V19.336L44.7604 16.112H41.4844V14.344H47.5164V16.008L44.2404 19.232H47.5164V21Z"
      fill="#968095"
    />
  </svg>
);

export default Snooze;
