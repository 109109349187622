import { useCallback } from 'react';
import { dequal as deepEqual } from 'dequal';
import { shallowEqual, useDispatch } from 'react-redux';

import { setMapLocation } from '../../actions/mapV2';
import { getMapLocation, getMapBoundingBox, getMapLocationLoaded } from '../../selectors/mapV2';
import { useAppSelector } from '../../stores/hooks';
import { Location } from '../../types/map';

const useMapLocation = () => {
  const dispatch = useDispatch();

  // Since location has a nested `center` object we need to deep equal to prevent re-renders
  const location = useAppSelector(getMapLocation, deepEqual);
  const boundingBox = useAppSelector(getMapBoundingBox, shallowEqual);
  const locationLoaded = useAppSelector(getMapLocationLoaded);

  const doSetMapLocation = useCallback(
    (newLocation: Location) => {
      dispatch(setMapLocation(newLocation));
    },
    [dispatch],
  );

  return { location, boundingBox, locationLoaded, doSetMapLocation };
};

export default useMapLocation;
