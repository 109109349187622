import React, { useMemo } from 'react';
import classNames from 'classnames';

export const Chevron: React.FC<{ className?: string; direction: string }> = ({
  className,
  direction,
}) => {
  const points = useMemo(() => {
    switch (direction) {
      case 'left':
        return '7 1, 0 6.5, 7 13';
      case 'right':
        return '0 0, 7 6.5, 0 13';
      case 'up':
        return '0 7, 6.5 0, 13 7';
      case 'down':
      default:
        return '0 0, 6.5 7, 13 0';
    }
  }, [direction]);

  const { width, height, viewBox } = useMemo(() => {
    switch (direction) {
      case 'left':
      case 'right':
        return {
          width: 7,
          height: 13,
          viewBox: '0 0 7 13',
        };
      case 'up':
      case 'down':
      default:
        return {
          width: 13,
          height: 7,
          viewBox: '0 0 13 7',
        };
    }
  }, [direction]);

  return (
    <svg
      width={width}
      height={height}
      viewBox={viewBox}
      className={classNames('quiver-chevron', className)}
    >
      <polyline points={points} fill="none" strokeLinejoin="round" />
    </svg>
  );
};

export default Chevron;
