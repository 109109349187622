import addCustomUserAgentHeader from 'utils/addCustomUserAgentHeader';
import baseFetch, { createParamString } from '../baseFetch';

// eslint-disable-next-line import/prefer-default-export
export const fetchRegionalArticleById = (id, cookies, isServer = false) =>
  baseFetch(`/feed/local?${createParamString({ id })}`, {
    cookies,
    headers: { ...addCustomUserAgentHeader(isServer) },
  });

export const fetchRegionalArticles = async (
  subregionId,
  start,
  limit,
  includeLocalNews,
  isServer = false,
) =>
  baseFetch(
    `/feed/regional?${createParamString({
      subregionId,
      start,
      limit,
      includeLocalNews,
    })}`,
    { headers: { ...addCustomUserAgentHeader(isServer) } },
  );
