import * as locationAPI from '../common/api/location';
import type { AppDispatch } from '../stores';
import { WorldTaxonomy } from '../types/locationView';
import { internalServerError } from './status';

export const FETCH_WORLD_TAXONOMY_SUCCESS = 'FETCH_WORLD_TAXONOMY_SUCCESS';

export const fetchWorldTaxonomy =
  (cookies?: Record<string, string>) => async (dispatch: AppDispatch) => {
    // If this fails we'll handle the error at the router level and send them a 500
    try {
      const { data } = (await locationAPI.fetchWorldTaxonomy(cookies)) as { data: WorldTaxonomy[] };
      dispatch({ type: FETCH_WORLD_TAXONOMY_SUCCESS, taxonomy: data });
    } catch (error) {
      dispatch(internalServerError('Failed to fetch Word Taxonomy'));
    }
  };
