const formatWaveHeight = (waveHeight: string, isToLowerCase: boolean = false) => {
  if (isToLowerCase) {
    return waveHeight?.toLowerCase() === 'hi'
      ? waveHeight?.toLowerCase()?.replace('hi', 'ft')
      : waveHeight?.toLowerCase();
  }
  return waveHeight === 'HI' ? waveHeight?.replace('HI', 'FT') : waveHeight;
};

export default formatWaveHeight;
