import { useTheme } from '@mui/material';
import { getUserSettings } from '@surfline/web-common';
import { useAppSelector } from 'stores/hooks';
import { CSSProperties, useMemo } from 'react';

interface Condition {
  key: string;
  value: string;
  color: CSSProperties['color'];
}

const useConditions = (conditionKey?: string) => {
  const theme = useTheme();
  const settings = useAppSelector(getUserSettings);

  const colorTheme = useMemo(
    () => (settings?.ratingsTheme === 'Legacy' ? 'Legacy' : 'Traffic Light'),
    [settings?.ratingsTheme],
  );

  const conditionColors = useMemo(
    () => (colorTheme === 'Traffic Light' ? theme.conditionsTrafficLight : theme.conditions),
    [colorTheme, theme.conditions, theme.conditionsTrafficLight],
  );

  const themeClassName = useMemo(
    () => (colorTheme === 'Traffic Light' ? 'sl-conditions-color--traffic-light' : undefined),
    [colorTheme],
  );

  const conditions = useMemo<Array<Condition>>(
    () => [
      {
        key: 'VERY_POOR',
        value: 'VERY POOR',
        color: conditionColors.veryPoor,
      },
      {
        key: 'POOR',
        value: 'POOR',
        color: conditionColors.poor,
      },
      {
        key: 'POOR_TO_FAIR',
        value: 'POOR TO FAIR',
        color: conditionColors.poorToFair,
      },
      {
        key: 'FAIR',
        value: 'FAIR',
        color: conditionColors.fair,
      },
      {
        key: 'FAIR_TO_GOOD',
        value: 'FAIR TO GOOD',
        color: conditionColors.fairToGood,
      },
      {
        key: 'GOOD',
        value: 'GOOD',
        color: conditionColors.good,
      },
      {
        key: 'EPIC',
        value: 'EPIC',
        color: conditionColors.epic,
      },
      {
        key: 'NONE',
        value: 'NONE',
        color: conditionColors.none,
      },
    ],
    [
      conditionColors.epic,
      conditionColors.fair,
      conditionColors.fairToGood,
      conditionColors.good,
      conditionColors.none,
      conditionColors.poor,
      conditionColors.poorToFair,
      conditionColors.veryPoor,
    ],
  );

  const activeCondition = useMemo(() => {
    const noCondition: Condition = {
      key: 'NONE',
      value: 'NONE',
      color: conditionColors.none,
    };
    return conditions.find((condition: Condition) => condition.key === conditionKey) || noCondition;
  }, [conditionColors, conditionKey, conditions]);

  return {
    activeCondition,
    colorTheme,
    conditions,
    themeClassName,
  };
};

export default useConditions;
