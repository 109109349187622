import React from 'react';
import PropTypes from 'prop-types';
import { useLeafletComponent } from './useLeaflet';

/**
 * @typedef {object} Center
 * @property {number} lat
 * @property {number} lon
 */

/**
 * @function Map
 * @param {object} props
 * @param {React.CSSProperties} props.style
 * @param {React.ReactNode} props.children
 * @param {Center} props.center
 * @param {number} props.zoom
 * @param {(center: Center) => void} props.onClick
 * @param {function} props.onZoomend
 * @param {import('@monsonjeremy/react-leaflet').MapContainerProps} props.mapProps
 * @return {JSX.Element}
 */
const Map = ({
  style,
  children,
  center: { lat, lon: lng },
  zoom,
  onClick,
  onZoomend,
  mapProps,
}) => {
  // Leaflet cannot be used on the server-side so we have to hack it :^)
  /** @type {import('@monsonjeremy/react-leaflet').MapContainer} */
  const LeafletMap = useLeafletComponent('MapContainer');

  const styleWithDefaults = {
    height: '100%',
    ...style,
  };

  if (!LeafletMap) {
    return null;
  }

  return (
    <LeafletMap
      className="sl-quiver-map"
      {...mapProps}
      style={styleWithDefaults}
      center={{ lat, lng }}
      zoom={zoom}
      onClick={
        typeof onClick === 'function'
          ? ({ latlng }) => onClick({ lat: latlng.lat, lon: latlng.lng })
          : undefined
      }
      onZoomend={onZoomend}
    >
      {children}
    </LeafletMap>
  );
};

Map.propTypes = {
  mapProps: PropTypes.shape({
    placeholder: PropTypes.node,
    whenCreated: PropTypes.func,
    whenReady: PropTypes.func,
  }),
  children: PropTypes.instanceOf(React.Component),
  style: PropTypes.shape({}),
  center: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }),
  zoom: PropTypes.number,
  onClick: PropTypes.func,
  onZoomend: PropTypes.func,
};

Map.defaultProps = {
  mapProps: {},
  children: null,
  style: null,
  center: { lat: 0, lng: 0 },
  zoom: 10,
  onClick: undefined,
  onZoomend: null,
};

export default Map;
