import { AnyAction, Reducer } from 'redux';
import createReducer from './createReducer';
import {
  FETCH_SEARCH_RESULTS,
  FETCH_SEARCH_RESULTS_SUCCESS,
  SHOW_ALL_TOGGLE,
} from '../actions/search';

const initialState = {
  results: [],
  sections: {
    'spot-suggest': {
      showAll: false,
      name: 'Surf Spots',
      initialCount: 20,
    },
    'subregion-suggest': {
      showAll: false,
      name: 'Regional Forecast',
      initialCount: 8,
    },
    'geoname-suggest': {
      showAll: false,
      name: 'Map Area',
      initialCount: 8,
    },
    'feed-tag-suggest': {
      showAll: false,
      name: 'Surf News',
      initialCount: 5,
    },
    'travel-suggest': {
      showAll: false,
      name: 'Travel Guide',
      initialCount: 5,
    },
  },
};

const handlers: { [key: string]: Reducer<any, AnyAction> } = {
  [FETCH_SEARCH_RESULTS]: (state) => ({
    ...state,
  }),
  [FETCH_SEARCH_RESULTS_SUCCESS]: (state, { results }) => ({
    ...state,
    results,
  }),
  [SHOW_ALL_TOGGLE]: (state, { section }) => {
    const { sections } = state;

    const newSections = Object.keys(sections).map((key) => {
      if (key === section) {
        return [key, { ...sections[key], showAll: !sections[key].showAll }];
      }
      return [key, sections[key]];
    });

    return {
      ...state,
      sections: Object.fromEntries(newSections),
    };
  },
};

export default createReducer(handlers, initialState);
