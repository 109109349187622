import React from 'react';

interface Props {
  className?: string;
}

export const WindIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="19" stroke="#171717" strokeWidth="2" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.638 26.2705C22.0556 26.2872 22.3082 25.8157 22.0628 25.4779L19.795 22.3566L28.7386 15.8587C28.9617 15.6965 29.0112 15.3845 28.849 15.1613L27.3469 13.0938C27.1844 12.8702 26.8711 12.8205 26.6475 12.983L17.7051 19.48L15.4387 16.3606C15.193 16.0224 14.6651 16.1165 14.552 16.5186L12.0905 25.272C12.0032 25.5828 12.2294 25.8936 12.5524 25.9065L21.638 26.2705Z"
      fill="#171717"
    />
  </svg>
);

export default WindIcon;
