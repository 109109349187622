import createReducer from './createReducer';
import { FETCH_SPOT_MAP_DATA_SUCCESS } from '../actions/spotMapData';

export const initialState = {
  tideHeight: null,
  waveHeight: null,
  windSpeed: null,
};

const handlers = {};

handlers[FETCH_SPOT_MAP_DATA_SUCCESS] = (state, { units }) => ({
  ...state,
  tideHeight: units.tideHeight,
  waveHeight: units.waveHeight,
  windSpeed: units.windSpeed,
});

export default createReducer(handlers, initialState);
