import React from 'react';
import classNames from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';

import type { MapMode } from 'types/map';
import useNotification from './useNotification';
import styles from './MapNotification.module.scss';

const getClassName = ({
  isError,
  isLoading,
  isMessage,
}: {
  isError: boolean;
  isLoading: boolean;
  isMessage: boolean;
}) =>
  classNames({
    [styles.slMapNotification]: true,
    [styles.slMapNotificationError]: isError,
    [styles.slMapNotificationLoading]: isLoading,
    [styles.slMapNotificationWithMessage]: isMessage,
  });

const MapNotification = ({ hide = false, mapMode }: { hide?: boolean; mapMode: MapMode }) => {
  const { message, isError, isLoading, isMessage } = useNotification(mapMode);

  if (hide) {
    return null;
  }

  return (
    <AnimatePresence>
      {message && (
        <motion.dialog
          initial={{ opacity: 0, y: -10, x: '-50%', scale: 0.3 }}
          animate={{ opacity: 1, y: 0, scale: 1 }}
          exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.15, ease: 'easeInOut' } }}
          className={getClassName({
            isError: !!isError,
            isLoading: !!isLoading,
            isMessage: !!isMessage,
          })}
        >
          <p className={styles.slMapNotificationMessage}>{message}</p>
        </motion.dialog>
      )}
    </AnimatePresence>
  );
};

export default MapNotification;
