import sanitizeHTML from 'sanitize-html';

const sanitizeArticleHTML = (html) =>
  sanitizeHTML(html, {
    allowedTags: false,
    allowedAttributes: false,
    // remove script tags
    exclusiveFilter: (node) => node.tag === 'script',
    // transform ig blockquotes attrs
    transformTags: {
      blockquote: (tagName, attribs) => {
        if (attribs['data-instgrm-permalink']) {
          return {
            tagName: 'blockquote',
            attribs: {
              class: 'sl-article__ig',
              'data-sl-ig': attribs['data-instgrm-permalink'],
            },
          };
        }
        return { tagName, attribs };
      },
      div: (tagName, attribs) => {
        if (attribs['data-sl-ig']) {
          return {
            tagName,
            attribs: {
              ...attribs,
              class: 'sl-article__ig',
            },
          };
        }
        return { tagName, attribs };
      },
    },
  });

export default sanitizeArticleHTML;
