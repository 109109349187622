import createReducer from '../createReducer';
import {
  FETCH_NEARBY_SPOTS,
  FETCH_NEARBY_SPOTS_SUCCESS,
  FETCH_NEARBY_SPOTS_FAILURE,
} from '../../actions/spot';
import { LEAVE_SPOT_ROUTE } from '../../actions/routes';

/**
 * @typedef {Object} Units
 * @property {string} windSpeed;
 * @property {string} waveHeight;
 * @property {string} tideHeight;
 */

/**
 * @typedef {Object} NearbySpots
 * @property {Array<any>} spots
 */
/**
 * @typedef {Object} NearbySpotsState
 * @property {boolean} loading
 * @property {NearbySpots} data
 * @property {any} error
 * @property {Units} units
 */

/** @type {NearbySpotsState} */
export const initialState = {
  loading: false,
  data: null,
  error: null,
};

const handlers = {};

handlers[FETCH_NEARBY_SPOTS] = (state) => ({
  ...state,
  data: null,
  loading: true,
});

handlers[FETCH_NEARBY_SPOTS_SUCCESS] = (state, { nearbySpots }) => ({
  ...state,
  reverseSortBeachView: nearbySpots.associated.reverseSortBeachView,
  data: nearbySpots.data,
  units: nearbySpots.associated.units,
  loading: false,
});

handlers[FETCH_NEARBY_SPOTS_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

handlers[LEAVE_SPOT_ROUTE] = () => initialState;

export default createReducer(handlers, initialState);
