import React from 'react';
import { useTheme } from '@mui/material';

export const MultiCam = ({
  className,
  color,
}: {
  className?: string;
  color?: React.CSSProperties['color'];
}) => {
  const theme = useTheme();
  return (
    <svg
      width="30px"
      height="30px"
      viewBox="0 0 30 30"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <title>Multi Cam</title>
      <rect width="30" height="30" rx="15" fill={color || theme.palette.secondary.main} />
      <rect
        x="1.5"
        y="1.5"
        width="27"
        height="27"
        rx="13.5"
        stroke="white"
        fill={color || theme.palette.secondary.main}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.1182 13.0708C22.2847 12.9674 22.5 13.0872 22.5 13.2832V18.539C22.5 18.735 22.2847 18.8548 22.1182 18.7515L19.8333 17.3333V19.1934C19.8333 19.6389 19.5324 20 19.1612 20H11.1721C10.8009 20 10.5 19.6389 10.5 19.1934V12.8066C10.5 12.3611 10.8009 12 11.1721 12H19.1612C19.5324 12 19.8333 12.3611 19.8333 12.8066V14.4889L22.1182 13.0708Z"
        fill="#FAFAFA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.5 10C8.94772 10 8.5 10.4477 8.5 11V17.5C8.5 17.7761 8.72386 18 9 18C9.27614 18 9.5 17.7761 9.5 17.5V11H17C17.2761 11 17.5 10.7761 17.5 10.5C17.5 10.2239 17.2761 10 17 10H9.5Z"
        fill="#FAFAFA"
      />
    </svg>
  );
};

export default MultiCam;
