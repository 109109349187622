import { fetchSwellEventById, fetchTaxononmySwellEvents } from '../common/api/kbyg';

import type { AppDispatch } from '../stores';
import { SwellEvent } from '../types/swellEvents';
import sanitizeArticleHTML from '../utils/sanitizeArticleHTML';
import { redirectToNotFound } from './status';

export const FETCH_SWELL_EVENTS = 'FETCH_SWELL_EVENTS';
export const FETCH_SWELL_EVENTS_SUCCESS = 'FETCH_SWELL_EVENTS_SUCCESS';
export const FETCH_SWELL_EVENTS_FAILURE = 'FETCH_SWELL_EVENTS_FAILURE';

export const FETCH_SWELL_EVENT = 'FETCH_SWELL_EVENT';
export const FETCH_SWELL_EVENT_SUCCESS = 'FETCH_SWELL_EVENT_SUCCESS';
export const FETCH_SWELL_EVENT_FAILURE = 'FETCH_SWELL_EVENT_FAILURE';

export const fetchSwellEvents = (id: string, type: string) => async (dispatch: AppDispatch) => {
  dispatch({
    type: FETCH_SWELL_EVENTS,
  });

  try {
    const { data } = (await fetchTaxononmySwellEvents(id, type)) as {
      data: SwellEvent[];
    };
    dispatch({
      type: FETCH_SWELL_EVENTS_SUCCESS,
      events: data,
    });
  } catch (error) {
    dispatch({
      type: FETCH_SWELL_EVENTS_FAILURE,
      error: (error as Error).message,
    });
  }
};

export const fetchSwellEvent =
  (id: string, cookies: any, isServer = false) =>
  async (dispatch: AppDispatch) => {
    dispatch({
      type: FETCH_SWELL_EVENT,
    });

    try {
      const { event } = await fetchSwellEventById({ id, cookies, isServer });
      // Validate if the event exists if not will throw a 404
      if (Object.keys(event).length === 0) {
        dispatch(redirectToNotFound('The swell event was not found'));
      }
      const sanitizedEvent = {
        ...event,
        updates: event.updates.map((update) => ({
          ...update,
          content: sanitizeArticleHTML(update.content),
        })),
      };
      dispatch({
        type: FETCH_SWELL_EVENT_SUCCESS,
        event: sanitizedEvent,
      });
    } catch (error: any) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The swell event was not found'));
      } else {
        dispatch({
          type: FETCH_SWELL_EVENT_FAILURE,
          error,
        });
      }
    }
  };
