import React from 'react';
import classNames from 'classnames';
import Lottie from 'react-lottie-player';
import animationData from './animationData.json';

const getClass = (rootClass?: string) => classNames('sl-page-loading', rootClass);

interface Props {
  classes?: { root?: string };
}

const PageLoading: React.FC<Props> = ({ classes = {} }) => (
  <div className={getClass(classes.root)}>
    <Lottie
      loop
      play
      animationData={animationData}
      style={{ width: 64, height: 64 }}
      // TODO: Remove below when this is resolved: https://github.com/mifi/react-lottie-player/issues/70
      audioFactory={undefined}
      renderer={undefined}
      rendererSettings={undefined}
    />
  </div>
);

export default PageLoading;
