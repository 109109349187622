import type { SwellSpectraWaveDataPoint } from 'types/spectra';
import { round, isNumber } from 'lodash';
import { BuoySpectra1dData, BuoySpectraFormattedDataPoint } from 'types/buoys';

const getHighestIndex = (energyArray: Array<number | null>, maxOfEnergy: number) => {
  const percentageOfMaxDataPoint = 0.02;

  const highestIndex = energyArray.reduceRight(
    (index: number | null, value: number | null, i: number) => {
      if (index !== null) {
        return index;
      }
      if (value !== null && value / maxOfEnergy >= percentageOfMaxDataPoint) {
        return i;
      }
      return null;
    },
    null,
  );

  const updatedEnergy = highestIndex !== null ? energyArray.slice(0, highestIndex + 1) : [];
  return updatedEnergy.length;
};

const replaceRepeatedDataPointsWithNull = (
  hourlyDataArray:
    | Array<{
        period?: number;
        energy?: number;
        direction?: number;
        spread?: number;
      }>
    | undefined,
) => {
  const seenPeriods = new Set();
  return hourlyDataArray?.map((obj) => {
    if (seenPeriods.has(obj.period)) {
      return null;
    }
    seenPeriods.add(obj.period);
    return obj;
  });
};

const preFormattedDataArray = (currentDataPoint: BuoySpectra1dData) =>
  currentDataPoint?.period?.map((periodValue: number, periodValueIndex: number) => ({
    direction: currentDataPoint?.direction?.[periodValueIndex],
    spread: currentDataPoint?.spread?.[periodValueIndex],
    energy: currentDataPoint?.energy?.[periodValueIndex],
    period: round(periodValue, 0),
  }));

const createBucketedData = (
  periodArray: Array<number>,
  filteredData: Array<Partial<BuoySpectraFormattedDataPoint> | null> | undefined,
) => {
  const bucketedData = periodArray?.map((p: number) => {
    const dir = filteredData?.find((val) => val?.period === p)?.direction;
    const spr = filteredData?.find((val) => val?.period === p)?.spread;
    const eng = filteredData?.find((val) => val?.period === p)?.energy;

    return {
      direction: isNumber(dir) ? round(dir) : null,
      spread: isNumber(spr) ? round(spr) : null,
      energy: isNumber(eng) ? round(eng, 2) : null,
      period: p,
    };
  });

  return bucketedData;
};

const filteredBuoySwellSpectraHourData = (currentDataPoint: Array<BuoySpectra1dData>) => {
  const staticPeriodRange = Array.from({ length: 28 - 1 }, (_, index) => index + 2);
  const transformedSpectraHourData = currentDataPoint.map(
    (spectraDataObject: BuoySpectra1dData) => {
      const preFilteredData = preFormattedDataArray(spectraDataObject);
      const filteredData = replaceRepeatedDataPointsWithNull(preFilteredData);
      const bucketedData = createBucketedData(staticPeriodRange, filteredData);

      return bucketedData;
    },
  );

  return transformedSpectraHourData;
};

export const filteredBuoySwellSpectraHourDataCurrent = (
  currentDataPoint: BuoySpectra1dData,
  period: Array<number>,
) => {
  const preFilteredData = preFormattedDataArray(currentDataPoint);
  const filteredData = replaceRepeatedDataPointsWithNull(preFilteredData);
  const bucketedData = createBucketedData(period, filteredData);

  return bucketedData;
};

export const overallMaxBuoySpectraPeriod = (data: Array<BuoySpectra1dData>) => {
  const filteredArray = filteredBuoySwellSpectraHourData(data);
  const maxEnergyLengths = filteredArray?.map((dataPoint) => {
    const energyArray = dataPoint?.map((dataObject) => dataObject?.energy);
    const maxOfEnergy = Math.max(...(energyArray as number[]));
    return getHighestIndex(energyArray, maxOfEnergy);
  });

  return Math.max(...maxEnergyLengths);
};

const overallMaxPeriod = (data: Array<Partial<SwellSpectraWaveDataPoint>>) => {
  const maxEnergyLengths = data?.map((dataPoint) => {
    const energy = dataPoint?.energy || [0];
    const maxOfEnergy = Math.max(...energy);
    return getHighestIndex(energy, maxOfEnergy);
  });

  return Math.max(...maxEnergyLengths);
};

export default overallMaxPeriod;
