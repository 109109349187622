import { createReducer } from '@reduxjs/toolkit';
import {
  setActiveSpot,
  setScrollPosition,
  clearActiveSpot,
  scrollToSpot,
} from '../actions/animations';

interface AnimationState {
  activeSpotId?: string;
  spotListScrollAnchor?: string;
  scrollPositions: {
    spotList: {
      top: number;
      left: number;
    };
    camList: {
      top: number;
      left: number;
    };
    topSpots: {
      top: null;
      left: number;
    };
  };
}

export const initialState: AnimationState = {
  scrollPositions: {
    spotList: {
      top: 0,
      left: 0,
    },
    camList: {
      top: 0,
      left: 0,
    },
    topSpots: {
      top: null,
      left: 0,
    },
  },
};

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(setActiveSpot, (state, action) => ({
      ...state,
      activeSpotId: action.payload,
    }))
    .addCase(clearActiveSpot, (state) => ({
      ...state,
      activeSpotId: undefined,
    }))
    .addCase(scrollToSpot, (state, action) => ({
      ...state,
      spotListScrollAnchor: action.payload,
      activeSpotId: action.payload,
    }))
    .addCase(setScrollPosition, (state, action) => ({
      ...state,
      scrollPositions: {
        ...state.scrollPositions,
        [action.payload.component]: action.payload.scrollPosition,
      },
    })),
);

export default reducer;
