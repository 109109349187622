import React from 'react';

export const InfoIcon: React.FC<{ color?: string }> = ({ color = '#424242' }) => (
  <svg fill="none" height="14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" width="14">
    <circle cx="7" cy="7.00024" r="6.5" stroke={color} />
    <rect x="6" y="5.50024" width="2" height="5" rx="1" fill={color} />
    <rect x="6" y="3.00024" width="2" height="2" rx="1" fill={color} />
  </svg>
);

export default InfoIcon;
