import { createReducer } from '@reduxjs/toolkit';
import {
  FETCH_SWELL_EVENTS,
  FETCH_SWELL_EVENTS_SUCCESS,
  FETCH_SWELL_EVENTS_FAILURE,
  FETCH_SWELL_EVENT,
  FETCH_SWELL_EVENT_SUCCESS,
  FETCH_SWELL_EVENT_FAILURE,
} from '../actions/swellEvents';
import { SwellEvent } from '../types/swellEvents';

interface SwellEventsState {
  events: SwellEvent[];
  loading: boolean;
  error: string | null;
  success: boolean;
  allEvents: {
    events: SwellEvent[];
    error: string | null;
    loading: boolean;
    success: boolean;
  };
  activeEvent: {
    loading: boolean;
    error: string | null;
    event: SwellEvent | null;
    success: boolean;
  };
}

export const initialState: SwellEventsState = {
  error: null,
  events: [],
  loading: false,
  success: false,
  activeEvent: {
    loading: false,
    error: null,
    event: null,
    success: false,
  },
  allEvents: {
    events: [],
    error: null,
    loading: false,
    success: false,
  },
};

const reducer = createReducer(initialState, (builder) => {
  builder.addCase(FETCH_SWELL_EVENTS, () => ({
    ...initialState,
    loading: true,
  }));
  builder.addCase<
    typeof FETCH_SWELL_EVENTS_SUCCESS,
    { events: SwellEvent[]; type: typeof FETCH_SWELL_EVENTS_SUCCESS }
  >(FETCH_SWELL_EVENTS_SUCCESS, (state, { events }) => ({
    ...state,
    events,
    loading: false,
    success: true,
  }));

  builder.addCase<
    typeof FETCH_SWELL_EVENTS_FAILURE,
    { error: string; type: typeof FETCH_SWELL_EVENTS_FAILURE }
  >(FETCH_SWELL_EVENTS_FAILURE, (state, { error }) => ({
    ...state,
    loading: false,
    error,
  }));
  builder.addCase(FETCH_SWELL_EVENT, (state) => ({
    ...initialState,
    activeEvent: {
      ...state.activeEvent,
      loading: true,
      success: initialState.activeEvent.success,
    },
  }));
  builder.addCase<
    typeof FETCH_SWELL_EVENT_SUCCESS,
    { event: SwellEvent[]; type: typeof FETCH_SWELL_EVENT_SUCCESS }
  >(FETCH_SWELL_EVENT_SUCCESS, (state, { event }) => ({
    ...state,
    activeEvent: {
      ...state.activeEvent,
      loading: false,
      success: true,
      // @ts-ignore
      event,
    },
  }));

  builder.addCase<
    typeof FETCH_SWELL_EVENT_FAILURE,
    { error: string; type: typeof FETCH_SWELL_EVENT_FAILURE }
  >(FETCH_SWELL_EVENT_FAILURE, (state, { error }) => ({
    ...state,
    activeEvent: {
      ...state.activeEvent,
      loading: false,
      success: false,
      error,
    },
  }));
});

export default reducer;
