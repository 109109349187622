import { createReducer } from '@reduxjs/toolkit';
import Taxonomy from 'components/WorldTaxonomy';
import { FETCH_WORLD_TAXONOMY_SUCCESS } from '../actions/worldTaxonomy';

export const initialState: (typeof Taxonomy)[] = [];

export default createReducer(initialState, (builder) => {
  builder.addCase<
    typeof FETCH_WORLD_TAXONOMY_SUCCESS,
    { taxonomy: (typeof Taxonomy)[]; type: typeof FETCH_WORLD_TAXONOMY_SUCCESS }
  >(FETCH_WORLD_TAXONOMY_SUCCESS, (_, { taxonomy }) => taxonomy);
});
