import React from 'react';

const CloseMediaPlayerIcon = () => (
  <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1">
    <g strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d={`M13.5295641,13 L17.7659656,8.76359846 C17.9103371,8.619227 17.9123118,
          8.38015841 17.7660767,8.23392333 C17.6241183,8.09196499 17.382698,
          8.08773797 17.2364015,8.23403439 L13,12.4704359 L8.76359846,8.23403439 C8.619227,
          8.08966293 8.38015841,8.08768824 8.23392333,8.23392333 C8.09196499,
          8.37588166 8.08773797,8.61730204 8.23403439,8.76359846 L12.4704359,
          13 L8.23403439,17.2364015 C8.08966293,17.380773 8.08768824,17.6198416 8.23392333,
          17.7660767 C8.37588166,17.908035 8.61730204,17.912262 8.76359846,17.7659656 L13,
          13.5295641 L17.2364015,17.7659656 C17.380773,17.9103371 17.6198416,17.9123118 17.7660767,
          17.7660767 C17.908035,17.6241183 17.912262,17.382698 17.7659656,17.2364015 L13.5295641,
          13 L13.5295641,13 Z`}
      />
    </g>
  </svg>
);

export default CloseMediaPlayerIcon;
