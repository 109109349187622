import { getUserSettings, getWavetrakIdentity } from '@surfline/web-common';

const getFeedGeotarget = (state) => {
  let geotarget = 'US';
  const identity = getWavetrakIdentity();
  if (identity?.isoCountryCode) {
    geotarget = identity.isoCountryCode;
  }
  const userSettings = getUserSettings(state);
  const localized = userSettings?.feed?.localized;
  if (localized) {
    geotarget = localized !== 'LOCALIZED' ? localized : geotarget;
  }
  return geotarget;
};

export default getFeedGeotarget;
