import React from 'react';

export const Instagram = () => (
  <svg
    className="quiver-instagram-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <title>Instagram Logo</title>
    <path
      d={`M9.5,1.71414683 C12.0359722,1.71414683 12.3360516,1.72394841 13.3376984,1.76956349
        C14.2635714,1.81178571 14.7664683,1.96634921 15.1012302,2.09640873 C15.5141722,2.24842737
        15.8877594,2.49113026 16.1944841,2.80664683 C16.5100007,3.11337154 16.7527036,3.4869588
        16.9047222,3.89990079 C17.0347817,4.2346627 17.1893452,4.73755952 17.2315675,5.66343254
        C17.2771825,6.66470238 17.2869841,6.96515873 17.2869841,9.50113095 C17.2869841,12.0371032
        17.2771825,12.3371825 17.2315675,13.3388294 C17.1893452,14.2647024 17.0347817,14.7675992
        16.9047222,15.1023611 C16.5852112,15.9305812 15.9305812,16.5852112 15.1023611,16.9047222
        C14.7675992,17.0347817 14.2647024,17.1893452 13.3388294,17.2315675 C12.3375595,17.2771825
        12.0371032,17.2869841 9.50113095,17.2869841 C6.96515873,17.2869841 6.66470238,17.2771825
        5.66343254,17.2315675 C4.73755952,17.1893452 4.2346627,17.0347817 3.89990079,16.9047222
        C3.4869588,16.7527036 3.11337154,16.5100007 2.80664683,16.1944841 C2.49113026,15.8877594
        2.24842737,15.5141722 2.09640873,15.1012302 C1.96634921,14.7664683 1.81178571,14.2635714
        1.76956349,13.3376984 C1.72394841,12.3364286 1.71414683,12.0359722 1.71414683,9.5
        C1.71414683,6.96402778 1.72394841,6.66394841 1.76956349,5.66230159 C1.81178571,4.73642857
        1.96634921,4.23353175 2.09640873,3.89876984 C2.24842737,3.48582785 2.49113026,3.11224059
        2.80664683,2.80551587 C3.11337154,2.48999931 3.4869588,2.24729641 3.89990079,2.09527778
        C4.2346627,1.96521825 4.73755952,1.81065476 5.66343254,1.76843254 C6.66470238,1.72281746
        6.96515873,1.71301587 9.50113095,1.71301587 L9.5,1.71414683 Z M9.50113095,0.00188492063
        C6.92180556,0.00188492063 6.59835317,0.0128174603 5.58539683,0.0591865079 C4.57244048,
        0.105555556 3.88293651,0.266904762 3.2797619,0.501388889 C2.64579524,0.739793363
        2.07148474,1.11366126 1.59690476,1.59690476 C1.11366126,2.07148474 0.739793363,2.64579524
        0.501388889,3.2797619 C0.266904762,3.88293651 0.106309524,4.57357143 0.0603174603,
        5.58426587 C0.0143253968,6.59496032 0.00301587302,6.9206746 0.00301587302,9.5
        C0.00301587302,12.0793254 0.0139484127,12.4027778 0.0603174603,13.4157341 C0.106686508,
        14.4286905 0.266904762,15.1170635 0.501388889,15.7202381 C0.739890442,16.3539351
        1.11375274,16.9279796 1.59690476,17.4023413 C2.07144755,17.8857209 2.64576085,18.2597192
        3.2797619,18.4982341 C3.88293651,18.7330952 4.5743254,18.8936905 5.58501984,18.9396825
        C6.59571429,18.9856746 6.92142857,18.9969841 9.50075397,18.9969841 C12.0800794,18.9969841
        12.4035317,18.9860516 13.4164881,18.9396825 C14.4294444,18.8933135 15.1170635,18.7330952
        15.7202381,18.4982341 C16.9961817,18.0048473 18.0048473,16.9961817 18.4982341,15.7202381
        C18.7330952,15.1170635 18.8936905,14.4256746 18.9396825,13.4149802 C18.9856746,12.4042857
        18.9969841,12.0785714 18.9969841,9.49924603 C18.9969841,6.91992063 18.9860516,6.59646825
        18.9396825,5.5835119 C18.8933135,4.57055556 18.7330952,3.88293651 18.4982341,3.2797619
        C18.2597326,2.64606494 17.8858703,2.07202036 17.4027183,1.59765873 C16.9283218,1.11418878
        16.3541387,0.740062727 15.7202381,0.501388889 C15.1170635,0.266904762 14.4264286,
        0.106309524 13.4157341,0.0603174603 C12.4050397,0.0143253968 12.0793254,0.00301587302
        9.5,0.00301587302 L9.50113095,0.00188492063 Z`}
    />
    <path
      d={`M9.5,4.62333333 C6.80669137,4.62333333 4.62333333,6.80669137 4.62333333,9.5 C4.62333333,
        12.1933086 6.80669137,14.3766667 9.5,14.3766667 C12.1933086,14.3766667 14.3766667,
        12.1933086 14.3766667,9.5 C14.3766667,8.20662758 13.8628765,6.96622834 12.9483241,
        6.05167593 C12.0337717,5.13712352 10.7933724,4.62333333 9.5,4.62333333 Z M9.5,12.6666667
        C7.75109829,12.6666667 6.33333333,11.2489017 6.33333333,9.5 C6.33333333,7.75109829
        7.75109829,6.33333333 9.5,6.33333333 C11.2489017,6.33333333 12.6666667,7.75109829
        12.6666667,9.5 C12.6666667,10.3398522 12.3330367,11.1453063 11.7391715,11.7391715
        C11.1453063,12.3330367 10.3398522,12.6666667 9.5,12.6666667 Z`}
    />
    <circle id="Oval" cx="14.5696825" cy="4.43031746" r="1.13962302" />
  </svg>
);

export default Instagram;
