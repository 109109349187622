import { trackEvent, getUserFavorites } from '@surfline/web-common';

import { fetchFavoriteSubregions } from 'common/api/region';
import { fetchNearbySpots } from 'common/api/spot';
import { getNumCams, getPrimarySpotIndex, getCollections } from 'selectors/multiCam';
import { getMultiCamPreferences } from 'utils/multiCamLocalStorage';

export const SUBREGIONS = 'subregions';
export const COLLECTIONS = 'collections';
export const COLLECTION_TYPES = [
  { name: 'Collections', type: COLLECTIONS, rank: 1 },
  { name: 'Favorite Regions', type: SUBREGIONS, rank: 2 },
];

export const FETCH_COLLECTION_SPOTS = 'FETCH_COLLECTION_SPOTS';
export const FETCH_COLLECTION_SPOTS_SUCCESS = 'FETCH_COLLECTION_SPOTS_SUCCESS';
export const FETCH_COLLECTION_SPOTS_FAILURE = 'FETCH_COLLECTION_SPOTS_FAILURE';

/**
 * @description Fetches the spots that are in a given collection based on it's type
 * @param {Object} collection
 * @param {string} collection._id
 * @param {"subregions"|"collections"} collection.type
 */
export const fetchCollectionSpots = (collection) => async (dispatch, getState) => {
  const collections = getCollections(getState());
  const collectionIndex = collections.findIndex(({ _id }) => _id === collection._id);
  dispatch({
    type: FETCH_COLLECTION_SPOTS,
    collectionIndex,
  });

  try {
    if (collection.type === SUBREGIONS) {
      const response = await fetchNearbySpots(collection.primarySpot);
      dispatch({
        type: FETCH_COLLECTION_SPOTS_SUCCESS,
        spots: response.data.spots,
        collectionIndex,
      });
    }
  } catch (error) {
    dispatch({
      type: FETCH_COLLECTION_SPOTS_FAILURE,
      collectionIndex,
      error: 'Something went wrong retrieving the spots for the collection',
    });
  }
};

export const SET_ACTIVE_COLLECTION = 'SET_ACTIVE_COLLECTION';

export const FETCH_COLLECTIONS = 'FETCH_COLLECTIONS';
export const FETCH_COLLECTIONS_FAILURE = 'FETCH_COLLECTIONS_FAILURE';
export const FETCH_COLLECTIONS_SUCCESS = 'FETCH_COLLECTIONS_SUCCESS';

/**
 * Sets the currently active collection based off user selection
 * @param {{
 *  name: String
 *  spots: Array<{}>
 * }} collection
 */
export const setActiveCollection = (collection) => (dispatch) => {
  if (!collection?.fetchedSpots) {
    dispatch(fetchCollectionSpots(collection));
  }

  dispatch({
    type: SET_ACTIVE_COLLECTION,
    active: collection._id,
  });
};

/**
 * Action that sets the default collection to be shown to the user
 * when they first land on the multi cam page.
 * @param {Array<{}>} favorites The users favorites
 * @returns {Function} dispatch function
 */
export const fetchCollections = (favorites, cookies) => async (dispatch, getState) => {
  dispatch({
    type: FETCH_COLLECTIONS,
    collectionTypes: COLLECTION_TYPES,
  });

  try {
    const allFavorites = {
      _id: 'all-favorites',
      name: 'All Favorites',
      loading: false,
      type: COLLECTIONS,
      error: false,
      fetchedSpots: true,
      spots: favorites || getUserFavorites(getState()),
    };

    const { subregions } = await fetchFavoriteSubregions(cookies);
    const subregionCollections = subregions.map((subregion) => ({
      ...subregion,
      type: SUBREGIONS,
      loading: false,
      error: false,
      fetchedSpots: false,
      spots: [],
    }));

    const collections = [allFavorites, ...subregionCollections];

    return dispatch({
      type: FETCH_COLLECTIONS_SUCCESS,
      active: allFavorites._id,
      collections,
    });
  } catch (err) {
    console.log(err);
    return dispatch({
      type: FETCH_COLLECTIONS_FAILURE,
      error: 'Something went wrong retrieving the collections',
    });
  }
};

export const SET_SELECTED_SPOTS = 'SET_SELECTED_SPOTS';

export const setSelectedSpots = (collection) => (dispatch) => {
  const { spots } = collection;

  const multiCamPreferences = getMultiCamPreferences();

  const selectedSpots = multiCamPreferences[collection._id]
    ? multiCamPreferences[collection._id].map((spotId) => {
        if (!spotId) return null;

        // Check if the spot saved in local storage exists in the current collection
        const [id, selectedCamAngleIndex] = spotId.split(':');
        const matchingSpot = spots?.find((spot) => spot._id === id);

        if (!matchingSpot) {
          return null;
        }

        return {
          ...spots?.find((spot) => spot._id === id),
          ...(selectedCamAngleIndex && { selectedCamAngleIndex }),
        };
      })
    : [...spots.slice(0, 9)];

  const numCams = multiCamPreferences?.numCams?.[collection._id];

  dispatch({
    type: SET_SELECTED_SPOTS,
    selectedSpots,
    numCams,
  });
};

export const CHANGE_PRIMARY_SPOT_INDEX = 'CHANGE_PRIMARY_SPOT_INDEX';

/**
 * @description Action used to update the currently selected "primary"
 * spot within in the selected spots in the multi cam experience
 *
 * @param {number} spotIndex
 */
export const changePrimarySpotIndex = (newIndex) => ({
  type: CHANGE_PRIMARY_SPOT_INDEX,
  // Ensure that we never get a string value for this
  newIndex: parseInt(newIndex, 10),
});

/**
 * @description Action used to decrement the selected "primary"
 * spot within in the selected spots in the multi cam experience
 */
export const decrementPrimarySpotIndex = () => (dispatch, getState) => {
  const state = getState();
  const primarySpotIndex = getPrimarySpotIndex(state);

  const isFirstCamPrimary = primarySpotIndex === 0;

  if (!isFirstCamPrimary) {
    dispatch({
      type: CHANGE_PRIMARY_SPOT_INDEX,
      newIndex: primarySpotIndex - 1,
    });
  }
};

/**
 * @description Action used to increment the selected "primary"
 * spot within in the selected spots in the multi cam experience
 */
export const incrementPrimarySpotIndex = () => (dispatch, getState) => {
  const state = getState();
  const numCams = getNumCams(state);
  const primarySpotIndex = getPrimarySpotIndex(state);

  const isLastCamPrimary = primarySpotIndex === numCams - 1;

  if (!isLastCamPrimary) {
    dispatch({
      type: CHANGE_PRIMARY_SPOT_INDEX,
      newIndex: primarySpotIndex + 1,
    });
  }
};

export const CHANGE_PRIMARY_SPOT = 'CHANGE_PRIMARY_SPOT';

export const changePrimarySpot = (newSpot) => (dispatch) => {
  dispatch({
    type: CHANGE_PRIMARY_SPOT,
    newSpot,
  });

  trackEvent('Changed Cam Selection', {
    category: 'cams & reports',
    pageName: 'multi-cam',
    spotId: newSpot._id,
    spotName: newSpot.name,
    camId: newSpot.cameras[0]?._id,
    camName: newSpot.cameras[0]?.title,
    subregionId: newSpot.subregionId,
    conditions: newSpot.conditions?.value,
    human: newSpot.conditions?.human,
    hasCam: !!newSpot.cameras.length,
    premiumCam: newSpot.cameras[0]?.isPremium,
    waveHeightMin: newSpot.waveHeight?.min,
    waveHeightMax: newSpot.waveHeight?.max,
  });
};

export const SWAP_PRIMARY_SPOT = 'SWAP_PRIMARY_SPOT';
/**
 * @description In the case that a user selects an already selectedSpot for
 * the primary cam, we want to just swap these spots in the `selectedSpots`
 * array
 *
 * @param {number} newSpotIndex The index to swap into the `selectedSpots`
 * array at the `primarySpotIndex` index
 */
export const swapPrimarySpot = (newSpotIndex) => ({
  type: SWAP_PRIMARY_SPOT,
  newSpotIndex,
});

export const SET_NUM_CAMS = 'SET_NUM_CAMS';
/**
 * @description Sets the number of cameras to display on the multi
 * cam page.
 * @param {number} numCams
 */
export const setNumCams = (numCams) => ({
  type: SET_NUM_CAMS,
  numCams,
});

export const SET_CAM_ANGLE_INDEX = 'SET_CAM_ANGLE_INDEX';

/**
 * When selecting a new cam angle, saves it to the spot inside the `selectedSpots` array in the store
 * @param {number} selectedSpotIndex
 * @param {number} camAngleIndex
 */
export const setCamAngleIndex = (selectedSpotIndex, camAngleIndex) => ({
  type: SET_CAM_ANGLE_INDEX,
  camAngleIndex,
  selectedSpotIndex,
});
