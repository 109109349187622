import React, { useMemo } from 'react';
import classNames from 'classnames';
import { isNil } from 'lodash';
import { useRouter } from 'next/router';
import { Box, Typography } from '@mui/material';

import { CamIcon, CameraInsightsIcon } from 'components/Icons';
import SurfConditions from 'components/SurfConditions';
import WavetrakLink from 'components/WavetrakLink';
import config from 'config';
import type { TaxonomyNode } from 'types/header';

import { SL_PREMIUM_PLUS } from 'common/treatments';
import { useTreatments } from 'utils/treatments';
import generateSlug from '../../../helpers/generateSlug';
import spotPagePath from '../../../helpers/spotPagePath';

import styles from './RenderSpot.module.scss';

interface Spot extends TaxonomyNode {
  cameras?: Array<string>;
  conditions?: {
    human?: boolean;
    value?: string;
  };
  hasCamera?: boolean;
  lastOfGroup?: boolean;
  insightsCameraId?: string;
}

interface RenderSpotProps {
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
  spot: Spot;
}

const RenderSpot = ({ onClickLink, spot }: RenderSpotProps) => {
  const treatments = useTreatments();
  const showCameraInsightsIcon = treatments[SL_PREMIUM_PLUS] === 'on';

  const getClassName = () =>
    classNames(styles.renderSpot, {
      [styles['renderSpot--lastOfGroup']]: spot?.lastOfGroup,
    });

  const getHref = ({ associated, _id, name }: Spot) => {
    if (associated) {
      // remove absolute path and make relative
      const href = associated.links.find((link) => link.key === 'www')?.href;
      return href?.replace(config.surflineHost, '');
    }
    const slug = generateSlug(name || '');
    return spotPagePath(_id || '', slug);
  };

  const isMultiCam = spot.cameras && spot.cameras.length > 1;
  const router = useRouter();
  const href = useMemo(() => getHref(spot), [spot]);
  const isExternal = useMemo(
    () => !isNil(href) && router.asPath.includes?.(href),
    [href, router.asPath],
  );

  const onClickHandler = () => {
    if (onClickLink) {
      onClickLink(
        {
          clickEndLocation: 'Spot',
          destinationUrl: getHref(spot),
          completion: true,
          linkName: spot.name,
        },
        true,
      );
    }
  };

  return (
    <div className={getClassName()}>
      <SurfConditions conditions={spot?.conditions?.value ?? 'NONE'} />
      <Typography
        component={WavetrakLink}
        href={getHref(spot) || ''}
        isExternal={isExternal}
        onClick={onClickHandler}
        variant="body2"
      >
        {spot.name}
      </Typography>
      {spot.insightsCameraId && showCameraInsightsIcon && (
        <Box className={styles.insightsIcon}>
          <CameraInsightsIcon />
        </Box>
      )}
      {(spot?.hasCamera || (spot?.cameras && spot.cameras.length > 0)) &&
        !spot.insightsCameraId && (
          <div className={styles.iconWrapper}>
            <CamIcon className={styles.icon} isMultiCam={isMultiCam} withText />
          </div>
        )}
    </div>
  );
};

export default RenderSpot;
