import { AnyAction, Reducer } from 'redux';
import {
  FETCH_CAMERA_SPOT,
  FETCH_CAMERA_SPOT_SUCCESS,
  FETCH_CAMERA_SPOT_FAILURE,
} from '../actions/cameras';
import createReducer from './createReducer';

export const initialState = {
  error: null,
  loading: false,
  spot: null,
};

const handlers: { [key: string]: Reducer<any, AnyAction> } = {};

handlers[FETCH_CAMERA_SPOT] = () => ({
  ...initialState,
  loading: true,
});

handlers[FETCH_CAMERA_SPOT_SUCCESS] = (state, { spot }) => ({
  ...state,
  spot,
  loading: false,
});

handlers[FETCH_CAMERA_SPOT_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

export default createReducer(handlers, initialState);
