import React from 'react';

interface Props {
  className?: string;
}

export const SurfIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask id="mask0_6_1844" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
      <circle cx="20" cy="20" r="20" fill="#C4C4C4" />
    </mask>
    <g mask="url(#mask0_6_1844)">
      <circle cx="20" cy="20" r="19" stroke="#171717" strokeWidth="2" />
      <rect x="28.333" y="30.8335" width="11.6667" height="1.66667" rx="0.833333" fill="#171717" />
      <rect x="28.333" y="34.1665" width="11.6667" height="1.66667" rx="0.833333" fill="#171717" />
      <rect x="28.333" y="27.5" width="11.6667" height="1.66667" rx="0.833333" fill="#171717" />
      <rect x="28.333" y="24.1665" width="11.6667" height="1.66667" rx="0.833333" fill="#171717" />
      <rect x="28.333" y="20.8335" width="11.6667" height="1.66667" rx="0.833333" fill="#171717" />
      <rect x="28.333" y="17.5" width="11.6667" height="1.66667" rx="0.833333" fill="#171717" />
      <rect
        opacity="0.3"
        x="28.333"
        y="14.1665"
        width="11.6667"
        height="1.66667"
        rx="0.833333"
        fill="#171717"
      />
      <rect
        opacity="0.3"
        x="28.333"
        y="10.8335"
        width="11.6667"
        height="1.66667"
        rx="0.833333"
        fill="#171717"
      />
      <rect
        opacity="0.3"
        x="28.333"
        y="7.5"
        width="11.6667"
        height="1.66667"
        rx="0.833333"
        fill="#171717"
      />
      <rect
        opacity="0.3"
        x="28.333"
        y="4.1665"
        width="11.6667"
        height="1.66667"
        rx="0.833333"
        fill="#171717"
      />
      <path
        d="M16.6663 18.3335C4.91978 18.5719 2.08301 35.4168 2.08301 35.4168V42.0835H34.1663V35.4168C34.1663 35.4168 11.6663 40.8335 16.6663 18.3335Z"
        fill="#171717"
        stroke="#171717"
        strokeWidth="2"
      />
    </g>
  </svg>
);

export default SurfIcon;
