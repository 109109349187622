import React, { FC, useMemo } from 'react';
import { isNil } from 'lodash';
import { useLocalStorage, useMount } from 'react-use';

import SwellEventCard from 'components/SwellEventCard/SwellEventCard';
import type { SwellEvent } from 'types/swellEvents';
import { oneDay } from 'utils/time';

import styles from './SwellEventCardContainer.module.scss';

export const LOCAL_STORAGE_KEY = 'sl-event-cards';

const hasTimeExceeded = (date: Date) => {
  const timestamp = new Date(date);
  const currentTime = new Date();
  if (currentTime.getTime() >= timestamp.getTime() + oneDay) {
    return true;
  }
  return false;
};

interface Props {
  events: SwellEvent[];
  onClickCard?: (event: SwellEvent) => void;
}

const SwellEventCardContainer: FC<Props> = ({ events = [], onClickCard }) => {
  const [closedCards, setClosedCards] = useLocalStorage(LOCAL_STORAGE_KEY);

  const closeCardsParsed = useMemo(() => {
    if (!isNil(closedCards)) {
      try {
        return JSON.parse(closedCards?.toString() ?? '');
      } catch (_) {
        return undefined;
      }
    }
    return undefined;
  }, [closedCards]);

  const expandedEvents = useMemo(
    () =>
      isNil(closeCardsParsed)
        ? []
        : events.filter(
            (event) =>
              isNil(closeCardsParsed?.[event.id]) || hasTimeExceeded(closeCardsParsed?.[event.id]),
          ),
    [closeCardsParsed, events],
  );

  const onCloseHandler = (event: SwellEvent) => {
    const closedCardsUpdate = Object.fromEntries(
      Object.entries({ ...closeCardsParsed, [event?.id]: new Date().toISOString() }).filter(
        ([, value]) => !hasTimeExceeded(value as Date),
      ),
    );
    setClosedCards(JSON.stringify(closedCardsUpdate));
  };

  useMount(() => {
    if (isNil(closeCardsParsed)) {
      setClosedCards('{}');
    }
  });

  return (
    <div className={styles.container}>
      {expandedEvents.map((event) => (
        <div className={styles.swellEventCardWrapper} key={event.id}>
          <SwellEventCard swellEvent={event} onClickCard={onClickCard} onClose={onCloseHandler} />
        </div>
      ))}
    </div>
  );
};

export default SwellEventCardContainer;
