import { transformGraphDataToDays as transformDataToDays } from '@surfline/web-common';

import {
  FETCH_GRAPH_SWELL_SPECTRA_FORECAST,
  FETCH_GRAPH_SWELL_SPECTRA_FORECAST_SUCCESS,
  FETCH_GRAPH_SWELL_SPECTRA_FORECAST_FAILURE,
  RESET_GRAPH_SWELL_SPECTRA_FORECAST,
} from 'actions/graphs';
import type {
  SwellSpectraResponse,
  SwellSpectraData,
  SwellSpectraWaveDataPoint,
} from 'types/spectra';

import overallMaxPeriod from 'utils/swellSpectraUtils';

import createReducer from '../../createReducer';
import createDayHourlyData from './helpers/createDayHourlyData';

export const initialState = {
  error: null,
  loading: true,
  utcOffset: null,
  period: null,
  overallMaxPeriod: null,
  days: null,
  runInitializationTimestamp: null as null | number,
};

const handlers: any = {};

handlers[RESET_GRAPH_SWELL_SPECTRA_FORECAST] = () => initialState;
handlers[FETCH_GRAPH_SWELL_SPECTRA_FORECAST] = () => initialState;

handlers[FETCH_GRAPH_SWELL_SPECTRA_FORECAST_SUCCESS] = (
  state: SwellSpectraData,
  {
    response,
    desired16DayInterval,
  }: {
    response: SwellSpectraResponse;
    desired16DayInterval: number;
  },
): SwellSpectraData => {
  const {
    data: { wave, period },
    associated: { runInitializationTimestamp },
  } = response;

  const swellSpectraData: Array<SwellSpectraWaveDataPoint> = wave?.map(
    ({ timestamp, utcOffset, energy, direction, spread }) => ({
      timestamp,
      utcOffset,
      energy,
      direction,
      spread,
    }),
  );

  const mapFunc = (p: any) => ({
    ...p,
  });

  return {
    ...state,
    loading: false,
    utcOffset: swellSpectraData?.[0]?.utcOffset,
    period,
    runInitializationTimestamp,
    overallMaxPeriod: overallMaxPeriod(wave),
    days:
      desired16DayInterval === 1 || desired16DayInterval === 3
        ? createDayHourlyData(swellSpectraData, 16, desired16DayInterval, mapFunc)
        : transformDataToDays(
            swellSpectraData.filter((_, index) => index % desired16DayInterval === 0),
            desired16DayInterval,
            mapFunc,
          ),
  };
};

handlers[FETCH_GRAPH_SWELL_SPECTRA_FORECAST_FAILURE] = (
  state: SwellSpectraData,
  { error }: { error: any },
): SwellSpectraData => ({
  ...state,
  error,
  loading: false,
});

export default createReducer(handlers, initialState);
