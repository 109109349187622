import { AnyAction, Reducer } from 'redux';
import createReducer from './createReducer';

import {
  FETCH_TOP_SPOTS,
  FETCH_TOP_SPOTS_SUCCESS,
  FETCH_TOP_SPOTS_FAILURE,
} from '../actions/topSpots';

const initialState = {
  loading: false,
  error: null,
  topSpots: [],
  units: null,
};

const handlers: { [key: string]: Reducer<any, AnyAction> } = {};

handlers[FETCH_TOP_SPOTS] = (state) => ({
  ...state,
  loading: true,
});

handlers[FETCH_TOP_SPOTS_SUCCESS] = (state, { topSpots }) => ({
  ...state,
  loading: false,
  topSpots,
});

handlers[FETCH_TOP_SPOTS_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

export default createReducer(handlers, initialState);
