import { useRouter } from 'next/router';
import { getWindow, trackNavigatedToPage } from '@surfline/web-common';
import { useMount, useUnmount } from 'react-use';

const useSetupMapPage = () => {
  const { asPath } = useRouter();
  const win = getWindow();

  useMount(() => {
    trackNavigatedToPage('Map', {
      url: `${win?.location?.href ?? ''}${asPath}`,
      path: asPath,
      name: 'Map',
    });

    if (win) win.document.body.classList.add('sl-hide-ze-widget', 'sl-map-page', 'sl-map-v2');
  });

  useUnmount(() => {
    if (win) win.document.body.classList.remove('sl-hide-ze-widget', 'sl-map-page', 'sl-map-v2');
  });
};

export default useSetupMapPage;
