import createReducer from './createReducer';
import {
  FETCH_FORECAST_ARTICLE,
  FETCH_FORECAST_ARTICLE_SUCCESS,
  FETCH_FORECAST_ARTICLE_FAILURE,
  FETCH_FORECAST_ARTICLES,
  FETCH_FORECAST_ARTICLES_SUCCESS,
  FETCH_FORECAST_ARTICLES_FAILURE,
  FETCH_SUBREGION_FORECAST,
  FETCH_SUBREGION_FORECAST_SUCCESS,
  FETCH_SUBREGION_FORECAST_FAILURE,
} from '../actions/forecast';

export const initialState = {
  loading: false,
  error: false,
  success: false,
  associated: null,
  overview: null,
  subregions: [],
  conditions: null,
  activeArticle: {
    loading: false,
    error: null,
    article: null,
    success: false,
  },
  articles: {
    regional: [],
    error: null,
    loading: false,
    nextStart: null,
    pageCount: 0,
  },
};

const handlers = {};

handlers[FETCH_FORECAST_ARTICLE] = (state) => ({
  ...state,
  activeArticle: {
    ...state.activeArticle,
    loading: true,
    success: initialState.activeArticle.success,
  },
});

handlers[FETCH_FORECAST_ARTICLE_SUCCESS] = (state, { article }) => ({
  ...state,
  activeArticle: {
    ...state.activeArticle,
    loading: false,
    success: true,
    article,
  },
});

handlers[FETCH_FORECAST_ARTICLE_FAILURE] = (state, { error }) => ({
  ...state,
  activeArticle: {
    ...state.activeArticle,
    loading: false,
    success: false,
    error,
  },
});

handlers[FETCH_SUBREGION_FORECAST] = (state) => ({
  ...state,
  loading: true,
  error: false,
});

handlers[FETCH_SUBREGION_FORECAST_SUCCESS] = (state, { forecast, overview, subregions }) => ({
  ...state,
  loading: false,
  error: false,
  success: true,
  associated: overview.associated,
  overview: overview.data,
  conditions: forecast.data.conditions,
  subregions,
});

handlers[FETCH_SUBREGION_FORECAST_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
  success: false,
});

handlers[FETCH_FORECAST_ARTICLES] = (state) => ({
  ...state,
  articles: {
    ...state.articles,
    loading: true,
  },
});

handlers[FETCH_FORECAST_ARTICLES_SUCCESS] = (state, { articles: { associated, data } }) => ({
  ...state,
  articles: {
    ...state.articles,
    regional: state.articles.regional.concat(data.regional),
    loading: false,
    nextStart: associated.nextStart,
    pageCount: state.articles.pageCount + 1,
  },
});

handlers[FETCH_FORECAST_ARTICLES_FAILURE] = (state, { error }) => ({
  ...state,
  articles: {
    ...state.articles,
    loading: false,
    error,
  },
});

export default createReducer(handlers, initialState);
