import { isPremiumPlusUser, isPremiumUser } from 'utils/user';

const isPremiumOrVIP = (entitlements: Array<string> | undefined) => {
  if (entitlements && entitlements.length) {
    return (
      isPremiumUser(entitlements) ||
      isPremiumPlusUser(entitlements) ||
      entitlements.includes('sl_youth') ||
      entitlements.includes('sl_vip') ||
      entitlements.includes('sl_vip_advertiser')
    );
  }
  return false;
};

export default isPremiumOrVIP;
