export const camsAndForecastsPaths = {
  base: '/surf-reports-forecasts-cams',
  map: 'map',
};

export const mapPath = ({ lat, lon }, zoom) =>
  `${camsAndForecastsPaths.base}-${camsAndForecastsPaths.map}/@${lat},${lon},${zoom}z`;

export const geonameMapPath = ({ slug, geonameId }) =>
  `${camsAndForecastsPaths.base}/${slug}/${geonameId}`;
