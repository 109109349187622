import React from 'react';
import { Typography } from '@mui/material';

import { Chevron, Earth } from 'components/Icons';

import styles from './BreadcrumbNode.module.scss';

interface BreadcrumbNodeProps {
  label?: string;
  onClickLink?: (clickProperties: any, closeMobileMenu: boolean) => void;
  onClickNode?: (taxonomyId: string) => void;
  taxonomyId?: string;
}

const BreadcrumbNode = ({
  label = '',
  taxonomyId = '',
  onClickNode,
  onClickLink,
}: BreadcrumbNodeProps) => {
  const handleClickedButton = () => {
    if (onClickNode) {
      onClickNode(taxonomyId);
    }
    if (onClickLink) {
      onClickLink(
        {
          clickEndLocation: 'Taxonomy Level',
          completion: false,
          linkName: label,
        },
        false,
      );
    }
  };

  return (
    <div className={styles.breadcrumbNode}>
      {onClickNode ? (
        <Typography
          component="button"
          type="button"
          className={styles.button}
          onClick={handleClickedButton}
          variant="footnote"
        >
          {label === 'Earth' ? <Earth className={styles.earth} /> : label}
        </Typography>
      ) : (
        <span className={styles.placeholder}>{label}</span>
      )}
      <Chevron direction="right" className={styles.chevron} />
    </div>
  );
};

export default BreadcrumbNode;
