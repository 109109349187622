import {
  RESET_GRAPH_CONSISTENCY_FORECAST,
  FETCH_GRAPH_CONSISTENCY_FORECAST,
  FETCH_GRAPH_CONSISTENCY_FORECAST_SUCCESS,
  FETCH_GRAPH_CONSISTENCY_FORECAST_FAILURE,
} from 'actions/graphs';
import type { ConsistencyData } from 'types/consistency';

import createReducer from '../../createReducer';
import createDayHourlyData from './helpers/createDayHourlyData';
import createFiveDayHourlyWave from './helpers/createFiveDayHourlyData';

export const initialState: ConsistencyData = {
  days: null,
  error: null,
  hourly: null,
  loading: true,
  utcOffset: null,
};

const handlers: any = {};

handlers[RESET_GRAPH_CONSISTENCY_FORECAST] = () => initialState;
handlers[FETCH_GRAPH_CONSISTENCY_FORECAST] = () => initialState;

handlers[FETCH_GRAPH_CONSISTENCY_FORECAST_SUCCESS] = (
  state: ConsistencyData,
  {
    desired16DayInterval,
    response,
  }: {
    response: {
      data: {
        consistency: Array<{
          timestamp: number;
          utcOffset: number;
          consistency: {
            waveCount: number;
          };
        }>;
      };
    };
    desired16DayInterval: 1 | 3;
  },
): ConsistencyData => {
  const {
    data: { consistency },
  } = response;

  const mapFunc = (p: any) => ({
    ...p,
  });

  return {
    ...state,
    days: createDayHourlyData(consistency, 16, desired16DayInterval, mapFunc),
    hourly: createFiveDayHourlyWave(consistency, mapFunc),
    loading: false,
    utcOffset: consistency?.[0]?.utcOffset,
  };
};

handlers[FETCH_GRAPH_CONSISTENCY_FORECAST_FAILURE] = (
  state: ConsistencyData,
  { error }: { error: any },
): ConsistencyData => ({
  ...state,
  error,
  loading: false,
});

export default createReducer(handlers, initialState);
