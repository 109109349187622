import { createReducer } from '@reduxjs/toolkit';
import { internalServerError, redirectToNotFound, sessionTimeout } from '../actions/status';

type StatusState = {
  code: number;
  message: string | null;
  sessionTimeout: boolean;
};

export const initialState: StatusState = {
  code: 200,
  message: null,
  sessionTimeout: false,
};

const reducer = createReducer(initialState, (builder) =>
  builder
    .addCase(internalServerError, (state, action) => ({
      ...state,
      code: 500,
      message: action?.payload?.message,
    }))
    .addCase(redirectToNotFound, (state, action) => ({
      ...state,
      code: 404,
      message: action?.payload?.message,
    }))
    .addCase(sessionTimeout, (state) => ({ ...state, sessionTimeout: true })),
);

export default reducer;
