import { canUseDOM, getWindow } from '@surfline/web-common';

class CTAHackChecker {
  constructor(config) {
    const win = getWindow();
    const { targetClasses, backgroundTransMatch, hideContainerClass, blurPxMatch } = config;
    this.targetClasses = targetClasses;
    this.backgroundTransMatch = backgroundTransMatch;
    this.hideContainerClass = hideContainerClass;
    this.blurPxMatch = blurPxMatch;

    this.intervalFunc = () => {};
    this.hasReloaded = false;
    this.doc = win?.document;
    this.ctaTargets = [];
    if (this.doc) {
      this.targetClasses.forEach((ctaTarget) => {
        this.ctaTargets.push(this.doc.querySelector(`.${ctaTarget}`));
      });
    }

    this.observerConfig = {
      subtree: true,
      childList: true,
      attributes: true,
      attributeOldValue: true,
    };

    if (this.doc) {
      this.mutationObserver = new MutationObserver((mutations) => {
        // check for removed or hidden target or (optional) background trans changes
        mutations.forEach((mutation) => {
          const { oldValue } = mutation;

          // check if they try to rename the --blurred classes in inspector
          let blurChanged = false;
          if (oldValue && oldValue.indexOf('blurred') > -1) {
            blurChanged = true;
          }

          // Check removedNodes for ctaTarget
          const nodes = Array.from(mutation.removedNodes);
          let directMatch = false;
          let parentMatch = false;

          this.ctaTargets.forEach((target) => {
            if (nodes.indexOf(target) > -1) {
              directMatch = true;
            }
            if (nodes.some((parent) => parent.contains(target))) {
              parentMatch = true;
            }
          });

          const hasChanged = directMatch || parentMatch || blurChanged;

          if (hasChanged) {
            this.reloadPage(this.hideContainerClass);
          }
        });
      });
    }

    this.reloadPage = (hideWrapperClass) => {
      // hide container class option to collapse any visible items
      if (hideWrapperClass) {
        const item = this.doc.querySelector(`.${hideWrapperClass}`);
        if (item) {
          item.style.display = 'none';
        }
      }

      if (!this.hasReloaded) {
        // reload window and disconnect observer and clear interval
        win.location.reload();
        clearInterval(this.intervalFunc);
        this.mutationObserver.disconnect();
      }
      this.hasReloaded = true;
    };
  }

  observe() {
    this.mutationObserver?.observe(this.doc.body, this.observerConfig);
  }

  disconnect() {
    this.mutationObserver?.disconnect();
  }

  startDOMCheck() {
    const win = getWindow();
    let testBlur = false;
    let testBackground = false;
    let ctaLoaded = false;
    let ctaVisible = true;
    let ctaHiddenCount = 0;
    let backgroundChangedCount = 0;

    this.intervalFunc = setInterval(() => {
      if (canUseDOM) {
        // ie polyfill NodeList.forEach not native
        NodeList.prototype.forEach = Array.prototype.forEach;
        this.ctaTargets = this.targetClasses
          .map((ctaTarget) => this.doc.querySelector(`.${ctaTarget}`))
          .filter((validTarget) => !!validTarget);

        const blurredElements = this.doc.querySelectorAll('[class*="--blurred"]');

        ctaLoaded = this.ctaTargets.length;
        const ctasVisible = [];
        const backgroundsChanged = [];
        // ensure CTA is fully loaded and ready for testing
        if (ctaLoaded) {
          // check display property is not none
          this.ctaTargets.forEach((target) => {
            const ctaDisplay = win.getComputedStyle
              ? win.getComputedStyle(target, null).getPropertyValue('display')
              : target.style.display;
            const ctaVisibility = win.getComputedStyle
              ? win.getComputedStyle(target, null).getPropertyValue('visibility')
              : target.style.visibility;
            ctaVisible =
              ctaDisplay.indexOf('none') === -1 && ctaVisibility.indexOf('hidden') === -1;

            if (ctaVisible) {
              ctasVisible.push(1);
            }

            if (this.backgroundTransMatch) {
              // if checking background transparency, ensure expected substring matches
              const backgroundColor = win.getComputedStyle
                ? win.getComputedStyle(target, null).getPropertyValue('background-color')
                : target.style.backgroundColor;
              // ensure background has an initial match before testing changes
              if (backgroundColor.indexOf(this.backgroundTransMatch) > -1) {
                testBackground = true;
              }

              if (testBackground) {
                const backgroundChanged = backgroundColor.indexOf(this.backgroundTransMatch) === -1;
                if (backgroundChanged) {
                  backgroundsChanged.push(1);
                  backgroundChangedCount += 1;
                }
              }
            }
          });

          // give some time for random resizing and loading of different ctas
          if (ctasVisible.length === 0) {
            ctaHiddenCount += 1;
          }
        }

        if (
          (ctaHiddenCount >= 2 && !ctasVisible.length) ||
          (backgroundChangedCount >= 2 && backgroundsChanged.length)
        ) {
          this.reloadPage(this.hideContainerClass);
        }

        // check if blurred styles have been modified (blur removed or px values altered)
        blurredElements.forEach((blurElement) => {
          const blurFilter = win.getComputedStyle
            ? win.getComputedStyle(blurElement, null).getPropertyValue('filter')
            : blurElement.style.filter;

          // ensure filter: blur has applied before testing removal or changes.
          if (blurFilter && blurFilter.indexOf('blur') > -1) {
            testBlur = true;
          }

          if (
            testBlur &&
            blurFilter &&
            this.blurPxMatch &&
            (blurFilter.indexOf('blur') === -1 || blurFilter.indexOf(this.blurPxMatch) === -1)
          ) {
            this.reloadPage(this.hideContainerClass);
          }
        });
      }
    }, 750);
  }

  stopDOMCheck() {
    clearInterval(this.intervalFunc);
  }
}

export default CTAHackChecker;
