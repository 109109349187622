import React from 'react';

interface Props {
  size?: number;
  fill?: string;
}

const LocationIcon: React.FC<Props> = ({ size = 12, fill = '#96A9B2' }) => (
  <svg
    className="quiver-location-icon"
    width={`${size}px`}
    height={`${size}px`}
    viewBox="0 0 12 12"
    version="1.1"
  >
    <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="pin-icon" fill={fill}>
        <path
          d="M6.03206343,0.00192563827 C8.61030051,0.0429417334 10.6878716,2.14410193 10.6878716,4.73206348 C10.6878716,8.21939439 6.59348327,11.5297591 5.9939358,12 C5.3944846,11.5296629 1.29999995,8.21949067 1.29999995,4.73206348 C1.29999995,2.14410193 3.37766736,0.0429417334 5.95580816,0.00192563827 L5.95580816,0 C5.96870994,0 5.98132287,0.000770255308 5.9939358,0.000770255308 C6.00654873,0.000770255308 6.01916166,0 6.03206343,0 L6.03206343,0.00192563827 Z M4.29999995,4.70010057 C4.29999995,5.63891207 5.06102097,6.39979905 5.99996649,6.39979905 C6.93891202,6.39979905 7.70000005,5.63891207 7.70000005,4.70010057 C7.70000005,3.76102101 6.93891202,3 5.99996649,3 C5.06102097,3 4.29999995,3.76102101 4.29999995,4.70010057 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </svg>
);

export default LocationIcon;
