import { canUseDOM, getWindow } from '@surfline/web-common';
import canUseNavigator from '../common/canUseNavigator';
import { getNearestSpot, fetchLocationMapView } from '../common/fetch';
import { surflineLocation, getUserLocationByGPS, getUserLocationByIP } from '../common/location';
import { mapPath } from '../utils/urls';
import { fetchSpotMapData } from './spotMapData';
import { redirectToNotFound } from './status';

export const SET_MAP_LOCATION = 'SET_MAP_LOCATION';
export const RERENDER_MAP = 'RERENDER_MAP';

export const FETCH_LOCATION_VIEW = 'FETCH_LOCATION_VIEW';
export const FETCH_LOCATION_VIEW_SUCCESS = 'FETCH_LOCATION_VIEW_SUCCESS';
export const FETCH_LOCATION_VIEW_FAILURE = 'FETCH_LOCATION_VIEW_FAILURE';

export const getUserLocation = async () => {
  let location = surflineLocation;
  if (canUseNavigator) {
    try {
      location = await getUserLocationByGPS();
    } catch (err) {
      location = await getUserLocationByIP();
    }
  } else {
    location = await getUserLocationByIP();
  }

  return location;
};

export const shouldRerender = () => ({
  type: RERENDER_MAP,
});

/**
 * @param {import('../propTypes/map/location').Location} location
 * @param {import('next/router').NextRouter} router
 * @param {boolean} [syncUrl]
 * @param {boolean} [storeLocationInSession]
 */
export const setMapLocation =
  ({ center, zoom }, router, syncUrl = false, storeLocationInSession = false) =>
  (dispatch) => {
    const win = getWindow();

    if (syncUrl) {
      const newPath = `${mapPath(center, zoom)}`;

      if (canUseDOM && newPath !== router.asPath) {
        router.replace(newPath);
      }
    }

    if (storeLocationInSession) {
      try {
        win.sessionStorage.setItem('slMapLocation', JSON.stringify({ center, zoom }));
      } catch (err) {
        /* eslint-disable-next-line */
        console.error('Session storage not available.');
      }
    }

    dispatch({
      type: SET_MAP_LOCATION,
      center,
      zoom,
    });
  };

/**
 * @param {import('next/router').NextRouter} router
 * @param {boolean} [syncUrl]
 * @param {boolean} [storeLocationInSession]
 */
export const goToUsersLocation =
  (router, syncUrl = false, storeLocationInSession = false) =>
  async (dispatch) => {
    let position;

    try {
      position = await getUserLocation();
    } catch (err) {
      position = surflineLocation;
    }

    dispatch(setMapLocation({ ...position }, router, syncUrl, storeLocationInSession));
  };

/**
 * @param {import('next/router').NextRouter} router
 * @param {boolean} [syncUrl]
 * @param {boolean} [storeLocationInSession]
 * @returns
 */
export const goToUsersNearestSpot =
  (router, syncUrl = false, storeLocationInSession = false) =>
  async (dispatch) => {
    let position = surflineLocation;
    try {
      const location = await getUserLocationByIP();
      const nearest = await getNearestSpot({ ...location.center });
      position = {
        center: {
          lat: nearest.spot.lat,
          lon: nearest.spot.lon,
        },
        zoom: location.zoom,
      };
      dispatch(setMapLocation({ ...position }, router, syncUrl, storeLocationInSession));
    } catch (err) {
      dispatch(setMapLocation({ ...position }, router, syncUrl, storeLocationInSession));
    }

    if (navigator) {
      try {
        const location = await getUserLocationByGPS();
        const nearest = await getNearestSpot({ ...location.center });
        position = {
          center: {
            lat: nearest.spot.lat,
            lon: nearest.spot.lon,
          },
          zoom: location.zoom,
        };
        dispatch(setMapLocation({ ...position }, router, syncUrl, storeLocationInSession));
      } catch (err) {
        dispatch(setMapLocation({ ...position }, router, syncUrl, storeLocationInSession));
      }
    }
  };

/**
 * @param {import('../propTypes/map/location').Location["center"]} center
 * @param {import('next/router').NextRouter} router
 * @param {boolean} [syncUrl]
 * @param {boolean} [storeLocationInSession]
 * @returns
 */
export const zoomIntoLocation =
  (center, router, syncUrl = false, storeLocationInSession = false) =>
  (dispatch, state) => {
    dispatch(
      setMapLocation(
        {
          center,
          zoom: state().map.location.zoom + 1,
        },
        router,
        syncUrl,
        storeLocationInSession,
      ),
    );
  };

/**
 * @param {string | number} geonameId
 */
export const fetchLocationView =
  (geonameId = null) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_LOCATION_VIEW,
    });
    try {
      if (parseInt(geonameId, 10)) {
        const response = await fetchLocationMapView(geonameId);
        const { taxonomy, travelContent, boundingBox, breadCrumbs, siblings, url } = response;
        await dispatch({
          type: FETCH_LOCATION_VIEW_SUCCESS,
          boundingBox,
          taxonomy,
          travelContent,
          breadCrumbs,
          siblings,
          url,
        });
        await dispatch(fetchSpotMapData(boundingBox, true));
      } else {
        throw new Error('geonameId is not a number');
      }
    } catch (error) {
      if (error.status === 400) {
        dispatch(redirectToNotFound('The location not be found'));
      }
      dispatch({
        type: FETCH_LOCATION_VIEW_FAILURE,
        error: error.message,
      });
    }
  };
