export const getCollections = (state) => {
  const { multiCam } = state;
  if (multiCam) {
    return multiCam.collections;
  }
  return [];
};

export const getActiveCollection = (state) => {
  const { multiCam } = state;
  const collections = getCollections(state);
  if (multiCam) {
    return collections.find((collection) => collection._id === multiCam.active);
  }
  return null;
};

export const getActiveCollectionId = (state) => {
  const { multiCam } = state;
  if (multiCam) {
    return multiCam.active;
  }
  return '';
};

/**
 *
 * @param {Object} state
 * @returns {Array<Object>}
 */
export const getCollectionTypes = (state) => {
  const { multiCam } = state;
  if (multiCam) {
    return multiCam.collectionTypes;
  }
  return [];
};

export const getCollectionsLoading = (state) => {
  const { multiCam } = state;
  if (multiCam) {
    return multiCam.collectionsLoading;
  }
  return false;
};

export const getNumCams = (state) => {
  const { multiCam } = state;
  if (multiCam) {
    return multiCam.numCams;
  }
  return null;
};

export const getSelectedSpots = (state) => {
  const { multiCam } = state;
  if (multiCam) {
    return multiCam.selectedSpots;
  }
  return [];
};

export const getPrimarySpotIndex = (state) => {
  const { multiCam } = state;
  if (multiCam) {
    return multiCam.primarySpotIndex;
  }
  return 0;
};

export const getMultiCam = (state) => ({
  active: getActiveCollection(state),
  collections: getCollections(state),
  collectionsLoading: getCollectionsLoading(state),
  numCams: getNumCams(state),
  selectedSpots: getSelectedSpots(state),
  primarySpotIndex: getPrimarySpotIndex(state),
});
