import React, { forwardRef, MouseEvent, useMemo } from 'react';
import { isNil } from 'lodash';
import Link from 'next/link';

import { getSessionTimeout, getStatusCode } from 'selectors/status';
import { useAppSelector } from 'stores/hooks';

interface Props extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  href: string;
  isExternal?: boolean;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  prefetch?: boolean;
}

/**
 * @description This is our custom implementation of a NextJS Link
 */
function WavetrakLink(
  { children, href, isExternal = false, prefetch = true, ...extraProps }: Props,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const status = useAppSelector(getStatusCode);
  const sessionTimeout = useAppSelector(getSessionTimeout);
  const isNextLink = useMemo(
    () =>
      !isExternal &&
      (status === 200 || isNil(status)) &&
      (!sessionTimeout || isNil(sessionTimeout)),
    [isExternal, sessionTimeout, status],
  );

  return isNextLink ? (
    <Link href={href} {...(prefetch === false ? { prefetch: false } : {})}>
      <a data-testid="link-internal" ref={ref} {...extraProps}>
        {children}
      </a>
    </Link>
  ) : (
    <a href={href} data-testid="link-external" ref={ref} {...extraProps}>
      {children}
    </a>
  );
}

export default forwardRef(WavetrakLink);
