import { Cookies } from 'react-cookie';

import type { ServiceConfiguration, TaxonomyNavigatorSettings } from 'types/header';

const updateSettings = async (
  settings: TaxonomyNavigatorSettings,
  serviceConfig: ServiceConfiguration,
) => {
  const cookie = new Cookies();
  const accessToken = cookie.get('access_token');
  const url = `${serviceConfig.serviceUrl}/user/settings`;
  const response = await fetch(accessToken ? `${url}?accesstoken=${accessToken}` : url, {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'PUT',
    body: JSON.stringify(settings),
  });
  const body = await response.json();

  if (response.status === 200) return body;
  throw body;
};

export default updateSettings;
