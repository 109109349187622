const buildBreadCrumb = (breadCrumbs: any, newsSuggestion: boolean, tags: any) => {
  let breadcrumb;
  if (newsSuggestion) breadcrumb = tags && tags.length ? tags[0].name : '';
  else {
    breadcrumb =
      breadCrumbs && breadCrumbs.length > 1
        ? `${breadCrumbs[0]} / ${breadCrumbs[breadCrumbs.length - 1]}`
        : '';
  }
  return breadcrumb;
};

export default buildBreadCrumb;
