import { slugify } from '@surfline/web-common';

export const subregionForecastPaths = {
  base: '/surf-forecasts',
};

const subregionForecastPath = (
  subregionId: string,
  subregionName: string,
  spotId?: string,
  isNative?: boolean,
) => {
  const params = new URLSearchParams();
  if (spotId) params.append('spotId', spotId);
  if (isNative) params.append('native', String(isNative));
  const queryString = params.toString();
  return `${subregionForecastPaths.base}/${slugify(subregionName)}/${subregionId}${
    queryString ? `?${queryString}` : ''
  }`;
};

export default subregionForecastPath;
