import React from 'react';
import classNames from 'classnames';

import styles from './TravelIcon.module.scss';

const TravelIcon = () => (
  <svg className={classNames(styles.travelIcon, 'quiver-travel-icon')} viewBox="0 0 16 16">
    <path
      fillRule="nonzero"
      d={
        'M5.339 12.114l-2.215-.435.6-.813 1.777.067 2.962-4.3L2 5.177l.799-1.084 ' +
        '7.164.304.516-.716c.31-.42.646-.763 1.009-1.028l.504-.36c.377-.262.63-.349.' +
        '762-.258.141.097.145.361.01.792l-.186.578a4.713 4.713 0 0 1-.692 1.28l-.523.' +
        '679L14 11.827l-.799 1.085L9.674 7.47 6.44 11.58l.643 1.606-.599.813-1.145-1.886z'
      }
    />
  </svg>
);

export default TravelIcon;
