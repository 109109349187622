import { get } from 'lodash';
import { Cookies } from 'react-cookie';
import { fetchOembed, trackEvent } from '@surfline/web-common';
import sanitizeHTML from 'sanitize-html';
import * as contestsAPI from '../common/api/contests';
import validateContestEntry from '../utils/validateContestEntry';
import { contestVoteNamespace } from '../utils/contests';
import { redirectToNotFound } from './status';
import config from '../config';

export const FETCH_CONTEST = 'FETCH_CONTEST';
export const FETCH_CONTEST_SUCCESS = 'FETCH_CONTEST_SUCCESS';
export const FETCH_CONTEST_FAILURE = 'FETCH_CONTEST_FAILURE';

export const CLEAR_CONTEST_ENTRIES = 'CLEAR_CONTEST_ENTRIES';
export const CLEAR_CONTEST_ENTRY = 'CLEAR_CONTEST_ENTRY';

export const FETCH_CONTEST_ENTRY = 'FETCH_CONTEST_ENTRY';
export const FETCH_CONTEST_ENTRY_SUCCESS = 'FETCH_CONTEST_ENTRY_SUCCESS';
export const FETCH_CONTEST_ENTRY_FAILURE = 'FETCH_CONTEST_ENTRY_FAILURE';

export const FETCH_CONTEST_NEWS = 'FETCH_CONTEST_NEWS';
export const FETCH_CONTEST_NEWS_SUCCESS = 'FETCH_CONTEST_NEWS_SUCCESS';
export const FETCH_CONTEST_NEWS_FAILURE = 'FETCH_CONTEST_NEWS_FAILURE';

export const FETCH_CONTEST_DETAILS = 'FETCH_CONTEST_DETAILS';
export const FETCH_CONTEST_DETAILS_SUCCESS = 'FETCH_CONTEST_DETAILS_SUCCESS';
export const FETCH_CONTEST_DETAILS_FAILURE = 'FETCH_CONTEST_DETAILS_FAILURE';

export const FETCH_CONTEST_ENTRIES = 'FETCH_CONTEST_ENTRIES';
export const FETCH_CONTEST_ENTRIES_SUCCESS = 'FETCH_CONTEST_ENTRIES_SUCCESS';
export const FETCH_CONTEST_ENTRIES_FAILURE = 'FETCH_CONTEST_ENTRIES_FAILURE';

export const FETCH_CONTEST_ENTRIES_LOCATIONS = 'FETCH_CONTEST_ENTRIES_LOCATIONS';
export const FETCH_CONTEST_ENTRIES_LOCATIONS_SUCCESS = 'FETCH_CONTEST_ENTRIES_LOCATIONS_SUCCESS';
export const FETCH_CONTEST_ENTRIES_LOCATIONS_FAILURE = 'FETCH_CONTEST_ENTRIES_LOCATIONS_FAILURE';

export const FETCH_CONTEST_ENTRIES_SURFERS = 'FETCH_CONTEST_ENTRIES_SURFERS';
export const FETCH_CONTEST_ENTRIES_SURFERS_SUCCESS = 'FETCH_CONTEST_ENTRIES_SURFERS_SUCCESS';
export const FETCH_CONTEST_ENTRIES_SURFERS_FAILURE = 'FETCH_CONTEST_ENTRIES_SURFERS_FAILURE';

export const FETCH_CONTEST_ENTRIES_MONTHS = 'FETCH_CONTEST_ENTRIES_MONTHS';
export const FETCH_CONTEST_ENTRIES_MONTHS_SUCCESS = 'FETCH_CONTEST_ENTRIES_MONTHS_SUCCESS';
export const FETCH_CONTEST_ENTRIES_MONTHS_FAILURE = 'FETCH_CONTEST_ENTRIES_MONTHS_FAILURE';

export const CHANGE_CONTEST_ENTRY_FORM = 'CHANGE_CONTEST_ENTRY_FORM';
export const CLEAR_CONTEST_ENTRY_FORM = 'CLEAR_CONTEST_ENTRY_FORM';

export const SUBMIT_CONTEST_ENTRY_FORM = 'SUBMIT_CONTEST_ENTRY_FORM';
export const SUBMIT_CONTEST_ENTRY_FORM_SUCCESS = 'SUBMIT_CONTEST_ENTRY_FORM_SUCCESS';
export const SUBMIT_CONTEST_ENTRY_FORM_FAILURE = 'SUBMIT_CONTEST_ENTRY_FORM_FAILURE';

export const UPVOTE_CONTEST_ENTRY = 'UPVOTE_CONTEST_ENTRY';
export const UPVOTE_CONTEST_ENTRY_SUCCCESS = 'UPVOTE_CONTEST_ENTRY_SUCCCESS';
export const UPVOTE_CONTEST_ENTRY_FAILURE = 'UPVOTE_CONTEST_ENTRY_FAILURE';

export const fetchContest =
  (params, cookies, isServer = false) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_CONTEST,
    });
    try {
      const contest = await contestsAPI.fetchContest(params, cookies, isServer);
      if (!contest.post) {
        const err = { statusCode: 400 };
        throw err;
      }
      dispatch({
        type: FETCH_CONTEST_SUCCESS,
        contest,
      });
    } catch (error) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The contest was not found'));
      } else {
        dispatch({
          type: FETCH_CONTEST_FAILURE,
          error,
        });
      }
    }
  };

export const fetchContestNews =
  (region, cookies, isServer = false) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_CONTEST_NEWS,
    });
    try {
      let contestNews = await contestsAPI.fetchContestNews(region, cookies, isServer);
      if (contestNews.news && contestNews.news.length) {
        contestNews = contestNews.news.map((article) => ({
          ...article,
          content: {
            ...article.content,
            body: sanitizeHTML(article.content.body, {
              exclusiveFilter: (node) => node.tag === 'script',
            }),
          },
        }));
      }
      dispatch({
        type: FETCH_CONTEST_NEWS_SUCCESS,
        contestNews,
      });
    } catch (error) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The article was not found'));
      } else {
        dispatch({
          type: FETCH_CONTEST_NEWS_FAILURE,
          error,
        });
      }
    }
  };

export const fetchContestEntries =
  (
    params,
    limit,
    loadMore = false,
    location = null,
    sort = null,
    surfer = null,
    month = null,
    isServer = false,
  ) =>
  async (dispatch, getState) => {
    dispatch({
      type: FETCH_CONTEST_ENTRIES,
    });
    try {
      const offset = getState().contests.contestEntries.pageOffset;
      const pageOffset = loadMore ? offset + 1 : 0;
      const queryLimit = limit || 3;
      const queryParams = {
        ...params,
        location,
        surfer,
        month,
        sort,
        limit: queryLimit,
        offset: pageOffset * queryLimit,
      };
      const entries = await contestsAPI.fetchContestEntries(queryParams, null, isServer);
      dispatch({
        type: FETCH_CONTEST_ENTRIES_SUCCESS,
        entries,
        pageOffset,
        location,
        surfer,
        month,
        sort,
        displayLoadMore: entries && entries.length === limit,
      });
    } catch (error) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The article was not found'));
      } else {
        dispatch({
          type: FETCH_CONTEST_ENTRIES_FAILURE,
          error,
        });
      }
    }
  };

export const clearContestEntries = () => ({
  type: CLEAR_CONTEST_ENTRIES,
});

export const fetchContestEntry =
  (entryId, cookies, isServer = false) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_CONTEST_ENTRY,
    });
    try {
      const activeEntry = await contestsAPI.fetchContestEntry(entryId, cookies, isServer);
      dispatch({
        type: FETCH_CONTEST_ENTRY_SUCCESS,
        activeEntry,
      });
    } catch (error) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The article was not found'));
      } else {
        dispatch({
          type: FETCH_CONTEST_ENTRY_FAILURE,
          error,
        });
      }
    }
  };

export const clearContestEntry = () => ({
  type: CLEAR_CONTEST_ENTRY,
});

export const upvoteContestEntry = (entryId) => async (dispatch, getState) => {
  const cookies = new Cookies();
  const voteNamespace = contestVoteNamespace(entryId);
  const hasAlreadyVoted = cookies.get(voteNamespace);

  const {
    contests: {
      contestEntries: { submittingVote },
    },
  } = getState();

  if (submittingVote || hasAlreadyVoted) return;

  dispatch({
    type: UPVOTE_CONTEST_ENTRY,
    entryId,
  });

  try {
    const updatedVoteCount = await contestsAPI.upvoteContestEntry(entryId);

    if (typeof updatedVoteCount === 'number') {
      cookies.set(voteNamespace, true, {
        path: '/',
        expires: new Date(new Date().getTime() + 86400000), // 24 hour expiry
        sameTime: false,
      });

      dispatch({
        type: UPVOTE_CONTEST_ENTRY_SUCCCESS,
        entryId,
        updatedVoteCount,
      });
    }
  } catch (error) {
    dispatch({
      type: UPVOTE_CONTEST_ENTRY_FAILURE,
      error,
    });
  }
};

export const fetchContestEntriesLocations =
  (params, isServer = false) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_CONTEST_ENTRIES_LOCATIONS,
    });
    try {
      const locations = await contestsAPI.fetchContestEntriesLocations(params, null, isServer);
      dispatch({
        type: FETCH_CONTEST_ENTRIES_LOCATIONS_SUCCESS,
        locations,
      });
    } catch (error) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The article was not found'));
      } else {
        dispatch({
          type: FETCH_CONTEST_ENTRIES_LOCATIONS_FAILURE,
          error,
        });
      }
    }
  };

export const fetchContestEntriesSurfers =
  (params, isServer = false) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_CONTEST_ENTRIES_SURFERS,
    });
    try {
      const surfers = await contestsAPI.fetchContestEntriesSurfers(params, null, isServer);
      dispatch({
        type: FETCH_CONTEST_ENTRIES_SURFERS_SUCCESS,
        surfers,
      });
    } catch (error) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The article was not found'));
      } else {
        dispatch({
          type: FETCH_CONTEST_ENTRIES_SURFERS_FAILURE,
          error,
        });
      }
    }
  };

export const fetchContestEntriesMonths =
  (params, isServer = false) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_CONTEST_ENTRIES_MONTHS,
    });
    try {
      const months = await contestsAPI.fetchContestEntriesMonths(params, null, isServer);
      dispatch({
        type: FETCH_CONTEST_ENTRIES_MONTHS_SUCCESS,
        months,
      });
    } catch (error) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The article was not found'));
      } else {
        dispatch({
          type: FETCH_CONTEST_ENTRIES_MONTHS_FAILURE,
          error,
        });
      }
    }
  };

export const fetchContestDetails =
  (params, cookies, isServer = false) =>
  async (dispatch) => {
    dispatch({
      type: FETCH_CONTEST_DETAILS,
    });
    try {
      const result = await contestsAPI.fetchContestDetails(params, cookies, isServer);
      const contestDetails = result ? result.content.rendered : null;
      dispatch({
        type: FETCH_CONTEST_DETAILS_SUCCESS,
        contestDetails,
      });
    } catch (error) {
      if (error.statusCode === 400) {
        dispatch(redirectToNotFound('The contest details were not found'));
      } else {
        dispatch({
          type: FETCH_CONTEST_DETAILS_FAILURE,
          error,
        });
      }
    }
  };

export const changeContestEntryForm = (type, details) => ({
  type: type || CHANGE_CONTEST_ENTRY_FORM,
  details,
});

export const clearContestEntryForm = () => ({
  type: CLEAR_CONTEST_ENTRY_FORM,
});

export const submitContestEntryForm = () => async (dispatch, getState) => {
  dispatch({
    type: SUBMIT_CONTEST_ENTRY_FORM,
  });
  try {
    const state = getState();
    const { contest, period, region } = get(state, 'contests.contest.meta.acf', {});
    const { fields } = state.contests.entryForm;
    fields.date = fields.date.replace(/-/g, '');
    const formErrors = validateContestEntry(fields);
    const errorMessage = 'Error(s) submitting the contest entry.';
    if (formErrors.length) throw Object({ message: errorMessage, details: formErrors });
    // eslint-disable-next-line camelcase
    const {
      mediaHost,
      oembedObj: { thumbnail_url: thumbnailUrl },
    } = await fetchOembed(fields.videoUrl, '/contests/oembed');
    const requestBody = { ...fields, mediaHost, posterUrl: thumbnailUrl, contest, period, region };
    const postNumber = await contestsAPI.submitContestEntry(requestBody);
    const adminLink = `${config.homepageUrl}wp-admin/post.php?post=${postNumber}&action=edit`;
    trackEvent('Contest Form Submitted', {
      date: fields.date,
      surfer: fields.surfer,
      spot: fields.location,
      videoUrl: fields.videoUrl,
      adminLink,
    });
    dispatch({
      type: SUBMIT_CONTEST_ENTRY_FORM_SUCCESS,
    });
  } catch (error) {
    if (error.statusCode === 400) {
      dispatch(redirectToNotFound(error));
    } else {
      dispatch({
        type: SUBMIT_CONTEST_ENTRY_FORM_FAILURE,
        error,
      });
    }
  }
};
