import createReducer from '../createReducer';
import { FETCH_SPOT_MAP_DATA_SUCCESS } from '../../actions/spotMapData';
import cluster from '../../utils/cluster';
import { conditionToRank, rankToCondition } from '../../utils/conditionRank';

export const initialState = [];

const handlers = {};

export const properties = (points) => {
  const spotCount = points.length;

  const conditionRanks = points
    .map((point) => conditionToRank(point.properties.conditions.value))
    .filter((rank) => rank > -1);

  return {
    spotCount,
    worstCondition: rankToCondition(Math.min(...conditionRanks)),
    bestCondition: rankToCondition(Math.max(...conditionRanks)),
  };
};

handlers[FETCH_SPOT_MAP_DATA_SUCCESS] = (_, { spots, zoom, doCluster }) => {
  if (!doCluster) {
    return initialState;
  }

  const radius = zoom > 7 ? 100 : 65;
  const options = { radius, properties };

  const points = spots.map((spot) => ({
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [spot.lon, spot.lat],
    },
    properties: spot,
  }));

  return cluster(points, zoom, options);
};

export default createReducer(handlers, initialState);
