import React, { ReactNode } from 'react';

import { trackEvent } from '@surfline/web-common';
import BaseMenuItem from '../BaseMenuItem';
import NewNotification from '../NewNotification';

import styles from './BaseMenuSection.module.scss';

interface Link {
  display?: string;
  href?: string;
  isExternal?: boolean;
  newWindow?: boolean;
  showNewNotification?: boolean;
  eventTracking?: Record<string, string | number | boolean>;
}

interface BaseMenuSectionProps {
  children?: ReactNode;
  links?: Array<Link>;
  onClick?: (properties: any, closeMobileMenu: boolean) => void;
}

const BaseMenuSection = ({ links = [], children = null, onClick }: BaseMenuSectionProps) => {
  const handleClick = (link: Link) => {
    if (onClick) {
      if (link?.eventTracking) {
        trackEvent('Button Clicked', {
          ...link?.eventTracking,
        });
      }
      onClick(
        {
          destinationUrl: link.href,
          linkName: link.display,
          completion: true,
        },
        true,
      );
    }
  };

  const createClick = (link: Link) => () => handleClick(link);

  return (
    <div className={styles.baseMenuSection}>
      {links
        ? links.map((link) => (
            <BaseMenuItem
              href={link?.href ?? ''}
              isExternal={link?.isExternal}
              key={`${link.display}-${link.href}`}
              newWindow={link?.newWindow}
              onClick={createClick(link)}
            >
              <div className={styles.linkContainer}>
                <div>{link.display}</div>
                {link?.showNewNotification && <NewNotification />}
              </div>
            </BaseMenuItem>
          ))
        : null}
      {children}
      <div className={styles.baseMenuDivider} />
    </div>
  );
};

export default BaseMenuSection;
