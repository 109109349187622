import { createAction } from '@reduxjs/toolkit';

export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';
export const NOT_FOUND = 'NOT_FOUND';
export const SESSION_TIMEOUT = 'SESSION_TIMEOUT';

export const internalServerError = createAction(INTERNAL_SERVER_ERROR, (reason: string) => ({
  type: INTERNAL_SERVER_ERROR,
  payload: {
    message: reason || null,
  },
}));
export const redirectToNotFound = createAction(NOT_FOUND, (reason: string) => ({
  type: NOT_FOUND,
  payload: {
    message: reason || null,
  },
}));
export const sessionTimeout = createAction(SESSION_TIMEOUT);
