import color from '../color';

const conditionColorMap = new Map([
  [
    'VERY_POOR',
    {
      default: color('conditions', 'very-poor'),
      dark: color('conditions', 'very-poor'),
    },
  ],
  [
    'POOR',
    {
      default: color('conditions', 'poor'),
      dark: color('conditions', 'poor'),
    },
  ],
  [
    'POOR_TO_FAIR',
    {
      default: color('conditions', 'poor-to-fair'),
      dark: color('conditions', 'poor-to-fair'),
    },
  ],
  [
    'FAIR',
    {
      default: color('conditions', 'fair'),
      dark: color('conditions', 'fair'),
    },
  ],
  [
    'FAIR_TO_GOOD',
    {
      default: color('conditions', 'fair-to-good'),
      dark: color('conditions', 'fair-to-good'),
    },
  ],
  [
    'GOOD',
    {
      default: color('conditions', 'good'),
      dark: color('conditions', 'good'),
    },
  ],
  [
    'EPIC',
    {
      default: color('conditions', 'epic'),
      dark: color('conditions', 'epic'),
    },
  ],
  [
    'NONE',
    {
      default: color('conditions', 'none'),
      dark: color('conditions', 'none'),
    },
  ],
]);

export default conditionColorMap;
