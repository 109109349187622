import { get as _get } from 'lodash';
import { fetchEditorialArticleById } from '../common/api/editorial';
import { redirectToNotFound } from './status';
import sanitizeArticleHTML from '../utils/sanitizeArticleHTML';
import getFeedGeotarget from '../utils/getFeedGeotarget';
import type { WavetrakStore, AppDispatch } from '../stores';

export const FETCH_EDITORIAL_ARTICLE = 'FETCH_EDITORIAL_ARTICLE';
export const FETCH_EDITORIAL_ARTICLE_SUCCESS = 'FETCH_EDITORIAL_ARTICLE_SUCCESS';
export const FETCH_EDITORIAL_ARTICLE_FAILURE = 'FETCH_EDITORIAL_ARTICLE_FAILURE';

export const fetchEditorialArticle =
  (
    id: string | string[] | undefined,
    preview: boolean | null = null,
    cookies: any = null,
    countryCode: string | null = null,
    isServer = false,
  ) =>
  async (dispatch: AppDispatch, getState: WavetrakStore['getState']) => {
    dispatch({
      type: FETCH_EDITORIAL_ARTICLE,
    });

    try {
      const state = getState();
      const geotarget = countryCode || getFeedGeotarget(state);
      const fetchedArticle: any = await fetchEditorialArticleById(
        id,
        preview,
        geotarget,
        cookies,
        isServer,
      );
      const article = {
        ...fetchedArticle,
        premium: {
          ...fetchedArticle.premium,
          teaser: fetchedArticle.premium.teaser
            ? sanitizeArticleHTML(fetchedArticle.premium.teaser)
            : null,
        },
        content: {
          ...fetchedArticle.content,
          body: sanitizeArticleHTML(fetchedArticle.content.body),
        },
      };
      dispatch({
        type: FETCH_EDITORIAL_ARTICLE_SUCCESS,
        article,
      });
      /* For promises like this, not sure the best type for errors */
    } catch (error: any) {
      const wpStatusCode = _get(error, 'response.status', null);
      if (error.statusCode === 400 || wpStatusCode === 404) {
        dispatch(redirectToNotFound('The article was not found'));
      } else {
        dispatch({
          type: FETCH_EDITORIAL_ARTICLE_FAILURE,
          error,
        });
      }
    }
  };
