import { AnyAction, Reducer } from 'redux';
import createReducer from './createReducer';

import {
  FETCH_LOCATION_VIEW_TRAVEL,
  FETCH_LOCATION_VIEW_TRAVEL_SUCCESS,
  FETCH_LOCATION_VIEW_TRAVEL_FAILURE,
} from '../actions/travelLocationView';

const initialState = {
  loading: false,
  taxonomy: null,
  url: null,
  units: null,
  boundingBox: null,
  travelContent: null,
};

const handlers: { [key: string]: Reducer<any, AnyAction> } = {};

handlers[FETCH_LOCATION_VIEW_TRAVEL] = (state) => ({
  ...state,
  loading: true,
});

handlers[FETCH_LOCATION_VIEW_TRAVEL_SUCCESS] = (
  state,
  { taxonomy, associated, boundingBox, children, travelContent, breadCrumbs, url },
) => ({
  ...state,
  loading: false,
  taxonomy,
  associated,
  boundingBox,
  children,
  travelContent,
  breadCrumbs,
  url,
});

handlers[FETCH_LOCATION_VIEW_TRAVEL_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  error,
});

export default createReducer(handlers, initialState);
