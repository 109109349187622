import React from 'react';
import Script from 'next/script';

const Scripts: React.FC = () => (
  <Script
    id="zendesk"
    strategy="afterInteractive"
    dangerouslySetInnerHTML={{
      __html: `
          window.zESettings = {
            webWidget: {
              zIndex: 99,
              offset: {
                horizontal: '-10px',
                vertical: '44px',
                mobile: {
                  horizontal: '-10px',
                  vertical: '44px'
                }
              }
            }
          };
          /*<![CDATA[*/
          window.onload = function (e, t) {
            var n, o, d, i, s, a = [], r = document.createElement("iframe");
            window.zEmbed = function () {
              a.push(arguments)
            }, window.zE = window.zE || window.zEmbed, r.src = "javascript:false", r.title = "", r.role = "presentation", (r.frameElement || r).style.cssText = "display: none", d = document.getElementsByTagName("script"), d = d[d.length - 1], d.parentNode.insertBefore(r, d), i = r.contentWindow, s = i.document;
            try {
              o = s
            } catch (e) {
              n = document.domain, r.src = 'javascript:var d=document.open();d.domain="' + n + '";void(0);', o = s
            }
            o.open()._l = function () {
              var e = this.createElement("script");
              n && (this.domain = n), e.id = "js-iframe-async", e.src = "https://assets.zendesk.com/embeddable_framework/main.js", this.t = +new Date, this.zendeskHost = "surfline.zendesk.com", this.zEQueue = a, this.body.appendChild(e)
            }, o.write('<body onload="document._l();">'), o.close()
          };
          /*]]>*/
        `,
    }}
  />
);

export default Scripts;
