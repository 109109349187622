import type { ServiceConfiguration } from 'types/header';
import { EARTH_TAXONOMY_ID } from '../NavigationBar/components/TaxonomyNavigator/constants';

const generateURL = (id: string, maxDepth: number, serviceConfig: ServiceConfiguration) =>
  `${serviceConfig.serviceUrl}/taxonomy?type=taxonomy&id=${id}&maxDepth=${maxDepth}`;

const requestTaxonomy = async (url: string) => {
  const response = await fetch(url);
  const body = await response.json();

  return { body, statusCode: response.status };
};

/* istanbul ignore next */
const fetchTaxonomy = async (id: string, maxDepth: number, serviceConfig: ServiceConfiguration) => {
  const url = generateURL(id, maxDepth, serviceConfig);
  const { body, statusCode } = await requestTaxonomy(url);

  if (statusCode === 200) {
    return body;
  }
  // If there's an error from the API, fetch the Taxonomy endpoint using the default Id
  const defaultUrl = generateURL(EARTH_TAXONOMY_ID, maxDepth, serviceConfig);
  const { body: defaultBody, statusCode: defaultStatusCode } = await requestTaxonomy(defaultUrl);
  if (defaultStatusCode === 200) return defaultBody;
  throw defaultBody;
};

export default fetchTaxonomy;
