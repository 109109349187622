import React from 'react';

const buildNameContent = (
  content?: {
    title?: string;
  },
  name?: string,
  isSuggestion?: boolean,
  newsSuggestion?: boolean,
  searchTerm?: string,
) => {
  let nameContent;
  const suggestionName: string = (newsSuggestion ? content?.title : name) || '';
  const indexOfSearchTerm = suggestionName.toLowerCase().indexOf(searchTerm?.toLowerCase() || '');

  if (newsSuggestion && isSuggestion) {
    nameContent = <span>{suggestionName}</span>;
  } else if (indexOfSearchTerm > -1) {
    const searchTermLength = searchTerm?.length ?? 0;
    const resultTermLength = suggestionName.length;
    const endOfHighlight = indexOfSearchTerm + searchTermLength;
    nameContent = (
      <span>
        {suggestionName.substring(0, indexOfSearchTerm)}
        <mark>{suggestionName.substring(indexOfSearchTerm, endOfHighlight)}</mark>
        {suggestionName.substring(endOfHighlight, resultTermLength)}
      </span>
    );
  } else {
    nameContent = (
      <span>
        {suggestionName
          .toLowerCase()
          .split('')
          .map((char, index) =>
            (searchTerm?.toLowerCase()?.indexOf(char) ?? -1) > -1 &&
            index <= suggestionName.toLowerCase().indexOf(char) ? (
              <mark key={suggestionName[index]}>{suggestionName[index]}</mark>
            ) : (
              suggestionName[index]
            ),
          )}
      </span>
    );
  }
  return nameContent;
};

export default buildNameContent;
