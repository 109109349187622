import React, { FC, useState } from 'react';
import classNames from 'classnames';
import { format } from 'date-fns';
import { Alert, Collapse, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import WavetrakLink from 'components/WavetrakLink';
import CloudflareResizedImage from 'components/CloudflareResizedImage';
import type { SwellEvent } from 'types/swellEvents';

import styles from './SwellEventCard.module.scss';

const formatBasinText = (basin: string): string =>
  basin
    .split('_')
    .map((word) => (word?.charAt?.(0)?.toUpperCase() ?? '') + word.slice(1))
    .join(' ');

const formatDate = (date: Date): string => format(date, 'MMM d • h:mma');

interface SwellEventCardProps {
  className?: string;
  onClickCard?: (swellEvent: SwellEvent) => void;
  onClose?: (swellEvent: SwellEvent) => void;
  swellEvent: SwellEvent;
  testId?: string;
}

const SwellEventCard: FC<SwellEventCardProps> = ({
  className,
  onClickCard,
  onClose,
  swellEvent,
  testId = 'swell-event-card',
}) => {
  const [closed, setClosed] = useState(false);
  const is20Foot = swellEvent?.titleTag?.toUpperCase() === '20FT+';
  const showThumbnail =
    (swellEvent?.thumbnailUrl && swellEvent?.thumbnailUrl !== '') || swellEvent?.isLive;

  const handleCloseClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    setClosed(true);
  };

  const handleTransitionClose = () => {
    if (onClose) {
      onClose(swellEvent);
    }
  };

  const onClickCardHandler = () => {
    if (onClickCard) onClickCard(swellEvent);
  };

  return (
    <Collapse data-testid={testId} in={!closed} onExited={handleTransitionClose}>
      <Alert
        className={classNames(styles.alert, className, {
          [styles.twentyFt]: is20Foot,
        })}
        icon={false}
        sx={{
          '& .MuiAlert-message': {
            width: '100%',
          },
        }}
        action={
          onClose ? (
            <div className={styles.action}>
              <IconButton
                aria-label="close"
                color="inherit"
                data-testid={`${testId}-close`}
                size="small"
                onClick={handleCloseClick}
              >
                <CloseIcon className={classNames('close-icon', styles.close)} fontSize="inherit" />
              </IconButton>
            </div>
          ) : null
        }
      >
        <WavetrakLink
          className={classNames(styles.link, className)}
          data-testid={`${testId}-link`}
          href={swellEvent.permalink}
          onClick={onClickCardHandler}
          rel={swellEvent.newWindow ? 'noreferrer' : ''}
          target={swellEvent.newWindow ? '_blank' : ''}
          isExternal
        >
          {is20Foot && (
            <div className={styles.twentyFtLogo} data-testid={`${testId}-20ft`}>
              <svg
                className={styles.twentyFtLogoSvg}
                fill="none"
                height="37"
                viewBox="0 0 94 37"
                width="94"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 17.1386L14.0112 10.7159V7.65295H17.1247V12.6657L0 20.5099V36.7339C9.9245 34.9145 15.1164 33.9292 20.4445 32.609C31.5054 30.1448 24.2276 18.1279 15.5678 25.6371C20.6488 19.6527 25.9011 17.8888 31.1357 18.457V0H0V17.1386Z"
                  fill="white"
                />
                <path
                  d="M70.8358 6.12232H77.8413V3.82637H70.8358V2.29595H79.3981V0H68.5006V9.18358H70.8358V6.12232Z"
                  fill="white"
                />
                <path
                  d="M82.5115 0V2.29595H86.7927V9.18358H89.1279V2.29595H93.4091V0H82.5115Z"
                  fill="white"
                />
                <path
                  d="M75.117 12.2446H86.7929V18.7495H93.4091V30.229H86.7929V36.7339H75.117V30.229H68.5006V18.7495H75.117V12.2446Z"
                  fill="white"
                />
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M51.6707 28.8153C46.1246 24.8741 40.2231 20.6804 34.2492 19.0576V0H65.3849V36.7339C61.2242 35.6039 56.587 32.3088 51.6707 28.8153ZM51.3739 7.65295H48.2604V24.0512C49.3073 24.7246 50.3463 25.4249 51.3739 26.1328V7.65295Z"
                  fill="white"
                />
              </svg>
            </div>
          )}
          <div className={styles.details}>
            <div className={styles.detailsTop}>
              <Typography variant="callout2" className={styles.tag} component="p">
                {swellEvent.updatedAt && formatDate(new Date(swellEvent.updatedAt))}
              </Typography>
            </div>
            <Typography variant="subtitle1" component="h2" className={styles.title}>
              {swellEvent.name}
            </Typography>
            <Typography variant="body1" component="p" className={styles.summary}>
              {swellEvent.summary}
            </Typography>
            {swellEvent?.basins?.length > 0 && (
              <ul className={styles.locations} data-testid={`${testId}-basins`}>
                {swellEvent.basins.map((basin) => (
                  <li className={styles.location} key={basin}>
                    <Typography variant="subtitle2" component="p" className={styles.locationText}>
                      {formatBasinText(basin)}
                    </Typography>
                  </li>
                ))}
              </ul>
            )}
          </div>
          {showThumbnail && (
            <div className={styles.thumbnail} data-testid={`${testId}-thumbnail`}>
              <CloudflareResizedImage
                className={styles.thumbnailImg}
                src={swellEvent?.thumbnailUrl}
                alt={swellEvent?.name}
                objectFit="cover"
                objectPosition="center"
                loading="lazy"
                placeholder={undefined}
                layout="fill"
                sizes="(min-width: 512px) 440px, 220px"
              />
              {swellEvent?.isLive && (
                <svg
                  className={styles.live}
                  data-testid={`${testId}-live`}
                  fill="none"
                  height="32"
                  viewBox="0 0 72 32"
                  width="72"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    fill="black"
                    fillOpacity="0.2"
                    height="30.5"
                    rx="4.25"
                    stroke="white"
                    strokeWidth="1.5"
                    width="70.5"
                    x="0.75"
                    y="0.75"
                  />
                  <path
                    d="M24.4683 10.44V21H31.1883V19.2H26.5083V10.44H24.4683ZM36.2847 21V10.44H34.2447V21H36.2847ZM46.2454 10.44L43.5454 18.135L40.8454 10.44H38.5054L42.5854 21H44.5054L48.5854 10.44H46.2454ZM57.7691 12.24V10.44H50.8091V21H57.7691V19.2H52.8491V16.56H56.8091V14.88H52.8491V12.24H57.7691Z"
                    fill="white"
                  />
                  <circle className={styles.liveDot} cx="14" cy="16" r="4" />
                </svg>
              )}
            </div>
          )}
        </WavetrakLink>
      </Alert>
    </Collapse>
  );
};

export default SwellEventCard;
