import React from 'react';
import classNames from 'classnames';

import { Map, TileLayer } from 'components/Map';
import MapNotification from 'components/MapNotification';
import MapPageMeta from 'components/MapPageMeta/MapPageMeta';
import MapPlaceholder from 'components/MapPlaceholder';
import PageRail from 'components/PageRail';
import useMap from 'hooks/map/useMap';
import useMapLocation from 'hooks/map/useMapLocation';
import useSetupMapPage from 'hooks/map/useSetupMapPage';
import { useMapError, useMapLoading } from 'selectors/mapV2';
import type { MapMode } from 'types/map';
import cloneChildrenWithProps from 'utils/cloneChildrenWithProps';
import mapOptions from 'utils/mapOptions';

import { ATTRIBUTION, MAP_TILE_URL } from './constants';
import useResetMapStateOnUnmount from './useResetMapStateOnUnmount';
import useSyncLocationToMap from './useSyncLocationToMap';
import useSyncMapBoundingBoxToStore from './useSyncMapBoundingBoxToStore';
import useSyncMapLocationToStore from './useSyncMapLocationToStore';

import styles from './MapV2.module.scss';

const mapClassName = (loading: boolean, error: boolean) =>
  // TODO: Make V1 base class once we have migrated all map pages
  classNames('sl-map-page', 'sl-map-page-v2', styles.slMapPageV2, {
    [styles.slMapPageV2Loading]: loading,
    [styles.slMapPageV2Error]: error,
  });

const getLeftRailClass = () => classNames('sl-left-rail', styles.slMapV2LeftRail);

const MapV2 = ({
  children,
  mapMode = 'buoy',
}: {
  children: React.ReactElement;
  mapMode?: MapMode;
}) => {
  const [map, setMap] = useMap();
  const loading = useMapLoading();
  const error = useMapError();

  useSetupMapPage();
  useResetMapStateOnUnmount();

  // Order matters here, we want to make sure we sync the location to the map first since the other hooks depend
  // the location of the map
  useSyncLocationToMap();
  useSyncMapBoundingBoxToStore();
  useSyncMapLocationToStore();
  const { location } = useMapLocation();
  const childrenWithMap = cloneChildrenWithProps(children, { map });

  return (
    <div className={mapClassName(!!loading, !!error)}>
      {location && <MapPageMeta location={location} mapMode={mapMode} />}
      <PageRail side="right" className={styles.slMapV2RightRail}>
        <MapPlaceholder show={!map} />
        <Map
          center={location.center}
          zoom={location.zoom}
          mapProps={{
            ...mapOptions,
            placeholder: <MapPlaceholder show />,
            whenCreated: (m) => {
              m.zoomControl.setPosition('topright');
              setMap(m);
            },
            preferCanvas: false,
          }}
        >
          <TileLayer attribution={ATTRIBUTION} url={MAP_TILE_URL} />
        </Map>
        <MapNotification hide={!map} mapMode={mapMode} />
      </PageRail>
      <PageRail side="left" className={getLeftRailClass()}>
        {childrenWithMap}
      </PageRail>
    </div>
  );
};

export default MapV2;
