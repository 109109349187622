import { useMemo } from 'react';
import { useAppSelector } from '../stores/hooks';

export const getPrimarySpotId = (state) => state.subregion.primarySpotId;
export const getSubregionFeed = (state) => state.subregion.feed;
export const getSubregionId = (state) => state.subregion.subregionId;

export const getSubregionAssociated = (state) => {
  const overview = state?.subregion?.overview;
  if (overview) {
    return overview.associated;
  }
  return null;
};

export const getSubregionOverviewLoading = (state) => state.subregion.overview.loading;

export const getSubregionOverviewData = (state) => {
  const overview = state?.subregion?.overview?.overview;
  if (overview) {
    return overview.data;
  }
  return null;
};

export const getFavoriteSubregions = (state) => {
  const data = state.subregion && state.subregion.favorites ? state.subregion.favorites.data : null;
  if (data) return data;
  return null;
};

export const getNearbySubregions = (state) => {
  const data = state.subregion && state.subregion.nearby ? state.subregion.nearby.data : null;
  if (data) {
    return data.subregions;
  }
  return null;
};

export const getForecastContent = (state) => {
  const data = state?.subregion?.forecast?.data ?? null;
  if (data) return data;
  return null;
};

/**
 * @param {import('../stores').AppState} state
 */
export const getSubregionLoading = (state) => state.subregion.loading;

export const useSubregionForecastArticles = () => {
  const allArticles = useAppSelector(getSubregionFeed);
  const forecastArticles = useMemo(
    () =>
      allArticles?.articles?.length > 0
        ? allArticles.articles.filter((article) => article.contentType === 'FORECAST')
        : [],
    [allArticles],
  );
  return forecastArticles;
};
