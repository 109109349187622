/**
 * @param {string | null} condition
 */
const conditionClassModifier = (condition) => {
  if (!condition) {
    return 'lotus';
  }

  return condition.toLowerCase().replace(/_/g, '-');
};

export default conditionClassModifier;
