import config from 'config';
import isNil from 'lodash/isNil';

const getSignInHref = (path: string | null = null) => {
  if (isNil(path) || path === '/') {
    return `${config.surflineHost}/sign-in`;
  }
  const redirectUrl = `${config.surflineHost}${path}`;
  return `${config.surflineHost}/sign-in?redirectUrl=${encodeURIComponent(redirectUrl)}`;
};

export default getSignInHref;
