/* eslint-disable import/prefer-default-export */
/* istanbul ignore file */
import { createApi } from '@reduxjs/toolkit/query/react';

import {
  BuoyDetailsResponse,
  BuoyReportResponse,
  BuoysBoundingBoxResponse,
  NearbyBuoysResponse,
  Station,
} from '../../types/buoys';
import { BoundingBox } from '../../types/map';
import { formatBuoyReportTableData } from '../../utils/formatBuoyReportTableData';

import { getAccessToken } from '../baseFetch';
import axiosBaseQuery from './axiosBaseQuery';

// We don't need to support server-side render for buoys so we can
// just retrieve the acccess token from cookies at the top level
const ACCESS_TOKEN = getAccessToken();

const TWENTY_MINUTES_IN_SECONDS = 1200;

interface GetBuoyReportArgs {
  id: Station['station']['id'];
}

interface GetBuoyReportResponse {
  data: ReturnType<typeof formatBuoyReportTableData>;
  associated: BuoyReportResponse['associated'];
}

interface NearbyBuoysArgs {
  latitude: number;
  longitude: number;
  swellHeight: string;
  limit?: number;
  distance?: number;
}

export const buoysAPI = createApi({
  reducerPath: 'buoysAPI',
  keepUnusedDataFor: TWENTY_MINUTES_IN_SECONDS,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getBuoyDetails: builder.query<BuoyDetailsResponse, Station['station']['id']>({
      query: (id) => ({
        path: `/kbyg/buoys/details/${id}`,
        params: { accesstoken: ACCESS_TOKEN },
      }),
    }),
    getNearbyBuoys: builder.query<NearbyBuoysResponse, NearbyBuoysArgs>({
      query: ({ latitude, longitude, swellHeight, limit, distance }) => ({
        path: `/kbyg/buoys/nearby?cacheEnabled=true&units[swellHeight]=${swellHeight}`,
        params: { latitude, longitude, limit, distance },
      }),
    }),
    getBuoysInBoundingBox: builder.query<BuoysBoundingBoxResponse, BoundingBox>({
      query: ({ north, south, east, west }) => ({
        path: `/kbyg/buoys/bounds`,
        params: { north, south, east, west, accesstoken: ACCESS_TOKEN },
      }),
    }),
    getBuoyReport: builder.query<GetBuoyReportResponse, GetBuoyReportArgs>({
      query: ({ id }) => ({
        path: `/kbyg/buoys/report/${id}?days=3`,
        params: { accesstoken: ACCESS_TOKEN },
      }),
      transformResponse: (response: BuoyReportResponse) => ({
        data: formatBuoyReportTableData(response),
        associated: response.associated,
      }),
    }),
  }),
});
