import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getBoundingBoxFromMap } from './map';
import { setMapBoundingBox } from '../../actions/mapV2';
import useMapLocation from '../../hooks/map/useMapLocation';
import useMap from '../../hooks/map/useMap';

/**
 * @description
 * We use the bounding box of the map for fetching items within the map bounds
 * so we want to sync it to the store
 */
const useSyncMapBoundingBoxToStore = () => {
  const [map] = useMap();
  const dispatch = useDispatch();
  const { location, locationLoaded } = useMapLocation();

  useEffect(() => {
    if (map && locationLoaded) {
      dispatch(setMapBoundingBox(getBoundingBoxFromMap(map)));
    }
  }, [dispatch, locationLoaded, location, map]);
};

export default useSyncMapBoundingBoxToStore;
