import createReducer from '../createReducer';
import { FETCH_SPOT_MAP_DATA_SUCCESS } from '../../actions/spotMapData';

export const initialState = {
  subregions: [],
  doCluster: true,
};

const handlers = {};

handlers[FETCH_SPOT_MAP_DATA_SUCCESS] = (_, { subregions, doCluster }) => ({
  subregions,
  doCluster,
});

export default createReducer(handlers, initialState);
