import React from 'react';
import classNames from 'classnames';

export const Facebook: React.FC<{ className?: string; 'data-testid'?: string }> = ({
  className = '',
  'data-testid': dataTestId,
}) => (
  <svg
    className={classNames(className, 'quiver-facebook-icon')}
    data-testid={dataTestId}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <title>Facebook Logo</title>
    <path
      d={`M13.3639826,5.82848272 L10.515504,5.82848272 L10.515504,3.96029807 C10.515504,3.2587042
        10.9805009,3.09513558 11.3080108,3.09513558 L13.3181536,3.09513558 L13.3181536,0.0108052163
        L10.5497827,0 C7.4766301,0 6.77727179,2.30039329 6.77727179,3.77251087 L6.77727179,
        5.82848272 L5,5.82848272 L5,9.00670669 L6.77727179,9.00670669 L6.77727179,18 L10.515504,18
        L10.515504, 9.00670669 L13.0379632,9.00670669 L13.3639826,5.82848272 L13.3639826,
        5.82848272 Z`}
    />
  </svg>
);

export default Facebook;
