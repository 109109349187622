import { useState, useEffect, useCallback } from 'react';

/**
 * @description A react hook that will return an object with the
 * width and height of the browser window
 * TODO: Get breakpoint sizes from @wavetrak/theme
 */
export function useDeviceSize() {
  const isClient = typeof window === 'object';

  const getSize = useCallback(
    () => ({
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
      isDesktop: isClient ? window.innerWidth > 976 : false,
      isMobile: isClient ? window.innerWidth < 512 : false,
      isTablet: isClient ? window.innerWidth > 512 && window.innerWidth < 976 : false,
    }),
    [isClient],
  );

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize());
    }

    if (isClient) {
      window.addEventListener('resize', handleResize);
    }
    return () => {
      if (isClient) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, [getSize, isClient]);

  return windowSize;
}

export default useDeviceSize;
