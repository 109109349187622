import createReducer from '../createReducer';
import {
  FETCH_BUOYS,
  FETCH_BUOYS_SUCCESS,
  FETCH_BUOYS_FAILURE,
  CLEAR_BUOYS,
} from '../../actions/spot';

export const initialState = {
  loading: false,
  data: null,
  error: null,
};

const handlers = {};

handlers[FETCH_BUOYS] = (state) => ({
  ...state,
  loading: true,
  data: null,
  error: null,
});

handlers[FETCH_BUOYS_SUCCESS] = (state, { buoys }) => ({
  ...state,
  loading: false,
  data: buoys,
  error: null,
});

handlers[FETCH_BUOYS_FAILURE] = (state, { error }) => ({
  ...state,
  loading: false,
  data: null,
  error,
});

handlers[CLEAR_BUOYS] = (state) => ({
  ...state,
  loading: false,
  data: null,
  error: null,
});

export default createReducer(handlers, initialState);
