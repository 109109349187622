import React from 'react';

const AddOutlineIcon = ({ fill = '#171717' }: { fill?: string }) => (
  <svg
    width="44"
    height="44"
    viewBox="0 0 44 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="add-outline-icon"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22 31C26.9706 31 31 26.9706 31 22C31 17.0294 26.9706 13 22 13C17.0294 13 13 17.0294 13 22C13 26.9706 17.0294 31 22 31ZM22 33C28.0751 33 33 28.0751 33 22C33 15.9249 28.0751 11 22 11C15.9249 11 11 15.9249 11 22C11 28.0751 15.9249 33 22 33Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.7464 17.999C22.7464 17.5848 22.4106 17.249 21.9964 17.249C21.5822 17.249 21.2464 17.5848 21.2464 17.999V21.2515H17.9954C17.5811 21.2515 17.2454 21.5873 17.2454 22.0015C17.2454 22.4157 17.5811 22.7515 17.9954 22.7515H21.2464V26.0039C21.2464 26.4181 21.5822 26.7539 21.9964 26.7539C22.4106 26.7539 22.7464 26.4181 22.7464 26.0039V22.7515H25.9974C26.4116 22.7515 26.7474 22.4157 26.7474 22.0015C26.7474 21.5873 26.4116 21.2515 25.9974 21.2515H22.7464V17.999Z"
      fill={fill}
    />
  </svg>
);

export default AddOutlineIcon;
