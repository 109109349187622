import React from 'react';

export const Earth = ({ className }: { className?: string }) => (
  <svg className={className} width="16" height="16" viewBox="0 0 16 16">
    <path
      fill="#000"
      fillRule="nonzero"
      d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM5.875 1.56c-.323.227-.493.684-.77.788-.29.107-.529-.06-.804-.03a6.751 6.751 0 0 1 1.574-.757zM8 14.782A6.781 6.781 0 0 1 3.957 2.556c-.185.235-.402.589-.361.644.126.172.503.096.604 0 .1-.096.603-.306.804-.306.201 0 .804.364 1.094.301.529-.113.48-.49.675-.603.165-.095.445 0 .747-.134.301-.135.188-.822-.066-1.006-.169-.123-.51-.128-.83-.093.444-.092.904-.14 1.376-.14 1.358 0 2.622.4 3.682 1.088-.143.431-1.002.037-1.577.417-.604.4-.6 1.119-.853 1.3-.867.627-2.395.75-2.746.882-.306.114-.73.302-.709.702.02.381.269.695.682.64.413-.056.963-.406 1.294-.406.33 0 .578.523.77.66.193.138.716.11.771.413.055.303-.055.88-.495.88s-1.377-.825-1.652-.907c-.275-.083-.578.082-.743-.028-.165-.11-.523-.465-.853-.413-.404.065-.56.446-.744.597-.204.169-.757.307-.853.615-.137.44-.109 1.505.092 1.954.253.565 1.819 1.005 2.055 1.395.184.302-.128.715.12 1.045.247.33.395.335.513.487.227.29-.073.752.275 1.01.65.477 2.034.143 2.258-.496.094-.272-.072-.703.009-1.092.055-.266.38-.634.486-.835.101-.193.01-.817.202-1.064.193-.248.798-.322.927-.395.107-.06.113-.38.22-.44.125-.071.456.074.578 0 .118-.073.16-.33.242-.495.083-.165.627-.36.625-.652-.003-.725-1.313-.589-1.335-.918-.025-.366.311-.588.743-.633.324-.034.88.247.963.468.11.294-.085.373.165.66.282.324.795.36.927.585.096.162-.098.34.092.6.07.095.307.252.554.29A6.782 6.782 0 0 1 8 14.781z"
    />
  </svg>
);

export default Earth;
