import React, { useEffect, useState, useCallback, useRef } from 'react';
import { difference } from 'lodash';
import {
  BrazeEvents,
  getAppboy,
  getContentCard,
  getWindow,
  trackContentCardImpressions,
  UserState,
} from '@surfline/web-common';
import { Box } from '@mui/material';

export interface Card {
  name?: string;
  id?: string;
  extras?: Record<string, unknown> | null;
}

interface WithCardProps {
  card?: Card;
  cards?: Card[];
  defaultCard?: Card;
  defaultCards?: Card[];
  dataTest?: string;
  segmentProperties?: any;
  className?: string;
  testId?: string;
  name?: string;
  isPremium?: boolean;
  user?: UserState['details'] | null;
  daysToDisplay?: Array<number>;
  isDesktop?: boolean;
  isLink?: boolean;
  warning?: {
    alert?: boolean;
    message?: string;
    type?: string;
  };
}

interface WithContentCardsProps {
  children: React.ReactElement<WithCardProps>;
}

const isControlCard = (card: Card) => card?.extras?.is_control === 'true';
const DEFAULT_BRAZE_TIMEOUT = 3000; // ms

const WithContentCards: React.FC<WithContentCardsProps> = ({ children }) => {
  const { card, cards, defaultCard, defaultCards } = children.props;
  const [fetchedCard, setFetchedCard] = useState(card);
  const [fetchedCards, setFetchedCards] = useState(cards);
  const [fetched, setFetched] = useState(false);
  const eventListenersAdded = useRef(false);
  // @ts-ignore
  const brazeTimedOut = useRef(!!getWindow()?.document[BrazeEvents.BRAZE_TIMEOUT]);

  const fetchCards = useCallback(() => {
    setFetched(true);
    if (card?.name) {
      const contentCard = getContentCard(card.name);
      if (isControlCard(contentCard)) {
        trackContentCardImpressions([contentCard]);
        setFetchedCard(defaultCard);
      } else if (defaultCard && contentCard) {
        /*
          Verify all entries in the defaultCard are present in the contentCard from Braze
          If not present then fall back to defaultCard
        */
        const requiredKeys = Object.keys(defaultCard?.extras);
        const keysPresent = Object.keys(contentCard?.extras);
        const isCardValid = difference(requiredKeys, keysPresent).length === 0;
        setFetchedCard(isCardValid ? contentCard : defaultCard);
      } else {
        setFetchedCard(contentCard ?? defaultCard);
      }
    }
    if (cards) {
      const contentCards = cards.map(({ name }: { name: string }) => {
        const listCard = getContentCard(name);
        if (isControlCard(listCard)) {
          trackContentCardImpressions([listCard]);
          return null;
        }
        return listCard;
      });
      setFetchedCards(contentCards.includes(null) ? defaultCards : contentCards);
    }
  }, [card, cards, defaultCard, defaultCards]);

  const appboyReady = getAppboy() && getAppboy().getCachedContentCards;
  useEffect(() => {
    let brazeTimer: string | number | NodeJS.Timeout | undefined;
    if (!fetched) {
      if (appboyReady || brazeTimedOut.current) fetchCards();
      else if (!eventListenersAdded.current) {
        window.document.addEventListener(BrazeEvents.BRAZE_LOADED, fetchCards);
        window.document.addEventListener(BrazeEvents.BRAZE_TIMEOUT, () => {
          // @ts-ignore
          window.document[BrazeEvents.BRAZE_TIMEOUT] = true;
          brazeTimedOut.current = true;
          return fetchCards();
        });
        eventListenersAdded.current = true;

        brazeTimer = setTimeout(() => {
          if (!fetched) {
            // If nothing has been fetched after timeout interval since adding the
            // braze event listeners, we'll go ahead with the default value
            fetchCards();
          }
        }, DEFAULT_BRAZE_TIMEOUT);
      }
    }
    return () => {
      eventListenersAdded.current = false;
      window.document.removeEventListener(BrazeEvents.BRAZE_LOADED, fetchCards);
      if (brazeTimer) {
        clearTimeout(brazeTimer);
      }
    };
  }, [appboyReady, fetchCards, fetched]);

  const componentWithCard = React.cloneElement(children, {
    ...children.props,
    card: fetchedCard,
    cards: fetchedCards,
  });

  return <Box data-testid="with-content-cards">{componentWithCard}</Box>;
};

interface WithContentCardsHOC {
  (Component: React.ComponentType): React.FC<WithCardProps>;
}

// eslint-disable-next-line react/display-name
const withContentCards: WithContentCardsHOC = (Component) => (props) =>
  (
    <WithContentCards>
      <Component {...props} />
    </WithContentCards>
  );

export default withContentCards;
