import React from 'react';
import PropTypes from 'prop-types';
import LocationIcon from 'components/Icons/LocationIcon';
import { color } from 'utils/colors';
import CustomMarker from './CustomMarker';

const LocationMarker = ({ map, position, fill, size, children, onClick, usingPortal }) => (
  <CustomMarker
    map={map}
    usingPortal={usingPortal}
    icon={<LocationIcon size={size} fill={fill} />}
    iconSize={[size, size]}
    iconAnchor={[size / 2, size]}
    position={position}
    onClick={onClick}
  >
    {children}
  </CustomMarker>
);

LocationMarker.propTypes = {
  fill: PropTypes.string,
  size: PropTypes.number,
  children: PropTypes.instanceOf(React.Component),
  position: PropTypes.shape({
    lat: PropTypes.number,
    lon: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func,
  map: PropTypes.shape(),
  usingPortal: PropTypes.bool,
};

LocationMarker.defaultProps = {
  fill: color('dark-blue', 50),
  size: 30,
  children: null,
  onClick: null,
  map: null,
  usingPortal: false,
};

export default LocationMarker;
