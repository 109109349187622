import React, { type MouseEvent, type ReactNode } from 'react';
import classNames from 'classnames';
import {
  cookieCategories,
  getWindow,
  minifyHtmlSnippet,
  segmentIntegrations,
  setSegmentIntegrations,
  updateAdsBasedOnConsent,
  updateAppsFlyerBasedOnConsent,
} from '@surfline/web-common';
import { Typography } from '@mui/material';

import styles from './OneTrust.module.scss';

interface OneTrustScriptsProps {
  autoBlock?: boolean;
  domainScript: string;
  language?: 'browser' | 'html';
}

// allow GA4 if performance cookie is accepted
const updateGABasedOnConsent = () =>
  minifyHtmlSnippet(`
    if (OnetrustActiveGroups.indexOf('${cookieCategories.Performance}') != -1)
      { gtag('consent', 'update', {'analytics_storage': 'granted'})};
  `);

/**
 * OneTrust Cookies Consent Notice for www.surfline.com
 */
/* eslint-disable @next/next/no-sync-scripts */
export const OneTrustScripts = ({
  autoBlock = false,
  domainScript,
  language = 'browser',
}: OneTrustScriptsProps) => (
  <>
    {autoBlock && (
      <script
        src={`https://cdn.cookielaw.org/consent/${domainScript}/OtAutoBlock.js`}
        type="text/javascript"
      />
    )}
    <script
      data-domain-script={domainScript}
      src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
      type="text/javascript"
      {...(language === 'html' ? { 'data-document-language': 'true' } : {})}
    />
    <script
      ccpa-opt-out-geo="us"
      ccpa-opt-out-ids={`${cookieCategories.Targeting},${cookieCategories['Sale of Personal Data']}`}
      ccpa-opt-out-lspa="true"
      src="https://cdn.cookielaw.org/opt-out/otCCPAiab.js"
      type="text/javascript"
    />
    <script
      type="text/javascript"
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: `
          function OptanonWrapper(){
            ${setSegmentIntegrations(segmentIntegrations)}
            ${updateAdsBasedOnConsent()}
            ${updateAppsFlyerBasedOnConsent()}
            ${updateGABasedOnConsent()}
          };
        `,
      }}
    />
  </>
);

interface OneTrustLinkProps {
  className?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  children?: ReactNode;
}

export const OneTrustLink = ({ children, className, onClick }: OneTrustLinkProps) => (
  <button
    className={classNames(styles.button, className)}
    data-testid="one-trust-link"
    onClick={(event) => {
      getWindow()?.OneTrust?.ToggleInfoDisplay();
      if (onClick) {
        onClick(event);
      }
    }}
    type="button"
  >
    <Typography component="span" variant="body1">
      {children ?? 'Do Not Sell My Personal Information'}
    </Typography>
  </button>
);

export default OneTrustScripts;
