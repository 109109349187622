import { slugify } from '@surfline/web-common';

const sectionLinkTitles = new Map();
sectionLinkTitles.set('Surf Spots', 'Surf Spots');
sectionLinkTitles.set('Regional Forecast', 'Regional Forecasts');
sectionLinkTitles.set('Map Area', 'Locations');
sectionLinkTitles.set('Travel Guide', 'Travel');
sectionLinkTitles.set('Surf News', 'Surf News');

const getViewAllLink = (searchTerm: string, sectionTitle: string) => ({
  href: `/search/${searchTerm}#${slugify(sectionTitle)}`,
  title: sectionLinkTitles.get(sectionTitle),
});

export default getViewAllLink;
