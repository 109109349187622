import classNames from 'classnames';
import React from 'react';

export const Twitter: React.FC<{ className?: string; 'data-testid'?: string }> = ({
  className = '',
  'data-testid': dataTestId,
}) => (
  <svg
    className={classNames(className, 'quiver-twitter-icon')}
    data-testid={dataTestId}
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
  >
    <title>X Logo</title>
    <path d="M 10.710938 7.617188 L 17.414062 0 L 15.824219 0 L 10.007812 6.613281 L 5.359375 0 L 0 0 L 7.027344 10.003906 L 0 17.992188 L 1.589844 17.992188 L 7.730469 11.003906 L 12.640625 17.992188 L 18 17.992188 Z M 8.539062 10.089844 L 7.824219 9.09375 L 2.160156 1.167969 L 4.597656 1.167969 L 9.171875 7.566406 L 9.882812 8.5625 L 15.828125 16.875 L 13.386719 16.875 Z M 8.539062 10.089844 " />
  </svg>
);

export default Twitter;
