import React, { Component } from 'react';
import isEqual from 'lodash/isEqual';
import { v1 as uuidV1 } from 'uuid';
import { getWindow } from '@surfline/web-common';
import writeDebugLog from 'utils/writeDebugLog';

export interface AdConfig {
  adClass?: string;
  adId: string;
  adSize: string;
  adSizeDesktop?: googletag.GeneralSize;
  adSizeMappings?: googletag.SizeMappingArray;
  adSizeMappingsSHE?: Array<Array<number> | number | string>;
  adSizeMobile?: googletag.GeneralSize;
  adSizes: googletag.GeneralSize;
  adTargets: Array<Array<string>>;
  adUnit: string;
  adViewType: string;
  additionalMappingSizes?: googletag.SizeMappingArray;
  autoRefresh?: boolean;
  collapseEmpty?: boolean;
  customAdSize?: Array<number>;
  fluid?: boolean;
  isOutOfPage?: boolean;
}

interface GoogleDFPProps {
  adConfig: AdConfig;
  useUuid?: boolean;
}

interface GoogleDFPState {
  uuid: string | null;
}

class GoogleDFP extends Component<GoogleDFPProps, GoogleDFPState> {
  // eslint-disable-next-line react/static-property-placement
  public static defaultProps = {
    useUuid: false,
  };

  adSlot!: googletag.Slot;

  // eslint-disable-next-line react/no-unused-class-component-methods
  ref!: HTMLDivElement | null;

  constructor(props: GoogleDFPProps) {
    super(props);
    this.state = { uuid: null };
  }

  componentDidMount() {
    this.setInitialState();
  }

  componentDidUpdate(prevProps: Readonly<GoogleDFPProps>, prevState: { uuid: string | null }) {
    const { uuid } = this.state;
    const { adConfig, useUuid } = this.props;
    if (!isEqual(prevProps.adConfig, adConfig)) {
      this.setState({ uuid: uuidV1() });
    }
    if (prevState.uuid !== uuid) {
      const { adId, adUnit } = adConfig;
      const currDivId = this.getUniqueAdId(adId, adUnit, useUuid, uuid);
      // init or re-initialize ad if new config passed in before component unmounts
      this.initializeAd(adConfig, currDivId);
    }
  }

  componentWillUnmount() {
    // destroy all ad slots
    const {
      adConfig: { adId, adUnit },
      useUuid,
    } = this.props;
    const { uuid } = this.state;
    const win = getWindow();
    const currDivId = this.getUniqueAdId(adId, adUnit, useUuid, uuid);
    if (win?.blogherads?.adq) {
      writeDebugLog(`GoogleDFP - pushing blogherads.destroySlots(${currDivId}) for adId=${adId}`);
      win.blogherads.adq.push(() => {
        win.blogherads?.destroySlots([currDivId]);
      });
    } else if (getWindow()?.googletag?.cmd) {
      googletag.cmd.push(() => {
        googletag.destroySlots([this.adSlot]);
      });
    }
  }

  // maintain backwards compatibility for default GAM units and optionally
  // append uuid to div id to ensure unique DOM element for each unit
  // eslint-disable-next-line class-methods-use-this
  getUniqueAdId = (
    adId: string,
    adUnit: string,
    useUuid: boolean | undefined,
    uuid: string | null,
  ) => (useUuid ? `${adId || adUnit}-${uuid}` : adId || adUnit);

  // ad not rendered until uuid set; uuid also optionally used for unique div id
  setInitialState = () => this.setState({ uuid: uuidV1() });

  initializeAd =
    /* istanbul ignore next */
    (prevAdConfig: AdConfig, currDivId: string) => {
      const { adConfig } = this.props;
      const {
        adId,
        adSize,
        adSizeMappingsSHE,
        adTargets,
        additionalMappingSizes,
        autoRefresh = true,
        collapseEmpty,
        customAdSize,
        fluid,
      } = adConfig;
      const win = getWindow();
      writeDebugLog(
        `GoogleDFP initializeAd adId=${adId} adSize=${JSON.stringify(
          adSize,
        )} adSizeMappingsSHE=${JSON.stringify(adSizeMappingsSHE)} currDivId=${currDivId}`,
      );
      if (!win?.blogherads) {
        win.blogherads = { adq: [] } as unknown as Window['blogherads'];
      }
      win?.blogherads?.adq?.push?.(() => {
        writeDebugLog(`blogherads.adq.push handler for adId=${adId}`);
        // chain options onto var currConfig to share targeting and display commands
        let currConfig: any;
        if (adSizeMappingsSHE) {
          // responsive ads with different size options at different viewport widths
          currConfig = win?.blogherads?.defineResponsiveSlot?.(adSizeMappingsSHE, currDivId);
          // manually add additional sizes to pre-configured mappings
          if (additionalMappingSizes?.length) {
            additionalMappingSizes.forEach((size) => {
              currConfig?.addSize?.(size[0], size[1]);
            });
          }
        } else if (adSize) {
          // static ad sizes i.e. 'banner'
          currConfig = win?.blogherads?.defineSlot?.(adSize, currDivId);
        } else if (customAdSize) {
          // static ad with additional, custom size options
          currConfig = win?.blogherads
            ?.defineCustomSlot?.(currDivId, customAdSize)
            // @ts-ignore
            ?.setDefaultAutoRefreshTime?.(0);
        } else if (fluid) {
          currConfig = win?.blogherads
            ?.defineSlot?.('nativemini', currDivId)
            // @ts-ignore
            ?.setDefaultAutoRefreshTime?.(0);
        }
        if (currConfig && collapseEmpty) {
          // collapse empty divs only if explicitly set in config
          currConfig?.setClsOptimization?.('disable');
        }
        if (currConfig && !autoRefresh) {
          // do not refresh ad if autoRefresh is true
          currConfig?.setAutoRefreshTime?.(0);
        }
        if (currConfig && adTargets?.length) {
          // attach ad target key/values
          adTargets.forEach((targetData) => {
            currConfig?.setTargeting?.(targetData[0], targetData[1]);
          });
        }
        if (currConfig) {
          // set sub path and display
          currConfig?.setSubAdUnitPath?.(adId)?.display?.();
        }
      });
    };

  render() {
    const { uuid } = this.state;
    const {
      adConfig: { adId, adClass, adUnit },
      useUuid,
    } = this.props;
    const uniqueAdId = this.getUniqueAdId(adId, adUnit, useUuid, uuid);
    return (
      <div
        className="quiver-google-dfp"
        ref={(el) => {
          // eslint-disable-next-line react/no-unused-class-component-methods
          this.ref = el;
        }}
      >
        {uuid ? <div id={uniqueAdId} className={adClass} data-testid="google-dfp-ad" /> : null}
      </div>
    );
  }
}

export default GoogleDFP;
