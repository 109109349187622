import { oneDaySeconds } from 'utils/time';
import createReducer from '../../createReducer';
import {
  FETCH_GRAPH_WIND_FORECAST,
  FETCH_GRAPH_WIND_FORECAST_SUCCESS,
  FETCH_GRAPH_WIND_FORECAST_FAILURE,
} from '../../../actions/graphs';
import createDayHourlyData from './helpers/createDayHourlyData';
import createFiveDayHourlyWind from './helpers/createFiveDayHourlyData';

/**
 * @typedef {import('../../../propTypes/wind').Wind} Wind
 */

/**
 * @typedef {Object} GraphRatingState
 * @property {{ lat: number, lon: number }} [location]
 * @property {string} [error]
 * @property {boolean} loading
 * @property {number} utcOffset
 * @property {number} overallMaxSpeed
 * @property {Wind[][]} [days]
 * @property {Wind[][]} [hourly]
 */

/**
 * @type {GraphWindState}
 */
export const initialState = {
  error: null,
  loading: true,
  units: null,
  utcOffset: null,
  location: null,
  overallMaxSpeed: null,
  days: null,
  hourly: null,
};

const handlers = {};

handlers[FETCH_GRAPH_WIND_FORECAST] = () => initialState;

handlers[FETCH_GRAPH_WIND_FORECAST_SUCCESS] = (state, { response, desired16DayInterval }) => {
  const returnedDays = createDayHourlyData(response.data.wind, 16, desired16DayInterval);
  const [lastDayOfData] = returnedDays.slice(-1);
  const [lastHourOfData] = lastDayOfData.slice(-1);

  const missingDays = Array.from({ length: 16 - returnedDays.length }, (_, index) =>
    Array.from({ length: 24 / desired16DayInterval }, (_, hourIndex) => ({
      timestamp: lastHourOfData.timestamp + oneDaySeconds * (index + 1) + hourIndex,
      utcOffset: lastHourOfData.utcOffset,
      speed: 0,
      direction: 0,
      directionType: 'Offshore',
      gust: 0,
      optimalScore: 0,
    })),
  );
  const combinedDays = [].concat(returnedDays, missingDays);
  return {
    ...state,
    loading: false,
    units: response.associated.units,
    utcOffset: response.associated.utcOffset,
    location: response.associated.location,
    overallMaxSpeed: Math.max(...response.data.wind.map(({ speed }) => speed)),
    days: combinedDays,
    hourly: createFiveDayHourlyWind(response.data.wind),
  };
};

handlers[FETCH_GRAPH_WIND_FORECAST_FAILURE] = (state, { error }) => ({
  ...state,
  error,
  loading: false,
});

export default createReducer(handlers, initialState);
