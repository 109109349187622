import getUserType from './getUserType';

const editorialHeroAdSizesPremium = {
  adUnit: '/1024858/Horizontal_Variable',
  adId: 'Horizontal_Variable',
  adClass: 'sl-horizontal-variable',
  adSizes: [
    [1170, 250],
    [750, 250],
    [320, 80],
  ],
  adSizeMappings: [
    [[1200, 0], [[1170, 250]]],
    [[750, 0], [[750, 250]]],
    [[320, 0], [[320, 80]]],
    [[0, 0], [[320, 80]]],
  ],
  adSizeMappingsSHE: [
    [[992, 0], 'flexbanner'],
    [[768, 0], 'banner'],
    [[0, 0], 'tinybanner'],
  ],
  additionalMappingSizes: [
    ['flexbanner', [[1170, 250]]],
    ['banner', [[750, 250]]],
    ['tinybanner', [[320, 80]]],
  ],
};

const editorialHeroAdSizes = {
  adUnit: '/1024858/Horizontal_Variable',
  adId: 'Horizontal_Variable',
  adClass: 'sl-horizontal-variable',
  adSizes: [
    'fluid',
    [1170, 250],
    [1170, 160],
    [990, 90],
    [970, 250],
    [320, 50],
    [970, 90],
    [970, 160],
    [320, 80],
    [300, 250],
    [750, 250],
    [750, 160],
    [728, 90],
  ],
  adSizeMappings: [
    [
      [1200, 0],
      ['fluid', [1170, 250], [1170, 160], [990, 90], [970, 250], [970, 160], [970, 90], [728, 90]],
    ],
    [
      [992, 0],
      ['fluid', [990, 90], [970, 250], [970, 160], [970, 90], [750, 250], [728, 90]],
    ],
    [
      [768, 0],
      ['fluid', [750, 250], [750, 160], [728, 90]],
    ],
    [
      [320, 0],
      ['fluid', [320, 80], [320, 50]],
    ],
    [
      [0, 0],
      ['fluid', [320, 50]],
    ],
  ],
  adSizeMappingsSHE: [
    [[992, 0], 'flexbanner'],
    [[768, 0], 'banner'],
    [[0, 0], 'tinybanner'],
  ],
  additionalMappingSizes: [
    ['flexbanner', [[1170, 250]]],
    ['banner', [[750, 250]]],
    ['tinybanner', [[320, 80]]],
  ],
};

const editorialTaxonomySizes = {
  adClass: 'sl-horizontal-variable',
  adSizes: [
    [970, 90],
    [728, 90],
    [300, 250],
  ],
  adSizeMappings: [
    [
      [1200, 0],
      [
        [970, 90],
        [970, 250],
        [728, 90],
      ],
    ],
    [
      [992, 0],
      [
        [970, 90],
        [970, 250],
        [728, 90],
      ],
    ],
    [[768, 0], [[728, 90]]],
    [[320, 0], [[300, 250]]],
    [
      [0, 0],
      [
        [970, 90],
        [970, 250],
        [728, 90],
      ],
    ],
  ],
  adSizeMappingsSHE: [
    [[992, 0], 'flexbanner'],
    [[768, 0], 'banner'],
    [[0, 0], 'medrec'],
  ],
};

const adConfigMap = {
  camHostAd: {
    adUnit: '/1024858/Text_Link',
    adId: 'Text_Link',
    adSizes: ['fluid'],
    adSizeMappings: [],
    adViewType: 'SPOT',
    fluid: true,
  },
  // cam companion ad to the right of the player on desktop
  camCompanionAd: {
    adUnit: '/1024858/SL_KBYG_Cams_TopRightRail',
    adId: 'SL_KBYG_Cams_TopRightRail',
    adSizes: [[300, 250]],
    adSizeDesktop: [[300, 250]],
    adSizeMobile: [],
    adSizeMappings: [[[300, 250]], [[0, 0]]],
    adSizeMappingsSHE: [[[0, 0], 'medrec']],
    adViewType: 'SPOT',
    collapseEmpty: true,
  },
  // cam companion ad below the player on small desktop sizes and below
  camCompanionAdBelowCam: {
    adUnit: '/1024858/SL_KBYG_BelowCam',
    adId: 'SL_KBYG_Cams_BelowCam',
    adSizes: [
      [970, 90],
      [728, 90],
      [320, 50],
    ],
    adSizeDesktop: [
      [970, 90],
      [728, 90],
    ],
    adSizeMobile: [[320, 50]],
    adSizeMappings: [
      [
        [970, 90],
        [728, 90],
      ],
      [[320, 50]],
    ],
    adSizeMappingsSHE: [
      [[970, 90], 'banner'],
      [[728, 90], 'banner'],
      [[320, 50], 'tinybanner'],
    ],
    adViewType: 'SPOT',
    collapseEmpty: true,
  },
  contest_sos_top: {
    adUnit: '/1024858/SL_Cuervo_Top',
    adId: 'SL_Cuervo_Top',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_sos_mid: {
    adUnit: '/1024858/SL_Cuervo_MidPage',
    adId: 'SL_Cuervo_MidPage',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_sos_bottom: {
    adUnit: '/1024858/SL_Cuervo_Bottom',
    adId: 'SL_Cuervo_Bottom',
    adSizes: [
      [300, 250],
      [970, 250],
    ],
    adSizeDesktop: [970, 250],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_top_2019: {
    adUnit: '/1024858/SL_WOTW_Top',
    adId: 'SL_WOTW_Top',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_mid_2019: {
    adUnit: '/1024858/SL_WOTW_MidPage',
    adId: 'SL_WOTW_MidPage',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_bottom_2019: {
    adUnit: '/1024858/SL_WOTW_Bottom',
    adId: 'SL_WOTW_Bottom',
    adSizes: [
      [300, 250],
      [728, 90],
      [970, 250],
    ],
    adSizeDesktop: [970, 250],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_regional_top_2019: {
    adUnit: '/1024858/SL_WOTW_Regional_Top',
    adId: 'SL_WOTW_Regional_Top',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_regional_mid_2019: {
    adUnit: '/1024858/SL_WOTW_Regional_Mid',
    adId: 'SL_WOTW_Regional_Mid',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_regional_bottom_2019: {
    adUnit: '/1024858/SL_WOTW_Regional_Bottom',
    adId: 'SL_WOTW_Regional_Bottom',
    adSizes: [
      [300, 250],
      [970, 250],
    ],
    adSizeDesktop: [970, 250],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_top_2020: {
    adUnit: '/1024858/SL_WOTW_Top20',
    adId: 'SL_WOTW_Top20',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_mid_2020: {
    adUnit: '/1024858/SL_WOTW_MidPage20',
    adId: 'SL_WOTW_MidPage20',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_bottom_2020: {
    adUnit: '/1024858/SL_WOTW_Bottom20',
    adId: 'SL_WOTW_Bottom20',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_regional_top_2020: {
    adUnit: '/1024858/SL_WOTW_Regional_Top20',
    adId: 'SL_WOTW_Regional_Top20',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_regional_mid_2020: {
    adUnit: '/1024858/SL_WOTW_Regional_Mid20',
    adId: 'SL_WOTW_Regional_Mid20',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_regional_bottom_2020: {
    adUnit: '/1024858/SL_WOTW_Regional_Bottom20',
    adId: 'SL_WOTW_Regional_Bottom20',
    adSizes: [
      [300, 250],
      [970, 250],
    ],
    adSizeDesktop: [970, 250],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_top_2021: {
    adUnit: '/1024858/SL_WOTW_Top21',
    adId: 'SL_WOTW_Top21',
    adSizes: [
      [300, 250],
      [728, 90],
      [970, 250],
    ],
    adSizeDesktop: [970, 250],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_mid_2021: {
    adUnit: '/1024858/SL_WOTW_MidPage21',
    adId: 'SL_WOTW_MidPage21',
    adSizes: [
      [300, 250],
      [728, 90],
      [970, 250],
    ],
    adSizeDesktop: [970, 250],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_bottom_2021: {
    adUnit: '/1024858/SL_WOTW_Bottom21',
    adId: 'SL_WOTW_Bottom21',
    adSizes: [
      [300, 250],
      [728, 90],
      [970, 250],
    ],
    adSizeDesktop: [970, 250],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_regional_top_2021: {
    adUnit: '/1024858/SL_WOTW_Regional_Top21',
    adId: 'SL_WOTW_Regional_Top21',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_regional_mid_2021: {
    adUnit: '/1024858/SL_WOTW_Regional_Mid21',
    adId: 'SL_WOTW_Regional_Mid21',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeDesktop: [728, 90],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  contest_regional_bottom_2021: {
    adUnit: '/1024858/SL_WOTW_Regional_Bottom21',
    adId: 'SL_WOTW_Regional_Bottom21',
    adSizes: [
      [300, 250],
      [970, 250],
    ],
    adSizeDesktop: [970, 250],
    adSizeMobile: [300, 250],
    adSizeMappingsSHE: [
      [[728, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'CONTEST',
  },
  editorialTaxonomyMidPage: {
    adUnit: '/1024858/Horizontal_Variable_MidPage',
    adId: 'Horizontal_Variable_MidPage',
    divId: 'Horizontal_Variable_MidPage',
    ...editorialTaxonomySizes,
  },
  editorialTaxonomyBelowNav: {
    adUnit: '/1024858/Horizontal_Variable_BelowNav',
    adId: 'Horizontal_Variable_BelowNav',
    adClass: 'sl-feed-ad__below-nav',
    adSizes: [
      [970, 90],
      [970, 250],
      [728, 90],
      [320, 50],
    ],
    adSizeMappings: [
      [
        [1200, 0],
        [
          [970, 90],
          [970, 250],
          [728, 90],
        ],
      ],
      [
        [992, 0],
        [
          [970, 90],
          [970, 250],
          [728, 90],
        ],
      ],
      [[768, 0], [[728, 90]]],
      [[320, 0], [[320, 50]]],
      [
        [0, 0],
        [
          [970, 90],
          [970, 250],
          [728, 90],
        ],
      ],
    ],
    adSizeMappingsSHE: [
      [[992, 0], 'flexbanner'],
      [[768, 0], 'banner'],
      [[0, 0], 'tinybanner'],
    ],
    additionalMappingSizes: [['tinybanner', [[320, 50]]]],
  },
  editorial_article_superheader_premium: {
    adViewType: 'CONTENT_ARTICLE',
    ...editorialHeroAdSizesPremium,
  },
  editorial_article_superheader: {
    adViewType: 'CONTENT_ARTICLE',
    ...editorialHeroAdSizes,
  },
  editorial_home: {
    adViewType: 'CONTENT_HOME',
    ...editorialHeroAdSizes,
  },
  editorial_category: {
    adViewType: 'CONTENT_CATEGORY',
    ...editorialHeroAdSizes,
  },
  editorial_tag: {
    adViewType: 'CONTENT_TAG',
    ...editorialHeroAdSizes,
  },
  editorial_series: {
    adViewType: 'CONTENT_SERIES',
    ...editorialHeroAdSizes,
  },
  editBody1: {
    adUnit: '/1024858/SL_Edit_Article_InCopy1',
    adId: 'SL_Edit_Article_InCopy1',
    adSizes: [
      [970, 90],
      [970, 250],
      [728, 90],
      [300, 250],
      [320, 50],
    ],
    adSizeMappings: [
      [
        [976, 250],
        [
          [970, 90],
          [970, 250],
          [728, 90],
        ],
      ],
      [
        [300, 250],
        [
          [300, 250],
          [320, 50],
        ],
      ],
    ],
    adSizeMappingsSHE: [
      [[992, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    additionalMappingSizes: [['medrec', [[320, 50]]]],
    adClass: 'sl-feed-ad__ad-body-1',
    adViewType: 'CONTENT_ARTICLE',
  },
  editBody2: {
    adUnit: '/1024858/SL_Edit_Article_InCopy2',
    adId: 'SL_Edit_Article_InCopy2',
    adSizes: [
      [970, 90],
      [970, 250],
      [728, 90],
      [300, 250],
    ],
    adSizeMappings: [
      [
        [976, 250],
        [
          [970, 90],
          [970, 250],
          [728, 90],
        ],
      ],
      [
        [300, 250],
        [300, 250],
      ],
    ],
    adSizeMappingsSHE: [
      [[976, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adClass: 'sl-feed-ad__ad-body-2',
    adViewType: 'CONTENT_ARTICLE',
  },
  editBody3: {
    adUnit: '/1024858/SL_Edit_Article_InCopy3',
    adId: 'SL_Edit_Article_InCopy3',
    adSizes: [
      [970, 90],
      [970, 250],
      [728, 90],
      [300, 250],
    ],
    adSizeMappings: [
      [
        [976, 250],
        [
          [970, 90],
          [970, 250],
          [728, 90],
        ],
      ],
      [
        [300, 250],
        [300, 250],
      ],
    ],
    adSizeMappingsSHE: [
      [[976, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adClass: 'sl-feed-ad__ad-body-3',
    adViewType: 'CONTENT_ARTICLE',
  },
  editFooter: {
    adUnit: '/1024858/SL_Edit_Article_Comments',
    adId: 'SL_Edit_Article_Comments',
    adSizes: [
      [300, 250],
      [300, 600],
    ],
    adSizeMappings: [
      [
        [976, 250],
        [300, 600],
      ],
      [
        [300, 250],
        [300, 250],
      ],
    ],
    adSizeMappingsSHE: [
      [[976, 0], 'flexrec'],
      [[0, 0], 'medrec'],
    ],
    adClass: 'sl-feed-ad__ad-comments',
    adViewType: 'CONTENT_ARTICLE',
  },
  editFooterBottom: {
    adUnit: '/1024858/SL_Edit_Article_Bottom',
    adId: 'SL_Edit_Article_Bottom',
    adSizes: [
      [970, 90],
      [970, 250],
      [728, 90],
      [300, 250],
    ],
    adSizeMappings: [
      [
        [976, 250],
        [
          [970, 90],
          [970, 250],
          [728, 90],
        ],
      ],
      [
        [300, 250],
        [300, 250],
      ],
    ],
    adSizeMappingsSHE: [
      [[976, 0], 'flexbanner'],
      [[0, 0], 'medrec'],
    ],
    adClass: 'sl-feed-ad__ad-bottom',
    adViewType: 'CONTENT_ARTICLE',
  },
  favoritesHorizontal: {
    adUnit: '/1024858/Mobile_Favorites_320x50',
    adId: 'mobile-favorites',
    adSizes: [[320, 50]],
    adSizeDesktop: [320, 50],
    adSizeMobile: [320, 50],
    adViewType: 'FAVORITES',
  },
  forecastFeedMobile: {
    adUnit: '/1024858/Forecast_Feed_Mobile',
    adId: 'Forecast_Feed_Mobile',
    adSize: 'medrec',
    adSizes: [300, 250],
    adSizeDesktop: [],
    adSizeMobile: [300, 250],
    adViewType: 'SUBREGION',
  },
  forecastFeedMobileBottom: {
    adUnit: '/1024858/Forecast_Feed_Mobile_Bottom',
    adId: 'Forecast_Feed_Mobile_Bottom',
    adSize: 'medrec',
    adSizes: [300, 250],
    adSizeDesktop: [],
    adSizeMobile: [300, 250],
    adViewType: 'SUBREGION',
  },
  legacyEditContent: {
    adUnit: '/1024858/Box',
    adId: 'div-gpt-ad-article-unit-3',
    adSize: 'medrec',
    adSizes: [[300, 250]],
    adSizeMappings: [
      [
        [300, 250],
        [300, 250],
      ],
    ],
    adClass: 'sl-feed-ad__ad-body',
    adViewType: 'CONTENT_ARTICLE',
  },
  'sl-multi-cam-companion': {
    adUnit: '/1024858/Position2',
    adId: 'Position2',
    adSize: 'medrec',
    adSizes: [[300, 250]],
    adSizeDesktop: [300, 250],
    adSizeMobile: [300, 250],
    adViewType: 'MULTI_CAM',
    adClass: 'sl-multi-cam-companion',
  },
  'sl-multi-cam-companion-mobile': {
    adUnit: '/1024858/Position2',
    adId: 'Position2',
    adSize: 'tinybanner',
    adSizes: [[320, 50]],
    adSizeDesktop: [320, 50],
    adSizeMobile: [320, 50],
    adViewType: 'MULTI_CAM',
    adClass: 'sl-multi-cam-companion-mobile',
  },
  sessionClipBottom: {
    adUnit: '/1024858/SL_Sessions_Bottom',
    adId: 'SL_Sessions_Bottom',
    adSizes: [
      [970, 90],
      [970, 250],
      [728, 90],
      [300, 250],
    ],
    adSizeDesktop: [
      [970, 90],
      [970, 250],
      [728, 90],
    ],
    adSizeMappingsSHE: [
      [[992, 0], 'flexbanner'],
      [[768, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adSizeMobile: [300, 250],
    adViewType: 'SESSIONS_CLIPS',
  },
  sponsoredArticleLogo: {
    adUnit: '/1024858/Logo',
    adId: 'Logo',
    adSizes: [[100, 40]],
    adSizeDesktop: [100, 40],
    adSizeMobile: [100, 40],
    adViewType: 'CONTENT_ARTICLE',
    customAdSize: [100, 40],
  },
  spotMiddle: {
    adUnit: '/1024858/SL_Cams_Middle',
    adId: 'SL_Cams_Middle',
    adSizes: [
      [970, 90],
      [970, 250],
      [728, 90],
      [300, 250],
    ],
    adSizeDesktop: [
      [970, 90],
      [970, 250],
      [728, 90],
    ],
    adSizeMappingsSHE: [
      [[992, 0], 'flexbanner'],
      [[768, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adSizeMobile: [300, 250],
    adViewType: 'SPOT',
    collapseEmpty: true,
  },
  stickyRightRail: {
    adUnit: '/1024858/SL_Forecast_ExpertContent_RightRail',
    adId: 'SL_Forecast_ExpertContent_RightRail',
    adSize: 'flexrec',
    adSizes: [
      [300, 250],
      [300, 600],
    ],
    adSizeMappings: [
      [
        [976, 250],
        [300, 600],
      ],
      [
        [300, 250],
        [300, 250],
      ],
    ],
    adClass: 'sl-feed-ad__ad-item',
    adViewType: 'FORECAST',
  },
  swellEventRight1: {
    adUnit: '/1024858/SL_Storm_Feed_Right1',
    adId: 'SL_Storm_Feed_Right1',
    adSize: 'medrec',
    adSizes: [[300, 250]],
    adSizeMappings: [
      [
        [300, 250],
        [300, 250],
      ],
    ],
    adClass: 'sl-swell-event-ad__ad-right1',
    adViewType: 'SWELL_EVENT',
  },
  swellEventRight2: {
    adUnit: '/1024858/SL_Storm_Feed_Right2',
    adId: 'SL_Storm_Feed_Right2',
    adSize: 'flexrec',
    adSizes: [
      [300, 250],
      [300, 600],
    ],
    adSizeMappings: [
      [
        [976, 250],
        [300, 600],
      ],
      [
        [300, 250],
        [300, 250],
      ],
    ],
    adClass: 'sl-swell-event-ad__ad-right2',
    adViewType: 'SWELL_EVENT',
  },
  swellEventHeroAd: {
    adUnit: '/1024858/SL_Storm_Feed_Top',
    adId: 'SL_Storm_Feed_Top',
    adClass: 'sl-horizontal-variable',
    adViewType: 'SWELL_EVENT',
    adSizes: [
      'fluid',
      [1170, 250],
      [1170, 160],
      [990, 90],
      [970, 250],
      [320, 50],
      [970, 90],
      [970, 160],
      [320, 80],
      [300, 250],
      [750, 250],
      [750, 160],
      [728, 90],
    ],
    adSizeMappings: [
      [
        [1200, 0],
        [
          'fluid',
          [1170, 250],
          [1170, 160],
          [990, 90],
          [970, 250],
          [970, 160],
          [970, 90],
          [728, 90],
        ],
      ],
      [
        [992, 0],
        ['fluid', [990, 90], [970, 250], [970, 160], [970, 90], [750, 250], [728, 90]],
      ],
      [
        [768, 0],
        ['fluid', [750, 250], [750, 160], [728, 90]],
      ],
      [
        [320, 0],
        ['fluid', [320, 80], [320, 50]],
      ],
      [
        [0, 0],
        ['fluid', [320, 50]],
      ],
    ],
    adSizeMappingsSHE: [
      [[992, 0], 'flexbanner'],
      [[768, 0], 'banner'],
      [[0, 0], 'tinybanner'],
    ],
    additionalMappingSizes: [
      ['flexbanner', [[1170, 250]]],
      ['banner', [[750, 250]]],
      ['tinybanner', [[320, 80]]],
    ],
    collapseEmpty: true,
  },
  homepageHeroAd: {
    adUnit: 'HP_Horizontal_Variable',
    adId: 'HP_Horizontal_Variable',
    adViewType: 'HOMEPAGE',
    adSizeMappings: [
      [
        [1200, 0],
        [
          'fluid',
          [1170, 250],
          [1170, 160],
          [990, 90],
          [970, 250],
          [970, 160],
          [970, 90],
          [728, 90],
        ],
      ],
      [
        [992, 0],
        ['fluid', [990, 90], [970, 250], [970, 160], [970, 90], [750, 250], [728, 90]],
      ],
      [
        [768, 0],
        ['fluid', [750, 250], [750, 160], [728, 90]],
      ],
      [
        [320, 0],
        ['fluid', [320, 80], [320, 50]],
      ],
      [
        [0, 0],
        ['fluid', [320, 50]],
      ],
    ],
    adSizeMappingsSHE: [
      [[992, 0], 'flexbanner'],
      [[768, 0], 'banner'],
      [[0, 0], 'tinybanner'],
    ],
    additionalMappingSizes: [
      ['flexbanner', [[1170, 250]]],
      ['banner', [[750, 250]]],
      ['tinybanner', [[320, 80]]],
    ],
    collapseEmpty: true,
    autoRefresh: false,
  },
  homepage_feed_top: {
    adUnit: '/1024858/SL_HP_Feed_Top',
    adId: 'SL_HP_Feed_Top',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeMappings: [
      [
        [1256, 90],
        [728, 90],
      ],
      [
        [976, 0],
        [300, 250],
      ],
      [
        [728, 0],
        [728, 90],
      ],
      [
        [0, 0],
        [300, 250],
      ],
    ],
    adSizeMappingsSHE: [
      [[1256, 90], 'banner'],
      [[976, 0], 'banner'],
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'FEED',
  },
  homepage_feed_bottom: {
    adUnit: '/1024858/SL_HP_Feed_Bottom',
    adId: 'SL_HP_Feed_Bottom',
    adSizes: [
      [300, 250],
      [728, 90],
    ],
    adSizeMappings: [
      [
        [1256, 90],
        [728, 90],
      ],
      [
        [976, 250],
        [300, 250],
      ],
      [
        [728, 90],
        [728, 90],
      ],
      [
        [0, 0],
        [300, 250],
      ],
    ],
    adSizeMappingsSHE: [
      [[1256, 0], 'banner'],
      [[976, 0], 'banner'],
      [[728, 0], 'banner'],
      [[0, 0], 'medrec'],
    ],
    adViewType: 'FEED',
  },
  slKbygHorizontalVariable: {
    adUnit: 'SL_KBYG_Horizontal_Variable',
    adId: 'SL_KBYG_Horizontal_Variable',
    adViewType: 'SPOT',
    adSizeMappings: [
      [
        [1200, 0],
        [
          'fluid',
          [1170, 250],
          [1170, 160],
          [990, 90],
          [970, 250],
          [970, 160],
          [970, 90],
          [728, 90],
        ],
      ],
      [
        [992, 0],
        ['fluid', [990, 90], [970, 250], [970, 160], [970, 90], [750, 250], [728, 90]],
      ],
      [
        [768, 0],
        ['fluid', [750, 250], [750, 160], [728, 90]],
      ],
      [
        [320, 0],
        ['fluid', [320, 80], [320, 50]],
      ],
      [
        [0, 0],
        ['fluid', [320, 50]],
      ],
    ],
    adSizeMappingsSHE: [
      [[992, 0], 'flexbanner'],
      [[768, 0], 'banner'],
      [[0, 0], 'tinybanner'],
    ],
    additionalMappingSizes: [
      ['flexbanner', [[1170, 250]]],
      ['banner', [[750, 250]]],
      ['tinybanner', [[320, 80]]],
    ],
    collapseEmpty: true,
    autoRefresh: true,
  },
  SL_Travel_Horizontal_Variable_NoProgrammatic: {
    adUnit: 'SL_Travel_Horizontal_Variable_NoProgrammatic',
    adId: 'SL_Travel_Horizontal_Variable_NoProgrammatic',
    adViewType: 'SPOT',
    adSizeMappings: [
      [
        [1200, 0],
        [
          'fluid',
          [1170, 250],
          [1170, 160],
          [990, 90],
          [970, 250],
          [970, 160],
          [970, 90],
          [728, 90],
        ],
      ],
      [
        [992, 0],
        ['fluid', [990, 90], [970, 250], [970, 160], [970, 90], [750, 250], [728, 90]],
      ],
      [
        [768, 0],
        ['fluid', [750, 250], [750, 160], [728, 90]],
      ],
      [
        [320, 0],
        ['fluid', [320, 80], [320, 50]],
      ],
      [
        [0, 0],
        ['fluid', [320, 50]],
      ],
    ],
    adSizeMappingsSHE: [
      [[992, 0], 'flexbanner'],
      [[768, 0], 'banner'],
      [[0, 0], 'tinybanner'],
    ],
    additionalMappingSizes: [
      ['flexbanner', [[1170, 250]]],
      ['banner', [[750, 250]]],
      ['tinybanner', [[320, 80]]],
    ],
    collapseEmpty: true,
    autoRefresh: true,
  },
};

export const subregionForecastAdBaseConfig = (subregionId, userEntitlements, isUser) => ({
  adTargets: [['subregionid', subregionId]],
  userEntitlements,
  isUser,
});

const sheAdTargetMap = {
  areaID: 'pub_meta_2',
  camId: 'pub_meta_1',
  contentCategoryId: 'pub_meta_7',
  contentKeyword: 'ci',
  contentSeriesId: 'pub_meta_8',
  contentTagId: 'pub_meta_9',
  regionID: 'pub_meta_3',
  spotid: ['directory', 'pub_meta_4'],
  sst: 'pub_meta_5',
  usertype: 'pub_meta_6',
  viewType: 'pub_meta_10',
};

const mapAdTargets = (adTargets, userType, viewType) => {
  const mappedAdTargets = adTargets.flatMap((target) => {
    const [key, value] = target;
    // eslint-disable-next-line
    const targetKey = sheAdTargetMap.hasOwnProperty(key) ? sheAdTargetMap[key] : key;
    if (Array.isArray(targetKey)) return targetKey.map((tk) => [tk, value]);
    return [[targetKey, value]];
  });

  return [
    ...mappedAdTargets,
    [sheAdTargetMap.usertype, userType],
    [sheAdTargetMap.viewType, viewType],
  ];
};

const getAdId = (adId, userType) => {
  if (
    (adId === 'SL_Cams_Middle' && userType === 'PREMIUM') ||
    (adId === 'SL_Sessions_Bottom' && userType === 'PREMIUM') ||
    (adId === 'SL_Forecast_ExpertContent_RightRail' && userType === 'PREMIUM') ||
    adId === 'HP_Horizontal_Variable' ||
    (adId === 'Horizontal_Variable' && userType === 'PREMIUM') ||
    (adId === 'SL_Forecast_ExpertContent_HorizontalVariable' && userType === 'PREMIUM') ||
    (adId === 'SL_Sessions_Horizontal_Variable' && userType === 'PREMIUM')
  ) {
    return `${adId}_NoProgrammatic`;
  }
  return adId;
};

const loadAdConfig = (
  adIdentifier,
  adTargets = [],
  entitlements = [],
  isUser = false,
  adViewType = null,
) => {
  const viewType = adViewType || adConfigMap[adIdentifier].adViewType;
  return {
    ...adConfigMap[adIdentifier],
    adId: getAdId(adConfigMap[adIdentifier]?.adId, getUserType(entitlements, isUser)),
    adTargets: mapAdTargets(adTargets, getUserType(entitlements, isUser), viewType),
    adViewType: viewType,
  };
};

export default loadAdConfig;
