import React from 'react';

interface Props {
  className?: string;
}

export const SwellIcon = ({ className }: Props) => (
  <svg
    className={className}
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="20" cy="20" r="19" stroke="#171717" strokeWidth="2" />
    <circle cx="20.0003" cy="19.9998" r="14.8333" stroke="#171717" strokeWidth="2" />
    <circle cx="19.9997" cy="20.0002" r="10.6667" stroke="#171717" strokeWidth="2" />
    <circle cx="20" cy="20" r="6.5" stroke="#171717" strokeWidth="2" />
    <circle cx="20.0003" cy="19.9998" r="2.33333" stroke="#171717" strokeWidth="2" />
    <path
      d="M20 40C22.6264 40 25.2272 39.4827 27.6537 38.4776C30.0802 37.4725 32.285 35.9993 34.1421 34.1421C35.9993 32.285 37.4725 30.0802 38.4776 27.6537C39.4827 25.2272 40 22.6264 40 20C40 17.3736 39.4827 14.7728 38.4776 12.3463C37.4725 9.91982 35.9993 7.71503 34.1421 5.85786C32.285 4.00069 30.0802 2.5275 27.6537 1.52241C25.2272 0.517314 22.6264 -9.89033e-07 20 -8.74228e-07L20 20L20 40Z"
      fill="#171717"
    />
  </svg>
);

export default SwellIcon;
