import type { TidePoint } from 'types/tide';
import { formatTimestampInTimezone } from 'utils/time';
import createDayHourlyData from './createDayHourlyData';

const createTideDayHourlyData = (data: TidePoint[], numDays = 16) => {
  if (data?.length <= 0) return [];
  const tideDays = createDayHourlyData(data, numDays + 1, 1) as TidePoint[][];
  tideDays.forEach((tideDay, dayIndex) => {
    tideDay.forEach((tideHour, hourIndex) => {
      if (
        hourIndex === 0 &&
        dayIndex > 0 &&
        tideDays[dayIndex - 1] &&
        tideHour &&
        formatTimestampInTimezone(tideHour.timestamp, tideHour.utcOffset, 'H') === '0'
      ) {
        // Add Midnight of the next day to the previous day for interpolaton and so that the
        // tide graph lines connect perfectly from day to day when shown side by side.
        // Note: we format the timestamp above to ensure its actually midnight
        tideDays[dayIndex - 1].push(tideHour);
      }
    });
  });
  return tideDays?.length > 0 ? tideDays.slice(0, numDays) : [];
};

export default createTideDayHourlyData;
